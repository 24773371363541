export class CatalogoSourvenirResponse {
   httpStatus : number;
   response : {
     id :  String,
     codeResponse :  String ,
     response :  String ,
     list : [
      {
         id :  String ,
         title :  String ,
         subtitle :  String ,
         groupId :  String ,
         groupName :  String ,
         topicId :  String ,
         topicName :  String ,
         price :  String ,
         quantity : String ,
         stock :  String ,
         image :  String ,
         total :  String 
      }]
    }
    constructor(){
        this.httpStatus = 0;
        this.response = {
          id :  "",
          codeResponse :  "",
          response :  "", 
          list : [
           {
              id :  "", 
              title :  "", 
              subtitle :  "", 
              groupId :  "" ,
              groupName :  "" ,
              topicId :  "" ,
              topicName :  "" ,
              price :  "" ,
              quantity : "" ,
              stock :  "" ,
              image :  "" ,
              total :  "" 
           }]
         }
    }
}
