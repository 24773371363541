import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from "@angular/common";
import { NgModule } from '@angular/core';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { SidebarModule } from 'ng-sidebar';

import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { AutocompleteLibModule} from 'angular-ng-autocomplete';
import { HttpClientModule} from "@angular/common/http";
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PaginaExternaComponent } from './pagina-externa/pagina-externa.component';
import { InicioComponent } from './pagina-externa/inicio/inicio.component';
import { HeaderComponent } from './pagina-externa/header/header.component';
import { FooterComponent } from './pagina-externa/footer/footer.component';
import { BannerComponent } from './pagina-externa/inicio/banner/banner.component';
import { CatalogoComponent } from './pagina-externa/inicio/catalogo/catalogo.component';
import { NovedadesComponent } from './pagina-externa/inicio/novedades/novedades.component';
import { DetalleComponent } from './pagina-externa/detalle/detalle.component';
import { InformacionEnvioComponent } from './pagina-externa/procesar-pago/informacion-envio/informacion-envio.component';
import { MedioPagoComponent } from './pagina-externa/procesar-pago/medio-pago/medio-pago.component';

import { MantCategoriaComponent } from './pagina-admin/dashboard/gestionLibros/mant-categoria/mant-categoria.component';

import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { CarritoComprasHeaderComponent } from './pagina-externa/header/carrito-compras-header/carrito-compras-header.component';
import { CarritocomprasComponent } from './pagina-externa/carritocompras/carritocompras.component';
import { NgxTextExpanderModule } from 'ngx-text-expander';
import { LoginComponent } from './pagina-externa/usuario/login/login.component';
import { RegistrarComponent } from './pagina-externa/usuario/registrar/registrar.component';
import { MiCuentaComponent } from './pagina-externa/usuario/mi-cuenta/mi-cuenta.component';
import { AngularDropdownModule } from 'angular-dropdown';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { NgStepperModule } from 'angular-ng-stepper';
import { ProcesarPagoComponent } from './pagina-externa/procesar-pago/procesar-pago.component';
import { ResulPagoPaymeComponent } from './pagina-externa/procesar-pago/resul-pago-payme/resul-pago-payme.component';
import { NgxLoadingModule,ngxLoadingAnimationTypes  } from 'ngx-loading';
import { LoginAdminComponent } from './pagina-admin/login-admin/login-admin.component';
import { DashboardComponent } from './pagina-admin/dashboard/dashboard/dashboard.component';
import { BandejaPedidoComponent } from './pagina-admin/dashboard/bandeja-pedido/bandeja-pedido.component';
import { CambioEstadoComponent } from './pagina-admin/dashboard/bandeja-pedido/cambio-estado/cambio-estado.component';
import { DetalleOrdenComponent } from './pagina-admin/dashboard/bandeja-pedido/detalle-orden/detalle-orden.component';
import { DmlCategoriaComponent } from './pagina-admin/dashboard/gestionLibros/mant-categoria/dml-categoria/dml-categoria.component';
import { DatosPersonalesComponent } from './pagina-externa/usuario/mi-cuenta/datos-personales/datos-personales.component';
import { MisPedidosComponent } from './pagina-externa/usuario/mi-cuenta/mis-pedidos/mis-pedidos.component';
import { ResulInternacionalComponent } from './pagina-externa/procesar-pago/resul-internacional/resul-internacional.component';
import { RecuperarContrasenaComponent } from './pagina-externa/usuario/recuperar-contrasena/recuperar-contrasena.component';
import { RecuperarContrasenaTokenComponent } from './pagina-externa/usuario/recuperar-contrasena-token/recuperar-contrasena-token.component';

//Módulos Redes Sociales
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';

import {
  GoogleLoginProvider,
  FacebookLoginProvider
} from 'angularx-social-login';
import { SourvenirComponent } from './pagina-externa/sourvenir/sourvenir.component';
import { BannerSourvenirComponent } from './pagina-externa/sourvenir/banner-sourvenir/banner-sourvenir.component';
import { NovedadesSourvenirComponent } from './pagina-externa/sourvenir/novedades-sourvenir/novedades-sourvenir.component';
import { CatalogoSourvenirComponent } from './pagina-externa/sourvenir/catalogo-sourvenir/catalogo-sourvenir.component';
import { DetalleSourvenirComponent } from './pagina-externa/detalle-sourvenir/detalle-sourvenir.component';
import { NgxImageZoomModule } from 'ngx-image-zoom';

//add angular material core
import { NgMaterialMultilevelMenuModule, MultilevelMenuService  } from 'ng-material-multilevel-menu';
import { NgxImgModule } from 'ngx-img';
import { MatDialogModule } from '@angular/material/dialog';

//administracion gestion
import { MantAniosComponent } from './pagina-admin/dashboard/gestionLibros/mant-anios/mant-anios.component';
import { DmlAnioComponent } from './pagina-admin/dashboard/gestionLibros/mant-anios/dml-anio/dml-anio.component';
import { MantAutorComponent } from './pagina-admin/dashboard/gestionLibros/mant-autor/mant-autor.component';
import { DmlAutorComponent } from './pagina-admin/dashboard/gestionLibros/mant-autor/dml-autor/dml-autor.component';
import { MantBookComponent } from './pagina-admin/dashboard/gestionLibros/mant-book/mant-book.component';
import { DmlBookComponent } from './pagina-admin/dashboard/gestionLibros/mant-book/dml-book/dml-book.component';
import { MatInventarioComponent } from './pagina-admin/dashboard/gestionLibros/mat-inventario/mat-inventario.component';
import { DmlInvetarioComponent } from './pagina-admin/dashboard/gestionLibros/mat-inventario/dml-invetario/dml-invetario.component';
import { BusAutoresComponent } from './pagina-admin/dashboard/bus-comunes/bus-autores/bus-autores.component';
import { BusEdicionesComponent } from './pagina-admin/dashboard/bus-comunes/bus-ediciones/bus-ediciones.component';

import { MantGruposComponent } from './pagina-admin/dashboard/gestionProductos/mant-grupos/mant-grupos.component';
import { DmlGruposComponent } from './pagina-admin/dashboard/gestionProductos/mant-grupos/dml-grupos/dml-grupos.component';
import { MantInventarioProdComponent } from './pagina-admin/dashboard/gestionProductos/mant-inventario-prod/mant-inventario-prod.component';
import { DmlInventarioProdComponent } from './pagina-admin/dashboard/gestionProductos/mant-inventario-prod/dml-inventario-prod/dml-inventario-prod.component';
import { DmlProductoComponent } from './pagina-admin/dashboard/gestionProductos/mant-producto/dml-producto/dml-producto.component';
import { MantProductoComponent } from './pagina-admin/dashboard/gestionProductos/mant-producto/mant-producto.component';
import { DmlSubmenuComponent } from './pagina-admin/dashboard/gestionProductos/mant-submenu/dml-submenu/dml-submenu.component';
import { MantSubmenuComponent } from './pagina-admin/dashboard/gestionProductos/mant-submenu/mant-submenu.component';
import { DmlTopicComponent } from './pagina-admin/dashboard/gestionProductos/mant-topic/dml-topic/dml-topic.component';
import { MantTopicComponent } from './pagina-admin/dashboard/gestionProductos/mant-topic/mant-topic.component';
import { BusConceptosComponent } from './pagina-admin/dashboard/bus-comunes/bus-conceptos/bus-conceptos.component';
import { ConfirmarComponent } from './comunes/confirmar/confirmar.component';
import { AddInventarioComponent } from './pagina-admin/dashboard/gestionProductos/mant-inventario-prod/dml-inventario-prod/add-inventario/add-inventario.component';
import { ConfirmarsionoComponent } from './comunes/confirmarsiono/confirmarsiono.component';

@NgModule({
  declarations: [
    AppComponent,
    PaginaExternaComponent,
    InicioComponent, 
    HeaderComponent, 
    FooterComponent, 
    BannerComponent, 
    CatalogoComponent, 
    NovedadesComponent, 
    DetalleComponent, 
    CarritoComprasHeaderComponent, 
    CarritocomprasComponent, LoginComponent, RegistrarComponent, MiCuentaComponent, ProcesarPagoComponent,InformacionEnvioComponent,MedioPagoComponent, ResulPagoPaymeComponent, LoginAdminComponent, 
    DashboardComponent,BandejaPedidoComponent, CambioEstadoComponent, DetalleOrdenComponent, DatosPersonalesComponent, MisPedidosComponent,ResulInternacionalComponent, RecuperarContrasenaComponent, RecuperarContrasenaTokenComponent, SourvenirComponent, BannerSourvenirComponent, NovedadesSourvenirComponent, CatalogoSourvenirComponent, DetalleSourvenirComponent,
    MantCategoriaComponent,
    DmlCategoriaComponent,
    MantAniosComponent,
    DmlAnioComponent,
    MantAutorComponent,
    DmlAutorComponent,
    MantBookComponent,
    DmlBookComponent,
    MatInventarioComponent,
    DmlInvetarioComponent,
    BusAutoresComponent,
    BusEdicionesComponent,
    MantGruposComponent,
    DmlGruposComponent,
    MantInventarioProdComponent,
    DmlInventarioProdComponent,
    DmlProductoComponent,
    MantProductoComponent,
    DmlSubmenuComponent,
    MantSubmenuComponent,
    DmlTopicComponent,
    MantTopicComponent,
    BusConceptosComponent,
    ConfirmarComponent,
    AddInventarioComponent,
    ConfirmarsionoComponent 
    //DashboardComponent,BandejaPedidoComponent, CambioEstadoComponent, DetalleOrdenComponent, ResulInternacionalComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    NgbPaginationModule,
    AppRoutingModule,
    CdkStepperModule,
    NgStepperModule,
    NgxTextExpanderModule,
    AccordionModule.forRoot(),
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(),
    SidebarModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    SlickCarouselModule,
    AutocompleteLibModule,
    HttpClientModule,
    MDBBootstrapModule.forRoot(),
    AngularDropdownModule,
    NgxLoadingModule.forRoot({ animationType: ngxLoadingAnimationTypes.wanderingCubes,fullScreenBackdrop:true}),
    SocialLoginModule,
    NgxImageZoomModule,
    NgxImgModule,
    MatDialogModule,
    NgMaterialMultilevelMenuModule, // angular menu    
  ],
  providers: [MultilevelMenuService,
    {provide : LocationStrategy,useClass: HashLocationStrategy},
    {
    provide: 'SocialAuthServiceConfig',
    useValue: {
      autoLogin: false,
      providers: [
        {
          id: GoogleLoginProvider.PROVIDER_ID,
          provider: new GoogleLoginProvider(
            //DEV '432752363268-uqau86r6ldpn72o5p29ctohg98lkgcre.apps.googleusercontent.com'
            '841789505243-l1ou206r472m4dh4h78sgknbfjjoels2.apps.googleusercontent.com'
            )
        },
        {
          id: FacebookLoginProvider.PROVIDER_ID,          
          provider: new FacebookLoginProvider('114559373733318')
          //DEV provider: new FacebookLoginProvider('727484904844760')
        }
      ]
    } as SocialAuthServiceConfig,
    
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
