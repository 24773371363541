import { AutorAdminListBean } from './autorAdminBean';

export class AutorAdminListResponse{
    httpStatus: String;
    response: {
        id: String,
        codeResponse: String,
        response: String,
        list: Array<AutorAdminListBean>
    }

    constructor(){
        this.httpStatus="0";
        this.response={
            id:"",
            codeResponse:"",
            response:"",
            list:[]
        }
      }
      
}