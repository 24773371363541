<!--Header-->
<div class="modal-header text-center" style="color: white;background-color: #544d84">    
    <h4 class="modal-title w-100">{{x_titulo}}</h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onClosemodal()">
      <span aria-hidden="true" class="white-text">&times;</span>
    </button>
</div>
  
<!--Body-->
<div class="modal-body">

  <mdb-card>
    <mdb-card-body>

      <div class="form-row">
        <div class="col-3">Código:</div>
        <div class="col-9">
            {{id}}
        </div>
      </div>
      <br>
      <div class="form-row">
        <div class="col-3">Titulo:</div>
        <div class="col-9">                
          <input mdbInput type="text" maxlength="200"  class="form-control" [(ngModel)]="txtTitle" placeholder="Ingrese titulo">
        </div>
      </div>
      <br>
      <div class="form-row">
        <div class="col-3">Sub Titulo:</div>
        <div class="col-9">                
          <input mdbInput type="text" maxlength="200"  class="form-control" [(ngModel)]="txtSubTitle" placeholder="Ingrese sub titulo">
        </div>
      </div>
      <br>
      <div class="form-row">
        <div class="col-3">Descripción:</div>
        <div class="col-9">                
          <input mdbInput type="text" maxlength="200"  class="form-control" [(ngModel)]="txtDescription" placeholder="Ingrese descripción">
        </div>
      </div>
      <br>
      <div class="form-row">
        <div class="col-3">Total de Páginas:</div>
        <div class="col-9">                
          <input mdbInput type="text" maxlength="5" (keypress)="fc_ValNumber($event)" class="form-control" [(ngModel)]="txtPages" placeholder="Ingrese total de páginas">
        </div>
      </div>
      <br>
      <div class="form-row">
        <div class="col-3">Peso:</div>
        <div class="col-9">                
          <input mdbInput type="text" maxlength="5" style="width: 50%;float:left;" (keypress)="fc_ValNumber($event)" class="form-control" [(ngModel)]="txtWeight" placeholder="Ingrese peso"> <span style="float:left;padding-left: 10px;padding-top: 5px;">(g)</span>
        </div>
      </div>
      <br>
      <div class="form-row">
        <div class="col-3">ISBN:</div>
        <div class="col-9">                
          <input mdbInput type="text" maxlength="200"  class="form-control" [(ngModel)]="txtIsbn" placeholder="Ingrese ISBN">
        </div>
      </div>
      <br>
      <div class="form-row">
        <div class="col-3">ISNN:</div>
        <div class="col-9">                
          <input mdbInput type="text" maxlength="200"  class="form-control" [(ngModel)]="txtIsnn" placeholder="Ingrese ISNN">      
        </div>
      </div>
      <br>
      <div class="form-row">
        <div class="col-3">Dimensiones:</div>
        <div class="col-9">                
          <input mdbInput type="text" maxlength="200"  class="form-control" [(ngModel)]="txtMeasure" placeholder="Ingrese las dimensiones del libro, ejemplo: 20x15 cm... ">
        </div>
      </div>
      <br>  
      <div class="form-row">
        <div class="col-3">Categoria:</div>
        <div class="col-9">                
            <select class="browser-default custom-select" [(ngModel)]="cboCategoryId">
                <option value="" selected>Seleccione</option>
                <option *ngFor="let listCategory of listCategory.response.list" [value]="listCategory.id">{{listCategory.name}}</option>
            </select>
        </div>
      </div>
      <br>  
      <div class="form-row">
        <div class="col-3">Tipo publicación:</div>
        <div class="col-9">                
            <select class="browser-default custom-select" [(ngModel)]="cboPublicationId">
                <option value="" selected>Seleccione</option>
                <option *ngFor="let listPublication of listPublication.response.list" [value]="listPublication.masterId">{{listPublication.masterName}}</option>
            </select>
        </div>
      </div>
      <br>      
      <div class="form-row">
        <div class="col-3">Colocar en Novedades:</div>
        <div class="col-9">
            <div class="px-4">
                <mdb-checkbox [(ngModel)]="txtNovelty" class="check-input"></mdb-checkbox>
            </div>        
        </div>
      </div>  
      <br>
      <div class="form-row">
        <div class="col-3">Orden:</div>
        <div class="col-9">                
          <input mdbInput type="number" pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==3) return false;" min="1" max="999" class="form-control" [(ngModel)]="txtOrder" id="txtOrder">
        </div>
      </div>
      <br>
      <div class="form-row">
        <div class="col-3">Estado:</div>
        <div class="col-9">                
            <select class="browser-default custom-select" [(ngModel)]="cboEnabled">
                <option value="" selected>Seleccione</option>
                <option *ngFor="let estado of listEstado.response.list" [value]="estado.masterName">{{estado.masterDescription}}</option>
            </select>
        </div>
      </div>
      <br>      
    </mdb-card-body>
  </mdb-card>
  <br>
  <div class="form-row">
    <div class="col-12">
      <mdb-card>
        <mdb-card-body>
          <button mdbBtn type="button" (click)="onAgregarAutor()" size="sm" class="btn btn-deep-purple btn-rounded" mdbWavesEffect>Agregar Autor</button>
          <div class="table-responsive text-nowrap">
            <table mdbTable hover="true">
              <thead>
                <tr>
                  <th *ngFor="let head of headAutor" scope="col">{{head}}</th>
                </tr>
              </thead>
              <tbody>
                <tr mdbTableCol *ngFor="let listAutor of listAutor;let i = index">
                  <th>{{i + 1}}</th>
                  <th>{{listAutor.name}}</th>
                  <td><button type="button" color="link" (click)="onDeleteAutor(listAutor)" class="btn-sm" mdbWavesEffect mdbBtn><mdb-icon fas icon="minus-square" size="2x" class="indigo-text pr-3"></mdb-icon></button></td>
                </tr>
              </tbody>
            </table>
          </div>
        </mdb-card-body>
      </mdb-card>
    </div>
  </div>
  <br>
  <div class="form-row">
    <div class="col-12">
      <mdb-card>
        <mdb-card-body>
          <button mdbBtn type="button" (click)="onAgregarEdicion()" size="sm" class="btn btn-deep-purple btn-rounded" mdbWavesEffect>Agregar Año de Publicación</button>
          <div class="table-responsive text-nowrap">
            <table mdbTable hover="true">
              <thead>
                <tr>
                  <th *ngFor="let head of headEdicion" scope="col">{{head}}</th>
                </tr>
              </thead>
              <tbody>
                <tr mdbTableCol *ngFor="let listEdicion of listEdicion;let i = index">
                  <th>{{i + 1}}</th>
                  <th>{{listEdicion.name}}</th>
                  <td><button type="button" color="link" (click)="onDeleteEdicion(listEdicion)" class="btn-sm" mdbWavesEffect mdbBtn><mdb-icon fas icon="minus-square" size="2x" class="indigo-text pr-3"></mdb-icon></button></td>
                </tr>
              </tbody>
            </table>
          </div>
        </mdb-card-body>
      </mdb-card>
    </div>
  </div>
  <br>  
  <mdb-card>
    <mdb-card-body>
        <div class="form-row">
          <div class="col-6">
            <div class="text-center">
              <span>Seleccionar o Reemplazar Imagen</span><br>
              <span style="font-size: 11px;">Tamaño en pixeles sugerido, entre 950 x 1400 de altura,máximo 1mb.</span>
              <ngx-img (onSelect)="onSelect($event)" (onReset)="onReset()">
              </ngx-img>
            </div>
          </div>
          <div class="col-6">
            <div class="text-center">
              <span style="display: block;">Imagen Actual</span><br>
              <img [src]="x_url" alt="Ninguno" class="img-thumbnail" style="height: 400px;">
            </div>
          </div>    
        </div>
    </mdb-card-body>
  </mdb-card>    
  <br>
  <!--Footer-->
<div class="modal-footer justify-content-center">
    <button mdbBtn type="button" (click)="onGrabar()" size="sm" class="btn btn-deep-purple btn-rounded" mdbWavesEffect>
        <mdb-icon fas icon="save" class="mr-1"></mdb-icon>Grabar</button>
</div>
