import { Component, OnInit } from '@angular/core';
import { UtilitarioService } from 'src/app/service/utilitarios/utilitario.service';

import { conceptoPagoListResponse } from 'src/app/modal/conceptoPago/conceptoPagoListResponse';
import { ConceptoPagoBean } from 'src/app/modal/conceptoPago/concepto-pago-bean';
import { InventLibAsignadosBeanRequest } from 'src/app/modal/inventario-libro/inventarioLibBean';
import { ConceptoPagoService } from 'src/app/service/conceptoPago/concepto-pago.service';
import { InventarioLibService } from 'src/app/service/inventario-lib/inventario-lib.service';
import { ConfirmarComponent } from 'src/app/comunes/confirmar/confirmar.component';
import { MDBModalRef } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: 'app-bus-conceptos',
  templateUrl: './bus-conceptos.component.html',
  styleUrls: ['./bus-conceptos.component.scss']
})
export class BusConceptosComponent implements OnInit {

  constructor(private conceptoPagoService:ConceptoPagoService,
    private utilitarioService: UtilitarioService,
    private inventarioLibService:InventarioLibService,
    public  modalRef: MDBModalRef, 
    private dialogo: MatDialog,
    ) { }

  headElements = ['Código','Concepto','Moneda','Precio','Accion'];
  txtdescripcion_bus:String = "";
  txtCodigoSigaSec:String = "";
  listConceptos:conceptoPagoListResponse = new conceptoPagoListResponse();
  listLibrosAsignados: InventLibAsignadosBeanRequest[];
  //variables del padre
  x_titulo:String;
  x_tipo_concepto:String;
  //action del padre para el retorno de datos
  action: Subject<ConceptoPagoBean> = new Subject();

  page = 1;
  pageSize = 15;

  ngOnInit(): void {
    this.cargarDatoInicial();  
  }

  onBuscar(){

    if (this.x_tipo_concepto == 'PRODUCTO') {
      this.utilitarioService.loading = true;
      this.conceptoPagoService.obtenerAdminListProducto(this.txtCodigoSigaSec.toString(), this.txtdescripcion_bus.toString()).subscribe(data=>{
        this.listConceptos = data;      
        this.utilitarioService.loading = false;
      })
    }else{ //LIBROS
      this.utilitarioService.loading = true;
      this.conceptoPagoService.obtenerAdminListLibro(this.txtCodigoSigaSec.toString(), this.txtdescripcion_bus.toString()).subscribe(data=>{
        this.listConceptos = data;      
        this.utilitarioService.loading = false;
      })
    }

  }

  onSeleccionar(conceptoPagoBean:ConceptoPagoBean){
    console.log(conceptoPagoBean);
    this.utilitarioService.loading = true;
    this.inventarioLibService.verifyConceptosSiga(conceptoPagoBean.cincodigo.toString(), conceptoPagoBean.idstpcodigo.toString()).subscribe(data=>{
      //this.listConceptos = data;
      this.utilitarioService.loading = false;
      this.listLibrosAsignados = data.response.list;
      if (this.listLibrosAsignados.length > 0){

        this.dialogo.open(ConfirmarComponent, {
          data: { mensaje: 'Este concepto de pago ya se encuentra asignado en otro producto, desea continuar ?', libros:this.listLibrosAsignados},
          width: '550px'
        })
        .afterClosed()
        .subscribe((confirmado: Boolean) => {
          if (confirmado) {
            // validar con el servicio segun concepto            
            this.action.next(conceptoPagoBean);            
            this.onClosemodal();
          } else {
            return;
          }
        });
        
      }else{
        this.action.next(conceptoPagoBean);        
        this.onClosemodal();
      }
      
    })    

  }

  onClosemodal(){
    this.modalRef.hide();
  }

  cargarDatoInicial(){
    this.onBuscar();
  }

}
