export class RespuestaComun {
    httpStatus:number;
    response: {
      id:string,
      codeResponse:string,
      response:string,
      list: [
        {
          masterId:string,
          masterName:string,
          masterDescription:string,
          masterValue:string
        }
    ]}
    constructor(){
        this.httpStatus=0;
        this.response= {
        id:"",
        codeResponse:"",
        response:"",
        list: [
            {
            masterId:"",
            masterName:"",
            masterDescription:"",
            masterValue:""
            }
        ]}
    }
}
