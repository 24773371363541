import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})

export class UtilitarioService {
  
  loading:Boolean = false;
  constructor(private toastrService:ToastrService) { }

  toastrInfo(mensaje:String){
    this.toastrService.info(mensaje.toString());
  }
  toastrSuccess(mensaje:String){
    this.toastrService.success(mensaje.toString());
  }
  toastrWarning(mensaje:String){
    this.toastrService.warning(mensaje.toString());
  }
  toastrError(mensaje:String){
    this.toastrService.error(mensaje.toString());
  }

}
