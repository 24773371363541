<!--Header-->
<div class="modal-header text-center" style="color: white;background-color: #544d84;">    
    <h4 class="modal-title w-100">Detalle de Orden</h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onClosemodal()">
      <span aria-hidden="true" class="white-text">&times;</span>
    </button>
</div>

<!--Body-->
<div class="modal-body">        
    <mdb-card>
        <mdb-card-body>
              <mdb-card-title>
                <h5>Datos de Compra</h5>
              </mdb-card-title>
              <mdb-card-text>
                <br>
                <div class="form-group row">
                    <!-- Default input -->
                    <label class="col-sm-2 col-form-label">Nro Orden</label>
                    <div class="col-sm-4">
                      <input mdbInput type="text" readonly [value]="listCabecera.response.list[0].number"  class="form-control">
                    </div>
                    <label class="col-sm-2 col-form-label">Fecha</label>
                    <div class="col-sm-4">
                      <input mdbInput type="text" readonly [value]="listCabecera.response.list[0].date"  class="form-control">
                    </div>
                </div>                                
                <div class="form-group row">
                    <label class="col-sm-2 col-form-label">Región</label>
                    <div class="col-sm-4">
                      <input mdbInput type="text" readonly [value]="listCabecera.response.list[0].regionType"  class="form-control">
                    </div>
                    <label class="col-sm-2 col-form-label">Tipo Envío</label>
                    <div class="col-sm-4">
                      <input mdbInput type="text" readonly [value]="listCabecera.response.list[0].deliveryType"  class="form-control">
                    </div>                    
                </div>
                <div class="form-group row">
                    <label class="col-sm-2 col-form-label">País</label>
                    <div class="col-sm-4">
                      <input mdbInput type="text" readonly [value]="listCabecera.response.list[0].countryName"  class="form-control">
                    </div>
                    <label class="col-sm-2 col-form-label">Dirección</label>
                    <div class="col-sm-4">
                      <input mdbInput type="text" readonly [value]="listCabecera.response.list[0].countryAddress"  class="form-control">
                    </div>                    
                </div>
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">Correo</label>
                  <div class="col-sm-4">
                    <input mdbInput type="text" readonly [value]="listCabecera.response.list[0].clientEmail"  class="form-control">
                  </div>
                  <label class="col-sm-2 col-form-label">Contacto</label>
                  <div class="col-sm-4">
                    <input mdbInput type="text" readonly [value]="listCabecera.response.list[0].contactName"  class="form-control">
                  </div>                    
              </div>
                <div class="form-group row">
                    <label class="col-sm-2 col-form-label">Teléfono</label>
                    <div class="col-sm-4">
                      <input mdbInput type="text" readonly [value]="listCabecera.response.list[0].contactCellphone"  class="form-control">
                    </div> 
                    <label class="col-sm-2 col-form-label">Estado</label>
                    <div class="col-sm-4">
                      <input mdbInput type="text" readonly [value]="listCabecera.response.list[0].stateName"  class="form-control">
                    </div>                                 
                </div>                
              </mdb-card-text>
              <br>
              <mdb-card-title>
                <h5>Comprobante</h5>
              </mdb-card-title>
              <mdb-card-text>
                <br>
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">Nro. Voucher</label>
                  <div class="col-sm-4">
                    <input mdbInput type="text" readonly [value]="listCabecera.response.list[0].voucherNro"  class="form-control">
                  </div>
                  <label class="col-sm-2 col-form-label">Tipo</label>
                  <div class="col-sm-4">
                    <input mdbInput type="text" readonly [value]="listCabecera.response.list[0].voucherType"  class="form-control">
                  </div>                    
                </div> 
                <div class="form-group row" *ngIf="listCabecera.response.list[0].voucherType.toUpperCase().includes('BOLETA')">
                  <label class="col-sm-2 col-form-label">Cliente</label>
                  <div class="col-sm-4">
                    <input mdbInput type="text" readonly [value]="listCabecera.response.list[0].voucherName"  class="form-control">
                  </div>
                  <label class="col-sm-2 col-form-label">Nro. DNI</label>
                  <div class="col-sm-4">
                    <input mdbInput type="text" readonly [value]="listCabecera.response.list[0].voucherDni"  class="form-control">
                  </div>                    
                </div>
                <div class="form-group row" *ngIf="listCabecera.response.list[0].voucherType.toUpperCase().includes('FACTURA')">
                  <label class="col-sm-2 col-form-label">Razón Social</label>
                  <div class="col-sm-4">
                    <input mdbInput type="text" readonly [value]="listCabecera.response.list[0].voucherRazonSocial"  class="form-control">
                  </div>
                  <label class="col-sm-2 col-form-label">Nro. RUC</label>
                  <div class="col-sm-4">
                    <input mdbInput type="text" readonly [value]="listCabecera.response.list[0].voucherRuc"  class="form-control">
                  </div>                    
                </div>                
              </mdb-card-text>
        </mdb-card-body>
    </mdb-card>
    <br>
    <mdb-card>
        <mdb-card-body>
            <mdb-card-title>
                <h5>Detalle de Productos</h5>
              </mdb-card-title>
              <mdb-card-text>
                <br>
                <table mdbTable bordered="true" hover="true">
                    <thead>
                      <tr>
                        <th *ngFor="let head of headElements" scope="col">{{head}} </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr mdbTableCol *ngFor="let detalle of listDetalle.response.list">
                        <th>{{detalle.bookTitle}} - <i> {{detalle.bookSubtitle}} </i> </th>
                        <td>{{detalle.orderDetailQuantity}}</td>
                        <td>S/ {{detalle.bookPrice}}</td>
                        <!-- <td>{{detalle.orderDetailSubtotal}}</td> -->
                      </tr>
                    </tbody>
                </table>                
              </mdb-card-text>
        </mdb-card-body>
    </mdb-card>
    <br>
    <mdb-card>
        <mdb-card-body>
            <mdb-card-title>
                <h5>Totales</h5>
              </mdb-card-title>
              <mdb-card-text>
                <div class="form-group row">
                    <!-- Default input -->
                    <label class="col-sm-2 col-form-label">Cantidad</label>
                    <div class="col-sm-4">
                      <input mdbInput type="text" readonly [value]="listCabecera.response.list[0].quantity"  class="form-control">
                    </div>
                    <label class="col-sm-2 col-form-label">Sub Total</label>
                    <div class="col-sm-4">
                      <input mdbInput type="text" readonly [value]="'S/ '+listCabecera.response.list[0].subtotal"  class="form-control">
                    </div>
                </div>
                <div class="form-group row">
                    <!-- Default input -->
                    <label class="col-sm-6 col-form-label"></label>

                   

                    <label class="col-sm-2 col-form-label">Delivery</label>
                    <div class="col-sm-4">
                      <input mdbInput type="text" readonly [value]="'S/ '+listCabecera.response.list[0].shipping"  class="form-control">
                    </div>
                </div>
                <div class="form-group row">
 
                    <label class="col-sm-6 col-form-label"></label>
                    <label class="col-sm-2 col-form-label">IGV</label>
                    <div class="col-sm-4">
                      <input mdbInput type="text" readonly [value]="'S/ '+listCabecera.response.list[0].igv"  class="form-control">
                    </div>
                </div>
                <div class="form-group row">

                    <label class="col-sm-6 col-form-label"></label>
                    <label class="col-sm-2 col-form-label">Total</label>
                    <div class="col-sm-4">
                      <input mdbInput type="text" readonly [value]="'S/ '+listCabecera.response.list[0].total"  class="form-control">
                    </div>
                </div>
              </mdb-card-text>
        </mdb-card-body>
    </mdb-card>
</div>
  <!--Footer-->
<div class="modal-footer justify-content-center">
</div>