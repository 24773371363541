import { Injectable } from '@angular/core';
import { catchError, map, timeout } from "rxjs/operators";
import { Observable, throwError, TimeoutError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { PersonaBeanM,RucEntidadBeanMResponse,RequestCredenciales } from '../../modal/externo/externo';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import {  } from 'src/app/modal/externo/externo';

@Injectable({
  providedIn: 'root'
})
export class ExternosService {

  constructor(private httpclient:HttpClient) { }  
  baseURLGestionUsuario = environment.apiURLGestionUsuario;

  busDocumentoIdentidad(formData:FormData): Observable<PersonaBeanM> { 

    var url : string;
    url = this.baseURLGestionUsuario + 'buscarxdocumentoidentidad';    
    let headers = new HttpHeaders();    
    return this.httpclient.post<PersonaBeanM>(url,formData).pipe(timeout(3000),catchError(this.handleError));;

  }

  busDocumentoRuc(formData:FormData): Observable<RucEntidadBeanMResponse> { 

    var url : string;
    url = this.baseURLGestionUsuario + 'buscarxruc';    
    let headers = new HttpHeaders();    
    return this.httpclient.post<RucEntidadBeanMResponse>(url,formData).pipe(timeout(3000),catchError(this.handleError));;

  }

  getCredencialesGestionUsuario(){
    let requestCredenciales = new RequestCredenciales();    
    
    requestCredenciales.usuario = environment.usuarioGestionUsuario;
    requestCredenciales.clave = environment.claveGestionUsuario;    
    return requestCredenciales;
  }

  handleError(err){
    if (err instanceof TimeoutError) {            
      return throwError('Timeout Exception');
    }        
    console.log(err);
    return throwError(err);
  }
  
}
