import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";
import { environment } from '../../../environments/environment';
import { AutorAdminListResponse } from '../../modal/autor/autorAdminListResponse';
import { AutorAdminRegIdResponse } from '../../modal/autor/autorAdminRegIdResponse';
import { AutorAdminDmlResponse } from '../../modal/autor/autorAdminDmlResponse';
import { AutorAdminBeanInsRequest,AutorAdminBeanUpdRequest } from '../../modal/autor/autorAdminBean';
import { HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AutorService {

  constructor(private httpClient:HttpClient) { }

  obtenerAdminList(nombre:string,estado:string){
    let url = `${environment.urlBase}${environment.listAutorAdmin}?nombre=${nombre}&estado=${estado}`;
    //console.log("url",url);
    return this.httpClient.get<AutorAdminListResponse>(url).pipe(
      map(data => {   
        let response: AutorAdminListResponse;
        response = data;
        return response;
      },
      error =>{      
        return new Response("9999", error);
      })) 
  }

  obtenerRegistro(id:String){
    let url = `${environment.urlBase}${environment.getAutorAdminId}/${id}`;
    //console.log("url",url);
    return this.httpClient.get<AutorAdminRegIdResponse>(url).pipe(
      map(data => {   
        let response: AutorAdminRegIdResponse;
        response = data;
        return response;
      },
      error =>{      
        return new Response("9999", error);
      })) 
  }

  grabar(autorAdminBeanInsRequest:AutorAdminBeanInsRequest){
    let url = `${environment.urlBase}${environment.insertAutorAdmin}`;
    return this.httpClient.post<AutorAdminDmlResponse>(url,autorAdminBeanInsRequest).pipe(
      map(data => {
        let response: AutorAdminDmlResponse;
        response = data;        

        return response;        
      },
      error =>{      
        return new Response("9999", error);
      }) 
    )
  }

  update(autorAdminBeanUpdRequest:AutorAdminBeanUpdRequest,id:String){
    let url = `${environment.urlBase}${environment.updateAutorAdmin}/${id}`;
    return this.httpClient.put<AutorAdminDmlResponse>(url,autorAdminBeanUpdRequest).pipe(
      map(data => {
        let response: AutorAdminDmlResponse;
        response = data;        
        return response;        
      },
      error =>{      
        return new Response("9999", error);
      }) 
    )
  }
  
}
