import { TopicListBean } from './topicBean';

export class TopicListResponse{
    httpStatus: String;
    response: {
        id: String,
        codeResponse: String,
        response: String,
        list: Array<TopicListBean>
    }

    constructor(){
        this.httpStatus="0";
        this.response={
            id:"",
            codeResponse:"",
            response:"",
            list:[]
        }
      }
      
}