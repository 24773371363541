import { Component, OnInit } from '@angular/core';
import { UtilitarioService } from 'src/app/service/utilitarios/utilitario.service';
import { AutorAdminListResponse } from 'src/app/modal/autor/autorAdminListResponse';
import { AutorAdminListBean } from 'src/app/modal/autor/autorAdminBean';

import { AutorService } from 'src/app/service/autor/autor.service';
import { MDBModalRef } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-bus-autores',
  templateUrl: './bus-autores.component.html',
  styleUrls: ['./bus-autores.component.scss']
})
export class BusAutoresComponent implements OnInit {

  constructor(private autorService:AutorService,
    private utilitarioService: UtilitarioService,
    public  modalRef: MDBModalRef, 
    ) { }

  headElements = ['Autor','Acción'];
  txtdescripcion_bus:String = "";
  listAutor:AutorAdminListResponse = new AutorAdminListResponse();
  
  //variables del padre
  x_titulo:String;
  //action del padre para el retorno de datos
  action: Subject<AutorAdminListBean> = new Subject();

  page = 1;
  pageSize = 9;

  ngOnInit(): void {
    this.cargarDatoInicial();  
  }

  onBuscar(){
    this.utilitarioService.loading = true;
    this.autorService.obtenerAdminList(this.txtdescripcion_bus.toString(), '1').subscribe(data=>{
      this.listAutor = data;      
      this.utilitarioService.loading = false;
    })
  }

  onSeleccionar(objetoAutor:AutorAdminListBean){    
    this.action.next(objetoAutor);
    this.modalRef.hide();
  }

  onClosemodal(){
    this.modalRef.hide();
  }

  cargarDatoInicial(){

    this.onBuscar();

  }

}
