export class InventarioLibListBean{
    inventoryId:String;
    inventoryState:String;
    sigaId:String;
    sigaSecuencia:String;
    bookId:String;
    bookTitle:String;
    bookSubtitle:String;
    bookImage:String;
    authorId:String;
    authorName:String;
    publicationId:String;
    publicationName:String;
    materialId:String;
    materialName:String;
    categoryId:String;
    categoryName:String;
    yearId:String;
    yearName:String;
    inventoryPrice:String;
    inventoryStock:String;
    inventoryStockBoolean:String;
    inventoryDescription:String;
    inventoryCurrency:String;
    inventoryStockOrigin:String;
    usuarioModificaId:String;
    usuarioCrearId:String;
    enabled: String;
}

export class InventarioLibIdBean{
    inventoryId: String;
    inventoryState: String;
    sigaId: String;
    sigaSecuencia: String;
    bookId: String;
    bookTitle: String;
    bookSubtitle: String;
    bookImage: String;
    authorId: String;
    authorName: String;
    publicationId: String;
    publicationName: String;
    materialId: String;
    materialName: String;
    categoryId: String;
    categoryName: String;
    yearId: String;
    yearName: String;
    inventoryPrice: String;
    inventoryStock: String;
    inventoryStockBoolean: String;
    inventoryDescription: String;
    inventoryCurrency: String;
    inventoryStockOrigin: String;
    usuarioModificaId: String;
    usuarioCrearId: String;
    enabled: String;    
}

export class InventarioLibBeanInsRequest{
    bookId: String;
    currency: String;
    description: String;
    enabled: String;
    inventoryId: String;
    materialId: String;
    price: String;
    sigaId: String;
    sigaSecuencia: String;
    stock: String;
    stockOrigin: String;
    usuarioCrearId: String;
}

export class InventarioLibBeanUpdRequest{    
    bookId: String;
    currency: String;
    description: String;
    enabled: String;
    inventoryId: String;
    materialId: String;
    price: String;
    sigaId: String;
    sigaSecuencia: String;
    stock: String;
    stockOrigin: String;
    usuarioModificaId: String;
}

export class InventLibAsignadosBeanRequest{ 
    inventoryId: String;
    inventoryDescription: String;
    bookId: String;
    bookTitle: String;
    productId: String;
    productTitle: String;
}