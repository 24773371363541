<div class="container">
    <div class="row mt-5 mb-5">
        <div class="col-sm-3 MenuDatos">
            <p *ngIf="datosUsuario.response.list[0].photo">
                <img class="imagenPersona"  src="{{datosUsuario.response.list[0].photo}}" />
            </p>
            <p *ngIf="!datosUsuario.response.list[0].photo">
                <img   src="/assets/image/portafolio/iconUser.png" />
            </p>
            <p>
                <span class="font-weight-bold">Mi cuenta</span><br>
                <span>Hola, <span class="font-weight-bold">{{this.nombreUsuario()}}</span></span>
            
            </p>
            <p class="Menu">
               <a class="menuOption" [routerLink]="['/mi-cuenta']"><img width="25px" src="/assets/image/portafolio/iconDatos.png"> Mis datos personales</a><br>
               <a class="menuOption" [routerLink]="['/mi-cuenta/mis-pedidos']"><img width="25px"  src="/assets/image/portafolio/iconPedido.png"> Mis pedidos</a>

            </p>
        </div>
        <div class="col-sm-9">
            <app-root></app-root>
        </div>
    </div>
</div>