<div class="menu-indice">Gestión de Libros / Libros</div>
<mdb-card>
    <mdb-card-body>        
        <div class="form-row align-items-center">
            <div class="form-group col-md-4">
            <div class="md-form mt-0 mb-0">
                <input type="text" id="txtdescripcion" maxlength="100" [(ngModel)]="txtdescripcion" class="form-control" mdbInput>
                <label for="txtdescripcion">Libro</label>
            </div>
            </div>
            <div class="form-group col-md-2">
                Categoria:
            </div>
            <div class="form-group col-md-2">
                    <select class="browser-default custom-select" [(ngModel)]="cboCategoria">
                    <option value="" selected>Todos</option>
                    <option *ngFor="let categoria of listCategoria.response.list" [value]="categoria.id">{{categoria.name}}</option>
                    </select>
            </div>
            <div class="form-group col-md-2">
                Estado:
            </div>
            <div class="form-group col-md-2">
                    <select class="browser-default custom-select" [(ngModel)]="cboEstado">
                    <option value="" selected>Todos</option>
                    <option *ngFor="let estado of listEstado.response.list" [value]="estado.masterValue">{{estado.masterDescription}}</option>
                    </select>
            </div>                                    
        </div>
        <div class="form-row align-items-center">
          <div class="form-group col-md-2">
            Ver novedades:
          </div>
          <div class="form-group col-md-2">
                  <select class="browser-default custom-select" [(ngModel)]="cboNovedades">                  
                  <option *ngFor="let novedades of listNovedades.response.list" [value]="novedades.masterValue">{{novedades.masterDescription}}</option>
                  </select>
          </div>
          <div class="form-group col-md-4"></div>          
          <div class="form-group col-md-2 d-flex justify-content-center">
            <button mdbBtn type="button" id="btnBuscar" (click)="onBuscar()" size="sm" color="deep-purple" mdbWavesEffect>
            <mdb-icon fas icon="search" class="mr-1"></mdb-icon>Buscar</button>                    
          </div>
          <div class="form-group col-md-2 d-flex justify-content-center">
            <button mdbBtn type="button" id="btnNuevo" (click)="onNuevo('0')" size="sm" color="deep-purple" mdbWavesEffect>
            <mdb-icon fas icon="plus-square" class="mr-1"></mdb-icon>Nuevo</button>                    
          </div>
        </div>
    </mdb-card-body>
  </mdb-card>
  <br/>  
  <div class="row">
    <div class="col-md-10" style="text-align: left;padding-bottom: 5px;font-weight: 500;font-size: 13px;">Total : {{listBook.response.list.length}}</div>
    <div class="col-md-2" style="text-align: end;"> <a (click)="onExportarExcel()" style="text-align: right;font-weight: 500;font-size: 13px;">Exportar <mdb-icon far icon="file-excel" class="indigo-text" size="lg" aria-hidden="true"></mdb-icon></a></div>
  </div>
  <mdb-card>
      <div class="table-responsive">
        <table mdbTable hover="true">
          <thead>
            <tr>
              <th *ngFor="let head of headElements" scope="col">{{head}}</th>
            </tr>
          </thead>
          <tbody>
            <tr mdbTableCol *ngFor="let listBook of listBook.response.list | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
              <th>{{listBook.title}}</th>
              <th>{{listBook.subTitle}}</th>
              <th>{{listBook.categoryDescription}}</th>                          
              <td>{{listBook.order}}</td>
              <td style="text-align: center;"><mdb-checkbox [checked]="listBook.novelty== '1'? true: false" class="check-input" [disabled]="true"></mdb-checkbox></td>
              <td>{{listBook.status}}</td>
              <td><button type="button" (click)="onEditar(listBook.id)" color="deep-purple" outline="true" class="btn-sm" mdbWavesEffect mdbBtn><mdb-icon fas icon="book" size="lg"></mdb-icon></button></td>            
            </tr>
          </tbody>
        </table>
      </div>
        <div class="text-center">
          
          <ngb-pagination [maxSize]=3 [(page)]="page" class="d-flex justify-content-center"
            [pageSize]="pageSize" [boundaryLinks]="true" [collectionSize]="listBook.response.list.length">
          </ngb-pagination>
          
        </div>
            
  </mdb-card>
<br>

