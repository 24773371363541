import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { UsuarioService } from 'src/app/service/usuario/usuario.service';
import { UtilitarioService } from 'src/app/service/utilitarios/utilitario.service';
import { MaestrasService } from 'src/app/service/maestras/maestras.service';
import { RespuestaComun } from 'src/app/modal/maestras/respuesta-comun';

import { SubmenuService } from 'src/app/service/submenu/submenu.service';
import { SubmenuBeanInsRequest,SubmenuBeanUpdRequest } from 'src/app/modal/submenu/submenuBean';

@Component({
  selector: 'app-dml-submenu',
  templateUrl: './dml-submenu.component.html',
  styleUrls: ['./dml-submenu.component.scss']
})
export class DmlSubmenuComponent implements OnInit {

  constructor(public  modalRef: MDBModalRef,
    private maestrasService: MaestrasService,    
    private utilitarioService: UtilitarioService,
    private usuarioService: UsuarioService,    
    private submenuService: SubmenuService,    
    ) { }

  listEstado:RespuestaComun = new RespuestaComun();
  cboEstado:String = "1";

  //variables del formulario reactivo
  txtDescription:String= "";
  txtOrder:String = "";
  //variables enviado desde el padre
  id:String;  
  x_titulo:String;
  x_proceso:String;

  ngOnInit(): void {

      this.cargarDatoInicial(this.x_proceso);

  }

  onClosemodal(){
    this.modalRef.hide();
  }

  onGrabar(){

    if (this.validarFormulario()==false){      
      return false;
    }
    
    let submenuBeanInsRequest = new SubmenuBeanInsRequest();
    let submenuBeanUpdRequest = new SubmenuBeanUpdRequest();    

    if (this.x_proceso=='I'){

      this.utilitarioService.loading = true;
      this.rellenarBean(submenuBeanInsRequest,submenuBeanUpdRequest,this.x_proceso);

      this.submenuService.grabar(submenuBeanInsRequest).subscribe(data=>{        

        if (data.response.codeResponse=="0000"){
          this.utilitarioService.toastrSuccess("Se ha registrado satisfactoriamente.");
          (<HTMLButtonElement>document.getElementById("btnBuscar")).click();
        }else{
          this.utilitarioService.toastrError(data.response.response);
        }        

        this.utilitarioService.loading = false;
        this.onClosemodal();
        
      });

    }else{

      this.utilitarioService.loading = true;
      this.rellenarBean(submenuBeanInsRequest,submenuBeanUpdRequest,this.x_proceso);

      this.submenuService.update(submenuBeanUpdRequest,this.id).subscribe(data=>{        

        if (data.response.codeResponse=="0000"){
          this.utilitarioService.toastrSuccess("Se ha actualizado satisfactoriamente.");
          (<HTMLButtonElement>document.getElementById("btnBuscar")).click();
        }else{
          this.utilitarioService.toastrError(data.response.response);
        }        

        this.utilitarioService.loading = false;
        this.onClosemodal();
        
      });

    }    

  }

  cargarDatoInicial(dml:String){

    this.utilitarioService.loading = true;
    this.listEstado = new RespuestaComun();    

    const cargarEstado = this.maestrasService.obtenerMaestra("TIPO_STATUS_TABLA").toPromise()
    .then(result => {
      this.listEstado = result;
    });

    if (dml=='I'){
      //carga los promesas culminadas para asignar los datos a las variables
      (<HTMLInputElement>document.getElementById("txtOrder")).disabled=true;
      Promise.all([cargarEstado])    
      .then(response => {      
        this.utilitarioService.loading = false;
      })
      .catch(error => console.log(`Error in executing ${error}`));
    }

    if (dml=='U'){
      //carga los promesas culminadas para asignar los datos a las variables
      Promise.all([cargarEstado])    
      .then(response => {      
        //obtener por id objeto
        this.submenuService.obtenerRegistro(this.id).subscribe(data=>{
          this.id = data.response.list[0].id;
          this.txtDescription = data.response.list[0].name;
          this.txtOrder = data.response.list[0].order;
          this.cboEstado = data.response.list[0].enabled;
          this.utilitarioService.loading = false;
        });
      })
      .catch(error => console.log(`Error in executing ${error}`));
    }    

  }

  rellenarBean(submenuBeanInsRequest:SubmenuBeanInsRequest,submenuBeanUpdRequest:SubmenuBeanUpdRequest,dml:String){    
        
    if (dml=='I'){
      submenuBeanInsRequest.order = '';
      submenuBeanInsRequest.name =this.txtDescription;
      submenuBeanInsRequest.description = this.txtDescription;
      submenuBeanInsRequest.createdUserId = this.usuarioService.obtenerIDSessionUsuarioAdmin().toString();      
      /* submenuBeanInsRequest.enabled = this.cboEstado; */
    }else{
      submenuBeanUpdRequest.order = this.txtOrder;      
      submenuBeanUpdRequest.id = this.id;
      submenuBeanUpdRequest.description =this.txtDescription;
      submenuBeanUpdRequest.name = this.txtDescription;
      submenuBeanUpdRequest.updatedUserid = this.usuarioService.obtenerIDSessionUsuarioAdmin().toString();      
      submenuBeanUpdRequest.enabled = this.cboEstado;
    }        

  }

  validarFormulario() : Boolean{    
    //valida formulario
    if (this.usuarioService.obtenerIDSessionUsuarioAdmin().toString()==null){
      this.utilitarioService.toastrError("Su session ha culminado, ingrese nuevamente al sistema.");      
      return false;
    }

    //valida tipo de comprobante
    if(this.txtDescription.trim()==""){
      this.utilitarioService.toastrInfo("Ingrese descripcion.");
      return false;
    }

    if(this.cboEstado==""){
      this.utilitarioService.toastrInfo("Seleccione estado.");
      return false;
    }

    if(this.txtOrder=="" && this.x_proceso == 'U'){
      this.utilitarioService.toastrInfo("Ingre orden de submenu.");
      return false;
    }

    return true;

  }

  fc_ValNumber(event: any) {          
        const pattern = /[0-9\+\-\ ]/;
        let inputChar = String.fromCharCode(event.charCode);
    
        if (!pattern.test(inputChar)) {
          // invalid character, prevent input
          return false;
          event.preventDefault();
        }    
        return true;
  }

}
