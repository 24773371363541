<div class="carrulseWeb">
  <mdb-carousel [animation]="'slide'">
    <!-- <mdb-carousel-item>
      <a class="w-100" [routerLink]="['/detalle/49']">
        <img class="d-block w-100" src="assets/image/inicio/banner_1.png" alt="First slide">
      </a>
    </mdb-carousel-item>
    <mdb-carousel-item>
      <a class="w-100" [routerLink]="['/detalle/45']">
        <img class="d-block w-100" src="assets/image/inicio/banner_2.png" alt="First slide">
      </a>
    </mdb-carousel-item>
    <mdb-carousel-item>
      <a class="w-100" [routerLink]="['/detalle/109']">
        <img class="d-block w-100" src="assets/image/inicio/banner_3.png" alt="First slide">
      </a>
    </mdb-carousel-item> -->
    <mdb-carousel-item>
      <a class="w-100" [routerLink]="['/']">
        <img class="d-block w-100" src="assets/image/banner/banner_5.jpg" alt="First slide">
      </a>
    </mdb-carousel-item>
    <mdb-carousel-item>
      <a class="w-100" [routerLink]="['/detalle/113']">
        <img class="d-block w-100" src="assets/image/banner/banner_1.jpg" alt="First slide">
      </a>
    </mdb-carousel-item>
    <!-- <mdb-carousel-item>
      <a class="w-100" >
        <img class="d-block w-100" src="assets/image/banner/banner_2.jpg" alt="First slide">
      </a>
    </mdb-carousel-item> -->
    <mdb-carousel-item>
      <a class="w-100" [routerLink]="['/detalle/117']">
        <img class="d-block w-100" src="assets/image/banner/banner_3.jpg" alt="First slide">
      </a>
    </mdb-carousel-item>
    <!-- <mdb-carousel-item>
      <a class="w-100">
        <img class="d-block w-100" src="assets/image/banner/banner_4.jpg" alt="First slide">
      </a>
    </mdb-carousel-item> -->
  </mdb-carousel>

</div>
<div class="carrulseTablet w-100">
  <mdb-carousel [animation]="'slide'">
    <!-- <mdb-carousel-item>
      <a class="w-100" [routerLink]="['/detalle/49']">
        <img class="d-block w-100"  src="assets/image/inicio/tablet_1.png" alt="First slide">
      </a>
    </mdb-carousel-item>
    <mdb-carousel-item>
      <a class="w-100" [routerLink]="['/detalle/45']">
        <img class="d-block w-100"  src="assets/image/inicio/tablet_2.png" alt="First slide">
      </a>
    </mdb-carousel-item>
    <mdb-carousel-item>
      <a class="w-100" [routerLink]="['/detalle/109']">
        <img class="d-block w-100" src="assets/image/inicio/tablet_3.png" alt="First slide">
      </a>
    </mdb-carousel-item> -->
    <mdb-carousel-item>
      <a class="w-100" [routerLink]="['/']">
        <img class="d-block w-100" src="assets/image/banner/tablet_5.jpg" alt="First slide">
      </a>
    </mdb-carousel-item>
    <mdb-carousel-item>
      <a class="w-100" [routerLink]="['/detalle/113']">
        <img class="d-block w-100" src="assets/image/banner/tablet_1.jpg" alt="First slide">
      </a>
    </mdb-carousel-item>
    <!-- <mdb-carousel-item>
      <a class="w-100" >
        <img class="d-block w-100" src="assets/image/banner/tablet_2.jpg" alt="First slide">
      </a>
    </mdb-carousel-item> -->
    <mdb-carousel-item>
      <a class="w-100" [routerLink]="['/detalle/117']">
        <img class="d-block w-100" src="assets/image/banner/tablet_3.jpg" alt="First slide">
      </a>
    </mdb-carousel-item>
    <!-- <mdb-carousel-item>
      <a class="w-100">
        <img class="d-block w-100" src="assets/image/banner/tablet_4.jpg" alt="First slide">
      </a>
    </mdb-carousel-item> -->
  </mdb-carousel>

</div>
<div class="carrulseMovil">
  <mdb-carousel [animation]="'slide'">
    <mdb-carousel-item>
      <a class="w-100" [routerLink]="['/']">
        <img class="d-block w-100" src="assets/image/banner/phone_5.jpg" alt="First slide">
      </a>
    </mdb-carousel-item>
    <mdb-carousel-item>
      <a class="w-100" [routerLink]="['/detalle/113']">
        <img class="d-block w-100" src="assets/image/banner/phone_1.jpg" alt="First slide">
      </a>
    </mdb-carousel-item>
    <!-- <mdb-carousel-item>
      <a class="w-100" >
        <img class="d-block w-100" src="assets/image/banner/phone_2.jpg" alt="First slide">
      </a>
    </mdb-carousel-item> -->
    <mdb-carousel-item>
      <a class="w-100" [routerLink]="['/detalle/117']">
        <img class="d-block w-100" src="assets/image/banner/phone_3.jpg" alt="First slide">
      </a>
    </mdb-carousel-item>
    <!-- <mdb-carousel-item>
      <a class="w-100">
        <img class="d-block w-100" src="assets/image/banner/phone_4.jpg" alt="First slide">
      </a>
    </mdb-carousel-item> -->
    <!-- <mdb-carousel-item>
      <a class="w-100" [routerLink]="['/detalle/49']">
        <img class="d-block w-100" width="100%" src="assets/image/inicio/phone_1.png" alt="First slide">
      </a>
    </mdb-carousel-item>
    <mdb-carousel-item>
      <a class="w-100" [routerLink]="['/detalle/45']">
        <img class="d-block w-100" width="100%" src="assets/image/inicio/phone_2.png" alt="First slide">
      </a>
    </mdb-carousel-item>
    <mdb-carousel-item>
      <a class="w-100" [routerLink]="['/detalle/109']">
        <img class="d-block w-100" width="100%" src="assets/image/inicio/phone_3.png" alt="First slide">
      </a>
    </mdb-carousel-item> -->
  </mdb-carousel>

</div>
