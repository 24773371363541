// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //desarrollo  
  urlBase: 'https://apilibreriavirtual.bnp.gob.pe:9880/libreriavirtual/',
  //urlBase: 'https://testapilibreriavirtual.bnp.gob.pe:9880/libreriavirtual/',
  //urlBase: 'http://servicioslibreriavirtual.bnp.gob.pe:8086/libreriavirtual/',
  //urlBase: 'http://localhost:9880/libreriavirtual/',
  urlPayme: 'https://vpayment.verifika.com/VPOS2/faces/pages/startPayme.xhtml',
  //urlPayme: 'https://integracion.alignetsac.com/VPOS2/faces/pages/startPayme.xhtml',
  //produccion
  //urlBase:'https://serviciospayment.bnp.gob.pe/libreriavirtual/',
  //urlPayme: 'https://vpayment.verifika.com/VPOS2/faces/pages/startPayme.xhtml',
  //inicio    
  //apiURLGestionUsuario : 'http://172.16.8.67/wsgestionusuario/api/',
  apiURLGestionUsuario:'https://apiuserpagosonline.bnp.gob.pe/wsgestionusuario/api/',
  usuarioGestionUsuario:'serviciouserbnp',
  claveGestionUsuario:'123456789*',

  inicioNovedades: 'api/portal/start/novelties',
  //catalogo
  catalogo:'api/portal/start/books',
  cata_autor:'api/authors',
  cata_categoria:'api/categories',  
  cata_anoPublicacion:'api/years',
  //detalleLibro
  detalleLibro:'api/portal/detail/book',
  recomendados:'api/portal/detail/recommended',
  //Maestra
  maestras: 'api/masters',
  //usuario
  login: 'api/portal/auth/login',
  loginAdmin: 'api/admin/auth/login',
  userid: 'api/portal/auth/user',
  direccion: 'api/portal/address/address',
  direccionList: 'api/portal/address/addresses',
  registrarUsuario:'api/portal/auth/signup',
  actualizarUsuario: 'api/portal/auth/user',
  listaPedidos: 'api/portal/order/orders',
  orderPedido : 'api/portal/order/orderDet',
  recuperarContrasea:'api/portal/auth/forgotpassword',
  recuperarPassToken:'api/portal/auth/user',
  //ubigeo
  ubigeoDepart: 'api/departments',
  ubigeoProv: 'api/provinces',
  ubigeoDist: 'api/districts',
  //OrdenPago   
  orderCabecera: 'api/portal/order/orderCab',
  calculateOrder: 'api/portal/order/calculate',
  orderGestion: 'api/portal/order/order',
  orderUser: 'api/portal/order/orders',

  paymerespuesta: 'api/portal/payment/pasarella/response',
  orderinternacional: 'api/portal/order/orderforeign',  

  obtenerMenu:'api/portal/start/menus',
  //sourvenir
  sourvenirNovedades: 'api/portal/souvenir/novelties/',
  sourvenirDetalle: 'api/portal/detail/souvenir/',
  sourvenirRecomendados: 'api/portal/detail/souvenir/recommended/',
  grupoXSubMenu:'api/portal/souvenir/group/',
  catalogoSourvenir: 'api/portal/souvenir/products/',
  listarTemasSourvenir:'api/portal/souvenir/topic',

  ////ADMINISTRADOR
  orderAdmin:'api/admin/tray/orders',
  orderEstadoContinuos: 'api/admin/tray/states',
  orderEstadoGrabar : 'api/admin/tray/order',
  orderDetalleAdmin : 'api/admin/tray/order/orderDet',
  orderCabeceraAdmin : 'api/admin/tray/order/orderCab',
  orderDetalle : 'api/admin/tray/order',

  //categoria
  listCategoriaAdmin : 'api/admin/library/book/category',
  getCategoriaAdminId : 'api/admin/library/book/category/get',
  insertCategoriaAdmin : 'api/admin/library/book/category',
  updateCategoriaAdmin : 'api/admin/library/book/category',
  //año de pulicacion
  listAnioAdmin : 'api/admin/library/book/year',
  getAnioAdminId : 'api/admin/library/book/year/get',
  insertAnioAdmin : 'api/admin/library/book/year',
  updateAnioAdmin : 'api/admin/library/book/year',
  //autor
  listAutorAdmin : 'api/admin/library/book/author',
  getAutorAdminId : 'api/admin/library/book/author/get',
  insertAutorAdmin : 'api/admin/library/book/author',
  updateAutorAdmin : 'api/admin/library/book/author',
  //book
  listBookAdmin : 'api/admin/library/book/book',
  getBookAdminId : 'api/admin/library/book/book/get',
  insertBookAdmin : 'api/admin/library/book/book',
  updateBookAdmin : 'api/admin/library/book/book',

  //inventario Book
  listInvBookAdmin : 'api/admin/library/book/inventory',
  getInvBookAdminId : 'api/admin/library/book/inventory/get',
  insertInvBookAdmin : 'api/admin/library/book/inventory',
  updateInvBookAdmin : 'api/admin/library/book/inventory',
  
  //grupo
  listGrupoAdmin : 'api/admin/library/product/group',  
  getGrupoAdminId : 'api/admin/library/product/group/get',
  insertGrupoAdmin : 'api/admin/library/product/group',
  updateGrupoAdmin : 'api/admin/library/product/group',
  listGrupoCombo: '/api/groups',
  
  //submenu
  listSubmenuAdmin : 'api/admin/library/product/submenu',
  getSubmenuAdminId : 'api/admin/library/product/submenu/get',
  insertSubmenuAdmin : 'api/admin/library/product/submenu',
  updateSubmenuAdmin : 'api/admin/library/product/submenu',
  listSubmenuCombo : '/api/submenus',
  
  //topic
  listTopicAdmin : 'api/admin/library/product/topic',
  getTopicAdminId : 'api/admin/library/product/topic/get',
  insertTopicAdmin : 'api/admin/library/product/topic',
  updateTopicAdmin : 'api/admin/library/product/topic',
  listTopicCombo: '/api/topics',

  //producto sourvenir
  listProductoAdmin : 'api/admin/library/product/product',
  getProductoAdminId : 'api/admin/library/product/product/get',
  insertProductoAdmin : 'api/admin/library/product/product',
  updateProductoAdmin : 'api/admin/library/product/product',
  listProductoControles : 'api/admin/library/product/menuItemsProduct',

  //inventario product
  listInvProdAdmin : '/api/admin/library/product/inventory',
  getInvProdAdminId : 'api/admin/library/product/inventory/get',
  insertInvProdAdmin : 'api/admin/library/product/inventory',
  updateInvProdAdmin : 'api/admin/library/product/inventory',
  getListInvProdAdmin : 'api/admin/library/product/inventory/list/get',
  updateInvProdConceptoAdmin : 'api/admin/library/product/inventory/siga',
  deleteInvProdAdmin : 'api/admin/library/product/inventory/del',

  //concepto de pago
  listConceptoPagoAdminLib : '/api/admin/library/external/book/get',
  listConceptoPagoAdminProd : '/api/admin/library/external/product/get',
  verefySigaLibro : '/api/admin/library/external/product/verify',

  //servicios externos
  buscarReniecDni : '/api/admin/library/consultarDNI',
  buscarSunatRuc : '/api/admin/library/consultarRUC',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
