export class ComboResponse {
    httpStatus: number;
    response: {
      id:string,
      codeResponse:string,
      response:string,
      list: [
        {
          id:string,
          name:string
        }
      ]
    }
    constructor(){
      this.httpStatus = 0;
      this.response =  {
        id:"",
        codeResponse:"",
        response:"",
        list: [
          {
            id:"",
            name:""
          }
        ]
      }
    }
  }