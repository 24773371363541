<div class="container MenuDatos">
    <div class="row">
        <div class="col-sm-12">
             <span class="font-weight-bold">Resúmen de pedidos solicitados {{listaPedidos.response.list.length}}</span>   
        </div>
    </div>
</div>
<div class="container MenuDatos mt-2">
    <div class="row">
        <div class="col-sm-12">
            <div class="table-responsive">
                <table class="table table-sm"  mdbTable bordered="true" hover="true" striped="true">
                  <thead>
                    <tr>   
                        <th scope="col" style="min-width: 50px;">Fecha</th>
                        <th scope="col" style="min-width: 40px;" >Título</th>
                        <th scope="col" >Orden de Compra</th>

                        <th scope="col">Cantidad</th>
                        <th scope="col">Total</th>
                        <th scope="col">Estado</th>

                        <th scope="col">Ver</th>                        
                    </tr>
                    </thead>
                    <tbody *ngIf="listaPedidos.response.list.length" style="white-space: nowrap;">
                        <tr mdbTableCol *ngFor="let pedido of listaPedidos.response.list | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                            <td>{{pedido.date}}</td>
                            <td>{{pedido.books}}</td>
                            <td>{{pedido.number}}</td>
                            <td>{{pedido.quantity}}</td>
                            <td>S/ {{pedido.total}}</td>
                            <td>{{pedido.stateName}}</td>

                            <td><a (click)="frame.show();obtenerDatosPedido(pedido.id)"><mdb-icon far icon="eye"></mdb-icon></a></td>
                        </tr>
                    </tbody> 
                    <tbody *ngIf="listaPedidos.response.list.length<1">
                        <tr>
                          <td colspan="7">
                             No se ha encontrado resultados
                          </td>
                        </tr>
                      </tbody>
                </table>
            </div>
        </div>
        <div class="col-sm-12 mt-3" >
            <div class="text-center">
              
              <ngb-pagination [maxSize]=3 [(page)]="page" class="d-flex justify-content-center"
                [pageSize]="pageSize" [boundaryLinks]="true" [collectionSize]="listaPedidos.response.list.length">
              </ngb-pagination>
              
            </div>
          
          </div> 
    </div>
</div>


<div mdbModal #frame="mdbModal" class="modal fade" id="frameModalTop" tabindex="-1" role="dialog" 
  aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-notify modal-info modal-dialog-scrollable" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
        
    
   

      <!--Body-->
      <div class="modal-body">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frame.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
        <p><br/></p>

        <div class="container">
          <div class="row">
            <div class="col-sm-12 mb-2" *ngFor="let pedidos of pedidoDetalle.response.list">
              <div class="row">
                <div class="col-sm-3">
                  <img width="100%" class="z-depth-2 rounded" src="{{pedidos.bookImage}}">
                </div>
                <div class="col-sm-6">
                  <p>
                    
                    <span>{{pedidos.bookTitle}}</span> <span class="font-weight-bold"> {{pedidos.bookAuthorName}} </span><br>
                    <span>{{pedidos.bookSubtitle}}</span><br>
                    <span *ngIf="pedidos.bookSizeName!='' && pedidos.bookSizeName!='0'">Talla: {{pedidos.bookSizeName}}</span><br>

                    <span>Cantidad: {{pedidos.orderDetailQuantity}}</span><br>
                    <span *ngIf="pedidos.bookMaterialName!='' && pedidos.bookMaterialName!='0'">{{pedidos.bookMaterialName}}</span><br>
                    <span *ngIf="pedidos.bookYearName!='' && pedidos.bookYearName!='0'">{{pedidos.bookYearName}}</span><br>
                  </p>
                </div>
                <div class="col-sm-3">
                    <p>
                      <!-- <span class="font-weight-bold">S/ {{pedidos.orderDetailSubtotal}}</span> -->
                      <span class="font-weight-bold">S/ {{pedidos.bookPrice}}</span>

                    </p>
                </div>
              </div>
              <hr>
            </div>
            <div class="col-sm-12 mb-2">
              <div class="row">
                <div class="col-sm-8">
                  <p>
                    <span>Fecha: {{orderCabeceraResponse.response.list[0].date}} </span><br>
                    <span>Orden de Compra: N° {{orderCabeceraResponse.response.list[0].number}} </span><br>
                    <span>Estado: {{orderCabeceraResponse.response.list[0].stateName}}</span><br>
                    <span *ngIf="orderCabeceraResponse.response.list[0].stateId.toString()!='24'">Número de Voucher: {{orderCabeceraResponse.response.list[0].voucherNro}}</span><br>
                    
                  </p>
                </div>
                <div class="col-sm-4">
                  <span>Subtotal: S/ {{orderCabeceraResponse.response.list[0].subtotal}} </span><br>
                  <span>Delivery: S/ {{orderCabeceraResponse.response.list[0].shipping}} </span><br>
                  <span>IGV: S/ {{orderCabeceraResponse.response.list[0].igv}} </span><br>
                  <span>Total: S/ {{orderCabeceraResponse.response.list[0].total}}</span><br>
                  <span></span><br>
                  <span></span><br>
                  <span></span><br>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

     
    </div>
    <!--/.Content-->
  </div>
</div>