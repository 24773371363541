import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { map } from "rxjs/operators";
import { environment } from '../../../environments/environment';
import { OrderCabeceraAdminResponse } from '../../modal/order-cabecera/orderCabeceraAdminResponse';


@Injectable({
  providedIn: 'root'
})

export class OrderCabeceraService {

  constructor(private httpClient:HttpClient) { }

  obtenerOrderCabecera(idorden:string){
    let url = `${environment.urlBase}${environment.orderCabeceraAdmin}/${idorden}`;
    return this.httpClient.get<OrderCabeceraAdminResponse>(url).pipe(
      map(data => {   
        let response: OrderCabeceraAdminResponse;
        response = data;
        return response;
      },
      error =>{      
        return new Response("9999", error);
      })) 
  }

}
