<div class="carrulseWeb">
    <mdb-carousel [animation]="'slide'">
      <mdb-carousel-item>        
          <img class="d-block w-100" src="assets/image/inicio/{{tipoSourvenir}}a.png" alt="First slide">        
      </mdb-carousel-item>
      <mdb-carousel-item>       
          <img class="d-block w-100" src="assets/image/inicio/{{tipoSourvenir}}b.png" alt="First slide">      
      </mdb-carousel-item>
      <mdb-carousel-item>      
          <img class="d-block w-100" src="assets/image/inicio/{{tipoSourvenir}}c.png" alt="First slide">      
      </mdb-carousel-item>
    </mdb-carousel>
  
  </div>
  <div class="carrulseTablet">
    <mdb-carousel [animation]="'slide'">
      <mdb-carousel-item>
        
          <img class="d-block w-100" src="assets/image/inicio/{{tipoSourvenir}}atablet.png" alt="First slide">
        
      </mdb-carousel-item>
      <mdb-carousel-item>
        
          <img class="d-block w-100" src="assets/image/inicio/{{tipoSourvenir}}btablet.png" alt="First slide">
       
      </mdb-carousel-item>
      <mdb-carousel-item>
        
          <img class="d-block w-100" src="assets/image/inicio/{{tipoSourvenir}}ctablet.png" alt="First slide">
        
      </mdb-carousel-item>
    </mdb-carousel>
  
  </div>
  <div class="carrulseMovil">
    <mdb-carousel [animation]="'slide'">
      <mdb-carousel-item>
        <a class="w-100">
          <img class="d-block w-100" src="assets/image/inicio/{{tipoSourvenir}}aphone.png" alt="First slide">
        </a>
      </mdb-carousel-item>
      <mdb-carousel-item>
        <a class="w-100">
          <img class="d-block w-100" src="assets/image/inicio/{{tipoSourvenir}}bphone.png" alt="First slide">
        </a>
      </mdb-carousel-item>
      <mdb-carousel-item>
        <a class="w-100">
          <img class="d-block w-100" src="assets/image/inicio/{{tipoSourvenir}}cphone.png" alt="First slide">
        </a>
      </mdb-carousel-item>
    </mdb-carousel>
  
  </div>
  