
<div class="container MenuDatos">
    <form [formGroup]="registrarForm">

    <div class="row">
    
        <div class="col-sm-6 mt-2 mb-5">
            <div class="row">
                <div class="col-sm-12 mt-4 ">
                               
                    <p class="font-weight-bold">Mis datos personales</p>
                        <div class="md-form">
                            <input mdbInput disabled type="email" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$" [ngClass]="displayFieldCss('txtCorreo')" formControlName="txtCorreo" id="txtCorreo" class="form-control">
                            <label for="txtCorreo" class="">Ingrese correo electrónico</label>
                        </div>
                        <div class="md-form">
                            <input mdbInput [ngClass]="displayFieldCss('txtNombre')" formControlName="txtNombre" type="text" id="txtNombre" class="form-control">
                            <label for="txtNombre" class="">Ingrese Nombre</label>
                        </div>
                        <div class="md-form">
                            <input mdbInput [ngClass]="displayFieldCss('txtApellidos')" formControlName="txtApellidos" type="text" id="txtApellidos" class="form-control">
                            <label for="txtApellidos" class="">Ingrese Apellidos</label>
                        </div>
                </div>  
                <div class="col-sm-4">
                    <label for="txtTipoDocumento" class="">Tipo Doc.</label>

                    <select class="browser-default custom-select" (change)="onChangeTipoDoc($event.target.value)" [ngClass]="displayFieldCss('txtTipoDocumento')" formControlName="txtTipoDocumento" id="txtTipoDocumento">
                       
                          
                                <option selected value="" disabled>Tipo Doc.</option>
                                <option *ngFor="let tipo of listTipoDocumento.response.list" value="{{tipo.masterId}}">{{tipo.masterDescription}}</option>
                           
                       

                    </select> 
                </div>
                <div class="col-sm-8">
                        <div class="md-form">
                            <input mdbInput type="text" [maxLength]="maxLengthTxtNroDocumento" [ngClass]="displayFieldCss('txtNroDocumento')" formControlName="txtNroDocumento" id="txtNroDocumento" (keypress)="fc_ValTextDocumento($event)" class="form-control">
                            <label for="txtNroDocumento" class="">Ingrese Número de Documento</label>
                        </div>
                </div>
                <div class="col-sm-12">
                    <div class="md-form">
                        
                        <input mdbInput type="date" [ngClass]="displayFieldCss('txtFechaNacimiento')" formControlName="txtFechaNacimiento" id="txtFechaNacimiento" class="form-control">
                        <label for="txtFechaNacimiento" class="">Ingrese Fecha de Nacimiento</label>
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="md-form">                        
                        <input mdbInput type="text" [ngClass]="displayFieldCss('txtTelefono')" formControlName="txtTelefono" id="txtTelefono" class="form-control">
                        <label for="txtTelefono" class="">Ingrese Número de Teléfono</label>
                    </div>
                </div>
                <div class="col-sm-12 text-center">
                    <a  class="btn w-50 text-center btn-sm btnRegistrar" (click)="actualizarUsuario()" >Guardar Cambios</a>
                </div>
            </div>  
            
        
     
    </div>
    <div class="col-sm-6 mt-2 mb-5">
        <div class="row">
            <div class="col-sm-12 mt-4 ">
                <p class="font-weight-bold">&nbsp;</p>
                <div class="md-form">
                    <input mdbInput type="password" [ngClass]="displayFieldCss('txtPasswordActual')" formControlName="txtPasswordActual" id="txtPasswordActual" class="form-control">
                    <label for="txtPasswordActual" class="">Ingrese contraseña actual</label>
                </div>
                <div class="md-form">
                    <input mdbInput type="password" [ngClass]="displayFieldCss('txtPassword')" formControlName="txtPassword" id="txtPassword" class="form-control">
                    <label for="txtPassword" class="">Ingrese nueva contraseña</label>
                </div>
                <div class="md-form">
                    <input mdbInput type="password" [ngClass]="displayFieldCss('txtPassword2')" formControlName="txtPassword2" id="txtPassword2" class="form-control">
                    <label for="txtPassword2" class="">Confimar nueva contraseña</label>
                </div>
            </div>
        </div>
    </div>
</div>
</form>
</div>