import { MensajeResponseBeanM } from '../externo/mensajeResponseBeanM';

export class PersonaBeanM {
	nrodocumento:String;
	tipodocumento:String;
	nombres:String;
	apepaterno:String;
	apematerno:String;
	direccion:String;
	ubigeo:String;
	f_emision_doc:String;
	mensajeResponseBeanM:MensajeResponseBeanM;
}

export class RucEntidadBeanMResponse {
    x_ruc:String;
    x_razonsocial:String;
    x_direccion:String;
    x_ubigeo:String;
    mensajeResponseBeanM:MensajeResponseBeanM;
} 

export class RequestCredenciales {
    usuario:String;
	clave:String;	
}