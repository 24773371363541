import { Component, OnInit } from '@angular/core';
import { UtilitarioService } from 'src/app/service/utilitarios/utilitario.service';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { MaestrasService } from 'src/app/service/maestras/maestras.service';
import { RespuestaComun } from 'src/app/modal/maestras/respuesta-comun';
import { ResponseComun } from 'src/app/modal/catalogo/response-comun';
import { LibroAdminListResponse } from 'src/app/modal/libro/LibroAdminListResponse';
import { BookService } from 'src/app/service/book/book.service';
import { NovedadesService } from 'src/app/service/catalogo/novedades.service';
import { ExcelService } from 'src/app/service/utilitarios/excel.service';

import { DmlBookComponent } from 'src/app/pagina-admin/dashboard/gestionLibros/mant-book/dml-book/dml-book.component';

@Component({
  selector: 'app-mant-book',
  templateUrl: './mant-book.component.html',
  styleUrls: ['./mant-book.component.scss']
})

export class MantBookComponent implements OnInit {

  constructor(private bookService:BookService,
    private novedadesService:NovedadesService,
    private utilitarioService: UtilitarioService,
    private maestrasService: MaestrasService,
    private modalService: MDBModalService,
    private excelService:ExcelService,
    ) { }

  txtdescripcion:String = "";
  cboEstado:String = "";
  cboCategoria:String = "";
  cboNovedades:String = "";

  modalRefAnioDml: MDBModalRef;
  listEstado:RespuestaComun = new RespuestaComun();
  listCategoria:ResponseComun = new ResponseComun();
  listNovedades:RespuestaComun = new RespuestaComun();

  headElements = ['Libros','Subtitulo','Categoria','Orden','Novedades', 'Estado','Editar'];
  listBook:LibroAdminListResponse = new LibroAdminListResponse();
  
  page = 1;
  pageSize = 9;
  foto : String;

  ngOnInit(): void {
    this.cargarDatoInicial();    
  }

  onBuscar(){
    this.utilitarioService.loading = true;
    this.bookService.obtenerAdminList(this.txtdescripcion.toString(), this.cboEstado.toString(),this.cboCategoria.toString(),this.cboNovedades.toString()).subscribe(data=>{
      this.listBook = data;
      console.log(data);
      this.utilitarioService.loading = false;
    })
  }

  onNuevo(id:String){
    this.modalRefAnioDml = this.modalService.show(DmlBookComponent,
      {
        backdrop: true,
        keyboard: true,
        focus: true,
        show: false,
        ignoreBackdropClick: false,
        class: 'modal-dialog modal-dialog-centered modal-dialog-scrollable modal-notify modal-warning modal-lg',
        containerClass: 'top',
        animated: true,
        data: {
          id: id,
          x_titulo:'Nuevo Libro',
          x_proceso:'I',
        }
      }
    );
  }

  async onEditar(id:String){

    //obtener imagen url
    /* let imgBase64:String = "";
    await this.getBase64ImageFromUrl('https://pbs.twimg.com/profile_images/558329813782376448/H2cb-84q_400x400.jpeg').then((result:String) => {
      imgBase64 = result;
      console.log(result);
    }) */
    
        this.modalRefAnioDml = this.modalService.show(DmlBookComponent,
            {
              backdrop: true,
              keyboard: true,
              focus: true,
              show: false,
              ignoreBackdropClick: false,
              class: 'modal-dialog modal-dialog-centered modal-dialog-scrollable modal-notify modal-warning modal-lg',
              containerClass: 'top',
              animated: true,
              data: {
                id: id,
                x_titulo:'Editar Libro',
                x_proceso:'U'
              }
            }
        );
            
  }

  cargarDatoInicial(){

    this.utilitarioService.loading = true;
    this.listEstado = new RespuestaComun();
    const cargarEstado = this.maestrasService.obtenerMaestra("TIPO_STATUS_TABLA").toPromise().then(data => {
      this.listEstado = data;
    });

    this.listNovedades = new RespuestaComun();
    const cargarNovedades = this.maestrasService.obtenerMaestra("TIPO_NOVEDADES").toPromise().then(data => {
      this.listNovedades = data;
    });

    this.listCategoria = new ResponseComun();
    const cargarCategoria = this.novedadesService.obtenerCategoria().toPromise().then(data => {
      this.listCategoria = data;      
    });

    Promise.all([cargarEstado,cargarCategoria,cargarNovedades])
      .then(response => {

        this.bookService.obtenerAdminList(this.txtdescripcion.toString(), this.cboEstado.toString(),this.cboCategoria.toString(),this.cboNovedades.toString()).subscribe(data=>{
          this.listBook = data;
          console.log(data);
          this.utilitarioService.loading = false;
        });        

      })
      .catch(error => {
        this.utilitarioService.loading = false;
        console.log(`Error in executing ${error}`);
      });

  }

  async getBase64ImageFromUrl(imageUrl):Promise<string> {
    var res = await fetch(imageUrl);

    var blob = await res.blob();

    return new Promise((resolve, reject) => {
      var reader  = new FileReader();
      reader.addEventListener("load", function () {
          resolve(reader.result.toString());
      }, false);
  
      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
  }

  onExportarExcel(){
    this.excelService.exportExcelLibros(this.listBook.response.list);
  }

}