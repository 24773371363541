import { DetailBook } from 'src/app/modal/procesapago/detailBook';

export class OrderRequest {
    
    addressId: String;
    clientId: String;
    detailBookAndProduct: DetailBook[];
    fiscalAddress: String;
    fullName: String;
    igvCost: String;
    nroDocumento: String;
    personaApellidoMaterno: String;
    personaApellidoPaterno: String;
    personaNombres: String;
    razonSocial: String;
    ruc: String;
    shippingCost: String;
    subtotalCost: String;
    totalCost: String;
    typeDeliveryId: String;
    typeRegionId: String;
    typeShippingId: String;
    typeVoucherId: String;

}
