<h2 mat-dialog-title>Confirmación</h2>
<div mat-dialog-content>
    <p>{{data.mensaje}}</p>
</div>
<mdb-card>
    
    <table mdbTable hover="true">
        <thead>
          <tr>
            <th *ngFor="let head of headElements" scope="col">{{head}}</th>
          </tr>
        </thead>
        <tbody>
          <tr mdbTableCol *ngFor="let libros of data.libros | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
            <th>{{libros.bookTitle}}</th>
            <th>{{libros.inventoryDescription}}</th>
          </tr>
        </tbody>
      </table>
      
      <div class="text-center">
          
          <ngb-pagination [maxSize]=3 [(page)]="page" class="d-flex justify-content-center"
            [pageSize]="pageSize" [boundaryLinks]="true" [collectionSize]="data.libros.length">
          </ngb-pagination>
          
      </div>
            
</mdb-card>      
<div mat-dialog-actions class="justify-content-center">
    <button mdbBtn type="button" color="deep-purple" (click)="confirmado()" outline="true" mdbWavesEffect>Si</button>
    <button mdbBtn type="button" color="deep-purple" (click)="cerrarDialogo()" outline="true" mdbWavesEffect>No</button>
</div>

