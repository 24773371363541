import { Component, OnInit } from '@angular/core';
import { MDBModalRef, MDBModalService} from 'angular-bootstrap-md';
import { UsuarioService } from 'src/app/service/usuario/usuario.service';
import { UtilitarioService } from 'src/app/service/utilitarios/utilitario.service';
import { MaestrasService } from 'src/app/service/maestras/maestras.service';
import { RespuestaComun } from 'src/app/modal/maestras/respuesta-comun';

import { ConceptoPagoBean } from 'src/app/modal/conceptoPago/concepto-pago-bean';
import { BusConceptosComponent } from 'src/app/pagina-admin/dashboard/bus-comunes/bus-conceptos/bus-conceptos.component';

import { InventarioLibService } from 'src/app/service/inventario-lib/inventario-lib.service';
import { InventarioLibBeanInsRequest,InventarioLibBeanUpdRequest } from 'src/app/modal/inventario-libro/inventarioLibBean';

@Component({
  selector: 'app-dml-invetario',
  templateUrl: './dml-invetario.component.html',
  styleUrls: ['./dml-invetario.component.scss']
})

export class DmlInvetarioComponent implements OnInit {

  constructor(public  modalRef: MDBModalRef,
    private maestrasService: MaestrasService,    
    private utilitarioService: UtilitarioService,
    private usuarioService: UsuarioService,    
    private inventarioLibService: InventarioLibService,
    private modalService: MDBModalService,
    ) { }

  modalRefConceptoBus: MDBModalRef;

  listEstado:RespuestaComun = new RespuestaComun();
  listMaterial:RespuestaComun = new RespuestaComun();
  cboEstado:String = "1";
  cboMaterial:String = "";

  txtBookid: String = "";
  txtCurrency: String = "";
  txtDescription: String = "";
  txtEnabled: String = "";
  txtInventoryid: String = "";
  txtMaterialid: String = "";
  txtPrice: String = "";
  txtSigaid: String = "";
  txtSigasecuencia: String = "";
  txtStock: String = "";
  txtStockorigin: String = "";
  txtUsuariocrearid: String = "";

  txtCodigoSigaSec: String = "";
  //variables enviado desde el padre
  id:String;
  x_titulo:String;
  x_proceso:String;
  x_titulo_libro:String;

  ngOnInit(): void {

    this.cargarDatoInicial(this.x_proceso);

}

onClosemodal(){
  this.modalRef.hide();
}

onGrabar(){

  if (this.validarFormulario()==false){      
    return false;
  }

  let inventarioLibBeanInsRequest = new InventarioLibBeanInsRequest();
  let inventarioLibBeanUpdRequest = new InventarioLibBeanUpdRequest();    

  if (this.x_proceso=='I'){

    this.utilitarioService.loading = true;
    this.rellenarBean(inventarioLibBeanInsRequest,inventarioLibBeanUpdRequest,this.x_proceso);
    
    this.inventarioLibService.grabar(inventarioLibBeanInsRequest).subscribe(data=>{        
      
      if (data.response.codeResponse=="0000"){
        this.utilitarioService.toastrSuccess("Se ha registrado satisfactoriamente.");
        (<HTMLButtonElement>document.getElementById("btnBuscar")).click();
      }else{
        this.utilitarioService.toastrError(data.response.response);
      }

      this.utilitarioService.loading = false;
      this.onClosemodal();
      
    });

  }else{

    this.utilitarioService.loading = true;
    this.rellenarBean(inventarioLibBeanInsRequest,inventarioLibBeanUpdRequest,this.x_proceso);
    console.log(this.id);
    console.log(JSON.stringify(inventarioLibBeanUpdRequest));
    this.inventarioLibService.update(inventarioLibBeanUpdRequest,this.id).subscribe(data=>{        
      console.log(data);
      if (data.response.codeResponse=="0000"){
        this.utilitarioService.toastrSuccess("Se ha actualizado satisfactoriamente.");
        (<HTMLButtonElement>document.getElementById("btnBuscar")).click();
      }else{
        this.utilitarioService.toastrError(data.response.response);
      }        

      this.utilitarioService.loading = false;
      this.onClosemodal();
      
    });

  }    

}

cargarDatoInicial(dml:String){

  this.utilitarioService.loading = true;
  this.listEstado = new RespuestaComun();

  const cargarEstado = this.maestrasService.obtenerMaestra("TIPO_STATUS_TABLA").toPromise()
  .then(result => {
    this.listEstado = result;
  });

  this.listMaterial = new RespuestaComun();
  const cargarMaterial = this.maestrasService.obtenerMaestra("TIPO_MATERIAL").toPromise()
  .then(result => {
    this.listMaterial = result;
  });  

  if (dml=='I'){
    //carga los promesas culminadas para asignar los datos a las variables    
    Promise.all([cargarEstado,cargarMaterial])    
    .then(response => {      
      this.utilitarioService.loading = false;
    })
    .catch(error => console.log(`Error in executing ${error}`));
  }

  if (dml=='U'){
    //carga los promesas culminadas para asignar los datos a las variables
    Promise.all([cargarEstado,cargarMaterial])    
    .then(response => {      
      //obtener por id objeto
      this.inventarioLibService.obtenerRegistro(this.id).subscribe(data=>{

        this.cboEstado = data.response.list[0].enabled;
        this.cboMaterial = data.response.list[0].materialId;
        
        this.txtBookid = data.response.list[0].bookId;
        this.txtCurrency= data.response.list[0].inventoryCurrency;
        this.txtDescription= data.response.list[0].inventoryDescription;
        //this.txtEnabled= data.response.list[0].enabled;
        //this.txtMaterialid= data.response.list[0].materialId;
        this.txtInventoryid = data.response.list[0].inventoryId;        
        this.txtPrice= data.response.list[0].inventoryPrice;
        this.txtSigaid= data.response.list[0].sigaId;
        this.txtSigasecuencia= data.response.list[0].sigaSecuencia;
        this.txtStock= data.response.list[0].inventoryStock;
        this.txtStockorigin= data.response.list[0].inventoryStockOrigin;
        this.txtUsuariocrearid= data.response.list[0].usuarioModificaId;
        
        this.txtCodigoSigaSec = this.txtSigaid + '-' + this.txtSigasecuencia;

        this.utilitarioService.loading = false;
      });
    })
    .catch(error => console.log(`Error in executing ${error}`));
  }    

}

rellenarBean(inventarioLibBeanInsRequest:InventarioLibBeanInsRequest,inventarioLibBeanUpdRequest:InventarioLibBeanUpdRequest,dml:String){

  if (dml=='I'){

    inventarioLibBeanInsRequest.bookId=this.id;
    inventarioLibBeanInsRequest.currency=this.txtCurrency;
    inventarioLibBeanInsRequest.description=this.txtDescription;
    inventarioLibBeanInsRequest.enabled=this.cboEstado;
    inventarioLibBeanInsRequest.inventoryId="0";
    inventarioLibBeanInsRequest.materialId=this.cboMaterial;
    inventarioLibBeanInsRequest.price=this.txtPrice;
    inventarioLibBeanInsRequest.sigaId=this.txtSigaid;
    inventarioLibBeanInsRequest.sigaSecuencia=this.txtSigasecuencia;
    inventarioLibBeanInsRequest.stock=this.txtStock;
    inventarioLibBeanInsRequest.stockOrigin=this.txtStock;
    inventarioLibBeanInsRequest.usuarioCrearId=this.usuarioService.obtenerIDSessionUsuarioAdmin().toString();

  }else{
    inventarioLibBeanUpdRequest.bookId=this.id;
    inventarioLibBeanUpdRequest.currency=this.txtCurrency;
    inventarioLibBeanUpdRequest.description=this.txtDescription;
    inventarioLibBeanUpdRequest.enabled=this.cboEstado;
    inventarioLibBeanUpdRequest.inventoryId=this.txtInventoryid;
    inventarioLibBeanUpdRequest.materialId=this.cboMaterial;
    inventarioLibBeanUpdRequest.price=this.txtPrice;
    inventarioLibBeanUpdRequest.sigaId=this.txtSigaid;
    inventarioLibBeanUpdRequest.sigaSecuencia=this.txtSigasecuencia;
    inventarioLibBeanUpdRequest.stock=this.txtStock;
    inventarioLibBeanUpdRequest.stockOrigin=this.txtStockorigin;
    inventarioLibBeanUpdRequest.usuarioModificaId=this.usuarioService.obtenerIDSessionUsuarioAdmin().toString();
  }  

}

validarFormulario() : Boolean{    
  //valida formulario
  if (this.usuarioService.obtenerIDSessionUsuarioAdmin().toString()==null){
    this.utilitarioService.toastrError("Su session ha culminado, ingrese nuevamente al sistema.");      
    return false;
  }

  if(this.cboMaterial==""){
    this.utilitarioService.toastrInfo("Seleccione el material del libro.");
    return false;
  }
  
  //valida tipo de comprobante  
  if(this.txtStock==="" || this.txtStock === null){
    this.utilitarioService.toastrInfo("Ingrese número de stock.");
    return false;
  }

  if(this.cboEstado==""){
    this.utilitarioService.toastrInfo("Seleccione estado.");
    return false;
  }  

  if(this.txtSigaid==""){
    this.utilitarioService.toastrInfo("Debe asociar el concepto de pago.");
    return false;
  }  

  /* if(this.txtOrder=="" && this.x_proceso == 'U'){
    this.utilitarioService.toastrInfo("Ingre orden de categoria.");
    return false;
  } */

  return true;

}

onAgregarConcepto(){
  this.modalRefConceptoBus = this.modalService.show(BusConceptosComponent,
    {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: false,
      class: 'modal-dialog modal-dialog-centered modal-dialog-scrollable modal-notify modal-warning modal-lg',
      containerClass: 'top',
      animated: true,
      data: {x_titulo:'Buscar Concepto de Pago',
             x_tipo_concepto:'LIBRO',
      }
    }
  );

  this.modalRefConceptoBus.content.action.subscribe( (result: ConceptoPagoBean) => 
  { 
    this.txtSigaid = result.cincodigo;
    this.txtSigasecuencia = result.idstpcodigo;
    this.txtPrice = result.cinimporte;
    this.txtDescription = result.sindescrip;
    this.txtCurrency = result.idmoncodigo;

    this.txtCodigoSigaSec = this.txtSigaid + '-' + this.txtSigasecuencia;

  });

}

}
