import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sourvenir',
  templateUrl: './sourvenir.component.html',
  styleUrls: ['./sourvenir.component.scss']
})
export class SourvenirComponent implements OnInit {

  constructor(private router:Router) { }

  ngOnInit(): void {
       //Redireccion al Inicio Sourve Desac
     //  this.router.navigate(['/']);
  }

}
