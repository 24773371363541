<!--Header-->
<div class="modal-header text-center" style="color: white;background-color: #544d84">    
    <h4 class="modal-title w-100">{{x_titulo}}</h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onClosemodal()">
      <span aria-hidden="true" class="white-text">&times;</span>
    </button>
</div>
  
<!--Body-->
<div class="modal-body">

    <mdb-card>
        <mdb-card-body>        
            <div class="form-row">
                <div class="form-group col-md-8">
                    <div class="md-form mt-0 mb-0">
                        <input type="text" id="txtdescripcion_bus" maxlength="100" [(ngModel)]="txtdescripcion_bus" class="form-control" mdbInput>
                        <label for="txtdescripcion_bus">Descripción</label>
                    </div>
                </div>                
                <div class="form-group col-md-4">
                        <button mdbBtn type="button" id="btnBuscar" (click)="onBuscar()" size="sm" color="deep-purple" mdbWavesEffect>
                        <mdb-icon fas icon="search" class="mr-1"></mdb-icon>Buscar</button>                    
                </div>                
            </div>
        </mdb-card-body>
      </mdb-card>
      <br/>
      <mdb-card>
        <div class="table-responsive">
        <table mdbTable hover="true">
            <thead>
              <tr>
                <th *ngFor="let head of headElements" scope="col">{{head}}</th>
              </tr>
            </thead>
            <tbody>
              <tr mdbTableCol *ngFor="let listAutor of listAutor.response.list | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                <th>{{listAutor.name}}</th>                
                <td><button type="button" (click)="onSeleccionar(listAutor)" color="deep-purple" outline="true" class="btn-sm" mdbWavesEffect mdbBtn>Seleccionar</button></td>
              </tr>
            </tbody>
          </table>
          </div>
            <div class="text-center">
              
              <ngb-pagination [maxSize]=3 [(page)]="page" class="d-flex justify-content-center"
                [pageSize]="pageSize" [boundaryLinks]="true" [collectionSize]="listAutor.response.list.length">
              </ngb-pagination>
              
            </div>
                
      </mdb-card>
    <br>
  
</div>  
  <!--Footer-->
<div class="modal-footer justify-content-center">

</div>


