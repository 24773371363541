import { UbigeoBean} from '../ubigeo/ubigeoBean';

export class UbigeoResponse {

    httpStatus:number;
    response: {
      id:string,
      codeResponse:string,
      response:string,
      list: UbigeoBean[]
    }    

    constructor(){
      this.httpStatus=0;
      this.response={
          id:"",
          codeResponse:"",
          response:"",
          list:[]
      }
    }
    
}
