export class UsuarioRequest {
    email: String;
    flagRedSocial: String;
    password: String
  constructor(){
    this.email="";
    this.flagRedSocial="0";
    this.password="0";
  }
}
