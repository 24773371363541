import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { OrderDetalleService } from 'src/app/service/orderdetalle/order-detalle.service';
import { OrderDetalleResponse } from 'src/app/modal/order-detalle/orderDetalleResponse';
import { OrderCabeceraAdminResponse } from 'src/app/modal/order-cabecera/orderCabeceraAdminResponse';
import { OrderCabeceraService } from 'src/app/service/ordercabecera/order-cabecera.service';
import { UtilitarioService } from 'src/app/service/utilitarios/utilitario.service';

@Component({
  selector: 'app-detalle-orden',
  templateUrl: './detalle-orden.component.html',
  styleUrls: ['./detalle-orden.component.scss']
})
export class DetalleOrdenComponent implements OnInit {

  constructor(public  modalRef: MDBModalRef,
    private orderDetalleService:OrderDetalleService,
    private orderCabeceraService:OrderCabeceraService,
    private utilitarioService:UtilitarioService) { }

  //variables enviados del padre
  idOrden: String;
  nroOrden: String;
  quantity: String;
  total: String;
  subtotal: String;
  igv: String;
  date: String;
  regionType: String;
  countryAddress: String;
  clientName: String;
  countryName: String;
  stateName: String;
  voucherType: String;
  voucherNro: String;
  deliveryType: String;
  shipping: String;
  contactName: String;
  contactCellphone: String;
  clientEmail: String;

  //
  listDetalle: OrderDetalleResponse;  
  listCabecera:OrderCabeceraAdminResponse = new OrderCabeceraAdminResponse();
  headElements = ['Producto', 'Cantidad', 'Precio'];

  ngOnInit(): void {
    
    this.listDetalle = new OrderDetalleResponse();    

    this.utilitarioService.loading=true;
    console.log("(this.idOrden.toString()",this.idOrden.toString());
    this.orderCabeceraService.obtenerOrderCabecera(this.idOrden.toString()).subscribe(datacab=>{
      console.log(datacab);
      this.listCabecera = datacab;
      
      this.orderDetalleService.obtenerOrderDetalle(this.idOrden.toString()).subscribe(data=>{
        //console.log(data);
        this.listDetalle = data;
        this.utilitarioService.loading=false;
      });
      
    });    

  }

  onClosemodal(){
    this.modalRef.hide();
  }

}
