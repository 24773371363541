export class CarritoHeader {
    lista:[{
        id:string;
        cantidad:string;
        tipoProducto:string;
        sizeID:string;
        //Tipo Producto 1: Libros  -- 2:Sourvenir
    }]  
  
    constructor(){
        this.lista=[{
            id:'',
            cantidad:'',
            tipoProducto:'',
            sizeID:''
        }]
    }
 

}
