import { Component, OnInit } from '@angular/core';
import { UtilitarioService } from 'src/app/service/utilitarios/utilitario.service';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { MaestrasService } from 'src/app/service/maestras/maestras.service';
import { RespuestaComun } from 'src/app/modal/maestras/respuesta-comun';
import { ResponseComun } from 'src/app/modal/catalogo/response-comun';

import { ProductoListResponse } from 'src/app/modal/producto/productoListResponse';
import { ProductoService } from 'src/app/service/producto/producto.service';
//import { NovedadesService } from 'src/app/service/producto/novedades.service';
import { DmlProductoComponent } from 'src/app/pagina-admin/dashboard/gestionProductos/mant-producto/dml-producto/dml-producto.component';

import { GrupoService } from 'src/app/service/grupo/grupo.service';
import { TopicService } from 'src/app/service/topic/topic.service';
import { ComboResponse } from 'src/app/modal/maestras/combo-response';

import { ExcelService } from 'src/app/service/utilitarios/excel.service';

@Component({
  selector: 'app-mant-producto',
  templateUrl: './mant-producto.component.html',
  styleUrls: ['./mant-producto.component.scss']
})
export class MantProductoComponent implements OnInit {

  constructor(private productoService:ProductoService,    
    private utilitarioService: UtilitarioService,
    private maestrasService: MaestrasService,
    private modalService: MDBModalService,
    private grupoService:GrupoService,
    private topicService:TopicService,
    private excelService:ExcelService,
    ) { }

  txtdescripcion:String = "";
  cboEstado:String = "";
  cboTipoRegalo:String = "";
  cboTematica:String = "";
  cboNovedades:String = "";
  cboNovedadesTopic:String = "";

  modalRefAnioDml: MDBModalRef;
  listEstado:RespuestaComun = new RespuestaComun();
  listTipoRegalo:ResponseComun = new ResponseComun();
  headElements = ['Producto','Tipo Regalo','Temática','Orden','Nov.Regalo','Nov.Temática', 'Estado','Editar'];
  listProducto:ProductoListResponse = new ProductoListResponse();
  listNovedades:RespuestaComun = new RespuestaComun();
  listNovedadesTopic:RespuestaComun = new RespuestaComun();
  listTopico:ComboResponse = new ComboResponse();

  page = 1;
  pageSize = 9;
  foto : String;

  ngOnInit(): void {
    this.cargarDatoInicial();
    this.onBuscar();
  }
  
  onBuscar(){
    this.utilitarioService.loading = true;
    this.productoService.obtenerAdminList(this.txtdescripcion.toString(),"", this.cboEstado.toString(), this.cboTipoRegalo.toString(),this.cboTematica.toString(),this.cboNovedades.toString(),this.cboNovedadesTopic.toString()).subscribe(data=>{
      this.listProducto = data;
      
      console.log(data);
      this.utilitarioService.loading = false;
    })
  }

  onNuevo(id:String){
    this.modalRefAnioDml = this.modalService.show(DmlProductoComponent,
      {
        backdrop: true,
        keyboard: true,
        focus: true,
        show: false,
        ignoreBackdropClick: false,
        class: 'modal-dialog modal-dialog-centered modal-dialog-scrollable modal-notify modal-warning modal-lg',
        containerClass: 'top',
        animated: true,
        data: {
          id: id,
          x_titulo:'Nuevo Producto',
          x_proceso:'I',
        }
      }
    );
  }

  async onEditar(id:String){

    //obtener imagen url
    /* let imgBase64:String = "";
    await this.getBase64ImageFromUrl('https://pbs.twimg.com/profile_images/558329813782376448/H2cb-84q_400x400.jpeg').then((result:String) => {
      imgBase64 = result;
      console.log(result);
    }) */
    
        this.modalRefAnioDml = this.modalService.show(DmlProductoComponent,
            {
              backdrop: true,
              keyboard: true,
              focus: true,
              show: false,
              ignoreBackdropClick: false,
              class: 'modal-dialog modal-dialog-centered modal-dialog-scrollable modal-notify modal-warning modal-lg',
              containerClass: 'top',
              animated: true,
              data: {
                id: id,
                x_titulo:'Editar Producto',
                x_proceso:'U'
              }
            }
        );
            
  }

  cargarDatoInicial(){

    this.utilitarioService.loading = true;
    this.listEstado = new RespuestaComun();
    this.maestrasService.obtenerMaestra("TIPO_STATUS_TABLA").subscribe(data=>{
      this.listEstado = data;            
    });

    this.listNovedades = new RespuestaComun();
    this.listNovedadesTopic = new RespuestaComun();

    const cargarNovedades = this.maestrasService.obtenerMaestra("TIPO_NOVEDADES").toPromise().then(data => {
      this.listNovedades = data;
      this.listNovedadesTopic = data;
    });    

    this.listTopico = new ComboResponse();
    const cargarPublicacion = this.topicService.obtenerListCombo().toPromise()
    .then(result => {
      console.log(result);
      this.listTopico = result;
    });

    this.listTipoRegalo = new ComboResponse();
    this.grupoService.obtenerListCombo().subscribe(data=>{      
      this.listTipoRegalo = data;
      this.utilitarioService.loading = false;
    });

  }

  async getBase64ImageFromUrl(imageUrl):Promise<string> {
    var res = await fetch(imageUrl);

    var blob = await res.blob();

    return new Promise((resolve, reject) => {
      var reader  = new FileReader();
      reader.addEventListener("load", function () {
          resolve(reader.result.toString());
      }, false);
  
      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
  }

  onExportarExcel(){
    this.excelService.exportExcelProductos(this.listProducto.response.list);
  }

}
