import { Component, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { CarritoHeader } from 'src/app/modal/carrito/carrito-header';
import { LibroDetalle } from 'src/app/modal/catalogo/libro-detalle';
import { SourvenirDetalleResponse } from 'src/app/modal/sourvenir/sourvenir-detalle-response';
import { CarritoService } from 'src/app/service/carrito/carrito.service';
import { NovedadesService } from 'src/app/service/catalogo/novedades.service';
import { UsuarioService } from 'src/app/service/usuario/usuario.service';
import { UtilitarioService } from 'src/app/service/utilitarios/utilitario.service';

@Component({
  selector: 'app-carritocompras',
  templateUrl: './carritocompras.component.html',
  styleUrls: ['./carritocompras.component.scss']
})
export class CarritocomprasComponent implements OnInit {

  carritoDetalle:CarritoHeader;
  carritoComprasTemp=[];
  carritoCompras=[];
  libroDetalle:LibroDetalle;
  precioTotal:number;
  precio:number;
  
  constructor(private carritoService:CarritoService,
    private novedadesService:NovedadesService, private router:Router,
    private usuarioService:UsuarioService,
    
   
    private utilitarioService:UtilitarioService){
  }

  async rellenarCarrito(){

    this.utilitarioService.loading=true;
    this.precioTotal=0;
    this.carritoCompras=[];
    this.carritoComprasTemp=[];

    for (let i = 0; i < this.carritoDetalle.lista.length; i++) {
      
      let libroDetalleTemp = new LibroDetalle();
      let sourvenirDetalleTemp = new SourvenirDetalleResponse();

      if(this.carritoDetalle.lista[i].tipoProducto=="1"){
        
        libroDetalleTemp = await this.novedadesService.obtenerDetalleLibroAsync(this.carritoDetalle.lista[i].id).toPromise();
        libroDetalleTemp.response.list[0].size = [];

      //console.log(libroDetalleTemp);
      if(Number(libroDetalleTemp.response.list[0].quantity)>10){
        libroDetalleTemp.response.list[0].quantity = "10";
      } 
            
      this.carritoComprasTemp.push({lista:libroDetalleTemp.response.list,cantidad:this.carritoDetalle.lista[i].cantidad})
      this.carritoCompras.push(this.carritoComprasTemp);
      this.precioTotal = Number((this.precioTotal + ( Number(this.carritoDetalle.lista[i].cantidad) * Number(libroDetalleTemp.response.list[0].price) )).toFixed(2));

      }
      else if(this.carritoDetalle.lista[i].tipoProducto=="2"){

      sourvenirDetalleTemp = await this.novedadesService.obtenerDetalleSourvenirAsync(this.carritoDetalle.lista[i].id).toPromise();
      console.log("ingreso");

      if(sourvenirDetalleTemp.response.list[0].size.length>0){        
        const result = sourvenirDetalleTemp.response.list[0].size.find( ({ id }) => id ==  this.carritoDetalle.lista[i].sizeID);
        console.log(result);
        sourvenirDetalleTemp.response.list[0].quantity = result.stock;
        sourvenirDetalleTemp.response.list[0].sizeName  = result.name;
      }

      if(Number(sourvenirDetalleTemp.response.list[0].quantity)>10){
        sourvenirDetalleTemp.response.list[0].quantity = "10";
      } 
            
      this.carritoComprasTemp.push({lista:sourvenirDetalleTemp.response.list,cantidad:this.carritoDetalle.lista[i].cantidad})
      this.carritoCompras.push(this.carritoComprasTemp);
      console.log(this.carritoCompras[0]);
      this.precioTotal = Number((this.precioTotal + ( Number(this.carritoDetalle.lista[i].cantidad) * Number(sourvenirDetalleTemp.response.list[0].price) )).toFixed(2));

      }
    }

    this.utilitarioService.loading=false;

  }
  
  obtenerExiteSession(){
    return this.usuarioService.existeSesionUsuario();
  }

  async procesarPago(){

    if(this.obtenerExiteSession()){
      let carritoDetalleTemp = new CarritoHeader();
      carritoDetalleTemp=this.carritoService.obtenerCarritoCompras()
      console.log(carritoDetalleTemp);
      for (let i = 0; i < carritoDetalleTemp.lista.length; i++) {

        let libroDetalleTemp = new LibroDetalle();
        let libroSourvenirTemp=new SourvenirDetalleResponse();
        let stockdisponible:number;

        if(this.carritoDetalle.lista[i].tipoProducto=="1"){

          libroDetalleTemp = await this.novedadesService.obtenerDetalleLibroAsync(this.carritoDetalle.lista[i].id).toPromise();          
          console.log("libroDetalleTemp",libroDetalleTemp);

          stockdisponible = Number(libroDetalleTemp.response.list[0].quantity) - Number(this.carritoDetalle.lista[i].cantidad);
          
          if(stockdisponible<0){
            this.utilitarioService.toastrInfo("No hay stock disponible en el libro " + libroDetalleTemp.response.list[0].title + ", cantidad disponible: " + libroDetalleTemp.response.list[0].quantity);
            this.utilitarioService.loading=false;
            return false;
          }          

        }else if(this.carritoDetalle.lista[i].tipoProducto=="2"){

            libroSourvenirTemp = await this.novedadesService.obtenerDetalleSourvenirAsync(this.carritoDetalle.lista[i].id).toPromise();
            console.log("libroSourvenirTemp",libroSourvenirTemp);

            if(libroSourvenirTemp.response.list[0].size.length>0){              
              const result = libroSourvenirTemp.response.list[0].size.find( ({ id }) => id ==  this.carritoDetalle.lista[i].sizeID);
              libroSourvenirTemp.response.list[0].quantity = result.stock;              
            }

            stockdisponible = Number(libroSourvenirTemp.response.list[0].quantity) - Number(this.carritoDetalle.lista[i].cantidad);

            if(stockdisponible<0){
              this.utilitarioService.toastrInfo("No hay stock disponible en el producto " + libroSourvenirTemp.response.list[0].title + ", cantidad disponible: " + libroSourvenirTemp.response.list[0].quantity);
              this.utilitarioService.loading=false;
              return;
            }

        }

        
      }
      this.router.navigate(['/procesarpago']);
    }
    else{
      this.utilitarioService.toastrWarning("Necesita iniciar sesión");
    }
  }

  obtenerCarrito(){
    this.carritoDetalle = new CarritoHeader();
    this.carritoDetalle=this.carritoService.obtenerCarritoCompras()
    this.rellenarCarrito();
  }

  limpiarCarrito(){
    // this.rellenarCarrito();
    this.carritoService.limpiarCarrito();
    this.carritoCompras = [];
    this.precioTotal = 0;
  }

  fprecioTotal(){
    this.precio = this.precioTotal
    return this.precio
  }
  ngOnInit(): void {
   //verifica si exite libros agregados
   this.carritoService.verificarCarritoSelecionados();
   this.obtenerCarrito();    
  }

  frecalculartotal(fila:number,cantidad:number,stock:string){
    //console.log(cantidad);
    //console.log(stock);
    
    if (cantidad>10){
      //console.log("por defecto");
      this.carritoDetalle.lista[fila].cantidad = stock;
      this.utilitarioService.toastrInfo("cantidad maxíma : " + "10");
    }else{
      //console.log("stock");
      if (cantidad > Number(stock)){
        this.utilitarioService.toastrInfo("queda en stock : " + stock + " cantidades");
        this.carritoDetalle.lista[fila].cantidad = stock;
      }      
    }

    if (cantidad<1){
      this.carritoDetalle.lista[fila].cantidad = "1";
    }
  
    //actualiza storage con nuevos cantidades    
    this.carritoService.actualizarCarrito(this.carritoDetalle.lista);
    this.rellenarCarrito();
    
  }

  // feliminarCarrito(i: number){

  //   this.carritoDetalle.lista.splice(i, 1);
  //   this.carritoService.actualizarCarrito(this.carritoDetalle.lista);
  //   this.rellenarCarrito();

  // }

  feliminarCarrito(i: number){
    console.log("Index Eliminar",i);
    this.carritoDetalle.lista.splice(i, 1);
    console.log("carrito compras 1");
    console.log(this.carritoDetalle);
  
    this.carritoService.eliminarCarrito(this.carritoDetalle.lista);
    this.rellenarCarrito();

    console.log("carrito compras 2");
    console.log(this.carritoDetalle);

  }

}
