<div class="container">
    <div class="row">
        <div class="col-sm-12 mt-5">
            <span class="h3-responsive novedades">Novedades</span><br>
            <img src="/assets/image/portafolio/raya_novedades.png" width="10%">
        </div>
        <div class="col-sm-12 p-5">
            <ngx-slick-carousel class="carousel" 
            #slickModal="slick-carousel" 
            [config]="slideConfig" 
            (init)="slickInit($event)"
            (breakpoint)="breakpoint($event)"
            (afterChange)="afterChange($event)"
            (beforeChange)="beforeChange($event)">
            <div ngxSlickItem *ngFor="let novedad of novedades.response.list" class="slide m-3">
              <div class="divLibroNovedades w-100">
                <p class="text-center">
                  <a [routerLink]="['/detalleSourvenir',novedad.id]">
                    <img src="{{novedad.image}}" class="z-depth-5 portadaImagen" style="display: initial;"/>
                  </a>
                </p> 
                <br>     
                <p class="text-left">
                  <span class="title">{{novedad.title}}</span><br>
                  <span class="subtitle">{{novedad.subtitle}}</span>

                </p>
                <p  class="text-center">
                  <span class="price">S/{{novedad.price}}</span>
                </p>
              </div>        
              
           
              
            </div>
            </ngx-slick-carousel>
        </div>
    </div>
    <hr>
</div>