<div class="container mt-5 mb-5">
    <div class="row">
        <div class="col-sm-3">
          <form [formGroup]="filtrosForm"> 
            
            <p *ngIf="tipoSourvenir!='6'"><br><span class="font-weight-bold cataTitle">Producto</span></p>
            <p *ngIf="tipoSourvenir=='6'"><br><span class="font-weight-bold cataTitle">Temas</span></p>
              

                <div class="ng-autocomplete w-100">
                <ng-autocomplete 
                  formControlName="txtCategoria"
                  [data]="listGroupXIDSourvenir.response.list"
                  placeHolder="Seleccione"
                  [searchKeyword]="keyword"
                  (selected)='selectEvent($event)'
                  (inputChanged)='onChangeSearch($event)'
                  (inputFocused)='onFocused($event)'
                  [itemTemplate]="itemTemplate"
                  notFoundText="No hay resutados"
                  [notFoundTemplate]="notFoundTemplate">                                 
                </ng-autocomplete>
                 
                <ng-template #itemTemplate let-item>
                <a [innerHTML]="item.name"></a>
                </ng-template>
                 
                <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
                </ng-template>
                </div>
                

                <br/>
                <a class="btn btn-filtros w-100" (click)="buscarLibrosTemas()">Aplicar Filtros</a>
                <br> <br>
                <p class="nroResultados"><span class="font-weight-bold">{{listProductos.response.list.length}}</span> Productos(s) encontrado(s)</p>

          </form>
          
        </div>
        <div class="col-sm-9 border borCatalogo">
            
            <div class="row" *ngIf="listProductos.response.list.length<=0">
              <div class="col-sm-12" >
                  No se encontraron registros
              </div>            
             
            </div>
            <div class="row" *ngIf="listProductos.response.list.length>0">
              
                <div class="col-sm-4" *ngFor="let libros of listProductos.response.list | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                 
                  <div class="m-4">
                    <div class="text-center">
                      <a [routerLink]="['/detalleSourvenir',libros.id]">
                      <img src="{{libros.image}}" class="imagenPortada z-depth-2 rounded" />
                      </a>
                    </div>
                    <div class="datosLibro mt-3">
                      <p >
                        <a [routerLink]="['/detalleSourvenir',libros.id]">
                          <span class="txtTitle">{{libros.title}}</span>
                        </a>  
                        <br/>

                        <span class="txtSubTitle">{{libros.subtitle}}</span>
                      </p>
                      <p class="text-center">
                        <span class="txtPrice">S/ {{libros.price}}</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12" >
                  <div class="text-center">
                    
                    <ngb-pagination [maxSize]=3 [(page)]="page" class="d-flex justify-content-center"
                    (pageChange)="cambiarPagina($event)"
                      [pageSize]="pageSize" [boundaryLinks]="true" [collectionSize]="listProductos.response.list.length">
                    </ngb-pagination>
                    
                  </div>
                
                </div> 
            </div>
        </div>
    </div>
</div>