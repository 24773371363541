<div class="container">
    <br>&nbsp;&nbsp;
    <a (click)="limpiarCarrito()"><span class="fas fa-trash"></span></a>&nbsp;&nbsp;
    Sub total &nbsp;&nbsp;<b>S/{{fprecioTotal()| number:'1.2-2'}}</b><br>
    
    <div class="w-100">
        <hr>
    </div>
    <div class="row divDetalleCarrito">       
        <!-- <div class="col-sm-12" *ngFor="let libro of carritoCompras">
            
            <div class="w-50 p-3 float-left">
                <img class="z-depth-1" width="80%" src="{{libro[0].image}}">
            </div>
            <div class="w-50 float-left">
                <span class="font-weight-bold">{{libro[0].title}}</span><br>
                <span>{{libro[0].subtitle}}</span><br>
                <span>{{libro[0].price}}</span>
            </div>
            <div class="w-100 float-left">
               <hr>
            </div>            
        </div>        -->

        <div *ngFor="let libro of carritoCompras[0];let i = index;"> 
            <div class="row p-2">
               
                <div class="col-sm-4 pb-5" >                       
                    <img class="z-depth-1" width="100%" src="{{libro.lista[0].image}}">
                </div>
                <div class="col-sm-8 pb-3">
                    <span class="font-weight-bold">{{libro.lista[0].title}}</span><br>
                    <span>{{libro.lista[0].subtitle}}</span><br>
                    <span *ngIf="libro.lista[0].size.length>0">Talla: {{libro.lista[0].sizeName}}<br></span>                    
                    <span>S/ {{libro.lista[0].price}}</span><br>                    
                    <div class="row">
                        <div class="col-sm-12">
                            <span>Cantidad:</span>
                        </div>
                        <br>
                        <div class="col-sm-8">
                            <input mdbInput type="number" style="width: 60px;" class="form-control" id="txtCantidad" onkeypress="return event.charCode >= 48" (change) = "frecalculartotal(i,$event.target.value,libro.lista[0].quantity);" min="1" max="{{libro.lista[0].quantity}}" [(ngModel)]="carritoDetalle.lista[i].cantidad">
                        </div>
                        <div class="col-sm-2" style="padding-right: 20px;text-align: right;">                                    
                            <button title="Eliminar Producto" class="btn btn-rounded btn-flat btn-sm" (click)="feliminarCarrito(i);"><i class="fas fa-trash fa-lg"></i></button>
                        </div>
                    </div>
                  
                </div>
            </div>
            <div class="w-100 float-left">
                <hr>
             </div>      
        </div>

    </div>
    <div class="row w-100">
        <div class="col-sm-12 p-3">
            <a class="btn btn-carrito" [routerLink]="'/carrito'">Ver Carrito</a>
        </div>
    </div>
</div>
