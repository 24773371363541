import { Component, OnInit,AfterViewInit } from '@angular/core';
import { MDBModalRef, MDBModalService} from 'angular-bootstrap-md';
import { UsuarioService } from 'src/app/service/usuario/usuario.service';
import { UtilitarioService } from 'src/app/service/utilitarios/utilitario.service';
import { MaestrasService } from 'src/app/service/maestras/maestras.service';
import { RespuestaComun } from 'src/app/modal/maestras/respuesta-comun';
import { ResponseComun } from 'src/app/modal/catalogo/response-comun';

//componentes
import { BusAutoresComponent } from 'src/app/pagina-admin/dashboard/bus-comunes/bus-autores/bus-autores.component';
import { BusEdicionesComponent } from 'src/app/pagina-admin/dashboard/bus-comunes/bus-ediciones/bus-ediciones.component';

import { AutorAdminBeanUpdRequest } from 'src/app/modal/autor/autorAdminBean';
import { AutorAdminListBean } from 'src/app/modal/autor/autorAdminBean';
import { AnioAdminBeanUpdRequest } from 'src/app/modal/anio/anioAdminBean';
import { AnioAdminListBean } from 'src/app/modal/anio/anioAdminBean';

import { BookService } from 'src/app/service/book/book.service';
import { NovedadesService } from 'src/app/service/catalogo/novedades.service';
import { LibroBeanUpdRequest } from 'src/app/modal/libro/LibroAdminBeanUpdRequest';
import { LibroAdminBeanInsRequest } from 'src/app/modal/libro/libroAdminBeanInsRequest';

@Component({
  selector: 'app-dml-book',
  templateUrl: './dml-book.component.html',
  styleUrls: ['./dml-book.component.scss']
})
export class DmlBookComponent implements OnInit {

  constructor(public  modalRef: MDBModalRef,
    private maestrasService: MaestrasService,    
    private utilitarioService: UtilitarioService,
    private usuarioService: UsuarioService,
    private modalService: MDBModalService,
    private bookService: BookService,
    private novedadesService: NovedadesService,   
  ) { }

  modalRefAutorBus: MDBModalRef;
  modalRefEdicionBus: MDBModalRef;

  listEstado:RespuestaComun = new RespuestaComun();
  listCategory:ResponseComun = new ResponseComun();
  listPublication:RespuestaComun = new RespuestaComun();
  
  //variables del formulario reactivo
  listAutor:Array<AutorAdminBeanUpdRequest> = [];
  listEdicion:Array<AnioAdminBeanUpdRequest> = [];
  cboCategoryId: String = "";
  cboPublicationId: String ="";
  cboEnabled: String="1";
  txtDescription: String = "";   
  txtIsbn: String = "";
  txtIsnn: String = "";
  txtMeasure: String = "";
  txtNovelty: Boolean = false;
  txtOrder:String = "";
  txtPages: String = "";
  txtPhoto64: String = "";
  txtPublishedDate: String = "";
  txtState: String = "";
  txtSubTitle: String = "";
  txtSubject: String = "";
  txtTitle: String = "";
  txtUsuarioCrearId: String;
  txtWeight: String = "";  

  imgSrc: any = [];
  x_url: String = "";
  x_foto:String = "";
  headAutor = ['Nro','Descripción','Eliminar'];
  headEdicion = ['Nro','Descripción','Eliminar'];

  //variables enviado desde el padre
  id:String;
  x_titulo:String;
  x_proceso:String;
  
  ngOnInit(): void {

      this.cargarDatoInicial(this.x_proceso);

  }

  ngAfterViewInit():void{    
    const imagen = document.getElementsByClassName('ngx-img-message');
    imagen.item(0).children[1].innerHTML="Selecionar o Arrastre su imagen";
    let buttonImg : HTMLButtonElement = imagen.item(0).children[2] as HTMLButtonElement;
    
    buttonImg.innerHTML = "Hacer clic"
  }

  onClosemodal(){
    this.modalRef.hide();
  }

  onGrabar(){

    if (this.validarFormulario()==false){      
      return false;
    }
    
    let libroAdminBeanInsRequest = new LibroAdminBeanInsRequest();
    let libroBeanUpdRequest = new LibroBeanUpdRequest();    

    if (this.x_proceso=='I'){

      this.utilitarioService.loading = true;
      this.rellenarBean(libroAdminBeanInsRequest,libroBeanUpdRequest,this.x_proceso);

      this.bookService.grabar(libroAdminBeanInsRequest).subscribe(data=>{        

        if (data.response.codeResponse=="0000"){
          this.utilitarioService.toastrSuccess("Se ha registrado satisfactoriamente.");
          (<HTMLButtonElement>document.getElementById("btnBuscar")).click();
        }else{
          this.utilitarioService.toastrError(data.response.response);
        }        

        this.utilitarioService.loading = false;
        this.onClosemodal();
        
      });

    }else{

      this.utilitarioService.loading = true;
      this.rellenarBean(libroAdminBeanInsRequest,libroBeanUpdRequest,this.x_proceso);
      
      this.bookService.update(libroBeanUpdRequest,this.id).subscribe(data=>{        
        console.log(data);
        if (data.response.codeResponse=="0000"){
          this.utilitarioService.toastrSuccess("Se ha actualizado satisfactoriamente.");
          (<HTMLButtonElement>document.getElementById("btnBuscar")).click();
        }else{
          this.utilitarioService.toastrError(data.response.response);
        }

        this.utilitarioService.loading = false;
        this.onClosemodal();
        
      });

    }   

  }

  async cargarDatoInicial(dml:String){

    this.utilitarioService.loading = true;

    this.listEstado = new RespuestaComun();
    const cargarEstado = this.maestrasService.obtenerMaestra("TIPO_STATUS_TABLA").toPromise()
    .then(result => {
      this.listEstado = result;
    });

    this.listCategory = new ResponseComun();
    const cargarCategory = this.novedadesService.obtenerCategoria().toPromise()
    .then(result => {
      this.listCategory = result;
    });

    this.listPublication = new RespuestaComun();
    const cargarPublicacion = this.maestrasService.obtenerMaestra("TIPO_PUBLICACION").toPromise()
    .then(result => {
      this.listPublication = result;
    });  

    if (dml=='I'){
      //carga los promesas culminadas para asignar los datos a las variables
      (<HTMLInputElement>document.getElementById("txtOrder")).disabled=true;
      Promise.all([cargarEstado,cargarCategory,cargarPublicacion])
      .then(response => {      
        this.utilitarioService.loading = false;
      })
      .catch(error => console.log(`Error in executing ${error}`));
    }

    if (dml=='U'){
      //carga los promesas culminadas para asignar los datos a las variables
      Promise.all([cargarEstado,cargarCategory,cargarPublicacion])
      .then(response => {
        //obtener por id objeto
        this.bookService.obtenerRegistro(this.id).subscribe(data=>{
          
          this.id = data.response.list[0].id;
          this.txtTitle= data.response.list[0].title;
          this.txtSubTitle= data.response.list[0].subTitle;
          this.txtDescription= data.response.list[0].description;
          this.txtPages= data.response.list[0].pages;
          this.txtWeight= data.response.list[0].weight;
          this.txtIsbn= data.response.list[0].isbn;
          this.txtIsnn= data.response.list[0].isnn;
          this.txtMeasure= data.response.list[0].measure;

          this.cboCategoryId= data.response.list[0].categoryId;
          this.cboPublicationId = data.response.list[0].publicationId;
          this.txtNovelty= data.response.list[0].novelty=='1'? true: false;
          this.txtOrder = data.response.list[0].order;
          this.cboEnabled = data.response.list[0].enabled;                                                 

          let now = new Date();
          this.x_url = data.response.list[0].imageRoute.toString() + '/' + data.response.list[0].imageName + '.' + data.response.list[0].imageExtension + '?v' + data.response.list[0].publishedDate;

          this.txtPublishedDate= data.response.list[0].publishedDate;
          this.txtState= data.response.list[0].state;
          this.txtUsuarioCrearId= data.response.list[0].usuarioCrearId;
          this.txtSubject= data.response.list[0].subject; //no se muestra

          this.listAutor = data.response.list[0].authors;
          this.listEdicion = data.response.list[0].yearsEditions;

          this.utilitarioService.loading = false;
          console.log(this.x_url);
          console.log(data);

        });
      })
      .catch(error => console.log(`Error in executing ${error}`));
    }    
    
  }  

  rellenarBean(libroAdminBeanInsRequest:LibroAdminBeanInsRequest,libroBeanUpdRequest:LibroBeanUpdRequest,dml:String){    
        
    if (dml=='I'){

      libroAdminBeanInsRequest.authorsIds = this.listAutor;
      libroAdminBeanInsRequest.editionsIds = this.listEdicion;
      libroAdminBeanInsRequest.categoryId= this.cboCategoryId;
      libroAdminBeanInsRequest.description= this.txtDescription;
      libroAdminBeanInsRequest.enabled=this.cboEnabled;
      libroAdminBeanInsRequest.id='';
      libroAdminBeanInsRequest.isbn=this.txtIsbn;
      libroAdminBeanInsRequest.isnn=this.txtIsnn;
      libroAdminBeanInsRequest.measure=this.txtMeasure;
      libroAdminBeanInsRequest.novelty= this.txtNovelty== true? "1": "0";
      libroAdminBeanInsRequest.order= this.txtOrder;
      libroAdminBeanInsRequest.pages=this.txtPages;
      libroAdminBeanInsRequest.photo64=this.x_foto;
      libroAdminBeanInsRequest.publicationId=this.cboPublicationId;
      libroAdminBeanInsRequest.publishedDate='';
      libroAdminBeanInsRequest.state='8';
      libroAdminBeanInsRequest.subTitle=this.txtSubTitle;
      libroAdminBeanInsRequest.subject=this.txtSubject;
      libroAdminBeanInsRequest.title=this.txtTitle;
      libroAdminBeanInsRequest.usuarioCrearId= this.usuarioService.obtenerIDSessionUsuarioAdmin().toString();
      libroAdminBeanInsRequest.weight=this.txtWeight.toString();            
      
    }else{
      
      libroBeanUpdRequest.authorsIds = this.listAutor;
      libroBeanUpdRequest.editionsIds = this.listEdicion;
      libroBeanUpdRequest.categoryId= this.cboCategoryId;
      libroBeanUpdRequest.description= this.txtDescription;
      libroBeanUpdRequest.enabled=this.cboEnabled;
      libroBeanUpdRequest.id='';
      libroBeanUpdRequest.isbn=this.txtIsbn;
      libroBeanUpdRequest.isnn=this.txtIsnn;
      libroBeanUpdRequest.measure=this.txtMeasure;
      libroBeanUpdRequest.novelty= this.txtNovelty== true? "1": "0";
      libroBeanUpdRequest.order= this.txtOrder;
      libroBeanUpdRequest.pages=this.txtPages;
      libroBeanUpdRequest.photo64=this.x_foto;
      libroBeanUpdRequest.publicationId=this.cboPublicationId;
      libroBeanUpdRequest.publishedDate='';
      libroBeanUpdRequest.state='8';
      libroBeanUpdRequest.subTitle=this.txtSubTitle;
      libroBeanUpdRequest.subject=this.txtSubject;
      libroBeanUpdRequest.title=this.txtTitle;
      libroBeanUpdRequest.usuarioModificaId= this.usuarioService.obtenerIDSessionUsuarioAdmin().toString();
      libroBeanUpdRequest.weight=this.txtWeight.toString();
      
    }        

  }

  validarFormulario() : Boolean{    
    //valida formulario
    if (this.usuarioService.obtenerIDSessionUsuarioAdmin().toString()==null){
      this.utilitarioService.toastrError("Su session ha culminado, ingrese nuevamente al sistema.");      
      return false;
    }

    //valida tipo de comprobante
    if(this.txtTitle==""){
      this.utilitarioService.toastrInfo("Ingrese titulo.");
      return false;
    }

    if(this.txtSubTitle==""){
      this.utilitarioService.toastrInfo("Ingrese Subtitulo.");
      return false;
    }

    if(this.txtDescription==""){
      this.utilitarioService.toastrInfo("Ingrese descripción del libro.");
      return false;
    }

    if(this.txtPages==""){
      this.utilitarioService.toastrInfo("Ingrese total de páginas.");
      return false;
    }

    if(this.txtWeight==""){
      this.utilitarioService.toastrInfo("Ingrese peso del libro.");
      return false;
    }

    if(this.txtMeasure==""){
      this.utilitarioService.toastrInfo("Ingrese las dimensiones del libro.");
      return false;
    }

    if(this.cboCategoryId==""){
      this.utilitarioService.toastrInfo("Seleccione la categoria del libro.");
      return false;
    }

    if(this.cboPublicationId==""){
      this.utilitarioService.toastrInfo("Seleccione el tipo de puliblicación del libro.");
      return false;
    }

    if (this.listAutor.length == 0){
      this.utilitarioService.toastrInfo("Agregue un autor como mínimo.");
      return false;
    }

    if (this.listEdicion.length == 0){
      this.utilitarioService.toastrInfo("Agregue un año de publicación como mínimo.");
      return false;
    }

    if(this.cboEnabled==""){
      this.utilitarioService.toastrInfo("Seleccione estado.");
      return false;
    }

    if(this.txtOrder=="" && this.x_proceso == 'U'){
      this.utilitarioService.toastrInfo("Ingre número de orden del libro.");
      return false;
    }

    if(this.x_foto=="" && this.x_proceso == 'I'){
      this.utilitarioService.toastrInfo("Seleccione la foto de la portada del libro.");
      return false;
    }

    return true;

  }

  fc_ValNumber(event: any) {          
        const pattern = /[0-9\+\-\ ]/;
        let inputChar = String.fromCharCode(event.charCode);
    
        if (!pattern.test(inputChar)) {
          // invalid character, prevent input
          return false;
          event.preventDefault();
        }    
        return true;
  }

  onSelect($event: any) {
    
    this.imgSrc = [];
    
    switch (typeof($event)) {
      case 'string':

        let indice:number = 0;

        this.imgSrc = [$event];
        this.x_foto= [$event].toString();
        indice = this.x_foto.indexOf(',');
        this.x_foto = this.x_foto.substr(indice+1);
        
        break;
      case 'object':
        console.log('prueba de registro');
        this.imgSrc = $event;
        break;
      default:
    }
  }

  onReset() {
    this.imgSrc = [];
  }

  options = {
    imgSrc: '', // base64 encoded image for default preview
    fileSize: 2048, // in Bytes (by default 2048 Bytes = 2 MB)
    minWidth: 0, // minimum width of image that can be uploaded (by default 0, signifies any width)
    maxWidth: 0,  // maximum width of image that can be uploaded (by default 0, signifies any width)
    minHeight: 0,  // minimum height of image that can be uploaded (by default 0, signifies any height)
    maxHeight: 0,  // maximum height of image that can be uploaded (by default 0, signifies any height)
    fileType: ['image/gif', 'image/jpeg', 'image/png'], // mime type of files accepted
    height: 400, // height of cropper
    quality: 0.8, // quality of image after compression
    crop: [  // array of objects for mulitple image crop instances (by default null, signifies no cropping)
      {
        autoCropArea: 0.8, // A number between 0 and 1. Define the automatic cropping area size (percentage).
        ratio: 1, // ratio in which image needed to be cropped (by default null, signifies ratio to be free of any restrictions)
        minWidth: 0, // minimum width of image to be exported (by default 0, signifies any width)
        maxWidth: 0,  // maximum width of image to be exported (by default 0, signifies any width)
        minHeight: 0,  // minimum height of image to be exported (by default 0, signifies any height)
        maxHeight: 0,  // maximum height of image to be exported (by default 0, signifies any height)
        width: 0,  // width of image to be exported (by default 0, signifies any width)
        height: 0,  // height of image to be exported (by default 0, signifies any height)
        output: 'base64',  // Output format. Can be 'base64' or 'blob'. (by default 'base64')
      }
    ]
  };

  onDeleteAutor(fila:AutorAdminBeanUpdRequest){
    const index = this.listAutor.indexOf(fila);
    this.listAutor.splice(index, 1);
  }  

  onAgregarAutor(){
    this.modalRefAutorBus = this.modalService.show(BusAutoresComponent,
      {
        backdrop: true,
        keyboard: true,
        focus: true,
        show: false,
        ignoreBackdropClick: false,
        class: 'modal-dialog modal-dialog-centered modal-dialog-scrollable modal-notify modal-warning',
        containerClass: 'top',
        animated: true,
        data: {x_titulo:'Buscar Autor'}
      }
    );

    this.modalRefAutorBus.content.action.subscribe( (result: AutorAdminListBean) => 
    {       
      let autorAdminBeanUpdRequest:AutorAdminBeanUpdRequest = new AutorAdminBeanUpdRequest();
      autorAdminBeanUpdRequest.about = result.about;
      autorAdminBeanUpdRequest.enabled = result.enabled;
      autorAdminBeanUpdRequest.id = result.id;
      autorAdminBeanUpdRequest.name = result.name;
      autorAdminBeanUpdRequest.order = result.order;
      autorAdminBeanUpdRequest.usuarioModificaId = result.usuarioModificaId;      
      this.listAutor.push(autorAdminBeanUpdRequest);
    });

  }

  onAgregarEdicion(){
    this.modalRefEdicionBus = this.modalService.show(BusEdicionesComponent,
      {
        backdrop: true,
        keyboard: true,
        focus: true,
        show: false,
        ignoreBackdropClick: false,
        class: 'modal-dialog modal-dialog-centered modal-dialog-scrollable modal-notify modal-warning',
        containerClass: 'top',
        animated: true,
        data: {x_titulo:'Buscar Año de Publicación'}
      }
    );

    this.modalRefEdicionBus.content.action.subscribe( (result: AnioAdminListBean) => 
    {       
      let anioAdminBeanUpdRequest:AnioAdminBeanUpdRequest = new AnioAdminBeanUpdRequest();
      anioAdminBeanUpdRequest.about = result.about;
      anioAdminBeanUpdRequest.enabled = result.enabled;
      anioAdminBeanUpdRequest.id = result.id;
      anioAdminBeanUpdRequest.name = result.name;
      anioAdminBeanUpdRequest.order = result.order;
      anioAdminBeanUpdRequest.usuarioModificaId = result.usuarioModificaId;      
      this.listEdicion.push(anioAdminBeanUpdRequest);
    });

  }

  onDeleteEdicion(fila:AnioAdminBeanUpdRequest){
    const index = this.listEdicion.indexOf(fila);
    this.listEdicion.splice(index, 1);
  } 

  async getBase64ImageFromUrl(imageUrl) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();
  
    return new Promise((resolve, reject) => {
      var reader  = new FileReader();
      reader.addEventListener("load", function () {
          resolve(reader.result.toString());
      }, false);
  
      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
  }

}
