import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { map } from "rxjs/operators";
import { environment } from '../../../environments/environment';
import { OrderDetalleResponse } from '../../modal/order-detalle/orderDetalleResponse';

@Injectable({
  providedIn: 'root'
})
export class OrderDetalleService {

  constructor(private httpClient:HttpClient) { }

  obtenerOrderDetalle(idorden:string){
    let url = `${environment.urlBase}${environment.orderDetalleAdmin}/${idorden}`;
    return this.httpClient.get<OrderDetalleResponse>(url).pipe(
      map(data => {   
        let response: OrderDetalleResponse;
        response = data;
        return response;
      },
      error =>{      
        return new Response("9999", error);
      })) 
  }
  obtenerOrderDetallePedido(idorden:string){
    let url = `${environment.urlBase}${environment.orderPedido}/${idorden}`;    
    return this.httpClient.get<OrderDetalleResponse>(url).pipe(
      map(data => {   
        let response: OrderDetalleResponse;
        response = data;
        return response;
      },
      error =>{      
        return new Response("9999", error);
      })) 
  }
}
