import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CarritocomprasComponent } from './pagina-externa/carritocompras/carritocompras.component';
import { DetalleComponent } from './pagina-externa/detalle/detalle.component';
import { InicioComponent } from './pagina-externa/inicio/inicio.component';
import { PaginaExternaComponent } from './pagina-externa/pagina-externa.component';
import { ProcesarPagoComponent } from './pagina-externa/procesar-pago/procesar-pago.component';
import { ResulPagoPaymeComponent } from './pagina-externa/procesar-pago/resul-pago-payme/resul-pago-payme.component';
import { LoginAdminComponent } from './pagina-admin/login-admin/login-admin.component';
import { DashboardComponent } from './pagina-admin/dashboard/dashboard/dashboard.component';
import { BandejaPedidoComponent } from './pagina-admin/dashboard/bandeja-pedido/bandeja-pedido.component';
import { RegistrarComponent } from './pagina-externa/usuario/registrar/registrar.component';
import { MiCuentaComponent } from './pagina-externa/usuario/mi-cuenta/mi-cuenta.component';
import { DatosPersonalesComponent } from './pagina-externa/usuario/mi-cuenta/datos-personales/datos-personales.component';
import { MisPedidosComponent } from './pagina-externa/usuario/mi-cuenta/mis-pedidos/mis-pedidos.component';
import { ResulInternacionalComponent } from './pagina-externa/procesar-pago/resul-internacional/resul-internacional.component';
import { RecuperarContrasenaComponent } from './pagina-externa/usuario/recuperar-contrasena/recuperar-contrasena.component';
import { RecuperarContrasenaTokenComponent } from './pagina-externa/usuario/recuperar-contrasena-token/recuperar-contrasena-token.component';
import { SourvenirComponent } from './pagina-externa/sourvenir/sourvenir.component';
import { DetalleSourvenirComponent } from './pagina-externa/detalle-sourvenir/detalle-sourvenir.component';

//mantenimiento
import { MantCategoriaComponent } from './pagina-admin/dashboard/gestionLibros/mant-categoria/mant-categoria.component';
import { MantAniosComponent } from './pagina-admin/dashboard/gestionLibros/mant-anios/mant-anios.component';
import { MantAutorComponent } from './pagina-admin/dashboard/gestionLibros/mant-autor/mant-autor.component';
import { MantBookComponent } from './pagina-admin/dashboard/gestionLibros/mant-book/mant-book.component';
import { MatInventarioComponent } from './pagina-admin/dashboard/gestionLibros/mat-inventario/mat-inventario.component';

import { MantGruposComponent } from './pagina-admin/dashboard/gestionProductos/mant-grupos/mant-grupos.component';
import { MantInventarioProdComponent } from './pagina-admin/dashboard/gestionProductos/mant-inventario-prod/mant-inventario-prod.component';
import { MantProductoComponent } from './pagina-admin/dashboard/gestionProductos/mant-producto/mant-producto.component';
import { MantSubmenuComponent } from './pagina-admin/dashboard/gestionProductos/mant-submenu/mant-submenu.component';
import { MantTopicComponent } from './pagina-admin/dashboard/gestionProductos/mant-topic/mant-topic.component';

const routes: Routes = [
  {path:'',component:PaginaExternaComponent,
  children: [
  {path:'',component: InicioComponent},
  {path:'catalogo/:idTipoPublicacion',component: InicioComponent},
  {path:'sourvenir/:idSourvenir',component: SourvenirComponent},
  {path:'sourvenir/:idSourvenir/:idTema',component: SourvenirComponent},
  {path:'detalle/:idLibro',component: DetalleComponent},
  {path:'detalleSourvenir/:idSourvenirProducto',component: DetalleSourvenirComponent},
  {path:'carrito',component: CarritocomprasComponent},
  {path:'procesarpago',component: ProcesarPagoComponent},
  {path:'respuestaproceso/:nrorden',component: ResulPagoPaymeComponent},
  {path:'registrar',component: RegistrarComponent},
  {path:'mi-cuenta',component: MiCuentaComponent,children:[
    {path:'',component: DatosPersonalesComponent},
    {path:'mis-pedidos',component: MisPedidosComponent}
  ]},

  {path:'respuestainternacional/:nrorden',component: ResulInternacionalComponent},
  {path:'registrar',component: RegistrarComponent},
  {path:'recuperar-contrasena',component: RecuperarContrasenaComponent},
  {path:'recuperar-contrasena/:token',component: RecuperarContrasenaTokenComponent}
  ]
  },
  {path:'loginadmin',component: LoginAdminComponent},
  {path:'dasboard',component: DashboardComponent,children: [
      {path:'bandejapedidos',component: BandejaPedidoComponent},
      {path:'mantCategoria',component: MantCategoriaComponent},
      {path:'mantAnio',component: MantAniosComponent},
      {path:'mantAutor',component: MantAutorComponent},
      {path:'mantBook',component: MantBookComponent},
      {path:'mantInventario',component: MatInventarioComponent},
      {path:'mantGrupos',component: MantGruposComponent},
      {path:'mantinventarioprod',component: MantInventarioProdComponent},
      {path:'mantsubmenu',component: MantSubmenuComponent},
      {path:'mantTopic',component: MantTopicComponent},
      {path:'mantProducto',component: MantProductoComponent},
    ]},
  {path: '**', component: PaginaExternaComponent,pathMatch: 'full',children:[{path:'',component: InicioComponent}] },

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    scrollPositionRestoration: 'enabled',
  }) 
  ],
  exports: [RouterModule] 
})
export class AppRoutingModule { }
