<app-header></app-header>
<!-- <div class="aviso">
    <p>
        <span>Versión de aplicativo en prueba</span>
    </p>
</div> -->
<div class="divSpace"></div>
<!-- <div class="container">
    <div class="row">
        <div class="col-sm-12">
            <img src="https://www.bnp.gob.pe/wp-content/uploads/2021/01/comunicado-covid-2021.jpeg" width="100%">
        </div>
    </div>
</div> -->
<router-outlet></router-outlet>
<app-footer></app-footer>