<div class="menu-indice">Gestión de Productos / Temática</div>
<mdb-card>
    <mdb-card-body>        
        <div class="form-row align-items-center">
            <div class="form-group col-md-4">
            <div class="md-form mt-0 mb-0">
                <input type="text" id="txtdescripcion" maxlength="100" [(ngModel)]="txtdescripcion" class="form-control" mdbInput>
                <label for="txtdescripcion">temática</label>
            </div>
            </div>
            <div class="form-group col-md-1">
                Estado:
            </div>
            <div class="form-group col-md-3">
                    <select class="browser-default custom-select" [(ngModel)]="cboEstado">
                    <option value="" selected>Todos</option>
                    <option *ngFor="let estado of listEstado.response.list" [value]="estado.masterName">{{estado.masterDescription}}</option>
                    </select>
            </div>
            <div class="form-group col-md-2 d-flex justify-content-center">
                    <button mdbBtn type="button" id="btnBuscar" (click)="onBuscar()" size="sm" color="deep-purple" mdbWavesEffect>
                    <mdb-icon fas icon="search" class="mr-1"></mdb-icon>Buscar</button>                    
            </div>
            <div class="form-group col-md-2 d-flex justify-content-center" *ngIf="false">
              <button mdbBtn type="button" id="btnNuevo" (click)="onNuevo('0')" size="sm" color="deep-purple" mdbWavesEffect>
              <mdb-icon fas icon="plus-square" class="mr-1"></mdb-icon>Nuevo</button>                    
            </div>
        </div>
    </mdb-card-body>
  </mdb-card>
  <br/>
  <div style="text-align: left;padding-bottom: 5px;font-weight: 500;font-size: 13px;">Total : {{listSubmenu.response.list.length}}</div>
  <mdb-card>
    <div class="table-responsive text-nowrap">
    <table mdbTable hover="true">
        <thead>
          <tr>
            <th *ngFor="let head of headElements" scope="col">{{head}}</th>
          </tr>
        </thead>
        <tbody>
          <tr mdbTableCol *ngFor="let listSubmenu of listSubmenu.response.list | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
            <th>{{listSubmenu.name}}</th>
            <td>{{listSubmenu.order}}</td>
            <td>{{listSubmenu.status}}</td>
            <td *ngIf="false"><button type="button" (click)="onEditar(listSubmenu.id)" color="deep-purple" outline="true" class="btn-sm" mdbWavesEffect mdbBtn><mdb-icon fas icon="book" size="lg"></mdb-icon></button></td>            
          </tr>
        </tbody>
      </table>
      </div>
        <div class="text-center">
          
          <ngb-pagination [maxSize]=3 [(page)]="page" class="d-flex justify-content-center"
            [pageSize]="pageSize" [boundaryLinks]="true" [collectionSize]="listSubmenu.response.list.length">
          </ngb-pagination>
          
        </div>            
  </mdb-card>
<br>

