import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";
import { environment } from '../../../environments/environment';
import { CategoriaAdminListResponse } from '../../modal/categoria/categoriaAdminListResponse';
import { CategoriaAdminRegIdResponse } from '../../modal/categoria/categoriaAdminRegIdResponse';
import { CategoriaAdminDmlResponse } from '../../modal/categoria/categoriaAdminDmlResponse';
import { CategoriaAdminBeanInsRequest, CategoriaAdminBeanUpdRequest } from '../../modal/categoria/categoriaAdminBean';
import { HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CategoriaService {

  constructor(private httpClient:HttpClient) { }

  obtenerCategoriaAdminList(nombre:string,estado:string){
    let url = `${environment.urlBase}${environment.listCategoriaAdmin}?nombre=${nombre}&estado=${estado}`;
    //console.log("url",url);
    return this.httpClient.get<CategoriaAdminListResponse>(url).pipe(
      map(data => {   
        let response: CategoriaAdminListResponse;
        response = data;
        return response;
      },
      error =>{      
        return new Response("9999", error);
      })) 
  }

  obtenerRegistro(id:String){
    let url = `${environment.urlBase}${environment.getCategoriaAdminId}/${id}`;
    //console.log("url",url);
    return this.httpClient.get<CategoriaAdminRegIdResponse>(url).pipe(
      map(data => {   
        let response: CategoriaAdminRegIdResponse;
        response = data;
        return response;
      },
      error =>{      
        return new Response("9999", error);
      })) 
  }

  grabar(categoriaAdminBeanInsRequest:CategoriaAdminBeanInsRequest){
    let url = `${environment.urlBase}${environment.insertCategoriaAdmin}`;
    return this.httpClient.post<CategoriaAdminDmlResponse>(url,categoriaAdminBeanInsRequest).pipe(
      map(data => {
        let response: CategoriaAdminDmlResponse;
        response = data;        

        return response;        
      },
      error =>{      
        return new Response("9999", error);
      }) 
    )
  }

  update(categoriaAdminBeanUpdRequest:CategoriaAdminBeanUpdRequest,id:String){
    let url = `${environment.urlBase}${environment.updateCategoriaAdmin}/${id}`;
    return this.httpClient.put<CategoriaAdminDmlResponse>(url,categoriaAdminBeanUpdRequest).pipe(
      map(data => {
        let response: CategoriaAdminDmlResponse;
        response = data;        
        return response;        
      },
      error =>{      
        return new Response("9999", error);
      }) 
    )
  }

}
