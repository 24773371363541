<div class="container">
    <div class="row">
        <div class="col-sm-2"></div>

        <div class="col-sm-8 mt-2 mb-5">
            <form [formGroup]="registrarForm">
            <div class="row">
                <div class="col-sm-12 mt-4 ">
                    <p class="text-center h3 h3-responsive">Recuperar Contraseña</p>                    
                        <div class="md-form">
                            <input mdbInput type="password"  [ngClass]="displayFieldCss('txtPassword1')" formControlName="txtPassword1" id="txtPassword1" class="form-control">
                            <label for="txtPassword1" class="">Ingrese nueva Contreña</label>
                        </div>
                        <div class="md-form">
                            <input mdbInput type="password"  [ngClass]="displayFieldCss('txtPassword2')" formControlName="txtPassword2" id="txtPassword2" class="form-control">
                            <label for="txtPassword2" class="">Confirme nueva Contreña</label>
                        </div>

                </div>
                <div class="col-sm-12 text-center">
                    <a  class="btn w-50 text-center btn-sm btnRegistrar" (click)="registrar()" >Recuperar</a>
                </div>
            </div>  
            </form>
        <div class="col-sm-2"></div>
     
    </div>
</div>