import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';

import { UtilitarioService } from 'src/app/service/utilitarios/utilitario.service';
import { AnioService } from 'src/app/service/anio/anio.service';

import { AnioAdminListResponse } from 'src/app/modal/anio/anioAdminListResponse';
import { AnioAdminListBean } from 'src/app/modal/anio/anioAdminBean';

@Component({
  selector: 'app-bus-ediciones',
  templateUrl: './bus-ediciones.component.html',
  styleUrls: ['./bus-ediciones.component.scss']
})
export class BusEdicionesComponent implements OnInit {

  constructor(
    private anioService:AnioService,    
    private utilitarioService: UtilitarioService,
    public  modalRef: MDBModalRef,
  ) { }

  txtdescripcion_bus:String = "";
  headElements = ['Edición','Acción'];
  listEdicion:AnioAdminListResponse = new AnioAdminListResponse();
  
  //variables del padre
  x_titulo:String;
  //action del padre para el retorno de datos
  action: Subject<AnioAdminListBean> = new Subject();

  page = 1;
  pageSize = 9;

  ngOnInit(): void {
    this.cargarDatoInicial();    
  }

  onBuscar(){
    this.utilitarioService.loading = true;
    this.anioService.obtenerAdminList(this.txtdescripcion_bus.toString(), '1').subscribe(data=>{
      this.listEdicion = data;
      console.log(data);
      this.utilitarioService.loading = false;
    })
  }  

  cargarDatoInicial(){

    this.onBuscar();

  }

  onSeleccionar(objetoAnio:AnioAdminListBean){    
    this.action.next(objetoAnio);
    this.modalRef.hide();
  }

  onClosemodal(){
    this.modalRef.hide();
  }

}
