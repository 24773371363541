export class CatalogoResponse {
   httpStatus : number;
   response : {
     id : string,
     codeResponse :  string,
     response : string,
     list : [
      {
         id : string,
         title :  string,
         subtitle :  string,
         authorId :  string,
         authorName :  string,
         publicationId : string,
         publicationName :  string,
         materialId :  string,
         materialName :  string,
         categoryId :  string,
         categoryName :  string,
         yearId : string,
         yearName :  string,
         price :  string,
         quantity : string,
         stock :  string,
         image :  string,
         total :  string
      }]
    }
    constructor(){

    this.httpStatus =0;
    this.response = {
     id : "",
     codeResponse :  "",
     response : "",
     list : [
      {
         id : "",
         title :  "",
         subtitle :  "",
         authorId :  "",
         authorName :  "",
         publicationId : "",
         publicationName :  "",
         materialId :  "",
         materialName :  "",
         categoryId :  "",
         categoryName :  "",
         yearId : "",
         yearName :  "",
         price :  "",
         quantity : "",
         stock :  "",
         image :  "",
         total :  ""
      }]
    }
    }

}
