import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { UsuarioService } from 'src/app/service/usuario/usuario.service';
import { UtilitarioService } from 'src/app/service/utilitarios/utilitario.service';
import { MaestrasService } from 'src/app/service/maestras/maestras.service';
import { RespuestaComun } from 'src/app/modal/maestras/respuesta-comun';

import { AnioService } from 'src/app/service/anio/anio.service';
import { AnioAdminBeanInsRequest,AnioAdminBeanUpdRequest } from 'src/app/modal/anio/anioAdminBean';

@Component({
  selector: 'app-dml-anio',
  templateUrl: './dml-anio.component.html',
  styleUrls: ['./dml-anio.component.scss']
})
export class DmlAnioComponent implements OnInit {

  constructor(public  modalRef: MDBModalRef,
    private maestrasService: MaestrasService,    
    private utilitarioService: UtilitarioService,
    private usuarioService: UsuarioService,    
    private anioService: AnioService,    
    ) { }

  listEstado:RespuestaComun = new RespuestaComun();
  cboEstado:String = "1";
  createdDate:String = "";

  //variables del formulario reactivo
  txtDescription:String= "";
  txtOrder:String = "";
  //variables enviado desde el padre
  id:String;  
  x_titulo:String;
  x_proceso:String;

  ngOnInit(): void {

      this.cargarDatoInicial(this.x_proceso);

  }

  onClosemodal(){
    this.modalRef.hide();
  }

  onGrabar(){

    if (this.validarFormulario()==false){      
      return false;
    }
    
    let anioAdminBeanInsRequest = new AnioAdminBeanInsRequest();
    let anioAdminBeanUpdRequest = new AnioAdminBeanUpdRequest();    

    if (this.x_proceso=='I'){

      this.utilitarioService.loading = true;
      this.rellenarBean(anioAdminBeanInsRequest,anioAdminBeanUpdRequest,this.x_proceso);

      this.anioService.grabar(anioAdminBeanInsRequest).subscribe(data=>{        

        if (data.response.codeResponse=="0000"){
          this.utilitarioService.toastrSuccess("Se ha registrado satisfactoriamente.");
          (<HTMLButtonElement>document.getElementById("btnBuscar")).click();
        }else{
          this.utilitarioService.toastrError(data.response.response);
        }        

        this.utilitarioService.loading = false;
        this.onClosemodal();
        
      });

    }else{

      this.utilitarioService.loading = true;
      this.rellenarBean(anioAdminBeanInsRequest,anioAdminBeanUpdRequest,this.x_proceso);

      this.anioService.update(anioAdminBeanUpdRequest,this.id).subscribe(data=>{        

        if (data.response.codeResponse=="0000"){
          this.utilitarioService.toastrSuccess("Se ha actualizado satisfactoriamente.");
          (<HTMLButtonElement>document.getElementById("btnBuscar")).click();
        }else{
          this.utilitarioService.toastrError(data.response.response);
        }        

        this.utilitarioService.loading = false;
        this.onClosemodal();
        
      });

    }    

  }

  cargarDatoInicial(dml:String){

    this.utilitarioService.loading = true;
    this.listEstado = new RespuestaComun();    

    const cargarEstado = this.maestrasService.obtenerMaestra("TIPO_STATUS_TABLA").toPromise()
    .then(result => {
      this.listEstado = result;
    });

    if (dml=='I'){
      //carga los promesas culminadas para asignar los datos a las variables      
      Promise.all([cargarEstado])    
      .then(response => {      
        this.utilitarioService.loading = false;
      })
      .catch(error => console.log(`Error in executing ${error}`));
    }

    if (dml=='U'){
      //carga los promesas culminadas para asignar los datos a las variables
      Promise.all([cargarEstado])    
      .then(response => {      
        //obtener por id objeto
        this.anioService.obtenerRegistro(this.id).subscribe(data=>{
          this.id = data.response.list[0].id;
          this.txtDescription = data.response.list[0].name;
          this.txtOrder = data.response.list[0].order;
          this.cboEstado = data.response.list[0].enabled;
          this.createdDate = data.response.list[0].createdDate;
          this.utilitarioService.loading = false;
        });
      })
      .catch(error => console.log(`Error in executing ${error}`));
    }    

  }

  rellenarBean(anioAdminBeanInsRequest:AnioAdminBeanInsRequest,anioAdminBeanUpdRequest:AnioAdminBeanUpdRequest,dml:String){    
        
    if (dml=='I'){
      anioAdminBeanInsRequest.order = '';
      anioAdminBeanInsRequest.name =this.txtDescription;
      anioAdminBeanInsRequest.about = this.txtDescription;
      anioAdminBeanInsRequest.usuarioRegistroId = this.usuarioService.obtenerIDSessionUsuarioAdmin().toString();
      anioAdminBeanInsRequest.enabled = this.cboEstado;
    }else{
      anioAdminBeanUpdRequest.order = this.txtOrder;
      anioAdminBeanUpdRequest.id = this.id;
      anioAdminBeanUpdRequest.about =this.txtDescription;
      anioAdminBeanUpdRequest.name = this.txtDescription;
      anioAdminBeanUpdRequest.usuarioModificaId = this.usuarioService.obtenerIDSessionUsuarioAdmin().toString();      
      anioAdminBeanUpdRequest.enabled = this.cboEstado;
    }        

  }

  validarFormulario() : Boolean{    
    //valida formulario
    if (this.usuarioService.obtenerIDSessionUsuarioAdmin().toString()==null){
      this.utilitarioService.toastrError("Su session ha culminado, ingrese nuevamente al sistema.");      
      return false;
    }

    //valida tipo de comprobante
    if(this.txtDescription.trim()==""){
      this.utilitarioService.toastrInfo("Ingrese año.");
      return false;
    }

    if(this.cboEstado==""){
      this.utilitarioService.toastrInfo("Seleccione estado.");
      return false;
    }

    return true;

  }

  fc_ValNumber(event: any) {          
        const pattern = /[0-9\+\-\ ]/;
        let inputChar = String.fromCharCode(event.charCode);
    
        if (!pattern.test(inputChar)) {
          // invalid character, prevent input
          return false;
          event.preventDefault();
        }    
        return true;
  }

}
