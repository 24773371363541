import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";
import { environment } from '../../../environments/environment';
import { GrupoAdmBeanInsRequest,GrupoAdmBeanUpdRequest } from '../../modal/grupo/grupoAdmBean';
import { GrupoAdmListResponse } from '../../modal/grupo/grupoAdmListResponse';
import { GrupoAdmRegIdResponse } from '../../modal/grupo/grupoAdmRegIdResponse';
import { GrupoAdmDmlResponse } from '../../modal/grupo/grupoAdmDmlResponse';
import { ComboResponse } from '../../modal/maestras/combo-response';
import { HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class GrupoService {

  constructor(private httpClient:HttpClient) { }

  obtenerAdminList(nombre:string,estado:string,idSubmenu:string){
    let url = `${environment.urlBase}${environment.listGrupoAdmin}?nombre=${nombre}&estado=${estado}&idSubmenu=${idSubmenu}`;
    //console.log("url",url);
    return this.httpClient.get<GrupoAdmListResponse>(url).pipe(
      map(data => {   
        let response: GrupoAdmListResponse;
        response = data;
        return response;
      },
      error =>{
        return new Response("9999", error);
      })) 
  }

  obtenerRegistro(id:String){
    let url = `${environment.urlBase}${environment.getGrupoAdminId}/${id}`;
    //console.log("url",url);
    return this.httpClient.get<GrupoAdmRegIdResponse>(url).pipe(
      map(data => {   
        let response: GrupoAdmRegIdResponse;
        response = data;
        return response;
      },
      error =>{      
        return new Response("9999", error);
      })) 
  }

  grabar(grupoAdmBeanInsRequest:GrupoAdmBeanInsRequest){
    let url = `${environment.urlBase}${environment.insertGrupoAdmin}`;
    return this.httpClient.post<GrupoAdmDmlResponse>(url,grupoAdmBeanInsRequest).pipe(
      map(data => {
        let response: GrupoAdmDmlResponse;
        response = data;        

        return response;        
      },
      error =>{      
        return new Response("9999", error);
      }) 
    )
  }

  update(grupoAdmBeanUpdRequest:GrupoAdmBeanUpdRequest,id:String){
    let url = `${environment.urlBase}${environment.updateGrupoAdmin}/${id}`;
    return this.httpClient.put<GrupoAdmDmlResponse>(url,grupoAdmBeanUpdRequest).pipe(
      map(data => {
        let response: GrupoAdmDmlResponse;
        response = data;        
        return response;        
      },
      error =>{      
        return new Response("9999", error);
      }) 
    )
  }

  obtenerListCombo(){
    let url = `${environment.urlBase}${environment.listGrupoCombo}`;
    //console.log("url",url);
    return this.httpClient.get<ComboResponse>(url).pipe(
      map(data => {   
        let response: ComboResponse;
        response = data;
        return response;
      },
      error =>{
        return new Response("9999", error);
      })) 
  }

}
