<!--Header-->
<div class="modal-header text-center" style="color: white;background-color: #544d84">    
    <h4 class="modal-title w-100">{{x_titulo}}</h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onClosemodal()">
      <span aria-hidden="true" class="white-text">&times;</span>
    </button>
</div>

<!--Body-->
<div class="modal-body">

  <mdb-card>
    <mdb-card-body>

      <div class="form-row">
        <div class="col-3">Código:</div>
        <div class="col-9">
            {{id}}
        </div>
      </div>
      <br>
      <div class="form-row form-group">
        <div class="col-3">Tipo de Regalo:</div>
        <div class="col-9">                
            <select class="browser-default custom-select" (change)="onchangeControl();"  [(ngModel)]="cboGroupId">                
                <option *ngFor="let listGrupo of listGrupo.response.list" [value]="listGrupo.id">{{listGrupo.name}}</option>
            </select>
        </div>
      </div>
      <div class="form-row form-group">
        <div class="col-3">Temática:</div>
        <div class="col-9">                
            <select class="browser-default custom-select" [(ngModel)]="cboTopicId">
                <option value="" selected>Seleccione</option>
                <option *ngFor="let listTopico of listTopico.response.list" [value]="listTopico.id">{{listTopico.name}}</option>
            </select>
        </div>        
      </div>
      <div class="form-row form-group" *ngIf="productoControles.showProductTitulo">
        <div class="col-3">Nombre / Titulo:</div>
        <div class="col-9">
          <input mdbInput type="text" maxlength="200"  class="form-control" [(ngModel)]="txtTitle" placeholder="Ingrese titulo">
        </div>        
      </div>      
      <div class="form-row form-group" *ngIf="productoControles.showProductSubtitulo">
        <div class="col-3">Sub Titulo:</div>
        <div class="col-9">                
          <input mdbInput type="text" maxlength="200"  class="form-control" [(ngModel)]="txtSubTitle" placeholder="Ingrese sub titulo">
        </div>        
      </div>      
      <div class="form-row form-group" *ngIf="productoControles.showProductDescripcion">
        <div class="col-3">Descripción:</div>
        <div class="col-9">                
          <input mdbInput type="text" maxlength="200"  class="form-control" [(ngModel)]="txtDescription" placeholder="Ingrese descripción">
        </div>        
      </div>      
      <div class="form-row form-group" *ngIf="productoControles.showMaterial">
        <div class="col-3">Material:</div>
        <div class="col-9">                
          <input mdbInput type="text" maxlength="200"  class="form-control" [(ngModel)]="txtMaterial" placeholder="Ingrese descripcion de material">      
        </div>        
      </div>      
      <div class="form-row form-group" *ngIf="productoControles.showLibretaNroPaginas">
        <div class="col-3">Nro. de Páginas:</div>
        <div class="col-9">                
          <input mdbInput type="text" maxlength="5" (keypress)="fc_ValNumber($event)" class="form-control" [(ngModel)]="txtPages" placeholder="Ingrese total de páginas">
        </div>        
      </div>      
      <div class="form-row form-group" *ngIf="productoControles.showLibretaPapel">
        <div class="col-3">Papel:</div>
        <div class="col-9">                
          <input mdbInput type="text" maxlength="200"  class="form-control" [(ngModel)]="txtPaper" placeholder="Ingrese tipo de papel">
        </div>        
      </div>
      <div class="form-row form-group" *ngIf="productoControles.showLibretaGrosorPapel">
        <div class="col-3">Gramaje  de papel:</div>
        <div class="col-9">                
          <input mdbInput type="text" maxlength="200"  class="form-control" [(ngModel)]="txtGrammage" placeholder="50 grm, 75 grm ....">
        </div>        
      </div>      
      <div class="form-row form-group" *ngIf="productoControles.showProductUnidadesMeasure">
        <div class="col-3">Unidad de Medida:</div>
        <div class="col-9">
          <input mdbInput type="text" maxlength="200"  class="form-control" [(ngModel)]="txtMeasure" placeholder="Unidad, packs ...">
        </div>        
      </div>      
      <div class="form-row form-group" *ngIf="productoControles.showProductTiempoVidaParaEdades">
        <div class="col-3">Edad:</div>
        <div class="col-9">
          <input mdbInput type="text" maxlength="200"  class="form-control" [(ngModel)]="txtLife" placeholder="edades de">
        </div>
      </div>      
      <div class="form-row form-group" *ngIf="productoControles.showProductTamanioSize">
        <div class="col-3">Tamaño:</div>
        <div class="col-9">
          <input mdbInput type="text" maxlength="200"  class="form-control" [(ngModel)]="txtSize" placeholder="">
        </div>        
      </div>      
      <div class="form-row form-group" *ngIf="productoControles.showProductPeso">
        <div class="col-3">Peso:</div>
        <div class="col-9">                
          <input mdbInput type="text" maxlength="5" style="width: 50%;float:left;" (keypress)="fc_ValNumber($event)" class="form-control" [(ngModel)]="txtWeight" placeholder="Ingrese peso"> <span style="float:left;padding-left: 10px;padding-top: 5px;">(g)</span>
        </div>        
      </div>      
      <div class="form-row form-group" *ngIf="productoControles.showProductComentario">
        <div class="col-3">Comentario:</div>
        <div class="col-9">
          <input mdbInput type="text" maxlength="250"  class="form-control" [(ngModel)]="txtComments" placeholder="Ingrese comentario">
        </div>        
      </div>                  
      <div class="form-row form-group">
        <div class="col-3">Colocar Novedades en Regalo :</div>
        <div class="col-9">
            <div class="px-4">
                <mdb-checkbox [(ngModel)]="txtNovelty" class="check-input"></mdb-checkbox>
            </div>        
        </div>
      </div>
      <div class="form-row form-group">
        <div class="col-3">Colocar Novedades en Tematica :</div>
        <div class="col-9">
            <div class="px-4">
                <mdb-checkbox [(ngModel)]="txtTopicNovelty" class="check-input"></mdb-checkbox>
            </div>        
        </div>
      </div>   
      <div class="form-row form-group">
        <div class="col-3">Orden:</div>
        <div class="col-9">                
          <input mdbInput type="number" pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==3) return false;" min="1" max="999" class="form-control" [(ngModel)]="txtOrder" id="txtOrder">
        </div>        
      </div>      
      <div class="form-row form-group" *ngIf="productoControles.showEnabled">
        <div class="col-3">Estado:</div>
        <div class="col-9">                
            <select class="browser-default custom-select" [(ngModel)]="cboEnabled">
                <option value="" selected>Seleccione</option>
                <option *ngFor="let estado of listEstado.response.list" [value]="estado.masterName">{{estado.masterDescription}}</option>
            </select>
        </div>        
      </div>      
    </mdb-card-body>
  </mdb-card>  
  <br>
  <mdb-card>
    <mdb-card-body>
        <div class="form-row">
          <div class="col-6">
            <div class="text-center">
              <span>Seleccionar o Reemplazar Imagen</span><br>
              <span style="font-size: 11px;">Tamaño en pixeles sugerido, entre 950 x 1400 de altura,máximo 1mb.</span>
              <ngx-img (onSelect)="onSelect($event)" (onReset)="onReset()">
              </ngx-img>
            </div>
          </div>
          <div class="col-6">
            <div class="text-center">
              <span style="display: block;">Imagen Actual</span><br>
              <img [src]="x_url" alt="Ninguno" class="img-thumbnail" style="height: 400px;">
            </div>
          </div>    
        </div>
    </mdb-card-body>
  </mdb-card>    
  <br>
  <!--Footer-->
<div class="modal-footer justify-content-center">
    <button mdbBtn type="button" (click)="onGrabar()" size="sm" class="btn btn-deep-purple btn-rounded" mdbWavesEffect>
        <mdb-icon fas icon="save" class="mr-1"></mdb-icon>Grabar</button>
</div>

