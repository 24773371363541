export class AutorAdminListBean{
    id: String;
    name: String;
    about: String;
    order: String;
    usuarioRegistroId: String;
    usuarioModificaId: String;
    enabled: String;
    status: String;
    createdDate: String;
}

export class AutorAdminIdBean{
    id: String;
    name: String;
    about: String;
    order: String;
    usuarioRegistroId: String;
    usuarioModificaId: String;
    enabled: String;
    status: String;
    createdDate: String;
}

export class AutorAdminBeanInsRequest{
    about: String;
    enabled: String;
    name: String;
    order: String;
    usuarioRegistroId: String;
}

export class AutorAdminBeanUpdRequest{    
    about: String;
    enabled: String;
    id: String;
    name: String;
    order: String;
    usuarioModificaId: String;
}



