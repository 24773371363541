import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RespuestaComun } from 'src/app/modal/maestras/respuesta-comun';
import { MaestrasService } from 'src/app/service/maestras/maestras.service';
import { UtilitarioService } from 'src/app/service/utilitarios/utilitario.service';
import {UsuarioRegistroRequest} from 'src/app/modal/usuario/usuario-registro-request';
import { UsuarioService } from 'src/app/service/usuario/usuario.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-registrar',
  templateUrl: './registrar.component.html',
  styleUrls: ['./registrar.component.scss']
})
export class RegistrarComponent implements OnInit {
  registrarForm: FormGroup;
  listTipoDocumento:RespuestaComun;
  registrarRequest:UsuarioRegistroRequest;
  maxLengthTxtNroDocumento:number=0;
  //@ViewChild(LoginComponent) loginComponent:LoginComponent;

  constructor(private routerLink:Router,private fb: FormBuilder,private maestraService:MaestrasService,private utilService:UtilitarioService,private usuarioService:UsuarioService) { 
  this.createForm();
  }


  createForm(){
    this.registrarForm = this.fb.group({
     
      txtCorreo:['',Validators.required],
      txtNombre:['',Validators.required],
      txtApellidos:['',Validators.required],
      txtTipoDocumento:['',Validators.required],
      txtNroDocumento:['',Validators.required],
      txtFechaNacimiento:['',Validators.required],
      txtTelefono:['',Validators.required],
      txtPassword:['',Validators.required],      
      txtPassword2:['',Validators.required],

    });
  }
  displayFieldCss(field: string) {
    if (this.registrarForm.get(field).touched){
      return {
        'is-invalid': this.isFieldValid(field),
        'is-valid': !this.isFieldValid(field)
      };      
    }
  }

  tipoDeDocumento(){
    this.listTipoDocumento = new RespuestaComun();
    this.maestraService.obtenerMaestra("TIPO_DOCUMENTO").subscribe(data=>{
      this.listTipoDocumento = data;
      console.log(data);
    })
  }

  onChangeTipoDoc(codigo:String){

    switch (codigo) {
      case "1":
        this.maxLengthTxtNroDocumento = 8;        
        break;
      case "2":
        this.maxLengthTxtNroDocumento = 15;        
        break;
      case "3":
        this.maxLengthTxtNroDocumento = 15;                
        break;
      case "4":
        this.maxLengthTxtNroDocumento = 15;
        break;
      default:
        break;
    }

    (<HTMLInputElement>document.getElementById("txtNroDocumento")).value = "";

  }
  
  valirdarPass(){
    let retorno = true;
    let pass1 = this.registrarForm.get("txtPassword").value;
    let pass2 = this.registrarForm.get("txtPassword2").value;
    if(pass1===pass2){
      retorno = false;
    }
    return retorno;
  }

  fc_ValTextDocumento(event: any) { 
    
    switch (this.registrarForm.get("txtTipoDocumento").value) {
      case "1":case "2":      
        const pattern = /[0-9\+\-\ ]/;
        let inputChar = String.fromCharCode(event.charCode);
    
        if (!pattern.test(inputChar)) {
          // invalid character, prevent input
          return false;
          event.preventDefault();
        }
        break;
      case "3":case "4":
        return true;
        break;
      default:
        break;
    }
    
  }

  formatearFecha(fecha){
    let fechaNac:string="";
    if(fecha!=null){
      var date = new Date(fecha+"T05:00:00Z");
      ////console.log(date);
      var day = date.getDate();
      var month = date.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
      var year = date.getFullYear();
      fechaNac = day+"/"+month+"/"+year;
    //console.log(fechaNac);
    }
    return fechaNac
  }
  registrar(){  
    if (this.registrarForm.valid) {
      this.utilService.loading=true;
      if(this.valirdarPass()){
        
        this.utilService.toastrError("Contraseñas Incorrectas");
        this.utilService.loading=false;
        return false;
      }
      
      this.registrarRequest = new UsuarioRegistroRequest();
      this.registrarRequest.birthDate = this.formatearFecha(this.registrarForm.get("txtFechaNacimiento").value);
      this.registrarRequest.email = this.registrarForm.get("txtCorreo").value;
      this.registrarRequest.name = this.registrarForm.get("txtNombre").value;
      this.registrarRequest.surname = this.registrarForm.get("txtApellidos").value;
      this.registrarRequest.typeDocument = this.registrarForm.get("txtTipoDocumento").value;
      this.registrarRequest.telephone = this.registrarForm.get("txtTelefono").value;
      this.registrarRequest.password = this.registrarForm.get("txtPassword").value;
      this.registrarRequest.document = this.registrarForm.get("txtNroDocumento").value;

      if((this.registrarRequest.document.length > 8 ||  this.registrarRequest.document.length < 8) && this.registrarRequest.typeDocument=='1'){
        this.utilService.toastrError("El número de DNI debe ser de 8 dígitos");
        this.utilService.loading=false;
        return false;
      }

      if((this.registrarRequest.document.length > 15) && this.registrarRequest.typeDocument=='2'){
        this.utilService.toastrError("El número de Carnet de Extranjeria no puede ser mayor a 15 dígitos");
        this.utilService.loading=false;
        return false;
      }

      if((this.registrarRequest.document.length > 15) && this.registrarRequest.typeDocument=='3'){
        this.utilService.toastrError("El número de Pasaporte no puede ser mayor a 15 dígitos");
        this.utilService.loading=false;
        return false;
      }

      //console.log(this.registrarRequest);
      this.usuarioService.registrarUsuario(this.registrarRequest).subscribe(data=>{
        console.log(data);
        if(data.response.codeResponse==="0000"){
          this.utilService.toastrSuccess(data.response.response);
          this.routerLink.navigate(['/']);
          // let credenciales:UsuarioRequest;
          // credenciales = new UsuarioRequest();
          // credenciales.email=this.registrarRequest.email;
          // credenciales.password=this.registrarRequest.password;
          // this.usuarioService.loginUser(credenciales).subscribe(data=>{
          //     this.utilService.toastrSuccess("Bienvenido "+data.response.list[0].name)
          //     this.loginComponent.flagLogin=true;
          //     this.routerLink.navigate(['/']);
          //   });
        }
        else{
          this.utilService.toastrError(data.response.response);
        }
        this.utilService.loading=false;
      })
    }
    else{
      this.validateAllFormFields(this.registrarForm);
      this.utilService.toastrError("Rellene los campos obligatorios");
    }

  }
  isFieldValid(field: string) {
    return !this.registrarForm.get(field).valid && this.registrarForm.get(field).touched;
  }   

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  verificarSession(){
    if(this.usuarioService.existeSesionUsuario()){
      this.routerLink.navigate(['/']);
    }
  }
  ngOnInit(): void {
    this.tipoDeDocumento();
    this.verificarSession();
  }
}
