import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { map } from "rxjs/operators";
import { environment } from '../../../environments/environment';
import { OrderAdminResponse } from '../../modal/order-pago/orderAdminResponse';
import { OrderEstadoContResponse } from '../../modal/order-pago/orderEstadoContResponse';
import { OrderCambioEstadoRequest } from '../../modal/order-pago/orderCambioEstadoRequest';
import { OrderCambioEstadoResponse } from '../../modal/order-pago/orderCambioEstadoResponse';
import { OrderCabeceraResponse } from '../../modal/order-pago/orderCabeceraResponse';

@Injectable({
  providedIn: 'root'
})
export class OrderPagoService {

  constructor(private httpClient:HttpClient) { }

  obtenerOrderAdmin(dateIni:string,dateFin:String,nroOrder:string,stateId:String,nroDocumento:String, sedeId:String){
    let url = `${environment.urlBase}${environment.orderAdmin}?dateIni=${dateIni}&dateFin=${dateFin}&nroOrder=${nroOrder}&nroDocument=${nroDocumento}&stateId=${stateId}&sedeId=${sedeId}`;
    //console.log("Url servicio: ",url);
    //console.log("SedeId servicio: ",sedeId);
    return this.httpClient.get<OrderAdminResponse>(url).pipe(
      map(data => {   
        let response: OrderAdminResponse;
        response = data;
        return response;
      },
      error =>{      
        return new Response("9999", error);
      })) 
  } 
  /*
  obtenerOrderAdmin(dateIni:string,dateFin:String,nroOrder:string,stateId:String,nroDocumento:String){
    let url = `${environment.urlBase}${environment.orderAdmin}?dateIni=${dateIni}&dateFin=${dateFin}&nroOrder=${nroOrder}&nroDocument=${nroDocumento}&stateId=${stateId}`;
    //console.log("url",url);
    return this.httpClient.get<OrderAdminResponse>(url).pipe(
      map(data => {   
        let response: OrderAdminResponse;
        response = data;
        return response;
      },
      error =>{      
        return new Response("9999", error);
      })) 
  }
  */ 

  obtenerOrderCabecera(idOrder:String){
    let url = `${environment.urlBase}${environment.orderCabecera}/${idOrder}`;
    //console.log("url",url);
    return this.httpClient.get<OrderCabeceraResponse>(url).pipe(
      map(data => {   
        let response: OrderCabeceraResponse;
        response = data;
        return response;
      },
      error =>{      
        return new Response("9999", error);
      })) 
  } 


  obtenerEstadosContinuos(idEstado:String){
    let url = `${environment.urlBase}${environment.orderEstadoContinuos}/${idEstado}`;
    //console.log("url",url);
    return this.httpClient.get<OrderEstadoContResponse>(url).pipe(
      map(data => {   
        let response: OrderEstadoContResponse;
        response = data;
        return response;
      },
      error =>{      
        return new Response("9999", error);
      })) 
  }

  grabarCambioEstados(orderCambioEstadoRequest:OrderCambioEstadoRequest){
    let url = `${environment.urlBase}${environment.orderEstadoGrabar}/${orderCambioEstadoRequest.orderId}`;
    return this.httpClient.put<OrderCambioEstadoResponse>(url,orderCambioEstadoRequest).pipe(
      map(data => {
        let response: OrderCambioEstadoResponse;
        response = data;        
        return response;        
      },
      error =>{      
        return new Response("9999", error);
      }) 
    )
  } 
  
}


