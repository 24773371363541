export class InventarioProListBean{
    inventoryId: String;
    inventoryState: String;
    sigaId: String;
    sigaSecuencia: String;
    productId: String;
    productTitle: String;
    productSubtitle: String;
    productImage: String;
    groupId: String;
    groupName: String;
    topicId: String;
    topicName: String;
    materialId: String;
    materialName: String;
    sizeId: String;
    sizeName: String;
    inventoryPrice: String;
    inventoryStock: String;
    inventoryStockBoolean: String;
    inventoryDescription: String;
    inventoryCurrency: String;
    inventoryStockOrigin: String;
    usuarioModificaId: String;
    usuarioCrearId: String;
    enabled: String;
}

export class InventProListAddBean{
inventory_id: String;
inventory_description: String;
inventory_currency: String;
inventory_price: String;
inventory_stock_origin: String;
inventory_stock: String;
product_id: String;
size_id: String;
siga_id: String;
siga_secuencia: String;
created_date: String;
updated_date: String;
estado_description: String;
size_description: String;
}

export class InventarioProIdBean{
    inventoryId: String;
    inventoryState: String;
    sigaId: String;
    sigaSecuencia: String;
    productId: String;
    productTitle: String;
    productSubtitle: String;
    productImage: String;
    groupId: String;
    groupName: String;
    topicId: String;
    topicName: String;
    materialId: String;
    materialName: String;
    sizeId: String;
    sizeName: String;
    inventoryPrice: String;
    inventoryStock: String;
    inventoryStockBoolean: String;
    inventoryDescription: String;
    inventoryCurrency: String;
    inventoryStockOrigin: String;
    usuarioModificaId: String;
    usuarioCrearId: String;
    enabled: String;
}

export class InventarioProBeanInsRequest{
    enabled: String;
    inventoryCurrency: String;
    inventoryDescription: String;
    inventoryId: String;
    inventoryPrice: String;
    inventorySizeId: String;
    inventoryStock: String;
    inventoryStockOrigin: String;
    productId: String;
    sigaId: String;
    sigaSecuencia: String;
    usuarioCrearId: String;
}

export class InventarioProBeanUpdRequest{    
    enabled: String;
    inventoryCurrency: String;
    inventoryDescription: String;
    inventoryId: String;
    inventoryPrice: String;
    inventorySizeId: String;
    inventoryStock: String;
    inventoryStockOrigin: String;
    productId: String;
    sigaConcepto: String;
    sigaId: String;
    usuarioModificaId: String;
}

export class InventProSigaBeanUpdRequest{    
    product_id: String;
	siga_id: String;
	siga_secuencia: String;
	usuarioCrearId: String;
}