export class CatalogoRequest {
     authorId : string;
     categoryId :  string;
     page :  string;
     publicationId :  string;
     size :  string;
     title :   string;
     yearId :  string;
     constructor(){
        this.authorId = "0";
        this.categoryId =  "0";
        this.page =  "0";
        this.publicationId =  "0";
        this.size =  "0";
        this.title =   "";
        this.yearId =  "0";
     }
}
