import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import {LibroDetalle} from 'src/app/modal/catalogo/libro-detalle';
import { NovedadesResponse } from 'src/app/modal/catalogo/novedades-response';
import { NovedadesService } from 'src/app/service/catalogo/novedades.service';
import { CarritoService } from 'src/app/service/carrito/carrito.service';
import { UtilitarioService } from 'src/app/service/utilitarios/utilitario.service';
import { UsuarioService } from 'src/app/service/usuario/usuario.service';
import {SourvenirDetalleResponse} from 'src/app/modal/sourvenir/sourvenir-detalle-response';
import { CarritoHeader } from 'src/app/modal/carrito/carrito-header';
@Component({
  selector: 'app-detalle-sourvenir',
  templateUrl: './detalle-sourvenir.component.html',
  styleUrls: ['./detalle-sourvenir.component.scss']
})
export class DetalleSourvenirComponent implements OnInit {

  idSourvenirProducto:string;
  flgAgregar:string = "0";
  //detalleLibro:LibroDetalle = new LibroDetalle();

  detalleSourvenir:SourvenirDetalleResponse = new SourvenirDetalleResponse();

  stockLibro=0;
  librosRecomendados:NovedadesResponse;
  cantidadLibro:number=1;
  precioTotal:number=1;
  sizeID:String="0";
  flagSize=false;


  myThumbnail="https://wittlock.github.io/ngx-image-zoom/assets/thumb.jpg";
  
  Arr = Array;
  constructor(  private route: ActivatedRoute,
    private novedadesService:NovedadesService ,
    private router : Router,
    private carritoService:CarritoService ,
    private usuarioService:UsuarioService,
    private utilitarioService: UtilitarioService) {
    this.idSourvenirProducto=this.route.snapshot.params.idSourvenirProducto
  }

  obtenerDetalleLibroAgregarCantidad(id){
    //this.sizeID = '0';    

    //if tiene talla
    if(this.detalleSourvenir.response.list[0].size.length>0){

      if(this.sizeID=="0"){
        this.stockLibro=1;
        this.utilitarioService.toastrInfo("Seleccione una talla");        
        return false;
      }else{
        
        const result = this.detalleSourvenir.response.list[0].size.find( ({ id }) => id ==  this.sizeID);        
        this.detalleSourvenir.response.list[0].quantity = result.stock;
        this.stockLibro = Number(result.stock);

      }

      console.log(this.sizeID);
      console.log(this.stockLibro);

    }

    if(this.cantidadLibro>Number(this.detalleSourvenir.response.list[0].quantity) || this.cantidadLibro==0){
      this.utilitarioService.toastrWarning("Cantidad debe ser estar entre 1 y "+this.stockLibro);
      this.cantidadLibro = this.stockLibro;
      return false;
    }
 
    if (this.cantidadLibro<1){
      this.cantidadLibro = 1;
    }
    if(this.cantidadLibro>10){
      this.cantidadLibro = 10;
      this.utilitarioService.toastrInfo("cantidad maxíma : " + "10");
    }

    this.detalleSourvenir = new SourvenirDetalleResponse();
    this.utilitarioService.loading=true;

    if(this.cantidadLibro>0){

      this.novedadesService.obtenerDetalleSourvenir(id).subscribe(data=>{
        this.detalleSourvenir = data;
        
        this.precioTotal = Number((this.cantidadLibro * Number(this.detalleSourvenir.response.list[0].price)).toFixed(2));
        
        if(this.detalleSourvenir.response.list[0].size.length>0){
          this.flagSize=true;
          const result = this.detalleSourvenir.response.list[0].size.find( ({ id }) => id ==  this.sizeID);        
          this.detalleSourvenir.response.list[0].quantity = result.stock;
          if(Number(result.stock)==0){
            this.detalleSourvenir.response.list[0].stock="0";
          }
        }        


        if(Number(this.detalleSourvenir.response.list[0].quantity)<10){
          this.stockLibro = Number(this.detalleSourvenir.response.list[0].quantity);
        }
        else{
          this.stockLibro = 10;
        }

        if(this.detalleSourvenir.response.list[0].stock=="0"){
          this.cantidadLibro = Number(this.detalleSourvenir.response.list[0].stock);
        }

        
        /* if(this.detalleSourvenir.response.list[0].size.length>0){

          this.flagSize=true;
          if(this.detalleSourvenir.response.list[0].size.length==1){
            this.sizeID = this.detalleSourvenir.response.list[0].size[0].id;
          }

        } */

        this.utilitarioService.loading=false;
        //console.log(this.detalleLibro);
        //console.log(this.stockLibro);
      })
    }
    else{
      this.cantidadLibro=1;
    }
    
  }

  obtenerDetalleLibro(id){

    this.sizeID = '0';
      
    if (this.cantidadLibro<1){
      this.cantidadLibro = 1;
    }
    if(this.cantidadLibro>10){
      this.cantidadLibro = 10;
      this.utilitarioService.toastrInfo("cantidad maxíma : " + "10");
    }

    this.detalleSourvenir = new SourvenirDetalleResponse();
    this.utilitarioService.loading=true;

    if(this.cantidadLibro>0){

      this.novedadesService.obtenerDetalleSourvenir(id).subscribe(data=>{
        this.detalleSourvenir = data;
        console.log(data);
        this.precioTotal = Number((this.cantidadLibro * Number(this.detalleSourvenir.response.list[0].price)).toFixed(2));
  
        if(Number(this.detalleSourvenir.response.list[0].quantity)<10){
          this.stockLibro = Number(this.detalleSourvenir.response.list[0].quantity);
        }
        else{
          this.stockLibro = 10;
        }

        if(this.detalleSourvenir.response.list[0].stock=="0"){
          this.cantidadLibro = Number(this.detalleSourvenir.response.list[0].stock);
        }

        if(this.detalleSourvenir.response.list[0].size.length>0){
          this.flagSize=true;
          this.stockLibro = 1;
          if(this.detalleSourvenir.response.list[0].size.length==1){
            this.sizeID = this.detalleSourvenir.response.list[0].size[0].id;
          }
        }

        this.utilitarioService.loading=false;
        //console.log(this.detalleLibro);
        //console.log(this.stockLibro);
      })
    }
    else{
      this.cantidadLibro=1;
    }
    
  }






  obtenerLibrosRecomendados(id){
    this.librosRecomendados = new NovedadesResponse();
    this.novedadesService.obtenerSourvenirRecomendados(id).subscribe(data=>{
      this.librosRecomendados = data;
    })
  }
  slideConfig = {"slidesToShow": 4, "slidesToScroll": 1,"responsive": [
    {
      'breakpoint': 600,
      'settings': {
        'slidesToShow': 1
              }
    }
      
    
  ]};
  cargarDetalleLibro(id){    
    this.obtenerDetalleLibro(id);
    this.obtenerLibrosRecomendados(id);
  }
  
  // agregarCarrito(){
   
  //   this.flgAgregar = "1";
  //   let idLibro = this.detalleSourvenir.response.list[0].id;
  //   let cantidad = this.cantidadLibro;
  //   let tipoProducto = "2";

  //   if(this.detalleSourvenir.response.list[0].size.length>0){
  //     if(this.sizeID=="0"){
  //       this.utilitarioService.toastrInfo("Seleccione una talla");
  //       return false;
  //     }      
  //   }
  //   let sizeID = this.sizeID;

  //   let libroArray = {};
  //   libroArray ={id:idLibro,cantidad:cantidad,tipoProducto:tipoProducto,sizeID:sizeID};
  //   this.carritoService.agregarCarrito(libroArray);
  //   this.utilitarioService.toastrSuccess('Agregado al carrito');

  // }

  agregarCarrito(){

    this.flgAgregar = "1";
    let idLibro = this.detalleSourvenir.response.list[0].id;
    let cantidad = this.cantidadLibro;
    let tipoProducto = "2";

    if(this.detalleSourvenir.response.list[0].size.length>0){
      if(this.sizeID=="0"){
        this.utilitarioService.toastrInfo("Seleccione una talla");
        return false;
      }      
    }

    let sizeID = this.sizeID;

    let libroArray = {};
    libroArray ={id:idLibro,cantidad:cantidad,tipoProducto:tipoProducto,sizeID:sizeID};

    let carritoSesionTemp = new CarritoHeader();
    carritoSesionTemp = this.carritoService.obtenerCarritoCompras();

    for(let i=0;i<carritoSesionTemp.lista.length;i++){

      if(carritoSesionTemp.lista[i].id==idLibro && carritoSesionTemp.lista[i].sizeID==sizeID){
        let cantidadTemp = carritoSesionTemp.lista[i].cantidad;
        let cantidadSumaTemp = Number(cantidadTemp) + Number(cantidad);
        if(Number(cantidadSumaTemp)>this.stockLibro){
          this.utilitarioService.toastrWarning("El limite de stock es "+this.stockLibro);
          return false;
        }
      }

    }
    
    if(carritoSesionTemp.lista)


    this.carritoService.agregarCarrito(libroArray);
    this.utilitarioService.toastrSuccess('Agregado al carrito');
    
  }



  onComprar(){
    if(this.usuarioService.obtenerSesionUsuario()){
    let idLibro = this.detalleSourvenir.response.list[0].id;
    let cantidad = this.cantidadLibro;
    let tipoProducto = "2";
    let sizeID = this.sizeID;
    let libroArray = {};
    
    this.carritoService.obtenerCantidadCarrito();
    if(this.detalleSourvenir.response.list[0].size.length>0){
      if(this.sizeID=="0"){
        this.utilitarioService.toastrInfo("Seleccione una talla");
        return false;
      }      
    }
    libroArray ={id:idLibro,cantidad:cantidad,tipoProducto:tipoProducto,sizeID:sizeID};
      //si agrega y limpio el carrito
      if((this.flgAgregar == "1") && ( this.carritoService.cantidadCarrito == 0)){
        this.carritoService.obtenerCantidadCarrito();
        this.carritoService.agregarCarrito(libroArray);
        this.router.navigate(['/procesarpago']);
        return;
      }
      //si no agrega, adiciona automaticamente al carrito
      if(this.flgAgregar == "0"){
      this.carritoService.obtenerCantidadCarrito();
      this.carritoService.agregarCarrito(libroArray);
      this.router.navigate(['/procesarpago']);
      return;
      }
      //si flag es 1 y no entra en las 2 condiciiones anteriores
      this.router.navigate(['/procesarpago']);
    }
    else{
      this.utilitarioService.toastrWarning("Necesita iniciar sesión")
    }
  }  
  regresarPagina(){
    window.history.back();
  }
  slickInit(e) {
    //console.log('slick initialized');
  }
  
  breakpoint(e) {
    //console.log('breakpoint');
  }
  
  afterChange(e) {
    //console.log('afterChange');
  }
  
  beforeChange(e) {
    //console.log('beforeChange');
  }

  ngOnInit(): void {
    //Redireccion al Inicio Sourve Desac
   // this.router.navigate(['/']);

    this.obtenerDetalleLibro(this.idSourvenirProducto);
    this.obtenerLibrosRecomendados(this.idSourvenirProducto);
   // //console.log("this",this.idLibro)
  }

  onChangeTalla(idTalla:String){

    this.cantidadLibro = 1;

    const result = this.detalleSourvenir.response.list[0].size.find( ({ id }) => id ==  idTalla);
    console.log(result);
    this.detalleSourvenir.response.list[0].quantity = result.stock;        
    this.stockLibro = Number(result.stock);
    
    if (Number(result.stock)==0){
      this.detalleSourvenir.response.list[0].stock = "0";
      
    }else if (Number(result.stock)>0){
      this.detalleSourvenir.response.list[0].stock = "1";
    }

  }


}
