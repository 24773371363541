import { Component, OnInit } from '@angular/core';
import { MDBModalRef, MDBModalService} from 'angular-bootstrap-md';
import { UsuarioService } from 'src/app/service/usuario/usuario.service';
import { UtilitarioService } from 'src/app/service/utilitarios/utilitario.service';
import { MaestrasService } from 'src/app/service/maestras/maestras.service';
import { RespuestaComun } from 'src/app/modal/maestras/respuesta-comun';

import { InventProListAddBean } from 'src/app/modal/inventario-producto/inventarioProBean';

import { ProductoService } from 'src/app/service/producto/producto.service';
import { ProductoControles } from 'src/app/modal/producto/productoBean';
import { InventarioProdService } from 'src/app/service/inventario-prod/inventario-prod.service';
import { InventarioProBeanInsRequest,InventarioProBeanUpdRequest } from 'src/app/modal/inventario-producto/inventarioProBean';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-add-inventario',
  templateUrl: './add-inventario.component.html',
  styleUrls: ['./add-inventario.component.scss']
})
export class AddInventarioComponent implements OnInit {

  constructor(public  modalRef: MDBModalRef,
    private maestrasService: MaestrasService,    
    private utilitarioService: UtilitarioService,
    private usuarioService: UsuarioService,    
    private inventarioProdService: InventarioProdService,
    private modalService: MDBModalService,
    private productoService:ProductoService,
    ) { }

  modalRefConceptoBus: MDBModalRef;

  listEstado:RespuestaComun = new RespuestaComun();
  listTalla:RespuestaComun = new RespuestaComun();  
  productoControles:ProductoControles = new ProductoControles();

  cboEstado:String = "1";  
  cboTalla:String = "";

  txtinventoryId: String = "";  
  txtinventorySizeId: String = "";
  txtinventoryStock: String = "";
  txtinventoryStockOrigin: String = "";
  txtproductId: String = "";  
  txtusuarioCrearId:String = "";

  //variables enviado desde el padre
  id:String;
  x_titulo:String;
  x_proceso:String;
  x_titulo_libro:String;
  x_groupid:String;
  inventarioProBeanInsRequest : InventarioProBeanInsRequest;
  inventarioProBeanUpdRequest : InventarioProBeanUpdRequest; 
  listInventProAddBean:Array<InventProListAddBean> = [];  

  action: Subject<String> = new Subject();
  showTallaTable:boolean = false;

  ngOnInit(): void {
    console.log(this.id);
    console.log(this.inventarioProBeanInsRequest);
    console.log(this.inventarioProBeanUpdRequest);
    this.cargarDatoInicial(this.x_proceso);
  }

  onClosemodal(){
    this.modalRef.hide();
  }

  onGrabar(){

    if (this.validarFormulario()==false){      
      return false;
    }

    if (this.x_proceso=='I'){

      this.utilitarioService.loading = true;
      this.rellenarBean(this.inventarioProBeanInsRequest,this.inventarioProBeanUpdRequest,this.x_proceso);
      


      this.inventarioProdService.grabar(this.inventarioProBeanInsRequest).subscribe(data=>{

        if (data.response.codeResponse=="0000"){
          this.utilitarioService.toastrSuccess("Se ha registrado satisfactoriamente.");
          this.action.next('1');
          (<HTMLButtonElement>document.getElementById("btnBuscar")).click();
        }else{
          this.action.next('0');
          this.utilitarioService.toastrError(data.response.response);
        }

        this.utilitarioService.loading = false;
        this.onClosemodal();

      });

    }else{

      this.utilitarioService.loading = true;
      this.rellenarBean(this.inventarioProBeanInsRequest,this.inventarioProBeanUpdRequest,this.x_proceso);            

      this.inventarioProdService.update(this.inventarioProBeanUpdRequest,this.id).subscribe(data=>{
        console.log(data);
        if (data.response.codeResponse=="0000"){
          this.utilitarioService.toastrSuccess("Se ha actualizado satisfactoriamente.");
          this.action.next('1');
          (<HTMLButtonElement>document.getElementById("btnBuscar")).click();
        }else{
          this.action.next('0');
          this.utilitarioService.toastrError(data.response.response);
        }

        this.utilitarioService.loading = false;
        this.onClosemodal();

      });

    }

  }

  cargarDatoInicial(dml:String){

    this.utilitarioService.loading = true;
    this.listEstado = new RespuestaComun();
    console.log(this.inventarioProBeanInsRequest);
    console.log(this.inventarioProBeanUpdRequest);

    const cargarEstado = this.maestrasService.obtenerMaestra("TIPO_STATUS_TABLA").toPromise()
    .then(result => {
      this.listEstado = result;
    });

    const cargarTalla = this.maestrasService.obtenerMaestra("TIPO_TALLA").toPromise()
    .then(result => {
      this.listTalla = result;
    });

    const cargarControles = this.productoService.obtenerListControles(this.x_groupid.toString()).toPromise()
    .then(result => {          
          this.productoControles = result.response.list[0];
    });

    if (dml=='I'){
      //carga los promesas culminadas para asignar los datos a las variables
      this.txtproductId = this.id;
      this.rellenarBean(this.inventarioProBeanInsRequest,this.inventarioProBeanUpdRequest,this.x_proceso);

      Promise.all([cargarEstado,cargarControles,cargarTalla])
      .then(response => {

        if (this.productoControles.showTalla==true){
          this.showTallaTable = true;
        }
        this.utilitarioService.loading = false;

      })
      .catch(error => console.log(`Error in executing ${error}`));
    }

    if (dml=='U'){
      //carga los promesas culminadas para asignar los datos a las variables
      Promise.all([cargarEstado,cargarControles,cargarTalla])    
      .then(response => {      
        
        if (this.productoControles.showTalla==true){
          this.showTallaTable = true;
        }

        //obtener por id objeto
        this.inventarioProdService.obtenerRegistro(this.id).subscribe(data=>{          

          this.cboEstado = data.response.list[0].enabled;          
          this.cboTalla = data.response.list[0].sizeId;
          this.txtinventoryId= data.response.list[0].inventoryId;          
          this.txtinventoryStock= data.response.list[0].inventoryStock;
          this.txtinventoryStockOrigin= data.response.list[0].inventoryStockOrigin;
          this.txtproductId= data.response.list[0].productId;          
          this.txtusuarioCrearId = data.response.list[0].usuarioCrearId;

          this.utilitarioService.loading = false;

        });
      })
      .catch(error => console.log(`Error in executing ${error}`));
    }    

  }

  rellenarBean(inventarioProBeanInsRequest:InventarioProBeanInsRequest,inventarioProBeanUpdRequest:InventarioProBeanUpdRequest,dml:String){

    if (dml=='I'){      

      inventarioProBeanInsRequest.enabled = this.cboEstado;
      inventarioProBeanInsRequest.inventorySizeId = this.cboTalla;
      inventarioProBeanInsRequest.inventoryId=this.txtinventoryId;
      inventarioProBeanInsRequest.inventoryStock=this.txtinventoryStock;
      inventarioProBeanInsRequest.inventoryStockOrigin=this.txtinventoryStockOrigin;
      inventarioProBeanInsRequest.productId=this.txtproductId;      
      inventarioProBeanInsRequest.usuarioCrearId=this.usuarioService.obtenerIDSessionUsuarioAdmin().toString();

    }else{

      inventarioProBeanUpdRequest.enabled =this.cboEstado;
      inventarioProBeanUpdRequest.inventorySizeId = this.cboTalla;    
      inventarioProBeanUpdRequest.inventoryId=this.txtinventoryId;      
      inventarioProBeanUpdRequest.inventoryStock=this.txtinventoryStock;
      inventarioProBeanUpdRequest.inventoryStockOrigin=this.txtinventoryStockOrigin;
      inventarioProBeanUpdRequest.productId=this.txtproductId;      
      inventarioProBeanUpdRequest.usuarioModificaId=this.usuarioService.obtenerIDSessionUsuarioAdmin().toString();
      
    }  

  }

  validarFormulario() : Boolean{        

    //valida formulario
    if (this.usuarioService.obtenerIDSessionUsuarioAdmin().toString()==null){
      this.utilitarioService.toastrError("Su session ha culminado, ingrese nuevamente al sistema.");      
      return false;
    }
    
    //valida tipo de comprobante
    if(this.txtinventoryStock==="" || this.txtinventoryStock === null){
      this.utilitarioService.toastrInfo("Ingrese número de stock.");
      return false;
    }
  
    if(this.cboEstado==""){
      this.utilitarioService.toastrInfo("Seleccione estado.");
      return false;
    }

    if(this.cboTalla=="" && this.showTallaTable == true){
      this.utilitarioService.toastrInfo("Seleccione talla.");
      return false;
    }
  
    if(this.showTallaTable == true){

      var existetalla:boolean;

      this.listInventProAddBean.forEach((inventProAddBean) => { // foreach statement
         if((inventProAddBean.size_id==this.cboTalla) && (this.txtinventoryId!=inventProAddBean.inventory_id)){
           console.log("ingreso");
           existetalla = true;
           return;
         }          
      });        
      console.log(existetalla);          
      if(existetalla==true){
        this.utilitarioService.toastrInfo("La talla seleccionada ya se encuentra registrada.");        
        return false;
      }else{        
        return true;
      }      

    }

    return true;    
  
  }  

}
