export class CategoriaAdminBean{
    id: String;
    name: String;
    description: String;
    order: String;
    enabled: String;
    createdUser: String;
    updatedUser: String;
    createdDate: String;
}

export class CategoriaAdminBeanInsRequest{
    createdUser: String;
    description: String;
    enabled: String;
    name: String;    
    order: String;
}

export class CategoriaAdminBeanUpdRequest{    
    description: String;
    enabled: String;    
    name: String;    
    order: String;
    updatedUser:String;
}