import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders} from '@angular/common/http';

import { InventarioLibListResponse } from '../../modal/inventario-libro/inventarioLibListResponse';
import { InventarioLibRegIdResponse } from '../../modal/inventario-libro/inventarioLibRegIdResponse';
import { InventarioLibDmlResponse } from '../../modal/inventario-libro/inventarioLibDmlResponse';
import { InventarioLibAsigResponse } from '../../modal/inventario-libro/inventarioLibAsigResponse';
import { InventarioLibBeanInsRequest,InventarioLibBeanUpdRequest } from '../../modal/inventario-libro/inventarioLibBean';

@Injectable({
  providedIn: 'root'
})

export class InventarioLibService {

  constructor(private httpClient:HttpClient) { }
  
  obtenerAdminList(idInventario:String,idLibro:String,libroAsignado01:String,tituloLibro:String,estadoLibro01:String){
    
    let url = `${environment.urlBase}${environment.listInvBookAdmin}?idInventario=${idInventario}&idLibro=${idLibro}&libroAsignado01=${libroAsignado01}&tituloLibro=${tituloLibro}&estadoLibro01=${estadoLibro01}`;
    //console.log("url",url);
    return this.httpClient.get<InventarioLibListResponse>(url).pipe(
      map(data => {   
        let response: InventarioLibListResponse;
        response = data;
        return response;
      },
      error =>{      
        return new Response("9999", error);
      })) 
  }

  obtenerRegistro(id:String){
    let url = `${environment.urlBase}${environment.getInvBookAdminId}/${id}`;
    //console.log("url",url);
    
    return this.httpClient.get<InventarioLibRegIdResponse>(url).pipe(
      map(data => {   
        let response: InventarioLibRegIdResponse;
        response = data;
        return response;
      },
      error =>{      
        return new Response("9999", error);
      })) 
  }

  grabar(inventarioLibBeanInsRequest:InventarioLibBeanInsRequest){
    let url = `${environment.urlBase}${environment.insertInvBookAdmin}`;
    return this.httpClient.post<InventarioLibDmlResponse>(url,inventarioLibBeanInsRequest).pipe(
      map(data => {
        let response: InventarioLibDmlResponse;
        response = data;        

        return response;        
      },
      error =>{      
        return new Response("9999", error);
      }) 
    )
  }

  update(inventarioLibBeanUpdRequest:InventarioLibBeanUpdRequest,id:String){
    let url = `${environment.urlBase}${environment.updateInvBookAdmin}/${id}`;
    return this.httpClient.put<InventarioLibDmlResponse>(url,inventarioLibBeanUpdRequest).pipe(
      map(data => {
        let response: InventarioLibDmlResponse;
        response = data;        
        return response;
      },
      error =>{      
        return new Response("9999", error);
      }) 
    )
  }

  verifyConceptosSiga(idSiga:string,codSecuencia:string){
    
    let url = `${environment.urlBase}${environment.verefySigaLibro}?idSiga=${idSiga}&codSecuencia=${codSecuencia}`;
    //console.log("url",url);
    return this.httpClient.get<InventarioLibAsigResponse>(url).pipe(
      map(data => {   
        let response: InventarioLibAsigResponse;
        response = data;
        return response;
      },
      error =>{      
        return new Response("9999", error);
      })) 
  }

}
