import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {UsuarioRequest} from 'src/app/modal/usuario/usuario-request';
import {UsuarioService} from 'src/app/service/usuario/usuario.service';

import { SocialAuthService, SocialAuthServiceConfig, SocialUser } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { UsuarioRegistroRequest } from 'src/app/modal/usuario/usuario-registro-request';
import { UtilitarioService } from 'src/app/service/utilitarios/utilitario.service';
 

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  credenciales:UsuarioRequest;
  user: SocialUser;
  loggedIn: boolean;
  loginRedesSociales = 0;
  registrarRequest:UsuarioRegistroRequest;
  flagLogin=false;
  constructor(private utilService:UtilitarioService, private authService: SocialAuthService,private fb: FormBuilder, private router: Router,private toastr:ToastrService, private usuarioService:UsuarioService) {
    this.createForm();
   }
  createForm(){
    this.loginForm = this.fb.group({
      txtUsuario:['',Validators.required],
      txtPass:['',Validators.required],
      usuarioBNP:['']
    });
  }
  displayFieldCss(field: string) {
    if (this.loginForm.get(field).touched){
      return {
        'is-invalid': this.isFieldValid(field),
        'is-valid': !this.isFieldValid(field)
      };      
    }
  }    

  isFieldValid(field: string) {
    return !this.loginForm.get(field).valid && this.loginForm.get(field).touched;
  }   

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  login(){
      if (this.loginForm.valid) {
        this.credenciales = new UsuarioRequest();
        this.credenciales.email=this.loginForm.get("txtUsuario").value;
        this.credenciales.password=this.loginForm.get("txtPass").value;
        this.usuarioService.loginUser(this.credenciales).subscribe(data=>{
          //console.log(data);
          if(data.response.list.length>0){
            this.showSuccess(data.response.list[0].name,"Bienvenido");
            this.flagLogin = this.usuarioService.existeSesionUsuario();
            this.router.navigate(['/']);
            this.createForm();
          }
          else{
            this.showWarning(data.response.response);
          } 
        })
       }
      else{
        this.validateAllFormFields(this.loginForm);
      }

  }
  obtenerNombreUsuario(){
    return this.usuarioService.obtenerNombreCompletoSesionUsuario();
  }
  showSuccess(mensaje,usuario) {
    this.toastr.success(mensaje,usuario);
  }
  showWarning(mensaje) {
    this.toastr.warning(mensaje);
  }
  showAlert(mensaje) {
    this.toastr.error(mensaje);
  }
  existeSesion(){
    this.flagLogin = this.usuarioService.existeSesionUsuario();
    //console.log(this.flagLogin);
  }

  logout(){
    
    this.usuarioService.logOut();
    this.flagLogin = this.usuarioService.existeSesionUsuario();
    this.router.navigate(['/']);
    this.createForm();
    this.signOut();
  }
  obtenerDatosRedesSociales(){
    this.utilService.loading = true;
      this.credenciales = new UsuarioRequest();
      this.credenciales.email=this.user.email;
      this.credenciales.password="";
      this.credenciales.flagRedSocial="1";
      //console.log("this.data",this.credenciales);
      //console.log("this.usuarioService.existeSesionUsuario()",this.usuarioService.existeSesionUsuario())
      this.usuarioService.loginUser(this.credenciales).subscribe(data=>{
                  //console.log(data);
                  if(data.response.list.length>0){
                    this.showSuccess(data.response.list[0].name,"Bienvenido");
                    this.flagLogin = this.usuarioService.existeSesionUsuario();
                    this.router.navigate(['/']);
                    this.createForm();
                  }
                  else if(data.response.codeResponse="0002"){
                    //this.showWarning(data.response.response);
                    this.registrarUsuarioRedSocial();
                  }
                  else{
                    this.showWarning(data.response.response);
                    this.signOut();
                  }
                  this.utilService.loading = false;
                })
  }

  obtenerDatosRedesSocialesFB(){
    this.utilService.loading = true;
      this.credenciales = new UsuarioRequest();
      this.credenciales.email=this.user.email;
      this.credenciales.password="";
      this.credenciales.flagRedSocial="1";
      //console.log("this.data",this.credenciales);
      //console.log("this.usuarioService.existeSesionUsuario()",this.usuarioService.existeSesionUsuario())
      this.usuarioService.loginUser(this.credenciales).subscribe(data=>{
                  //console.log(data);
                  if(data.response.list.length>0){
                    this.showSuccess(data.response.list[0].name,"Bienvenido");
                    this.flagLogin = this.usuarioService.existeSesionUsuario();
                    this.router.navigate(['/']);
                    this.createForm();
                  }
                  else if(data.response.codeResponse="0002"){
                    //this.showWarning(data.response.response);
                    this.registrarUsuarioRedSocialFB();
                  }
                  else{
                    this.showWarning(data.response.response);
                    this.signOut();
                  }
                  this.utilService.loading = false;
                })
  }


  registrarUsuarioRedSocial(){
    this.registrarRequest = new UsuarioRegistroRequest();
    this.utilService.loading=true;
    this.registrarRequest.email = this.user.email;;
    this.registrarRequest.name = this.user.firstName;
    this.registrarRequest.surname = this.user.lastName;
    this.registrarRequest.photo = this.user.photoUrl;
    this.registrarRequest.provider = "GOOGLE";

    //console.log(this.registrarRequest);
      this.usuarioService.registrarUsuario(this.registrarRequest).subscribe(data=>{
        if(data.response.codeResponse==="0000"){
          this.credenciales = new UsuarioRequest();
          this.credenciales.email=this.user.email;
          this.credenciales.password="";
          this.credenciales.flagRedSocial="1";
          //console.log("this.data",this.credenciales);
          //console.log("this.usuarioService.existeSesionUsuario()",this.usuarioService.existeSesionUsuario())
          this.usuarioService.loginUser(this.credenciales).subscribe(data=>{
                      //console.log(data);
                      if(data.response.list.length>0){
                        this.showSuccess(data.response.list[0].name,"Bienvenido");
                        this.flagLogin = this.usuarioService.existeSesionUsuario();
                        this.router.navigate(['/']);
                        this.createForm();
                      }                     
                      else{
                        this.showWarning(data.response.response);
                        this.signOut();
                      } 
                      this.utilService.loading=false;
                    })
          
        }
        else{
          this.showWarning(data.response.response);
        }
      })
  }
  registrarUsuarioRedSocialFB(){
    this.registrarRequest = new UsuarioRegistroRequest();
    this.utilService.loading=true;
    this.registrarRequest.email = this.user.email;;
    this.registrarRequest.name = this.user.firstName;
    this.registrarRequest.surname = this.user.lastName;
    this.registrarRequest.photo = this.user.photoUrl;
    this.registrarRequest.provider = "FACEBOOK";

    //console.log(this.registrarRequest);
      this.usuarioService.registrarUsuario(this.registrarRequest).subscribe(data=>{
        if(data.response.codeResponse==="0000"){
          this.credenciales = new UsuarioRequest();
          this.credenciales.email=this.user.email;
          this.credenciales.password="";
          this.credenciales.flagRedSocial="1";
          //console.log("this.data",this.credenciales);
          //console.log("this.usuarioService.existeSesionUsuario()",this.usuarioService.existeSesionUsuario())
          this.usuarioService.loginUser(this.credenciales).subscribe(data=>{
                      //console.log(data);
                      if(data.response.list.length>0){
                        this.showSuccess(data.response.list[0].name,"Bienvenido");
                        this.flagLogin = this.usuarioService.existeSesionUsuario();
                        this.router.navigate(['/']);
                        this.createForm();
                      }                     
                      else{
                        this.showWarning(data.response.response);
                        this.signOut();
                      } 
                      this.utilService.loading=false;
                    })
          
        }
        else{
          this.showWarning(data.response.response);
        }
      })
  }
  loginGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(data=>{
      this.obtenerDatosRedesSociales();
    });
   
    
  }
 
  loginFacebook(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(data=>{
      this.obtenerDatosRedesSocialesFB();
    })
  }
 
  signOut(): void {
    this.authService.signOut();
  }


  ngOnInit(): void {
    this.existeSesion();
    this.authService.authState.subscribe((user) => {
      this.user = user;
      this.loggedIn = (user != null);
    });
  }

}
