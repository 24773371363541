import { Component, OnInit } from '@angular/core';
import { UtilitarioService } from 'src/app/service/utilitarios/utilitario.service';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { MaestrasService } from 'src/app/service/maestras/maestras.service';
import { RespuestaComun } from 'src/app/modal/maestras/respuesta-comun';

import { InventarioProListResponse } from 'src/app/modal/inventario-producto/inventarioProListResponse';
import { InventarioProdService } from 'src/app/service/inventario-prod/inventario-prod.service';
import { DmlInventarioProdComponent } from 'src/app/pagina-admin/dashboard/gestionProductos/mant-inventario-prod/dml-inventario-prod/dml-inventario-prod.component';

import { ExcelService } from 'src/app/service/utilitarios/excel.service';

@Component({
  selector: 'app-mant-inventario-prod',
  templateUrl: './mant-inventario-prod.component.html',
  styleUrls: ['./mant-inventario-prod.component.scss']
})

export class MantInventarioProdComponent implements OnInit {

  constructor(private inventarioProdService:InventarioProdService,
    private utilitarioService: UtilitarioService,
    private maestrasService: MaestrasService,
    private modalService: MDBModalService,
    private excelService:ExcelService,
    ) { }

  txtFilProductTitle:String = "";
  cboEstado:String = "";
  cboInventario:String = "";
  cboStock:String = "";

  modalRefAnioDml: MDBModalRef;
  listEstadoFil:RespuestaComun = new RespuestaComun();
  listStockFil:RespuestaComun = new RespuestaComun();
  listInventarioFil:RespuestaComun = new RespuestaComun();

  headElements = ['Producto','Tipo de Regalo', 'Stock','Precio','Inventario','Asignar','Modificar'];
  listInventario:InventarioProListResponse = new InventarioProListResponse();
  
  page = 1;
  pageSize = 9;

  ngOnInit(): void {
    this.cargarDatoInicial();
    this.onBuscar();
  }

  onBuscar(){
    this.utilitarioService.loading = true;
    this.inventarioProdService.obtenerAdminList(this.cboStock, "",this.cboInventario,this.txtFilProductTitle,this.cboEstado).subscribe(data=>{
      console.log(data);
      this.listInventario = data;      
      this.utilitarioService.loading = false;
    })
  }

  onNuevo(id:String,titulo:String,groupid:String){
    this.modalRefAnioDml = this.modalService.show(DmlInventarioProdComponent,
      {
        backdrop: true,
        keyboard: true,
        focus: true,
        show: false,
        ignoreBackdropClick: false,
        class: 'modal-dialog modal-dialog-centered modal-dialog-scrollable modal-notify modal-warning modal-lg',
        containerClass: 'top',
        animated: true,
        data: {
          id: id,
          x_titulo:'Asignar inventario',
          x_proceso:'I',
          x_titulo_libro: titulo,
          x_groupid:groupid,
        }
      }
    );
  }

  onEditar(id:String,titulo:String,groupid:String){
    this.modalRefAnioDml = this.modalService.show(DmlInventarioProdComponent,
      {
        backdrop: true,
        keyboard: true,
        focus: true,
        show: false,
        ignoreBackdropClick: false,
        class: 'modal-dialog modal-dialog-centered modal-dialog-scrollable modal-notify modal-warning modal-lg',
        containerClass: 'top',
        animated: true,
        data: {
          id: id,
          x_titulo:'Editar Inventario',
          x_proceso:'U',
          x_titulo_libro: titulo,
          x_groupid:groupid,
        }
      }
    );
  }

  cargarDatoInicial(){

    this.utilitarioService.loading = true;
    this.listEstadoFil = new RespuestaComun();
    this.maestrasService.obtenerMaestra("TIPO_STATUS_TABLA").subscribe(data=>{
      this.listEstadoFil = data;            
    });

    this.listInventarioFil = new RespuestaComun();
    this.maestrasService.obtenerMaestra("TIPO_INVENTARIO_ASIGNADO").subscribe(data=>{
      this.listInventarioFil = data;            
    });

    this.listStockFil = new RespuestaComun();
    this.maestrasService.obtenerMaestra("TIPO_LIMITE_INVENTARIO").subscribe(data=>{
      this.listStockFil = data;            
    });

    this.utilitarioService.loading = false;

  }

  onExportarExcel(){
    this.excelService.exportExcelProductosInve(this.listInventario.response.list);
  }

}
