import { Component, OnInit, SimpleChanges } from '@angular/core';
import { CarritoService } from 'src/app/service/carrito/carrito.service';
import { MaestrasService } from 'src/app/service/maestras/maestras.service';
import { UsuarioService } from 'src/app/service/usuario/usuario.service';
import {MenuResponse} from 'src/app/modal/maestras/menu-response';
import { NavigationEnd, Router } from '@angular/router';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  flagActualizar=1;
  cantidadCarrito:number;
  menuFlag=false;
  menuResponse:MenuResponse;
  libreriaVirtual:boolean=true;
  constructor(private router: Router,private carritoService:CarritoService,private usuarioService:UsuarioService,private maestraService:MaestrasService) {
    

  }

  obtenerSesion(){
    return this.usuarioService.existeSesionUsuario();
  }

  obtenerCantidadCarrito(){    
    this.cantidadCarrito = Number(this.carritoService.cantidadCarrito);    
  }
 
  actualizarCarrito(){
    this.flagActualizar = this.flagActualizar + 1;
    
  }

  obtenerMenu(){
    this.menuResponse= new MenuResponse();
    this.maestraService.obtenerMenu().subscribe(data=>{
      this.menuResponse = data;
      this.menuFlag=true;

    })
  }

  ngOnInit(): void {
    this.obtenerMenu();
    this.carritoService.obtenerCantidadCarrito();    

    setInterval(() => {
      this.obtenerCantidadCarrito()
    }, 100);

  }
  
}
