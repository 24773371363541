import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RequestPayme } from '../../../modal/Payme/RequestPayme';
import { ProcesarPagoService } from 'src/app/service/procesarpago/procesar-pago.service';
import { OrderRequest } from '../../../modal/procesapago/orderRequest';
import { DetailBook } from '../../../modal/procesapago/detailBook';
import { OrderResponse } from '../../../modal/procesapago/orderResponse';
import { NovedadesService } from 'src/app/service/catalogo/novedades.service';
import { CarritoHeader } from 'src/app/modal/carrito/carrito-header';
import { CarritoService } from 'src/app/service/carrito/carrito.service';
import { LibroDetalle } from 'src/app/modal/catalogo/libro-detalle';
import { OrderCalculateRequest } from 'src/app/modal/procesapago/orderCalculateRequest';
import { CdkStep, CdkStepper } from '@angular/cdk/stepper';
import { UtilitarioService } from 'src/app/service/utilitarios/utilitario.service';
import { SourvenirDetalleResponse } from 'src/app/modal/sourvenir/sourvenir-detalle-response';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-medio-pago',
  templateUrl: './medio-pago.component.html',
  styleUrls: ['./medio-pago.component.scss']
})
export class MedioPagoComponent implements OnInit {

  constructor(private procesarPagoService:ProcesarPagoService,
              private carritoService:CarritoService,
              private novedadesService:NovedadesService,
              private utilitarioService:UtilitarioService) { }

  @Input() orderRequest: OrderRequest;
  @Input() cdkStepper : CdkStepper;
  @Input() cdkStepOne : CdkStep;
  
  public stepTwo: FormGroup;
  carritoDetalle:CarritoHeader; 
  rdbpagotarjeta=true;    
  templateUnchecked = "DA";
  templateChecked = "TAR";
  template = "TAR";
  requestPayme = new RequestPayme();  
  precioTotal : number;
  cantidadTotal : number;
  weightTotal : number;

  SubTotalTemp = 0;
  IGVProducto = 0;
  IGVDelivery = 0;
  IGVTotal = 0;    
  
  urlPayme:String;


  ngOnInit(): void {    
    this.urlPayme = environment.urlPayme;
  }  

  async onPagarPreorden(){        

    //valida que exista un libro seleccionado
    if(this.carritoService.verificarCarritoSelecionados()==false){return;};

    this.carritoDetalle = new CarritoHeader();
    this.carritoDetalle=this.carritoService.obtenerCarritoCompras();

    this.precioTotal = 0;
    this.cantidadTotal = 0;
    this.weightTotal = 0;

    this.SubTotalTemp = 0;
    this.IGVDelivery = 0;
    this.IGVProducto = 0;
    this.IGVTotal = 0;



    this.utilitarioService.loading=true;
    //obtiene nuevamente costos con BD por seguridad
    for (let i = 0; i < this.carritoDetalle.lista.length; i++) {
        
        let libroDetalleTemp=new LibroDetalle();
        let libroSourvenirTemp=new SourvenirDetalleResponse();

        let stockdisponible:number;
        //recorre carrito en temporal con bd para obtener costos y detalle del producto uno por uno
       if(this.carritoDetalle.lista[i].tipoProducto=="1"){

        var libroDetalle:LibroDetalle = await this.novedadesService.obtenerDetalleLibroAsync(this.carritoDetalle.lista[i].id).toPromise();
       
        if (libroDetalle.response.codeResponse="0000"){
          libroDetalleTemp = libroDetalle;

          stockdisponible = Number(libroDetalleTemp.response.list[0].quantity) - Number(this.carritoDetalle.lista[i].cantidad);
          if(stockdisponible<0){
            this.utilitarioService.toastrInfo("No hay stock disponible en el libro " + libroDetalleTemp.response.list[0].title + ", cantidad disponible: " + libroDetalleTemp.response.list[0].quantity);
            this.utilitarioService.loading=false;
            return;
          }
          this.SubTotalTemp = Number((this.SubTotalTemp + ( Number(this.carritoDetalle.lista[i].cantidad) * Number(libroDetalleTemp.response.list[0].price))).toFixed(2));

          this.precioTotal = Number((this.precioTotal + ( Number(this.carritoDetalle.lista[i].cantidad) * Number(libroDetalleTemp.response.list[0].price))).toFixed(2));
          this.cantidadTotal = this.cantidadTotal + Number(this.carritoDetalle.lista[i].cantidad);
          this.weightTotal = this.weightTotal + ( Number(this.carritoDetalle.lista[i].cantidad) *  Number(libroDetalleTemp.response.list[0].weight) );
        }
        }
        else if(this.carritoDetalle.lista[i].tipoProducto=="2"){

          var libroSourvenir:SourvenirDetalleResponse = await this.novedadesService.obtenerDetalleSourvenirAsync(this.carritoDetalle.lista[i].id).toPromise();
         
          if (libroSourvenir.response.codeResponse="0000"){

            //if have size
            if(libroSourvenir.response.list[0].size.length>0){              
              const result = libroSourvenir.response.list[0].size.find( ({ id }) => id ==  this.carritoDetalle.lista[i].sizeID);
              libroSourvenir.response.list[0].quantity = result.stock;              
            }

            libroSourvenirTemp = libroSourvenir;
  
            stockdisponible = Number(libroSourvenirTemp.response.list[0].quantity) - Number(this.carritoDetalle.lista[i].cantidad);
            if(stockdisponible<0){
              this.utilitarioService.toastrInfo("No hay stock disponible en el producto " + libroSourvenirTemp.response.list[0].title + ", cantidad disponible: " + libroSourvenirTemp.response.list[0].quantity);
              this.utilitarioService.loading=false;
              return;
            }
            
            this.precioTotal = Number((this.precioTotal + ( Number(this.carritoDetalle.lista[i].cantidad) * Number(libroSourvenirTemp.response.list[0].price))).toFixed(2));
            this.cantidadTotal = this.cantidadTotal + Number(this.carritoDetalle.lista[i].cantidad);
            //this.weightTotal = this.weightTotal + Number(libroSourvenirTemp.response.list[0].weight);
            this.weightTotal = this.weightTotal + ( Number(this.carritoDetalle.lista[i].cantidad) *  Number(libroSourvenirTemp.response.list[0].weight) );

            let tempPrecioSinIGV = 0;
            //tempPrecioSinIGV = Number(libroSourvenirTemp.response.list[0].price) - ( Number(libroSourvenirTemp.response.list[0].price) * 0.18);
            tempPrecioSinIGV =  Number((Number(libroSourvenirTemp.response.list[0].price) / 1.18).toFixed(2));

            
            //this.IGVProducto = this.IGVProducto + (Number(libroSourvenirTemp.response.list[0].price) * 0.18);
            //this.IGVProducto = this.IGVProducto + ((Number(libroSourvenirTemp.response.list[0].price) * 0.18)*Number(this.carritoDetalle.lista[i].cantidad));
           // this.IGVProducto = this.IGVProducto + (Number((Number((Number(libroSourvenirTemp.response.list[0].price) / 1.18).toFixed(2)) * 0.18).toFixed(2))*Number(this.carritoDetalle.lista[i].cantidad));
           this.IGVProducto = this.IGVProducto +   (Number(libroSourvenirTemp.response.list[0].price) - tempPrecioSinIGV ) * Number(this.carritoDetalle.lista[i].cantidad);


            this.SubTotalTemp = Number((this.SubTotalTemp + ( Number(this.carritoDetalle.lista[i].cantidad) * tempPrecioSinIGV)).toFixed(2));
  


          }

        


        }else{
          this.utilitarioService.toastrInfo(libroDetalle.response.response);
          this.utilitarioService.loading=false;
          return;
        }
        
    }

    let orderCalculateRequest = new OrderCalculateRequest();
    orderCalculateRequest.addressId = this.orderRequest.addressId;
    orderCalculateRequest.amount = this.precioTotal.toString();
    orderCalculateRequest.quantity= this.cantidadTotal.toString();
    orderCalculateRequest.typeDeliveryId = this.orderRequest.typeDeliveryId; //tipo entrega
    orderCalculateRequest.typeShippingId = this.orderRequest.typeShippingId; //codigo pais peru
    orderCalculateRequest.weight = this.weightTotal.toString();
    console.log(this.orderRequest);
    console.log("orderCalculateRequest",orderCalculateRequest);
    this.procesarPagoService.obtenerCalculoTotales(orderCalculateRequest).subscribe(data => {
      console.log("obtenerCalculoTotales",data);
      //console.log("obtener totales");
      if (data.response.codeResponse!="0000"){//console.log(data); 
      this.utilitarioService.toastrError("Ocurrio un error en el servicio, no se ha efectuado el pago de la compra");return;}

      this.IGVDelivery = Number(data.response.list[0].igvCost);
      this.IGVTotal = this.IGVProducto + this.IGVDelivery;

      //obtiene por seguridad datos totales con la bd
    //  this.orderRequest.igvCost = data.response.list[0].igvCost;
      this.orderRequest.totalCost = data.response.list[0].totalCost;
      this.orderRequest.shippingCost = data.response.list[0].shippingCost;
    //  this.orderRequest.subtotalCost = data.response.list[0].subtotalCost;
      this.orderRequest.subtotalCost = this.SubTotalTemp.toString();
      this.orderRequest.igvCost = this.IGVTotal.toString();
     
      //REGISTRA ORDEN
      console.log(JSON.stringify(this.orderRequest));
      
      this.procesarPagoService.grabarOrden(this.orderRequest).subscribe(data=>{
          console.log(data);
          if (data.response.codeResponse=="0000"){
            console.log("genera orden");
            this.CargarDatosPaymeRequest(data);
            //(<HTMLFormElement>document.getElementById("frmVPOS2")).submit();
            //this.utilitarioService.loading=false;
            setTimeout(function(){ (<HTMLFormElement>document.getElementById("frmVPOS2")).submit() ; this.utilitarioService.loading=false;}, 800);
          }else{
            this.utilitarioService.toastrError(data.response.response);
            this.utilitarioService.loading=false;
          }
      },
      (err:any) => {
        this.utilitarioService.toastrError("Ocurrio un error en el Servicio");
        console.log(err);
      });
    },
    (err:any) => {
      this.utilitarioService.toastrError("Ocurrio un error en el Servicio");
      console.log(err);
    });   

  }

  CargarDatosPaymeRequest(orderResponse:OrderResponse) {
   
    this.requestPayme.acquirerId= orderResponse.response.list[0].acquirerId;
    this.requestPayme.idCommerce= orderResponse.response.list[0].idCommerce;
    this.requestPayme.purchaseOperationNumber = orderResponse.response.list[0].purchaseOperationNumber;
    this.requestPayme.purchaseAmount = orderResponse.response.list[0].purchaseAmount;
    this.requestPayme.purchaseCurrencyCode = orderResponse.response.list[0].purchaseCurrencyCode;
    this.requestPayme.language = orderResponse.response.list[0].language;
    this.requestPayme.shippingFirstName = orderResponse.response.list[0].shippingFirstName;
    this.requestPayme.shippingLastName = orderResponse.response.list[0].shippingLastName;
    this.requestPayme.shippingEmail = orderResponse.response.list[0].shippingEmail;
    this.requestPayme.shippingAddress = orderResponse.response.list[0].shippingAddress;
    this.requestPayme.shippingZIP = orderResponse.response.list[0].shippingZIP;
    this.requestPayme.shippingCity= orderResponse.response.list[0].shippingCity;
    this.requestPayme.shippingState= orderResponse.response.list[0].shippingState;
    this.requestPayme.shippingCountry = orderResponse.response.list[0].shippingCountry;
    this.requestPayme.shippingPhone = orderResponse.response.list[0].shippingTelephone;
    this.requestPayme.userCommerce = orderResponse.response.list[0].userCommerce;
    this.requestPayme.userCodePayme = orderResponse.response.list[0].userCodePayme;
    this.requestPayme.descriptionProducts = orderResponse.response.list[0].descriptionProducts;
    this.requestPayme.programmingLanguage = orderResponse.response.list[0].programmingLanguage;
    this.requestPayme.purchaseVerification = orderResponse.response.list[0].purchaseVerification; 
 
   }

   onBack(){
    this.cdkStepOne.completed=false;
    this.cdkStepper.previous();
   }

}
