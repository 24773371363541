import { SourvenirDetalle } from 'src/app/modal/sourvenir/sourvenir-detalle';

export class SourvenirDetalleResponse {
    httpStatus : number;
   response : {
     id : string  ,
     codeResponse :  string ,
     response :  string ,
     list : SourvenirDetalle[]
  }
  constructor(){
    this.httpStatus = 0;
    this.response = {
      id : ""  ,
      codeResponse :  "" ,
      response :  "" ,
      list : []
  }
}
}
