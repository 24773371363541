import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { ResponseComun } from 'src/app/modal/catalogo/response-comun';

import { SubmenuBeanInsRequest,SubmenuBeanUpdRequest } from '../../modal/submenu/submenuBean';
import { SubmenuListResponse } from '../../modal/submenu/submenuListResponse';
import { SubmenuRegIdResponse } from '../../modal/submenu/submenuRegIdResponse';
import { SubmenuDmlResponse } from '../../modal/submenu/submenuDmlResponse';

@Injectable({
  providedIn: 'root'
})

export class SubmenuService {

  constructor(private httpClient:HttpClient) { }

  obtenerAdminList(nombre:string,estado:string){
    let url = `${environment.urlBase}${environment.listSubmenuAdmin}?nombre=${nombre}&estado=${estado}`;
    //console.log("url",url);
    return this.httpClient.get<SubmenuListResponse>(url).pipe(
      map(data => {   
        let response: SubmenuListResponse;
        response = data;
        return response;
      },
      error =>{
        return new Response("9999", error);
      })) 
  }

  obtenerRegistro(id:String){
    let url = `${environment.urlBase}${environment.getSubmenuAdminId}/${id}`;
    //console.log("url",url);
    return this.httpClient.get<SubmenuRegIdResponse>(url).pipe(
      map(data => {   
        let response: SubmenuRegIdResponse;
        response = data;
        return response;
      },
      error =>{      
        return new Response("9999", error);
      })) 
  }

  grabar(submenuBeanInsRequest:SubmenuBeanInsRequest){
    let url = `${environment.urlBase}${environment.insertSubmenuAdmin}`;
    return this.httpClient.post<SubmenuDmlResponse>(url,submenuBeanInsRequest).pipe(
      map(data => {
        let response: SubmenuDmlResponse;
        response = data;        

        return response;        
      },
      error =>{      
        return new Response("9999", error);
      }) 
    )
  }

  update(submenuBeanUpdRequest:SubmenuBeanUpdRequest,id:String){
    let url = `${environment.urlBase}${environment.updateSubmenuAdmin}/${id}`;
    return this.httpClient.put<SubmenuDmlResponse>(url,submenuBeanUpdRequest).pipe(
      map(data => {
        let response: SubmenuDmlResponse;
        response = data;        
        return response;        
      },
      error =>{      
        return new Response("9999", error);
      }) 
    )
  }

  obtenerListCombo(){
    let url = `${environment.urlBase}${environment.listSubmenuCombo}`;
    //console.log("url",url);
    return this.httpClient.get<ResponseComun>(url).pipe(
      map(data => {   
        let response: ResponseComun;
        response = data;
        return response;
      },
      error =>{
        return new Response("9999", error);
      })) 
  }
  
}
