export class Menu {

    menuOperador = [
        {
          label: 'Bandeja de Pedidos',
          link: 'bandejapedidos',
          icon: 'offline_pin'
        }
    ];

    menuAdmin = [
        {
          label: 'Bandeja de Pedidos',
          link: 'bandejapedidos',
          icon: 'offline_pin'
        },
        {
          label: 'Gestion de Libros',
          faIcon: 'fab fa-500px',
          items: [
            {
              label: 'Categorias',
              link: 'mantCategoria',
              faIcon: 'far fa-newspaper'
            },
            {
              label: 'Año de publicación',
              faIcon: 'far fa-newspaper',
              link: 'mantAnio',
            },
            {
              label: 'Autores',
              faIcon: 'far fa-newspaper',
              link: 'mantAutor',
            },
            {
              label: 'Libros',
              faIcon: 'far fa-newspaper',
              link: 'mantBook',
            },
            {
              label: 'Inventario Stock',
              faIcon: 'far fa-newspaper',
              link: 'mantInventario',
            }
          ]
        },
        {
          label: 'Gestion de Productos',
          faIcon: 'fab fa-500px',
          items: [
            {
              label: 'Grupo de regalos',
              link: 'mantsubmenu',
              faIcon: 'far fa-newspaper',
            },
            {
              label: 'Tipo de regalo',
              link: 'mantGrupos',
              faIcon: 'far fa-newspaper'
            },            
            {
              label: 'Tematica de Producto',
              link: 'mantTopic',
              faIcon: 'far fa-newspaper',
            },
            {
              label: 'productos',
              link: 'mantProducto',
              faIcon: 'far fa-newspaper',
            },
            {
              label: 'inventario Stock',
              link: 'mantinventarioprod',
              faIcon: 'far fa-newspaper',
            }
          ]
        },
        {
          label: 'Item 4',
          link: '/item-4',
          icon: 'star_rate',
          hidden: true
        }
    ];

}
