import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";
import { environment } from '../../../environments/environment';
import { conceptoPagoListResponse } from '../../modal/conceptoPago/conceptoPagoListResponse';
import { ConceptoPagoBean } from '../../modal/conceptoPago/concepto-pago-bean';
import { HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConceptoPagoService {

  constructor(private httpClient:HttpClient) { }

  obtenerAdminListLibro(codConcepto:string,descripcion:string){
    let url = `${environment.urlBase}${environment.listConceptoPagoAdminLib}?codConcepto=${codConcepto}&descripcion=${descripcion}`;
    //console.log("url",url);
    return this.httpClient.get<conceptoPagoListResponse>(url).pipe(
      map(data => {   
        let response: conceptoPagoListResponse;
        response = data;
        return response;
      },
      error =>{      
        return new Response("9999", error);
      })) 
  }

  obtenerAdminListProducto(codConcepto:string,descripcion:string){
    let url = `${environment.urlBase}${environment.listConceptoPagoAdminProd}?codConcepto=${codConcepto}&descripcion=${descripcion}`;
    //console.log("url",url);
    return this.httpClient.get<conceptoPagoListResponse>(url).pipe(
      map(data => {   
        let response: conceptoPagoListResponse;
        response = data;
        return response;
      },
      error =>{      
        return new Response("9999", error);
      })) 
  }

}
