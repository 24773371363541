import { Component, OnInit } from '@angular/core';
import { UtilitarioService } from 'src/app/service/utilitarios/utilitario.service';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { MaestrasService } from 'src/app/service/maestras/maestras.service';
import { RespuestaComun } from 'src/app/modal/maestras/respuesta-comun';

import { InventarioLibListResponse } from 'src/app/modal/inventario-libro/inventarioLibListResponse';
import { InventarioLibService } from 'src/app/service/inventario-lib/inventario-lib.service';
import { DmlInvetarioComponent } from 'src/app/pagina-admin/dashboard/gestionLibros/mat-inventario/dml-invetario/dml-invetario.component';

import { ExcelService } from 'src/app/service/utilitarios/excel.service';

@Component({
  selector: 'app-mat-inventario',
  templateUrl: './mat-inventario.component.html',
  styleUrls: ['./mat-inventario.component.scss']
})

export class MatInventarioComponent implements OnInit {

  constructor(private inventarioLibService:InventarioLibService,
    private utilitarioService: UtilitarioService,
    private maestrasService: MaestrasService,
    private modalService: MDBModalService,
    private excelService:ExcelService,
    ) { }

  txtFilBookTitle:String = "";
  cboEstado:String = "";
  cboInventario:String = "";
  cboStock:String = "";

  modalRefAnioDml: MDBModalRef;
  listEstadoFil:RespuestaComun = new RespuestaComun();
  listStockFil:RespuestaComun = new RespuestaComun();
  listInventarioFil:RespuestaComun = new RespuestaComun();

  headElements = ['Libro','Subtitulo','Categoria', 'Stock','Precio','Inventario','Asignar','Modificar'];
  listInventario:InventarioLibListResponse = new InventarioLibListResponse();
  
  page = 1;
  pageSize = 9;

  ngOnInit(): void {
    this.cargarDatoInicial();
    this.onBuscar();
  }

  onBuscar(){
    this.utilitarioService.loading = true;
    this.inventarioLibService.obtenerAdminList(this.cboStock, "",this.cboInventario,this.txtFilBookTitle,this.cboEstado).subscribe(data=>{
      this.listInventario = data;      
      this.utilitarioService.loading = false;
    })
  }

  onNuevo(id:String,titulo:String,subtitulo:String){
    this.modalRefAnioDml = this.modalService.show(DmlInvetarioComponent,
      {
        backdrop: true,
        keyboard: true,
        focus: true,
        show: false,
        ignoreBackdropClick: false,
        class: 'modal-dialog modal-dialog-centered modal-dialog-scrollable modal-notify modal-warning modal-lg',
        containerClass: 'top',
        animated: true,
        data: {
          id: id,
          x_titulo:'Asignar inventario',
          x_proceso:'I',
          x_titulo_libro: titulo + " - " + subtitulo,
        }
      }
    );
  }

  onEditar(id:String,titulo:String,subtitulo:String){
    this.modalRefAnioDml = this.modalService.show(DmlInvetarioComponent,
      {
        backdrop: true,
        keyboard: true,
        focus: true,
        show: false,
        ignoreBackdropClick: false,
        class: 'modal-dialog modal-dialog-centered modal-dialog-scrollable modal-notify modal-warning modal-lg',
        containerClass: 'top',
        animated: true,
        data: {
          id: id,
          x_titulo:'Editar Inventario',
          x_proceso:'U',
          x_titulo_libro: titulo+ " - " + subtitulo,
        }
      }
    );
  }

  cargarDatoInicial(){

    this.utilitarioService.loading = true;
    this.listEstadoFil = new RespuestaComun();
    this.maestrasService.obtenerMaestra("TIPO_STATUS_TABLA").subscribe(data=>{
      this.listEstadoFil = data;            
    });

    this.listInventarioFil = new RespuestaComun();
    this.maestrasService.obtenerMaestra("TIPO_INVENTARIO_ASIGNADO").subscribe(data=>{
      this.listInventarioFil = data;            
    });

    this.listStockFil = new RespuestaComun();
    this.maestrasService.obtenerMaestra("TIPO_LIMITE_INVENTARIO").subscribe(data=>{
      this.listStockFil = data;            
    });

    this.utilitarioService.loading = false;

  }

  onExportarExcel(){
    this.excelService.exportExcelLibroInve(this.listInventario.response.list);
  }
  
}
