export class UsuarioBeanResponse {

    httpStatus:  string;
    response: {
        id:  string;
        codeResponse:  string;
        response:  string;
        list: [{
            id:  string,
            name:  string,
            surname:  string,
            telephone: string,
            email:  string,
            birthDate:  string,
            typeDocumentId:  string,
            typeDocumentName:  string,
            document:  string,
            photo:  string,
            provider:  string,
            enabled:  string,
            createdDate:  string,
            lastLoginDate:  string
        }]
    }

    constructor(){
        this.httpStatus="";
        this.response={
            id:"",
            codeResponse:"",
            response:"",
            list:[{
                id: "",
                name: "",
                surname: "",
                telephone: "",
                email: "",
                birthDate: "",
                typeDocumentId: "",
                typeDocumentName: "",
                document: "",
                photo: "",
                provider: "",
                enabled: "",
                createdDate: "",
                lastLoginDate: ""
            }]
        }
    }
}
