import { Component, OnInit } from '@angular/core';
import { UsuarioBeanResponse } from 'src/app/modal/usuario/usuario-bean-response';
import { UsuarioResponse } from 'src/app/modal/usuario/usuario-response';
import { UsuarioService } from 'src/app/service/usuario/usuario.service';

@Component({
  selector: 'app-mi-cuenta',
  templateUrl: './mi-cuenta.component.html',
  styleUrls: ['./mi-cuenta.component.scss']
})
export class MiCuentaComponent implements OnInit {
  usuarioSesion: UsuarioResponse;
  datosUsuario:UsuarioBeanResponse
  constructor(private usuarioService:UsuarioService) { }

  obtenerDatosUsuario(){
   this.usuarioSesion = this.usuarioService.obtenerSesionUsuario();
  }


  obtenerInformacion(){
    this.datosUsuario = new UsuarioBeanResponse();
    this.usuarioService.obtenerUser(this.usuarioService.obtenerIDSessionUsuario()).subscribe(data=>{
      this.datosUsuario = data;
     
    })
  }

  nombreUsuario(){
    return this.usuarioSesion.response.list[0].name + " " + this.usuarioSesion.response.list[0].surname
  }
  ngOnInit(): void {
    this.obtenerDatosUsuario();
    this.obtenerInformacion();
  }

}
