<div class="menu-indice">Gestión de Libros / Inventario Stock</div>
<mdb-card>
    <mdb-card-body>        
        <div class="form-row align-items-center">
            <div class="form-group col-md-4">
            <div class="md-form mt-0 mb-0">
                <input type="text" id="txtFilBookTitle" maxlength="100" [(ngModel)]="txtFilBookTitle" class="form-control" mdbInput>
                <label for="txtFilBookTitle">Titulo de Libro</label>
            </div>
            </div>
            <div class="form-group col-md-1">
              Inventario:
            </div>
            <div class="form-group col-md-3">
                    <select class="browser-default custom-select" [(ngModel)]="cboInventario">   
                    <option value="" selected>Todos</option>
                    <option *ngFor="let listInventarioFil of listInventarioFil.response.list" [value]="listInventarioFil.masterValue">{{listInventarioFil.masterDescription}}</option>
                    </select>
            </div>
            <div class="form-group col-md-2">
              Stock menor a:
            </div>
            <div class="form-group col-md-2">
                    <select class="browser-default custom-select" [(ngModel)]="cboStock">
                    <option *ngFor="let listStockFil of listStockFil.response.list" [value]="listStockFil.masterValue">{{listStockFil.masterDescription}}</option>
                    </select>
            </div>                        
        </div>
        <div class="form-row align-items-center">
          
          <div class="form-group col-md-1">
            Estado:
          </div>
          <div class="form-group col-md-3">
                  <select class="browser-default custom-select" [(ngModel)]="cboEstado">
                  <option value="" selected>Todos</option>
                  <option *ngFor="let listEstadoFil of listEstadoFil.response.list" [value]="listEstadoFil.masterName">{{listEstadoFil.masterDescription}}</option>
                  </select>
          </div>
          <div class="form-group col-md-1">

          </div>
          <div class="form-group col-md-2 d-flex justify-content-left">
            <button mdbBtn type="button" id="btnBuscar" (click)="onBuscar()" size="sm" color="deep-purple" mdbWavesEffect>
            <mdb-icon fas icon="search" class="mr-1"></mdb-icon>Buscar</button>                    
          </div>
        </div>
    </mdb-card-body>
  </mdb-card>
  <br/>
  <div class="row">
    <div class="col-md-10" style="text-align: left;padding-bottom: 5px;font-weight: 500;font-size: 13px;">Total : {{listInventario.response.list.length}}</div>
    <div class="col-md-2" style="text-align: end;"> <a (click)="onExportarExcel()" style="text-align: right;font-weight: 500;font-size: 13px;">Exportar <mdb-icon far icon="file-excel" class="indigo-text" size="lg" aria-hidden="true"></mdb-icon></a></div>
  </div>  
  <mdb-card>
    <div class="table-responsive">
    <table mdbTable hover="true" class="table">
        <thead>
          <tr>
            <th *ngFor="let head of headElements" scope="col">{{head}}</th>
          </tr>
        </thead>
        <tbody>
          <tr mdbTableCol *ngFor="let listInventario of listInventario.response.list | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
            <th scope="row">{{listInventario.bookTitle}}</th>
            <th>{{listInventario.bookSubtitle}}</th>
            <td>{{listInventario.categoryName}}</td>
            <td>{{listInventario.inventoryStock}}</td>
            <td>{{listInventario.inventoryPrice}}</td>
            <td>{{listInventario.inventoryState == "1" ? "Asignado":"No asignado"}}</td>
            <td><button *ngIf="listInventario.inventoryState == '0'" type="button" (click)="onNuevo(listInventario.bookId,listInventario.bookTitle,listInventario.bookSubtitle)" color="deep-purple" outline="true" class="btn-sm" mdbWavesEffect mdbBtn><mdb-icon fas icon="book" size="lg"></mdb-icon></button></td>
            <td><button *ngIf="listInventario.inventoryState == '1'" type="button" (click)="onEditar(listInventario.bookId,listInventario.bookTitle,listInventario.bookSubtitle)" color="deep-purple" outline="true" class="btn-sm" mdbWavesEffect mdbBtn><mdb-icon fas icon="book" size="lg"></mdb-icon></button></td>
          </tr>
        </tbody>
      </table>
      </div>
        <div class="text-center">
          
          <ngb-pagination [maxSize]=3 [(page)]="page" class="d-flex justify-content-center"
            [pageSize]="pageSize" [boundaryLinks]="true" [collectionSize]="listInventario.response.list.length">
          </ngb-pagination>
          
        </div>
            
  </mdb-card>
<br>