import { Component, OnInit } from '@angular/core';
import { OrderPagoService } from 'src/app/service/orderpago/order-pago.service';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { CambioEstadoComponent } from 'src/app/pagina-admin/dashboard/bandeja-pedido/cambio-estado/cambio-estado.component';
import { DetalleOrdenComponent } from 'src/app/pagina-admin/dashboard/bandeja-pedido/detalle-orden/detalle-orden.component';

import { UtilitarioService } from 'src/app/service/utilitarios/utilitario.service';
import { MaestrasService } from 'src/app/service/maestras/maestras.service';
import { RespuestaComun } from 'src/app/modal/maestras/respuesta-comun';
import { OrderAdminResponse } from 'src/app/modal/order-pago/orderAdminResponse';
import { OrderAdminBean } from 'src/app/modal/order-pago/orderAdminBean';
import { ExcelService } from 'src/app/service/utilitarios/excel.service';

@Component({
  selector: 'app-bandeja-pedido',
  templateUrl: './bandeja-pedido.component.html',
  styleUrls: ['./bandeja-pedido.component.scss']
})
export class BandejaPedidoComponent implements OnInit {

  constructor(private orderPagoService:OrderPagoService,
    private modalService: MDBModalService,
    private maestrasService: MaestrasService,    
    private utilitarioService: UtilitarioService,
    private excelService:ExcelService,) { }

  headElements = ['Nro orden', 'Nombres','Documento', 'Fecha', 'Total', 'Estado', 'Detalle','Cambio.Estado', 'Sede'];
  modalRefCambioEstado: MDBModalRef;
  
  listEstado:RespuestaComun = new RespuestaComun();
  listSede:RespuestaComun = new RespuestaComun();
  cboEstado:String = "0";
  cboSede:String = "0";

  listOrder:OrderAdminResponse = new OrderAdminResponse();
  txtOrden:String="";
  txtFechaIni:String="";
  txtFechaFin:String="";
  txtnrodocumento="";
  

  fechaIniLoad:String="";
  fechaFinLoad:String="";

  page = 1;
  pageSize = 9;

  ngOnInit(): void {
    //fecah defaul en formato 2020-01-01 datepiker
    let dateCurrent = new Date();
    this.fechaIniLoad = dateCurrent.getFullYear() + "-01-01";
    this.fechaFinLoad = dateCurrent.getFullYear() + "-12-31";

    this.txtFechaIni = this.fechaIniLoad;
    this.txtFechaFin = this.fechaFinLoad;

    this.obtenerEstado();
    this.obtenerSedes();
    this.onBuscar('load');

  }

  obtenerEstado(){

    this.utilitarioService.loading = true;
    this.listEstado = new RespuestaComun();
    this.maestrasService.obtenerMaestra("TIPO_ESTADO_PEDIDO").subscribe(data=>{
      this.listEstado = data;      
      //console.log(data);
      this.utilitarioService.loading = false;
    });
    
  }

  obtenerSedes(){

    this.utilitarioService.loading = true;
    this.listSede = new RespuestaComun();
    this.maestrasService.obtenerMaestra("TIPO_ENTREGA").subscribe(data=>{
      //debugger;
      this.listSede = data;      
      //console.log(data);
      this.utilitarioService.loading = false;
    });
    
  }

  
  onBuscar(evento:String){
    this.utilitarioService.loading = true;
    if (evento=="load"){

      //formato date para la consulta
      let dateCurrent = new Date();
      this.fechaIniLoad = "01/01/" + dateCurrent.getFullYear();
      this.fechaFinLoad = "31/12/" + dateCurrent.getFullYear();
    
      this.listOrder = new OrderAdminResponse();
      this.orderPagoService.obtenerOrderAdmin(this.fechaIniLoad.toString(),this.fechaFinLoad.toString(),"0","0","","0").subscribe(data=>{
        this.listOrder = data;        
        console.log(data);
        this.utilitarioService.loading = false;
      })

    }else{

      let ordenBus:string;
      //validar campos de entrada
      if (this.txtOrden.toString()==""){ ordenBus = "0";}
      else{ordenBus = this.txtOrden.toString()}

      if (this.txtFechaIni.toString()!="" && this.isFecha(this.formatFecha(this.txtFechaIni.toString())) == false){
        this.utilitarioService.toastrWarning("Verifique la fecha de inicio");
      }

      if (this.txtFechaFin.toString()!="" && this.isFecha(this.formatFecha(this.txtFechaFin.toString())) == false){
        this.utilitarioService.toastrWarning("Verifique la fecha de Fin");
      }      
            
      this.listOrder = new OrderAdminResponse();
      //debugger;
      this.orderPagoService.obtenerOrderAdmin(this.formatFecha(this.txtFechaIni.toString()),this.formatFecha(this.txtFechaFin.toString()),ordenBus,this.cboEstado,this.txtnrodocumento, this.cboSede).subscribe(data=>{
        this.listOrder = data;
        //console.log(data);
        this.utilitarioService.loading = false;
      })
    }    

  }

  onOpenCambioEstado(orderAdminBean:OrderAdminBean) {
    this.modalRefCambioEstado = this.modalService.show(CambioEstadoComponent,
      {
        backdrop: true,
        keyboard: true,
        focus: true,
        show: false,
        ignoreBackdropClick: false,
        class: 'modal-dialog modal-dialog-centered modal-notify modal-warning',
        containerClass: 'top',
        animated: true,
        data: {
          idOrden: orderAdminBean.id,
          idEstado: orderAdminBean.stateId,
          nroOrden: orderAdminBean.number,
        }
      }
      );
  }  

  onOpenOrdenDetalle(orderAdminBean:OrderAdminBean) {
    //console.log("orderAdminBean",orderAdminBean);
    this.modalRefCambioEstado = this.modalService.show(DetalleOrdenComponent,
      {
        backdrop: true,
        keyboard: true,
        focus: true,
        show: false,
        ignoreBackdropClick: false,
        class: 'modal-dialog modal-dialog-centered modal-dialog-scrollable modal-notify modal-warning modal-lg',
        containerClass: 'top',
        animated: true,
        data: {
          idOrden: orderAdminBean.id,          
          nroOrden: orderAdminBean.number,
          total: orderAdminBean.total,
          date: orderAdminBean.date,
          clientName: orderAdminBean.clientName,
          stateName: orderAdminBean.stateName,         
        }
      }
    );
  }

  ocultarbuttonEstado(idEstado:String){
    
    if (idEstado=="29" || idEstado=="31" || idEstado=="30" ){ //entregado
      //console.log(idEstado);
      return false;
    }
   
    
    else{return true}

  }

  isFecha(fecha:String){

    let stringFecha = fecha;
    const [d, m, y] = stringFecha.split('/');
    // cambiamos al estandar de fecha yyyy/mm/dd (de otra forma no te podrá convertir un string a fecha de forma apropiada)
    const dateConversion = new Date(`${y}/${m}/${d}`);
    if (dateConversion.toString()=="Invalid Date"){
      return false;
    }
    return true;

  }

  formatFecha(fecha:String){

    let stringFecha = fecha;
    const [y,m,d] = stringFecha.split('-');
    // cambiamos al estandar de fecha yyyy/mm/dd (de otra forma no te podrá convertir un string a fecha de forma apropiada)
    const fechaformat = `${d}/${m}/${y}`;    
    return fechaformat;

  }

  onExportarExcel(){
    this.excelService.exportExcelBandejaPagados(this.listOrder.response.list);
  }

}
