export class AnioAdminListBean{
    id: String;
    name: String;
    about: String;
    order: String;
    usuarioRegistroId: String;
    usuarioModificaId: String;
    enabled: String;
    status: String;
    createdDate: String;
}

export class AnioAdminIdBean{
    id: String;
    name: String;
    about: String;
    order: String;
    usuarioRegistroId: String;
    usuarioModificaId: String;
    enabled: String;
    createdDate: String;
}

export class AnioAdminBeanInsRequest{
    about: String;
    enabled: String;
    name: String;
    order: String;
    usuarioRegistroId: String;
}

export class AnioAdminBeanUpdRequest{    
    about: String;
    enabled: String;
    id: String;
    name: String;
    order: String;
    usuarioModificaId: String;
}