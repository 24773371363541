import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders} from '@angular/common/http';

import { InventarioProListResponse,InventarioProListAddResponse } from '../../modal/inventario-producto/inventarioProListResponse';
import { InventarioProRegIdResponse } from '../../modal/inventario-producto/inventarioProRegIdResponse';
import { InventarioProDmlResponse } from '../../modal/inventario-producto/inventarioProDmlResponse';

import { InventarioProBeanInsRequest,InventarioProBeanUpdRequest,InventProSigaBeanUpdRequest } from '../../modal/inventario-producto/inventarioProBean';

@Injectable({
  providedIn: 'root'
})
export class InventarioProdService {

  constructor(private httpClient:HttpClient) { }
  
  obtenerAdminList(idInventario:String,idProducto:String,productoAsignado01:String,tituloProducto:String,estadoProducto01:String){
    
    let url = `${environment.urlBase}${environment.listInvProdAdmin}?idInventario=${idInventario}&idProducto=${idProducto}&productoAsignado01=${productoAsignado01}&tituloProducto=${tituloProducto}&estadoProducto01=${estadoProducto01}`;
    //console.log("url",url);
    return this.httpClient.get<InventarioProListResponse>(url).pipe(
      map(data => {   
        let response: InventarioProListResponse;
        response = data;
        return response;
      },
      error =>{      
        return new Response("9999", error);
      })) 
  }

  obtenerAdminAddList(idProducto:String){
    
    let url = `${environment.urlBase}${environment.getListInvProdAdmin}/${idProducto}`;
    //console.log("url",url);
    return this.httpClient.get<InventarioProListAddResponse>(url).pipe(
      map(data => {
        let response: InventarioProListAddResponse;
        response = data;
        return response;
      },
      error =>{      
        return new Response("9999", error);
      })) 
  }

  obtenerRegistro(id:String){
    
    let url = `${environment.urlBase}${environment.getInvProdAdminId}/${id}`;
    //console.log("url",url);
    
    return this.httpClient.get<InventarioProRegIdResponse>(url).pipe(
      map(data => {   
        let response: InventarioProRegIdResponse;
        response = data;
        return response;
      },
      error =>{      
        return new Response("9999", error);
      }))

  }

  grabar(inventarioProBeanInsRequest:InventarioProBeanInsRequest){
    let url = `${environment.urlBase}${environment.insertInvProdAdmin}`;
    return this.httpClient.post<InventarioProDmlResponse>(url,inventarioProBeanInsRequest).pipe(
      map(data => {
        let response: InventarioProDmlResponse;
        response = data;        

        return response;        
      },
      error =>{      
        return new Response("9999", error);
      }) 
    )
  }

  update(inventarioProBeanUpdRequest:InventarioProBeanUpdRequest,id:String){
    let url = `${environment.urlBase}${environment.updateInvProdAdmin}/${id}`;
    return this.httpClient.put<InventarioProDmlResponse>(url,inventarioProBeanUpdRequest).pipe(
      map(data => {
        let response: InventarioProDmlResponse;
        response = data;        
        return response;
      },
      error =>{      
        return new Response("9999", error);
      }) 
    )
  }

  updateSigaConceptos(inventProSigaBeanUpdRequest:InventProSigaBeanUpdRequest,id:String){
    let url = `${environment.urlBase}${environment.updateInvProdConceptoAdmin}/${id}`;
    return this.httpClient.put<InventarioProDmlResponse>(url,inventProSigaBeanUpdRequest).pipe(
      map(data => {
        let response: InventarioProDmlResponse;
        response = data;        
        return response;
      },
      error =>{      
        return new Response("9999", error);
      }) 
    )
  }

  delete(id:String){
    let url = `${environment.urlBase}${environment.deleteInvProdAdmin}/${id}`;
    return this.httpClient.delete<InventarioProDmlResponse>(url).pipe(
      map(data => {
        let response: InventarioProDmlResponse;
        response = data;        
        return response;
      },
      error =>{      
        return new Response("9999", error);
      }) 
    )
  }

}
