export class MenuResponse {
   httpStatus : number;
   response : {
     id : string,
     codeResponse :string,
     response :  string,
     list : [
      {
         id :  string,
         name :  string,
         description :  string,
         order :  string,
         submenu : [
          {
             id :  string,
             name :  string,
             description : string,
             order :  string,
          }
        ]
      }
    ]
    }
    constructor(){
        this.httpStatus = 0;
        this.response = {
          id : "",
          codeResponse :"",
          response :  "",
          list : [
           {
              id :  "",
              name :  "",
              description :  "",
              order :  "",
              submenu : [
               {
                  id :  "",
                  name :  "",
                  description : "",
                  order :  "",
               }
             ]
           }
         ]
         }


    }
}
