import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { map } from "rxjs/operators";
import { environment } from 'src/environments/environment';
import { UbigeoResponse } from 'src/app/modal/ubigeo/ubbigeoResponse';

@Injectable({
  providedIn: 'root'
})
export class UbigeoService {

  constructor(private httpClient:HttpClient) { }

  obtenerDepartamento(){
    let url = `${environment.urlBase}${environment.ubigeoDepart}`;
    //console.log("url",url);
    return this.httpClient.get<UbigeoResponse>(url).pipe(
      map(data => {   
        let response: UbigeoResponse;
        response = data;
        if(data.response.codeResponse==="0000"){
          return response;  
        }
      },
      error =>{      
        return new Response("9999", error);
      })) 
  }

  obtenerProvincia(codeDepartment:String){
    let url = `${environment.urlBase}${environment.ubigeoProv}/${codeDepartment}`;
    //console.log("url",url);
    return this.httpClient.get<UbigeoResponse>(url).pipe(
      map(data => {   
        let response: UbigeoResponse;
        response = data;
        if(data.response.codeResponse==="0000"){
          return response;
        }
      },
      error =>{      
        return new Response("9999", error);
      }))
  }

  obtenerDistrito(codeDepartment:String,codeProvincia:String){
    let url = `${environment.urlBase}${environment.ubigeoDist}/${codeDepartment}/${codeProvincia}`;
    //console.log("url",url);
    return this.httpClient.get<UbigeoResponse>(url).pipe(
      map(data => {   
        let response: UbigeoResponse;
        response = data;
        if(data.response.codeResponse==="0000"){
          return response;  
        }
      },
      error =>{      
        return new Response("9999", error);
      })) 
  }

}
