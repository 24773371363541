export class OrderCalculateResponse {

    httpStatus:number;
    response: {
      id:string,
      codeResponse:string,
      response:string,
      list: [{
        subtotalCost: String,
        shippingCost: String,
        igvCost: String,
        totalCost: String,
      }]
    }    

    constructor(){
      this.httpStatus=0;
      this.response={
          id:"",
          codeResponse:"",
          response:"",
          list:[{
            subtotalCost: "0",
            shippingCost: "0",
            igvCost: "0",
            totalCost: "0",
          }]
      }
    }
    
}