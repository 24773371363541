import { Component, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-banner-sourvenir',
  templateUrl: './banner-sourvenir.component.html',
  styleUrls: ['./banner-sourvenir.component.scss']
})
export class BannerSourvenirComponent implements OnInit {
  tipoSourvenir="";

  constructor(private route: ActivatedRoute) {
   
  }

 
  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.tipoSourvenir = params.get('idSourvenir');
      console.log(this.tipoSourvenir);

  });
  }

}
