import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders} from '@angular/common/http';

import { TopicBeanInsRequest,TopicBeanUpdRequest } from '../../modal/topic/topicBean';
import { TopicListResponse } from '../../modal/topic/topicListResponse';
import { TopicRegIdResponse } from '../../modal/topic/topicRegIdResponse';
import { TopicDmlResponse } from '../../modal/topic/topicDmlResponse';
import { ComboResponse } from '../../modal/maestras/combo-response';

@Injectable({
  providedIn: 'root'
})
export class TopicService {

  constructor(private httpClient:HttpClient) { }

  obtenerAdminList(nombre:string,estado:string){
    let url = `${environment.urlBase}${environment.listTopicAdmin}?nombre=${nombre}&estado=${estado}`;
    //console.log("url",url);
    return this.httpClient.get<TopicListResponse>(url).pipe(
      map(data => {
        let response: TopicListResponse;
        response = data;
        return response;
      },
      error =>{
        return new Response("9999", error);
      })) 
  }

  obtenerRegistro(id:String){
    let url = `${environment.urlBase}${environment.getTopicAdminId}/${id}`;
    //console.log("url",url);
    return this.httpClient.get<TopicRegIdResponse>(url).pipe(
      map(data => {   
        let response: TopicRegIdResponse;
        response = data;
        return response;
      },
      error =>{      
        return new Response("9999", error);
      })) 
  }

  grabar(topicBeanInsRequest:TopicBeanInsRequest){
    let url = `${environment.urlBase}${environment.insertTopicAdmin}`;
    return this.httpClient.post<TopicDmlResponse>(url,topicBeanInsRequest).pipe(
      map(data => {
        let response: TopicDmlResponse;
        response = data;        

        return response;        
      },
      error =>{      
        return new Response("9999", error);
      }) 
    )
  }

  update(submenuBeanUpdRequest:TopicBeanUpdRequest,id:String){
    let url = `${environment.urlBase}${environment.updateTopicAdmin}/${id}`;
    return this.httpClient.put<TopicDmlResponse>(url,submenuBeanUpdRequest).pipe(
      map(data => {
        let response: TopicDmlResponse;
        response = data;        
        return response;        
      },
      error =>{      
        return new Response("9999", error);
      }) 
    )
  }

  obtenerListCombo(){
    let url = `${environment.urlBase}${environment.listTopicCombo}`;
    //console.log("url",url);
    return this.httpClient.get<ComboResponse>(url).pipe(
      map(data => {   
        let response: ComboResponse;
        response = data;
        return response;
      },
      error =>{
        return new Response("9999", error);
      })) 
  }

}
