export class ResponseSourvenirComun {
    httpStatus:number;
    response: {
      id:string,
      codeResponse:string,
      response:string,
      list: [
        {
          id:string,
          name:string,
          description:string,
          order:string
        }
    ]}
    constructor(){
        this.httpStatus=0;
        this.response= {
        id:"",
        codeResponse:"",
        response:"",
        list: [
            {
            id:"",
            name:"",
            description:"",
            order:""
            }
        ]}
    }
}
