import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { map } from "rxjs/operators";
import { environment } from 'src/environments/environment';
import { OrderCalculateRequest } from 'src/app/modal/procesapago/orderCalculateRequest';
import { OrderCalculateResponse } from 'src/app/modal/procesapago/orderCalculateResponse';
import { OrderRequest } from 'src/app/modal/procesapago/orderRequest';
import { OrderResponse } from 'src/app/modal/procesapago/orderResponse';
import { RespuestaPayme } from 'src/app/modal/procesapago/respuestaPayme';
import { OrderInternacionalRequest } from 'src/app/modal/procesapago/OrderInternacionalRequest';
import { OrderInternacionalResponse } from 'src/app/modal/procesapago/OrderInternacionalResponse';

@Injectable({
  providedIn: 'root'
})

export class ProcesarPagoService {

  constructor(private httpClient: HttpClient) { }

  obtenerCalculoTotales(orderCalculateRequest:OrderCalculateRequest){
    return this.httpClient.post<OrderCalculateResponse>(environment.urlBase+environment.calculateOrder,orderCalculateRequest).pipe(
      map(data => {
        let response: OrderCalculateResponse;
        response = data;
        
        return response;
        
      },
      error =>{
        return new Response("9999", error);
      })
    )
  }

  grabarOrden(orderRequest:OrderRequest){
    return this.httpClient.post<OrderResponse>(environment.urlBase+environment.orderGestion,orderRequest).pipe(
      map(data => {
        let response: OrderResponse;
        response = data;
        
        return response;
        
      },
      error =>{
        return new Response("9999", error);
      })
    )
  }

  grabarOrdenInternacional(orderInternacionalRequest:OrderInternacionalRequest){
    return this.httpClient.post<OrderInternacionalResponse>(environment.urlBase+environment.orderinternacional,orderInternacionalRequest).pipe(
      map(data => {
        let response: OrderInternacionalResponse;
        response = data;        
        return response;        
      },
      error =>{
        return new Response("9999", error);
      })
    )
  }

  obtenerRespuestaPayme(nroOrden:string,idAplicativo:String){
    let url = `${environment.urlBase}${environment.paymerespuesta}/${nroOrden}/${idAplicativo}`;
    //console.log("url",url);
    return this.httpClient.get<RespuestaPayme>(url).pipe(
      map(data => {   
        let response: RespuestaPayme;
        response = data;        
        
        return response;

      },
      error =>{      
        return new Response("9999", error);
      })) 
  }  

}
