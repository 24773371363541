<div class="container">
    <div class="row">
        <div class="col-sm-2"></div>

        <div class="col-sm-8 mt-2 mb-5">
            <form [formGroup]="registrarForm">
            <div class="row">
                <div class="col-sm-12 mt-4 ">
                    <p class="text-center h3 h3-responsive">Crea tu cuenta</p>                    
                    <p class="text-center textGris h3 h3-responsive">Haz el seguimiento de tus pedidos</p>
                        <div class="md-form">
                            <input mdbInput type="email" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$" [ngClass]="displayFieldCss('txtCorreo')" formControlName="txtCorreo" id="txtCorreo" class="form-control">
                            <label for="txtCorreo" class="">Ingrese correo email</label>
                        </div>
                        <div class="md-form">
                            <input mdbInput [ngClass]="displayFieldCss('txtNombre')" formControlName="txtNombre" type="text" id="txtNombre" class="form-control">
                            <label for="txtNombre" class="">Ingrese nombre</label>
                        </div>
                        <div class="md-form">
                            <input mdbInput [ngClass]="displayFieldCss('txtApellidos')" formControlName="txtApellidos" type="text" id="txtApellidos" class="form-control">
                            <label for="txtApellidos" class="">Ingrese apellidos</label>
                        </div>
                </div>  
                <div class="col-sm-4">
                    <label for="txtTipoDocumento" class="">Tipo de Documento</label>

                    <select class="browser-default custom-select" [ngClass]="displayFieldCss('txtTipoDocumento')" (change)="onChangeTipoDoc($event.target.value)" formControlName="txtTipoDocumento" id="txtTipoDocumento">
                                <option selected value="" disabled>Seleccione tipo de documento</option>
                                <option *ngFor="let tipo of listTipoDocumento.response.list" value="{{tipo.masterId}}">{{tipo.masterDescription}}</option>                                                  
                    </select> 
                </div>
                <div class="col-sm-8">
                        <div class="md-form">
                            <input mdbInput type="text" [ngClass]="displayFieldCss('txtNroDocumento')" [maxLength]="maxLengthTxtNroDocumento" formControlName="txtNroDocumento" id="txtNroDocumento" (keypress)="fc_ValTextDocumento($event)" class="form-control">
                            <label for="txtNroDocumento" class="">Ingrese Número de Documento</label>
                        </div>
                </div>
                <div class="col-sm-8">
                    <div class="md-form">
                        <input mdbInput type="text" [ngClass]="displayFieldCss('txtTelefono')" formControlName="txtTelefono" id="txtTelefono" class="form-control">
                        <label for="txtTelefono" class="">Ingrese Número de Teléfono</label>
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="md-form">
                        
                        <input mdbInput type="date" [ngClass]="displayFieldCss('txtFechaNacimiento')" formControlName="txtFechaNacimiento" id="txtFechaNacimiento" class="form-control">
                        <label for="txtFechaNacimiento" class="">Ingrese Fecha de Nacimiento</label>
                    </div>
                    <div class="md-form">
                        <input mdbInput type="password" [ngClass]="displayFieldCss('txtPassword')" formControlName="txtPassword" id="txtPassword" class="form-control">
                        <label for="txtPassword" class="">Ingrese Contraseña</label>
                    </div>
                    <div class="md-form">
                        <input mdbInput type="password" [ngClass]="displayFieldCss('txtPassword2')" formControlName="txtPassword2" id="txtPassword2" class="form-control">
                        <label for="txtPassword2" class="">Repita Contraseña</label>
                    </div>

                    <div class="col-md-12 text-center" style="margin-bottom: 10px;">
                        <div class="col-md-12">
                            <strong>Nota: </strong>
                                <a href="https://recursos.bnp.gob.pe/documentos/proteccion-datos-personales-bnp.pdf" title="Política de privacidad" target="_blank">
                                    "Política de privacidad para la protección de datos personales en la BNP"
                                </a>
                        </div>
                    </div>

                </div>
                <div class="col-sm-12 text-center">
                    <a  class="btn w-50 text-center btn-sm btnRegistrar" (click)="registrar()" >Registrate</a>
                </div>
            </div>  
            </form>
        <div class="col-sm-2"></div>
     
    </div>
</div>