import { ProductoControles } from './productoBean';

export class ProductoControlResponse{
    httpStatus: String;
    response: {
        id: String,
        codeResponse: String,
        response: String,
        list: Array<ProductoControles>
    }

    constructor(){
        this.httpStatus="0";
        this.response={
            id:"",
            codeResponse:"",
            response:"",
            list:[]
        }
      }
      
}