import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RespuestaComun } from 'src/app/modal/maestras/respuesta-comun';
import { MaestrasService } from 'src/app/service/maestras/maestras.service';
import { UtilitarioService } from 'src/app/service/utilitarios/utilitario.service';
import {PasswordToken} from 'src/app/modal/usuario/password-token';
import { UsuarioService } from 'src/app/service/usuario/usuario.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-recuperar-contrasena-token',
  templateUrl: './recuperar-contrasena-token.component.html',
  styleUrls: ['./recuperar-contrasena-token.component.scss']
})
export class RecuperarContrasenaTokenComponent implements OnInit {
  registrarForm: FormGroup;
  listTipoDocumento:RespuestaComun;
  passwordToken:PasswordToken;
 
  //@ViewChild(LoginComponent) loginComponent:LoginComponent;

  constructor(private router: ActivatedRoute,private routerLink:Router,private fb: FormBuilder,private maestraService:MaestrasService,private utilService:UtilitarioService,private usuarioService:UsuarioService) { 
  this.createForm();
  }


  createForm(){
    this.registrarForm = this.fb.group({
     
      txtPassword1:['',Validators.required],
      txtPassword2:['',Validators.required],

    });
  }
  displayFieldCss(field: string) {
    if (this.registrarForm.get(field).touched){
      return {
        'is-invalid': this.isFieldValid(field),
        'is-valid': !this.isFieldValid(field)
      };      
    }
  }    
  tipoDeDocumento(){
    this.listTipoDocumento = new RespuestaComun();
    this.maestraService.obtenerMaestra("TIPO_DOCUMENTO").subscribe(data=>{
      this.listTipoDocumento = data;
      //console.log(data);
    })
  }
  valirdarPass(){
    let retorno = true;
    let pass1 = this.registrarForm.get("txtPassword1").value;
    let pass2 = this.registrarForm.get("txtPassword2").value;
    if(pass1===pass2){
      retorno = false;
    }
    return retorno;

  }
  formatearFecha(fecha){
    let fechaNac:string="";
    if(fecha!=null){
      var date = new Date(fecha+"T05:00:00Z");
      ////console.log(date);
      var day = date.getDate();
      var month = date.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
      var year = date.getFullYear();
      fechaNac = day+"/"+month+"/"+year;
    //console.log(fechaNac);
    }
    return fechaNac
  }
  registrar(){  
    if (this.registrarForm.valid) {
      this.utilService.loading = true;
      let token =  this.router.snapshot.params.token;

      if(this.valirdarPass()){
        
        this.utilService.toastrError("Contraseñas Incorrectas");
        this.utilService.loading = false;

        return false;
      }
      this.passwordToken = new PasswordToken();
      this.passwordToken.password = this.registrarForm.get("txtPassword1").value;  

      //console.log(this.passwordToken);
      this.usuarioService.actualizarPassword(token,this.passwordToken).subscribe(data=>{
        if(data.response.codeResponse==="0000"){
          this.utilService.toastrSuccess(data.response.response);
          this.routerLink.navigate(['/']);

        }
        else{
          this.utilService.toastrError(data.response.response);
        }
        this.utilService.loading = false;

      })
    }
    else{
      this.validateAllFormFields(this.registrarForm);
      this.utilService.toastrError("Rellene los campos obligatorios");
    }

  }
  isFieldValid(field: string) {
    return !this.registrarForm.get(field).valid && this.registrarForm.get(field).touched;
  }   

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  verificarSession(){
    if(this.usuarioService.existeSesionUsuario()){
      this.routerLink.navigate(['/']);
    }
  }
  ngOnInit(): void {
    this.tipoDeDocumento();
    this.verificarSession();
  }
}