<div class="container mt-5 mb-5">
    <div class="row">
        <div class="col-sm-3">
          <form [formGroup]="filtrosForm"> 

            <p><span class="font-weight-bold cataTitle">Título</span></p>


            
            <p>  <input mdbInput type="text" id="form1"  (keyup.enter)="buscarLibros()" formControlName="txtTitulo" class="form-control" placeholder="Ingrese título"></p>
            <p><span class="font-weight-bold cataTitle">Autor</span></p>
            <div class="ng-autocomplete w-100">
                <ng-autocomplete 
                  formControlName="txtAutor"
                  [data]="listAutor.response.list"
                  placeHolder="Seleccione autor"
                  [searchKeyword]="keyword"
                
                  (inputFocused)='onFocused($event)'
                  [itemTemplate]="itemTemplate"
                  [notFoundTemplate]="notFoundTemplate"
                  notFoundText="No hay resutados"    
                  >
                                        
                </ng-autocomplete>
                 
                <ng-template #itemTemplate let-item>
                <a [innerHTML]="item.name"></a>
                </ng-template>
                 
                <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
                </ng-template>
                </div>
                <p><br><span class="font-weight-bold cataTitle">Categoría</span></p>
                <div class="ng-autocomplete w-100">
                <ng-autocomplete 
                  formControlName="txtCategoria"
                  [data]="listCategoria.response.list"
                  placeHolder="Seleccione categoría"
                  [searchKeyword]="keyword"
                  (selected)='selectEvent($event)'
                  (inputChanged)='onChangeSearch($event)'
                  (inputFocused)='onFocused($event)'
                  [itemTemplate]="itemTemplate"
                  notFoundText="No hay resutados"
                  [notFoundTemplate]="notFoundTemplate">                                 
                </ng-autocomplete>
                 
                <ng-template #itemTemplate let-item>
                <a [innerHTML]="item.name"></a>
                </ng-template>
                 
                <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
                </ng-template>
                </div>
                <p><br><span class="font-weight-bold cataTitle">Tipo de publicación</span></p>
                <div class="ng-autocomplete w-100">
                <ng-autocomplete 
                  formControlName="txtTipoPublicacion"
                  [data]="listTipoPubli.response.list"
                  [searchKeyword]="keyword2"
                  placeHolder="Seleccione tipo publicación"
                  (selected)='selectEvent($event)'
                  (inputChanged)='onChangeSearch($event)'
                  (inputFocused)='onFocused($event)'
                  [itemTemplate]="itemTemplate2"
                  notFoundText="No hay resutados"
                  [notFoundTemplate]="notFoundTemplate2">                                 
                </ng-autocomplete>
                 
                <ng-template #itemTemplate2 let-item>
                <a [innerHTML]="item.masterName"></a>
                </ng-template>
                 
                <ng-template #notFoundTemplate2 let-notFound>
                <div [innerHTML]="notFound"></div>
                </ng-template>
                </div>
                <p><br><span class="font-weight-bold cataTitle">Año de publicación</span></p>
                <div class="ng-autocomplete w-100">
                <ng-autocomplete 
                   formControlName="txtAnoPublicacion"
                  [data]="listAno.response.list"
                  placeHolder="Seleccione año publicación"
                  [searchKeyword]="keyword"
                  (selected)='selectEvent($event)'
                  (inputChanged)='onChangeSearch($event)'
                  (inputFocused)='onFocused($event)'
                  [itemTemplate]="itemTemplate"
                  notFoundText="No hay resutados"
                  [notFoundTemplate]="notFoundTemplate">                                 
                </ng-autocomplete>
                 
                <ng-template #itemTemplate let-item>
                <a [innerHTML]="item.name"></a>
                </ng-template>
                 
                <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
                </ng-template>
                </div>

                <br/>
                <a class="btn btn-filtros w-100" (click)="buscarLibros()">Aplicar Filtros</a>
                <br> <br>
                <p class="nroResultados"><span class="font-weight-bold">{{listLibros.response.list.length}}</span> Libro(s) encontrado(s)</p>

          </form>
          
        </div>
        <div class="col-sm-9 border borCatalogo">
            
            <div class="row" *ngIf="listLibros.response.list.length<=0">
              <div class="col-sm-12" >
                  No se encontraron registros
              </div>            
             
            </div>
            <div class="row" *ngIf="listLibros.response.list.length>0">
              
                <div class="col-sm-4" *ngFor="let libros of listLibros.response.list | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                 
                  <div class="m-4">
                    <div class="text-center">
                      <a [routerLink]="['/detalle',libros.id]">
                      <img src="{{libros.image}}" class="imagenPortada z-depth-2 rounded" />
                      </a>
                    </div>
                    <div class="datosLibro mt-3">
                      <p >
                        <a [routerLink]="['/detalle',libros.id]">
                          <span class="txtTitle">{{libros.title}}</span>
                        </a>  
                        <br/>

                        <span class="txtSubTitle">{{libros.subtitle}}</span>
                      </p>
                      <p class="text-center">
                        <span class="txtPrice">S/ {{libros.price}}</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12" >
                  <div class="text-center">
                    
                    <ngb-pagination [maxSize]=3 [(page)]="page" class="d-flex justify-content-center"
                      (pageChange)="cambiarPagina($event)"
                      [pageSize]="pageSize" [boundaryLinks]="true" [collectionSize]="listLibros.response.list.length">
                    </ngb-pagination>
                    
                  </div>
                
                </div> 
            </div>
        </div>
    </div>
</div>
