<!--Header-->
<div class="modal-header text-center" style="color: white;background-color: #544d84">    
    <h4 class="modal-title w-100">{{x_titulo}}</h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onClosemodal()">
      <span aria-hidden="true" class="white-text">&times;</span>
    </button>
</div>

<!--Body-->
  <div class="modal-body">
    <div class="form-row">
          <div class="col-12">
            <mdb-card>
              <mdb-card-body>              
                    <div class="form-row">
                      <div class="col-3">Código:</div>
                      <div class="col-9">
                          {{id}}
                      </div>
                    </div>
                    <br>
                    <div class="form-row">
                      <div class="col-3">Producto:</div>
                      <div class="col-9">
                          {{x_titulo_libro}}
                      </div>
                    </div>                  
              </mdb-card-body>
            </mdb-card>
          </div>
    </div>        

  <br>
  <div class="form-row">
    <div class="col-12">
      <mdb-card>
        <mdb-card-body>
          <div class="form-row">
            <div class="col-12">
                <div style="float: left;"><button mdbBtn type="button" id="btn_agregar_concepto" (click)="onAgregarConcepto()" size="sm" class="btn btn-deep-purple btn-rounded" mdbWavesEffect>Enlazar Concepto de Pago</button></div>
                <div style="float: left;"><button mdbBtn type="button" id="btn_modificar_concepto" (click)="onModificarConcepto()" size="sm" class="btn btn-deep-purple btn-rounded" mdbWavesEffect>Cambiar Concepto de Pago</button></div>
                <div style="float: left;"><button mdbBtn type="button" id="btn_actualizar_concepto" (click)="onActualizarConceptos()" size="sm" class="btn btn-deep-purple btn-rounded" mdbWavesEffect><mdb-icon fas icon="save" class="mr-1"></mdb-icon>Acualizar Concepto</button></div>
                <div style="float: left;"><button mdbBtn type="button" id="btn_cancelar_concepto" (click)="onCancelarConcepto()" size="sm" class="btn btn-deep-purple btn-rounded" mdbWavesEffect>Cancelar Cambio</button></div>
            </div>
          </div>
          <br>
          <div class="form-row">
            <div class="col-3">Código:</div>
            <div class="col-9">                
              <input mdbInput type="text" class="form-control" readonly [(ngModel)]="txtCodigoSigaSec" placeholder="0000-0">
            </div>
          </div>
          <br>
          <div class="form-row">
            <div class="col-3">Descripción:</div>
            <div class="col-9">                
              <input mdbInput type="text" readonly class="form-control" [(ngModel)]="txtinventoryDescription" placeholder="Concepto de pago">
            </div>
          </div>
          <br>
          <div class="form-row">
            <div class="col-3">Moneda:</div>
            <div class="col-9">                
              <input mdbInput type="text" readonly class="form-control" [(ngModel)]="txtinventoryCurrency" placeholder="">
            </div>
          </div>
          <br>
          <div class="form-row">
            <div class="col-3">Precio:</div>
            <div class="col-9">                
              <input mdbInput type="text" readonly class="form-control" [(ngModel)]="txtinventoryPrice" placeholder="">
            </div>
          </div>
          <br>
        </mdb-card-body>
      </mdb-card>
    </div>
  </div>  
  <br>
  <div class="form-row">
    <div class="col-12">
      <mdb-card>
        <mdb-card-body>
          <button mdbBtn type="button" id="btn_agregar_inventario" (click)="onAgregarInventario()" size="sm" class="btn btn-deep-purple btn-rounded" mdbWavesEffect>Agregar Inventario</button>
          <div class="table-responsive text-nowrap">
            <table mdbTable hover="true">
              <thead>
                <tr>                  
                  <th class="col-sm-1" scope="col">Nro</th>
                  <th class="col-sm-3" scope="col">Stock</th>
                  <th class="col-sm-3" scope="col" *ngIf="showTallaTable">Talla</th>
                  <th class="col-sm-3" scope="col">Estado</th>
                  <th class="col-sm-1" scope="col">Eli.</th>
                  <th class="col-sm-1" scope="col">Edi.</th>
                </tr>
              </thead>
              <tbody>
                <tr mdbTableCol *ngFor="let ListInventProAddBean of listInventProAddBean;let i = index">
                  <th>{{i + 1}}</th>
                  <th>{{ListInventProAddBean.inventory_stock}}</th>                  
                  <th *ngIf="showTallaTable">{{ListInventProAddBean.size_description}}</th>
                  <th>{{ListInventProAddBean.estado_description}}</th>
                  <td><button type="button" style="padding: 0px;" color="link" (click)="onEliminarInventario(ListInventProAddBean.inventory_id)" class="btn-sm" mdbWavesEffect mdbBtn><mdb-icon fas icon="minus-square" size="2x" class="indigo-text pr-3"></mdb-icon></button></td>
                  <td><button type="button" style="padding: 0px;" color="link" (click)="onEditarInventario(ListInventProAddBean.inventory_id)" class="btn-sm" mdbWavesEffect mdbBtn><mdb-icon fas icon="book" size="2x" class="indigo-text pr-3"></mdb-icon></button></td>
                </tr>
              </tbody>
            </table>
          </div>
        </mdb-card-body>
      </mdb-card>
    </div>
  </div>
  <!--Footer-->
<div class="modal-footer justify-content-center">
    
</div>

