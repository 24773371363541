<div class="container">
    <div class="row">
        <div class="col-sm-2"></div>

        <div class="col-sm-8 mt-2 mb-5">
            <form [formGroup]="registrarForm">
            <div class="row">
                <div class="col-sm-12 mt-4 ">
                    <p class="text-center h3 h3-responsive">Recuperar Contraseña</p>                    
                        <div class="md-form">
                            <input mdbInput type="email" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$" [ngClass]="displayFieldCss('txtCorreo')" formControlName="txtCorreo" id="txtCorreo" class="form-control">
                            <label for="txtCorreo" class="">Ingrese Correo</label>
                        </div>
                        

                </div>
                <div class="col-sm-12 text-center">
                    <a  class="btn w-50 text-center btn-sm btnRegistrar" (click)="registrar()" >Recuperar</a>
                </div>
            </div>  
            </form>
        <div class="col-sm-2"></div>
     
    </div>
</div>