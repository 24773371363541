<mdb-card-body>
    <mdb-card-text>               
        <div class="form-check">
            <input type="radio" class="form-check-input" id="materialChecked" name="materialExampleRadios"
                   [(ngModel)]="template" [value]="templateChecked">
            <label class="form-check-label" style="font-size: 14px;" for="materialChecked">Pago con tarjeta</label>
        </div>
        <br>
    </mdb-card-text>
</mdb-card-body>
<div class="row">
    <div class="col-md-12" style="text-align: right;">
        <button class="btn btn-flat btn-outline-indigo" (click)="onBack()">Regresar<i class="fas fa-arrow-left pl-1"></i></button>
    </div>
</div>
<div class="row">
    <div class="col-md-12" style="text-align: right;"> 
        <button mdbBtn type="button" block="true" (click)="onPagarPreorden()" gradient="blue" rounded="true" mdbWavesEffect>Pagar</button>
    </div>
</div>

<div style="display: none;">

    <form name="frmVPOS2" id="frmVPOS2" action="{{urlPayme}}" method="POST" target="_parent">
   
    <input type="text" name="acquirerId" [(ngModel)]="requestPayme.acquirerId">
    <input type="text" name="idCommerce" [(ngModel)]="requestPayme.idCommerce">
    <input type="text" name ="purchaseOperationNumber" [(ngModel)]="requestPayme.purchaseOperationNumber">
    <input type="text" name="purchaseAmount" [(ngModel)]="requestPayme.purchaseAmount">
    <input type="text" name="purchaseCurrencyCode" [(ngModel)]="requestPayme.purchaseCurrencyCode">
    <input type="text" name="language" [(ngModel)]="requestPayme.language">
    <input type="text" name="shippingFirstName" [(ngModel)]="requestPayme.shippingFirstName">
    <input type="text" name="shippingLastName" [(ngModel)]="requestPayme.shippingLastName">
    <input type="text" name="shippingEmail" [(ngModel)]="requestPayme.shippingEmail">
    <input type="text" name="shippingAddress" [(ngModel)]="requestPayme.shippingAddress">
    <input type="text" name="shippingZIP" [(ngModel)]="requestPayme.shippingZIP">
    <input type="text" name="shippingCity" [(ngModel)]="requestPayme.shippingCity">
    <input type="text" name="shippingState" [(ngModel)]="requestPayme.shippingState">
    <input type="text" name="shippingCountry" [(ngModel)]="requestPayme.shippingCountry">
    <input type="text" name="shippingPhone" [(ngModel)]="requestPayme.shippingPhone">
    <input type="text" name="userCommerce" [(ngModel)]="requestPayme.userCommerce">
    <input type="text" name="userCodePayme" [(ngModel)]="requestPayme.userCodePayme">
    <input type="text" name="descriptionProducts" [(ngModel)]="requestPayme.descriptionProducts">
    <input type="text" name="programmingLanguage" [(ngModel)]="requestPayme.programmingLanguage">
    <input type="text" name="purchaseVerification" [(ngModel)]="requestPayme.purchaseVerification">
    
    <!-- DEV -->
    <!-- <input type="submit" id="btnpasarella" style="position: absolute;" value="Pagar">-->
    <!-- PROD -->
    <!-- <input type="button" id="btnpasarella" style="position: absolute;" onclick="AlignetVPOS2.openModal('','2')" value="Pagar"> -->

    </form>
</div>
