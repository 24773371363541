import { Component, OnInit } from '@angular/core';
import { UtilitarioService } from 'src/app/service/utilitarios/utilitario.service';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { MaestrasService } from 'src/app/service/maestras/maestras.service';
import { GrupoAdmListResponse } from 'src/app/modal/grupo/grupoAdmListResponse';
import { GrupoService } from 'src/app/service/grupo/grupo.service';
import { RespuestaComun } from 'src/app/modal/maestras/respuesta-comun';
import { DmlGruposComponent } from 'src/app/pagina-admin/dashboard/gestionProductos/mant-grupos/dml-grupos/dml-grupos.component';

import { ResponseComun } from 'src/app/modal/catalogo/response-comun';
import { SubmenuService } from 'src/app/service/submenu/submenu.service';

@Component({
  selector: 'app-mant-grupos',
  templateUrl: './mant-grupos.component.html',
  styleUrls: ['./mant-grupos.component.scss']
})
export class MantGruposComponent implements OnInit {

  constructor(private grupoService:GrupoService,
    private utilitarioService: UtilitarioService,
    private maestrasService: MaestrasService,
    private modalService: MDBModalService,
    private submenuService:SubmenuService,
    ) { }

  listSubmenu:ResponseComun = new ResponseComun();
  cboSubmenu:String = "";

  txtdescripcion:String = "";
  cboEstado:String = "";
  modalRefAnioDml: MDBModalRef;
  listEstado:RespuestaComun = new RespuestaComun();
  headElements = ['Tipo de Regalo','Grupo','Orden', 'Estado','Editar'];
  listGrupo:GrupoAdmListResponse = new GrupoAdmListResponse();
  
  page = 1;
  pageSize = 9;

  ngOnInit(): void {
    this.cargarDatoInicial();
    this.onBuscar();
  }

  onBuscar(){
    this.utilitarioService.loading = true;
    this.grupoService.obtenerAdminList(this.txtdescripcion.toString(), this.cboEstado.toString(),this.cboSubmenu.toString()).subscribe(data=>{
      this.listGrupo = data;
      console.log(data);
      this.utilitarioService.loading = false;
    })
  }

  onNuevo(id:String){
    this.modalRefAnioDml = this.modalService.show(DmlGruposComponent,
      {
        backdrop: true,
        keyboard: true,
        focus: true,
        show: false,
        ignoreBackdropClick: false,
        class: 'modal-dialog modal-dialog-centered modal-dialog-scrollable modal-notify modal-warning modal-lg',
        containerClass: 'top',
        animated: true,
        data: {
          id: id,
          x_titulo:'Nuevo Tipo de Regalo',
          x_proceso:'I',
        }
      }
    );
  }

  onEditar(id:String){
    this.modalRefAnioDml = this.modalService.show(DmlGruposComponent,
      {
        backdrop: true,
        keyboard: true,
        focus: true,
        show: false,
        ignoreBackdropClick: false,
        class: 'modal-dialog modal-dialog-centered modal-dialog-scrollable modal-notify modal-warning modal-lg',
        containerClass: 'top',
        animated: true,
        data: {
          id: id,
          x_titulo:'Editar Tipo de Regalo',
          x_proceso:'U',
        }
      }
    );
  }

  cargarDatoInicial(){

    this.utilitarioService.loading = true;
    this.listEstado = new RespuestaComun();
    this.maestrasService.obtenerMaestra("TIPO_STATUS_TABLA").subscribe(data=>{
      this.listEstado = data;
    });

    this.listSubmenu = new ResponseComun();

    this.submenuService.obtenerListCombo().subscribe(data=>{
      console.log(data);
      
      this.listSubmenu = data;
      this.utilitarioService.loading = false;
    });

  }

}
