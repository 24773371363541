import { InventarioProListBean } from './inventarioProBean';
import { InventProListAddBean } from './inventarioProBean';

export class InventarioProListResponse{
    httpStatus: String;
    response: {
        id: String,
        codeResponse: String,
        response: String,
        list: Array<InventarioProListBean>
    }

    constructor(){
        this.httpStatus="0";
        this.response={
            id:"",
            codeResponse:"",
            response:"",
            list:[]
        }
      }
      
}

export class InventarioProListAddResponse{
    httpStatus: String;
    response: {
        id: String,
        codeResponse: String,
        response: String,
        list: Array<InventProListAddBean>
    }

    constructor(){
        this.httpStatus="0";
        this.response={
            id:"",
            codeResponse:"",
            response:"",
            list:[]
        }
      }
      
}