<div class="fixed-top " *ngIf="libreriaVirtual">
  <mdb-navbar SideClass="navbar navbar-expand-lg navbar-light indigo">

    <mdb-navbar-brand>
      
          <a class="navbar-brand" [routerLink]="['/']"><img class="imgLogo"  src="/assets/image/portafolio/logos_bnp.png"  ></a>
          <a class="navbar-brand" [routerLink]="['/']"><img class="imgTienda"  src="/assets/image/portafolio/Libreria-virtual-Logo.png"  ></a>
   
    

      
    </mdb-navbar-brand> 
  

    <links>
  

      <ul class="navbar-nav ml-auto">
        
        <li class="nav-item">
          <a class="nav-link waves-light" [routerLink]="['/']" mdbWavesEffect><img src="/assets/image/portafolio/ic_1.png" width="20px" > Inicio</a>
        </li>
      
          
  
        <li class="nav-item dropdown" ngDropdown>
          <a  mdbWavesEffect type="button"  ngDropdownControl class="nav-link waves-light" mdbWavesEffect>
            <img src="/assets/image/portafolio/ic_2.png" width="20px" > <span *ngIf="!obtenerSesion()"> Ingrese</span> <span *ngIf="obtenerSesion()"> Mi cuenta</span> <span class="caret"></span></a>
          <div ngDropdownContent class="dropdown-menu dropdown dropdown-primary" dropdownClass="slide-fade">
            <app-login></app-login>
          </div>
        </li>
  
        <li class="nav-item dropdown" ngDropdown>
          <a  type="button"  ngDropdownControl (click)="actualizarCarrito()" class="nav-link  waves-light"  mdbWavesEffect>
            <img src="/assets/image/portafolio/ic_3.png" width="20px" >&nbsp; <mdb-badge danger="true">{{cantidadCarrito}}</mdb-badge> Carrito<span class="caret"></span></a>
          <div ngDropdownContent class="dropdown-menu dropdown dropdown-primary redIn divMenuRegalos" dropdownClass="slide-fade">
            <app-carrito-compras-header [flagActualizar]="flagActualizar"></app-carrito-compras-header>
          </div>
        </li>



        <!-- <li class="nav-item dropdown" dropdown>
          <a dropdownToggle mdbWavesEffect type="button" (click)="actualizarCarrito()" class="nav-link  waves-light" mdbWavesEffect>
            <img src="/assets/image/portafolio/ic_3.png" width="20px" >&nbsp; <mdb-badge danger="true">{{cantidadCarrito}}</mdb-badge> Carrito<span class="caret"></span></a>
          <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
            <app-carrito-compras-header [flagActualizar]="flagActualizar"></app-carrito-compras-header>
          </div>
        </li>   -->
  
      </ul>
    
   
      
    </links>
   
    
  
  </mdb-navbar>
  
  
  
  <mdb-navbar SideClass="navbar navbar-expand-lg navbar-light redIn divHeader" class="divHeader">

    <mdb-navbar-brand><a class="navbar-brand" href="#"></a></mdb-navbar-brand>

    <links>
  
      <ul class="navbar-nav mr-auto" *ngIf="menuFlag">
        

        <!-- <li class="nav-item" *ngFor="let menu of menuResponse.response.list[1].submenu">
          <a class="nav-link waves-light text-white text-upperCase" [routerLink]="['/sourvenir',menu.id]"> {{menu.description}}</a>
        </li> -->

        <li class="nav-item" >
          <a dropdownToggle mdbWavesEffect [routerLink]="['/']" type="button" class="nav-link  waves-light text-white" mdbWavesEffect>
            PUBLICACIONES
          </a>
        </li>
        <!-- <li class="nav-item dropdown" dropdown>
          <a dropdownToggle mdbWavesEffect type="button" class="nav-link  waves-light text-white" mdbWavesEffect>
            PUBLICACIONES
          </a>
          <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary redIn"  role="menu">
            <div class="w-100 pt-1 pl-3 pb-3 pr-3">   
              <ul style="list-style: none;color: #fff;margin-left: -1rem;">
                
                <li class="nav-item" >
                  <a class="nav-link waves-light text-white text-upperCase" [routerLink]="['/catalogo','LIBROS']">LIBROS</a>
                </li> 
                <li class="nav-item">
                  <a class="nav-link waves-light text-white text-upperCase" [routerLink]="['/catalogo','REVISTAS']">REVISTAS</a>
                </li> 
              </ul>
            </div>
          </div>
        </li> -->
        <li class="nav-item dropdown menuRegalosWeb" dropdown>
          <a dropdownToggle mdbWavesEffect type="button" class="nav-link  waves-light text-white" mdbWavesEffect>
            REGALOS
          </a>
          <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary redIn divMenuRegalos"  role="menu">
            <div class="w-100 pt-1 pl-3 pb-3 pr-3">       

                      <div class="w-50 float-left">
                        <span class="font-weight-bold text-white text-upperCase pointer" [routerLink]="['/sourvenir',6]" >TEMAS </span>
                        
                        <ul style="list-style: disc;color: #fff;margin-left: -1rem;">
                          <li class="nav-item">
                            <a class="nav-link waves-light text-white" [routerLink]="['/sourvenir',6,1]">Somos libres, leamos siempre</a>
                          </li>
                          <li class="nav-item">
                            <a class="nav-link waves-light text-white" [routerLink]="['/sourvenir',6,2]">Justo Apu Sahuaraura Inca</a>
                          </li>
                          <li class="nav-item">
                            <a class="nav-link waves-light text-white" [routerLink]="['/sourvenir',6,3]">Acuarelas de José María Eguren</a>
                          </li>
                          <li class="nav-item">
                            <a class="nav-link waves-light text-white" [routerLink]="['/sourvenir',6,5]">Ilustraciones de Fondo Antiguo</a>
                          </li>
                        </ul>
                           
                     
                       
                      </div>
                      <div class="w-50 float-left">
                        <span class="text-white font-weight-bold text-upperCase">REGALOS </span>
                        <ul style="list-style: disc;color: #fff;margin-left: -1rem;">
                          <li class="nav-item">
                            <a class="nav-link waves-light text-white" [routerLink]="['/sourvenir',3]">Hogar</a>
                          </li> 
                          <li class="nav-item">
                            <a class="nav-link waves-light text-white" [routerLink]="['/sourvenir',4]">Vestimenta y Accesorios</a>
                          </li> 
                          <li class="nav-item">
                            <a class="nav-link waves-light text-white" [routerLink]="['/sourvenir',5]">Juegos y tiempo libre</a>
                          </li> 
                          <li class="nav-item">
                            <a class="nav-link waves-light text-white" [routerLink]="['/sourvenir',2]">Papelería y oficina</a>
                          </li>                         
                        </ul>                            
                      </div>                                     
            </div>
          </div>
        </li> 
        <li class="nav-item dropdown menuRegalosMovil" dropdown>
          <a dropdownToggle mdbWavesEffect type="button" class="nav-link  waves-light text-white" mdbWavesEffect>
            REGALOS
          </a>
          <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary redIn divMenuRegalos"  role="menu">
            <div class="w-100 pt-1 pl-3 pb-3 pr-3">       

                      <div class="w-100 float-left">
                        <span class="font-weight-bold text-white text-upperCase pointer" [routerLink]="['/sourvenir',6]" >TEMAS </span>
                        
                        <ul style="list-style: disc;color: #fff;margin-left: -1rem;">
                          <li class="nav-item">
                            <a class="nav-link waves-light text-white" [routerLink]="['/sourvenir',6,1]">Somos libres, leamos siempre</a>
                          </li>
                          <li class="nav-item">
                            <a class="nav-link waves-light text-white" [routerLink]="['/sourvenir',6,2]">Justo Apu Sahuaraura Inca</a>
                          </li>
                          <li class="nav-item">
                            <a class="nav-link waves-light text-white" [routerLink]="['/sourvenir',6,3]">Acuarelas de José María Eguren</a>
                          </li>
                          <li class="nav-item">
                            <a class="nav-link waves-light text-white" [routerLink]="['/sourvenir',6,5]">Ilustraciones de Fondo Antiguo</a>
                          </li>
                        </ul>                                     
                        <span class="text-white font-weight-bold text-upperCase">REGALOS </span>
                        <ul style="list-style: disc;color: #fff;margin-left: -1rem;">
                          <li class="nav-item">
                            <a class="nav-link waves-light text-white" [routerLink]="['/sourvenir',3]">Hogar</a>
                          </li> 
                          <li class="nav-item">
                            <a class="nav-link waves-light text-white" [routerLink]="['/sourvenir',4]">Vestimenta y Accesorios</a>
                          </li> 
                          <li class="nav-item">
                            <a class="nav-link waves-light text-white" [routerLink]="['/sourvenir',5]">Juegos y tiempo libre</a>
                          </li> 
                          <li class="nav-item">
                            <a class="nav-link waves-light text-white" [routerLink]="['/sourvenir',2]">Papeleria y oficina</a>
                          </li>                         
                        </ul>                            
                      </div>                                     
            </div>
          </div>
        </li> 

       
  
      </ul>
    
  
      
    </links>
    
  
  </mdb-navbar>
</div>

<!-- 
<div class="fixed-top" *ngIf="!libreriaVirtual">
  <mdb-navbar SideClass="navbar navbar-expand-lg navbar-light indigo">


    <mdb-navbar-brand>
      
          <a class="navbar-brand" [routerLink]="['/']"><img class="imgLogo"  src="/assets/image/portafolio/logos_bnp.png"  ></a>
          <a class="navbar-brand" [routerLink]="['/sourvenir/6']"><img class="imgTienda"  src="/assets/image/inicio/LOGO-TIENDA-BNP.png"  ></a>

          <a class="navbar-brand" [routerLink]="['/']"><img class="imgLibreria"  src="/assets/image/portafolio/logo_footer_libreria_virtual.png"  ></a>
   
    

      
    </mdb-navbar-brand> 
  

    <links>
  

      <ul class="navbar-nav ml-auto">
        
        <li class="nav-item">
          <a class="nav-link waves-light" [routerLink]="['/']" mdbWavesEffect><img src="/assets/image/portafolio/ic_1.png" width="20px" > Inicio</a>
        </li>
 
          
  
        <li class="nav-item dropdown" ngDropdown>
          <a  mdbWavesEffect type="button"  ngDropdownControl class="nav-link waves-light" mdbWavesEffect>
            <img src="/assets/image/portafolio/ic_2.png" width="20px" > <span *ngIf="!obtenerSesion()"> Ingrese</span> <span *ngIf="obtenerSesion()"> Mi cuenta</span> <span class="caret"></span></a>
          <div ngDropdownContent class="dropdown-menu dropdown dropdown-primary" dropdownClass="slide-fade">
            <app-login></app-login>
          </div>
        </li>
  
   
        <li class="nav-item dropdown" dropdown>
          <a dropdownToggle mdbWavesEffect type="button" (click)="actualizarCarrito()" class="nav-link  waves-light" mdbWavesEffect>
            <img src="/assets/image/portafolio/ic_3.png" width="20px" >&nbsp; <mdb-badge danger="true">{{cantidadCarrito}}</mdb-badge> Carrito<span class="caret"></span></a>
          <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
            <app-carrito-compras-header [flagActualizar]="flagActualizar"></app-carrito-compras-header>
          </div>
        </li>  
  
      </ul>
    
  
      
    </links>

    
  
  </mdb-navbar>
  
  
  
  <mdb-navbar SideClass="navbar navbar-expand-lg navbar-light redIn">
    <mdb-navbar-brand><a class="navbar-brand" href="#"></a></mdb-navbar-brand>
    <links>  
      <ul class="navbar-nav mr-auto" *ngIf="menuFlag">       
    
        <li class="nav-item" *ngFor="let menu of menuResponse.response.list[1].submenu">
          <a class="nav-link waves-light text-white text-upperCase" [routerLink]="['/sourvenir',menu.id]"> {{menu.description}}</a>
        </li>
  
      </ul>      
      
    </links>    
  
  </mdb-navbar>
</div>  -->






<!-- PROD -->
<!-- 
<div class="fixed-top" >
  <mdb-navbar SideClass="navbar navbar-expand-lg navbar-light indigo">

    <mdb-navbar-brand>
      
          <a class="navbar-brand" [routerLink]="['/']"><img class="imgLogo"  src="/assets/image/portafolio/logos_bnp.png"  ></a>
          <a class="navbar-brand" [routerLink]="['/']"><img class="imgTienda"  src="/assets/image/portafolio/logo_footer_libreria_virtual.png"  ></a>
   
    

      
    </mdb-navbar-brand> 
  

    <links>
  

      <ul class="navbar-nav ml-auto">
        
        <li class="nav-item">
          <a class="nav-link waves-light" [routerLink]="['/']" mdbWavesEffect><img src="/assets/image/portafolio/ic_1.png" width="20px" > Inicio</a>
        </li>
      
          
  
        <li class="nav-item dropdown" ngDropdown>
          <a  mdbWavesEffect type="button"  ngDropdownControl class="nav-link waves-light" mdbWavesEffect>
            <img src="/assets/image/portafolio/ic_2.png" width="20px" > <span *ngIf="!obtenerSesion()"> Ingrese</span> <span *ngIf="obtenerSesion()"> Mi cuenta</span> <span class="caret"></span></a>
          <div ngDropdownContent class="dropdown-menu dropdown dropdown-primary" dropdownClass="slide-fade">
            <app-login></app-login>
          </div>
        </li>
  
   
        <li class="nav-item dropdown" dropdown>
          <a dropdownToggle mdbWavesEffect type="button" (click)="actualizarCarrito()" class="nav-link  waves-light" mdbWavesEffect>
            <img src="/assets/image/portafolio/ic_3.png" width="20px" >&nbsp; <mdb-badge danger="true">{{cantidadCarrito}}</mdb-badge> Carrito<span class="caret"></span></a>
          <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
            <app-carrito-compras-header [flagActualizar]="flagActualizar"></app-carrito-compras-header>
          </div>
        </li>  
  
      </ul>
    
   
      
    </links>
   
    
  
  </mdb-navbar>
  
  
  
  <mdb-navbar SideClass="navbar navbar-expand-lg navbar-light redIn">

    <mdb-navbar-brand><a class="navbar-brand" href="#"></a></mdb-navbar-brand>

    <links>
  
      <ul class="navbar-nav mr-auto" *ngIf="menuFlag">
        
        <li class="nav-item">
          <a class="nav-link waves-light text-white text-upperCase" [routerLink]="['/']"> PUBLICACIONES BNP</a>
        </li>
      -->

        <!-- <li class="nav-item" *ngFor="let menu of menuResponse.response.list[1].submenu">
          <a class="nav-link waves-light text-white text-upperCase" [routerLink]="['/sourvenir',menu.id]"> {{menu.description}}</a>
        </li> -->



       
<!--   
      </ul>
    
  
      
    </links>
    
  
  </mdb-navbar>
</div> -->
