<!--Header-->
<div class="modal-header text-center" style="color: white;background-color: #544d84">    
    <h4 class="modal-title w-100">{{x_titulo}}</h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onClosemodal()">
      <span aria-hidden="true" class="white-text">&times;</span>
    </button>
</div>

<!--Body-->
<div class="modal-body">
  <div class="form-row">
        <div class="col-12">
          <mdb-card>
            <mdb-card-body>              
                  <div class="form-row">
                    <div class="col-3">Código:</div>
                    <div class="col-9">
                        {{id}}
                    </div>
                  </div>
                  <br>
                  <div class="form-row">
                    <div class="col-3">Producto:</div>
                    <div class="col-9">
                        {{x_titulo_libro}}
                    </div>
                  </div>
                  <br>                  
                  <div class="form-row">
                    <div class="col-3">Stock:</div>
                    <div class="col-9">                
                      <input mdbInput type="number" pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==4) return false;" min="1" max="9999" class="form-control" [(ngModel)]="txtinventoryStock" id="txtinventoryStock">
                    </div>
                  </div>                  
                  <br *ngIf="showTallaTable">
                  <div class="form-row" *ngIf="showTallaTable">
                    
                    <div class="col-3">Talla:</div>
                    <div class="col-9">                
                        <select class="browser-default custom-select" [(ngModel)]="cboTalla">
                            <option value="" selected>Seleccione</option>
                            <option *ngFor="let talla of listTalla.response.list" [value]="talla.masterId">{{talla.masterDescription}}</option>
                        </select>
                    </div>
                  </div>
                  <br>
                  <div class="form-row">
                    <div class="col-3">Estado:</div>
                    <div class="col-9">                
                        <select class="browser-default custom-select" [(ngModel)]="cboEstado">
                            <option value="" selected>Seleccione</option>
                            <option *ngFor="let estado of listEstado.response.list" [value]="estado.masterName">{{estado.masterDescription}}</option>
                        </select>
                    </div>
                  </div>
            </mdb-card-body>
          </mdb-card>
        </div>
  </div>      
  <br>
  <!--Footer-->
<div class="modal-footer justify-content-center">
    <button mdbBtn type="button" (click)="onGrabar()" size="sm" class="btn btn-deep-purple btn-rounded" mdbWavesEffect>
        <mdb-icon fas icon="save" class="mr-1"></mdb-icon>Grabar</button>
</div>
