import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { map } from "rxjs/operators";
import { environment } from 'src/environments/environment';
import { RespuestaComun } from 'src/app/modal/maestras/respuesta-comun';
import {MenuResponse} from 'src/app/modal/maestras/menu-response';
@Injectable({
  providedIn: 'root'
})
export class MaestrasService {
  constructor(private httpClient: HttpClient) { }

  obtenerMaestra(NameOption:string){
    let url = `${environment.urlBase}${environment.maestras}/${NameOption}`;
    //console.log("url",url);
    return this.httpClient.get<RespuestaComun>(url).pipe(
      map(data => {   
        let response: RespuestaComun;
        response = data;
        if(data.response.codeResponse==="0000"){
          return response;  
        }
      },
      error =>{      
        return new Response("9999", error);
      })) 
  }

  obtenerMenu(){
    let url = `${environment.urlBase}${environment.obtenerMenu}`;
    //console.log("url",url);
    return this.httpClient.get<MenuResponse>(url).pipe(
      map(data => {   
        let response: MenuResponse;
        response = data;
        if(data.response.codeResponse==="0000"){
          return response;  
        }
      },
      error =>{      
        return new Response("9999", error);
      })) 
  }
  guardarPaginacionLibros(page){ 
    if(page == this.obtenerPaginacionLibros()){
      return false;
    }
    else{
      let pageLibros = page;
      sessionStorage.setItem("pageLibros",JSON.stringify(pageLibros));
    }
  }

  guardarPaginacionSourvenir(page,idSourvenir,idTema){ 
    console.log("VE",this.exitePaginacionSourvenir())
    if(this.exitePaginacionSourvenir()){
      let arrayTemp = JSON.parse(this.obtenerPaginacionSourvenir());
      let arrayTemp2 

      if(idTema!=null){
        arrayTemp2 = arrayTemp.filter(v =>
          v.idtema == idTema && v.idSourvenir == idSourvenir
        )
      }
      else{
        arrayTemp2 = arrayTemp.filter(v =>
          v.idSourvenir == idSourvenir
        )
      }

  
      if(arrayTemp2.length>0){
        let objIndex;
        if(idTema!=null){
          objIndex = arrayTemp.findIndex((v => v.idtema == idTema && v.idSourvenir == idSourvenir));
          arrayTemp[objIndex].page=page;


        }
        else{
          objIndex = arrayTemp.findIndex((v => v.idSourvenir == idSourvenir));
          arrayTemp[objIndex].page=page;

        }

        sessionStorage.setItem("pageSourvenir",JSON.stringify(arrayTemp));

      }
      else{
        let array = {page:page,idSourvenir:idSourvenir,idtema:idTema}
        arrayTemp.push(array);
        sessionStorage.setItem("pageSourvenir",JSON.stringify(arrayTemp));

      }

    }
    else{
      let array = [{page:page,idSourvenir:idSourvenir,idtema:idTema}]
      sessionStorage.setItem("pageSourvenir",JSON.stringify(array));
    }
 
   
  }



  obtenerPaginacionLibros(){
    return sessionStorage.getItem('pageLibros')
  }
  obtenerPaginacionSourvenir(){
    return sessionStorage.getItem('pageSourvenir')
  }
  exitePaginacionLibros(){
    return !(sessionStorage.getItem('pageLibros') === null)
  }

  exitePaginacionSourvenir(){
    return !(sessionStorage.getItem('pageSourvenir') === null)
  }
  obtenerPaginaSourvenir(idTema,idSourvenir){ 
    if(this.exitePaginacionSourvenir()){
      let arrayTemp = JSON.parse(this.obtenerPaginacionSourvenir());
      let arrayTemp2
      console.log("array",arrayTemp2)
      if(idTema!=null){
        arrayTemp2 = arrayTemp.filter(v =>
          v.idtema == idTema && v.idSourvenir == idSourvenir
        )
      }
      else{
        arrayTemp2 = arrayTemp.filter(v =>
          v.idSourvenir == idSourvenir
        )
      }
      console.log("array2",arrayTemp2)
      if(arrayTemp2.length>0){
        return arrayTemp2[0].page;
      }
      else{
        return 1;
      }
    }
    else{
      return 1
    }
  }
}
