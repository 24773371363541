import { Component, OnInit } from '@angular/core';
import { MDBModalRef, MDBModalService} from 'angular-bootstrap-md';
import { UsuarioService } from 'src/app/service/usuario/usuario.service';
import { UtilitarioService } from 'src/app/service/utilitarios/utilitario.service';
import { MaestrasService } from 'src/app/service/maestras/maestras.service';
import { RespuestaComun } from 'src/app/modal/maestras/respuesta-comun';
import { ComboResponse } from 'src/app/modal/maestras/combo-response';

//componentes

import { GrupoService } from 'src/app/service/grupo/grupo.service';
import { TopicService } from 'src/app/service/topic/topic.service';

import { ProductoService } from 'src/app/service/producto/producto.service';
import { ProductoBeanUpdRequest,ProductoBeanInsRequest,ProductoControles } from 'src/app/modal/producto/productoBean';
import { ProductoControlResponse } from 'src/app/modal/producto/productoControlResponse';

@Component({
  selector: 'app-dml-producto',
  templateUrl: './dml-producto.component.html',
  styleUrls: ['./dml-producto.component.scss']
})
export class DmlProductoComponent implements OnInit {

  constructor(public  modalRef: MDBModalRef,
    private maestrasService: MaestrasService,    
    private utilitarioService: UtilitarioService,
    private usuarioService: UsuarioService,
    private modalService: MDBModalService,
    private productoService: ProductoService,
    private grupoService: GrupoService,
    private topicService: TopicService,
  ) { }

  modalRefAutorBus: MDBModalRef;
  modalRefEdicionBus: MDBModalRef;

  listEstado:RespuestaComun = new RespuestaComun();
  listGrupo:ComboResponse = new ComboResponse();
  listTopico:ComboResponse = new ComboResponse();
  productoControles:ProductoControles = new ProductoControles();
  productoControlResponse:ProductoControlResponse = new ProductoControlResponse();

  //variables del formulario reactivo 

  cboGroupId: String = "23";
  cboTopicId: String ="";
  cboEnabled: String="1";

  txtComments: String= "";
  txtCreatedUserId: String= "";
  txtDescription: String= "";
  txtGrammage: String= "";
  txtLife: String= "";
  txtMaterial: String= "";
  txtMeasure: String= "";
  txtNovelty: Boolean = false;
  txtOrder: String= "";
  txtPages: String= "";
  txtPaper: String= "";
  txtPhoto64: String= "";
  txtQuotation: String= "";
  txtSize: String= "";
  txtSubTitle: String= "";
  txtTitle: String= "";
  txtTopicNovelty: Boolean = false;
  txtWeight: String= "";

  imgSrc: any = [];
  x_url: String;
  x_foto:String = "";
  headAutor = ['Nro','Descripcion','Eliminar'];
  headEdicion = ['Nro','Descripcion','Eliminar'];

  //variables enviado desde el padre
  id:String;  
  x_titulo:String;
  x_proceso:String;  
  
  ngOnInit(): void {

      this.cargarDatoInicial(this.x_proceso);
      console.log(this.productoControles);
  }

  ngAfterViewInit():void{    
    const imagen = document.getElementsByClassName('ngx-img-message');
    imagen.item(0).children[1].innerHTML="Selecionar o Arrastre su imagen";
    let buttonImg : HTMLButtonElement = imagen.item(0).children[2] as HTMLButtonElement;
    console.log(buttonImg);
    buttonImg.innerHTML = "Hacer clic"
  }

  onClosemodal(){
    this.modalRef.hide();
  }

  onGrabar(){

    if (this.validarFormulario()==false){      
      return false;
    }
    
    let productoBeanInsRequest = new ProductoBeanInsRequest();
    let productoBeanUpdRequest = new ProductoBeanUpdRequest();    

    if (this.x_proceso=='I'){

      this.utilitarioService.loading = true;
      this.rellenarBean(productoBeanInsRequest,productoBeanUpdRequest,this.x_proceso);

      this.productoService.grabar(productoBeanInsRequest).subscribe(data=>{        

        if (data.response.codeResponse=="0000"){
          this.utilitarioService.toastrSuccess("Se ha registrado satisfactoriamente.");
          (<HTMLButtonElement>document.getElementById("btnBuscar")).click();
        }else{
          this.utilitarioService.toastrError(data.response.response);
        }        

        this.utilitarioService.loading = false;
        this.onClosemodal();
        
      });

    }else{

      this.utilitarioService.loading = true;
      this.rellenarBean(productoBeanInsRequest,productoBeanUpdRequest,this.x_proceso);
      console.log(JSON.stringify(productoBeanUpdRequest));
      this.productoService.update(productoBeanUpdRequest,this.id).subscribe(data=>{        

        if (data.response.codeResponse=="0000"){
          this.utilitarioService.toastrSuccess("Se ha actualizado satisfactoriamente.");
          (<HTMLButtonElement>document.getElementById("btnBuscar")).click();
        }else{
          this.utilitarioService.toastrError(data.response.response);
        }        

        this.utilitarioService.loading = false;
        this.onClosemodal();
        
      });

    }   

  }

  async cargarDatoInicial(dml:String){

    this.utilitarioService.loading = true;

    this.listEstado = new RespuestaComun();
    const cargarEstado = this.maestrasService.obtenerMaestra("TIPO_STATUS_TABLA").toPromise()
    .then(result => {
      this.listEstado = result;
    });

    this.listGrupo = new ComboResponse();
    const cargarCategory = this.grupoService.obtenerListCombo().toPromise()
    .then(result => {
      this.listGrupo = result;
    });

    this.listTopico = new ComboResponse();
    const cargarPublicacion = this.topicService.obtenerListCombo().toPromise()
    .then(result => {
      this.listTopico = result;
    });        

    if (dml=='I'){
      //carga los promesas culminadas para asignar los datos a las variables
      (<HTMLInputElement>document.getElementById("txtOrder")).disabled=true;
      Promise.all([cargarEstado,cargarCategory,cargarPublicacion])
      .then(response => {

        const cargarControles = this.productoService.obtenerListControles(this.cboGroupId.toString()).toPromise()
        .then(result => {
          
          this.productoControles = result.response.list[0];          
          this.utilitarioService.loading = false;          

        });

      })
      .catch(error => console.log(`Error in executing ${error}`));
    }

    if (dml=='U'){
      //carga los promesas culminadas para asignar los datos a las variables
      Promise.all([cargarEstado,cargarCategory,cargarPublicacion])
      .then(response => {
        //obtener por id objeto
        this.productoService.obtenerRegistro(this.id).subscribe(data=>{

          this.cboGroupId= data.response.list[0].groupId;
          this.cboTopicId= data.response.list[0].topicId;
          this.cboEnabled = data.response.list[0].enabled;

          this.txtTitle= data.response.list[0].title;
          this.txtSubTitle= data.response.list[0].subtitle;          
          this.txtDescription= data.response.list[0].description;          
          this.txtMaterial= data.response.list[0].material;
          this.txtPages= data.response.list[0].pages;
          this.txtPaper= data.response.list[0].paper;
          this.txtGrammage= data.response.list[0].grammage;
          this.txtMeasure= data.response.list[0].measure;

          this.txtLife= data.response.list[0].life;          
          this.txtSize= data.response.list[0].size;
          this.txtWeight= data.response.list[0].weight;
          this.txtComments= data.response.list[0].comments;          
          this.txtNovelty = data.response.list[0].novelty=='1'? true: false;
          this.txtTopicNovelty = data.response.list[0].topicNovelty =='1'? true: false;

          this.txtOrder= data.response.list[0].order;                                        

          //this.txtPhoto64= data.response.list[0].
          this.txtQuotation= data.response.list[0].quotation;          

          this.txtCreatedUserId= data.response.list[0].createdUserId; 
          this.x_url = data.response.list[0].routeImage.toString() + '/' + data.response.list[0].nameImage + '.' + data.response.list[0].extensionImage + '?v' + data.response.list[0].updatedDate;

          this.productoService.obtenerListControles(this.cboGroupId.toString()).toPromise()
          .then(result => {
            console.log(result)
            this.productoControles = result.response.list[0];
            this.utilitarioService.loading = false;

          });          
          
        });
      })
      .catch(error => console.log(`Error in executing ${error}`));
    }    
    
  }  

  rellenarBean(productoBeanInsRequest:ProductoBeanInsRequest,productoBeanUpdRequest:ProductoBeanUpdRequest,dml:String){    
        
    if (dml=='I'){
      
      productoBeanInsRequest.comments= this.txtComments;
      productoBeanInsRequest.createdUserId= this.usuarioService.obtenerIDSessionUsuarioAdmin().toString();
      productoBeanInsRequest.description= this.txtDescription;
      productoBeanInsRequest.grammage= this.txtGrammage;
      productoBeanInsRequest.groupId= this.cboGroupId;
      productoBeanInsRequest.id= "0";
      productoBeanInsRequest.life= this.txtLife;
      productoBeanInsRequest.material= this.txtMaterial;
      productoBeanInsRequest.measure= this.txtMeasure;
      productoBeanInsRequest.novelty= this.txtNovelty== true? "1": "0";
      productoBeanInsRequest.order= this.txtOrder;
      productoBeanInsRequest.pages= this.txtPages;
      productoBeanInsRequest.paper= this.txtPages;
      productoBeanInsRequest.photo64= this.x_foto;
      productoBeanInsRequest.quotation= this.txtQuotation;
      productoBeanInsRequest.size= this.txtSize;
      productoBeanInsRequest.state= "8";
      productoBeanInsRequest.subtitle= this.txtSubTitle;
      productoBeanInsRequest.title= this.txtTitle;
      productoBeanInsRequest.topicId= this.cboTopicId;
      productoBeanInsRequest.topicNovelty= this.txtTopicNovelty== true? "1": "0";
      productoBeanInsRequest.weight= this.txtWeight;           
      
    }else{

      productoBeanUpdRequest.comments= this.txtComments;
      productoBeanUpdRequest.updatedUserId= this.usuarioService.obtenerIDSessionUsuarioAdmin().toString();
      productoBeanUpdRequest.description= this.txtDescription;
      productoBeanUpdRequest.grammage= this.txtGrammage;
      productoBeanUpdRequest.groupId= this.cboGroupId;
      productoBeanUpdRequest.id= this.id;
      productoBeanUpdRequest.life= this.txtLife;
      productoBeanUpdRequest.material= this.txtMaterial;
      productoBeanUpdRequest.measure= this.txtMeasure;
      productoBeanUpdRequest.novelty= this.txtNovelty== true? "1": "0";
      productoBeanUpdRequest.order= this.txtOrder;
      productoBeanUpdRequest.pages= this.txtPages;
      productoBeanUpdRequest.paper= this.txtPages;
      productoBeanUpdRequest.photo64= this.x_foto;
      productoBeanUpdRequest.quotation= this.txtQuotation;
      productoBeanUpdRequest.size= this.txtSize;
      productoBeanUpdRequest.state= "8";
      productoBeanUpdRequest.subtitle= this.txtSubTitle;
      productoBeanUpdRequest.title= this.txtTitle;
      productoBeanUpdRequest.topicId= this.cboTopicId;
      productoBeanUpdRequest.topicNovelty= this.txtTopicNovelty== true? "1": "0";
      productoBeanUpdRequest.weight= this.txtWeight;
      productoBeanUpdRequest.enabled= this.cboEnabled;
      
    }        

  }

  validarFormulario() : Boolean{    
    //valida formulario
    if (this.usuarioService.obtenerIDSessionUsuarioAdmin().toString()==null){
      this.utilitarioService.toastrError("Su session ha culminado, ingrese nuevamente al sistema.");      
      return false;
    }

    //valida tipo de comprobante
    if(this.txtTitle=="" && Boolean(this.productoControles.showProductTitulo)==true){
      this.utilitarioService.toastrInfo("Ingrese titulo del producto.");
      return false;
    }

    if(this.txtSubTitle=="" && Boolean(this.productoControles.showProductSubtitulo)==true){
      this.utilitarioService.toastrInfo("Ingrese el subtitulo del producto.");
      return false;
    }

    if(this.txtDescription=="" && Boolean(this.productoControles.showProductDescripcion)==true){
      this.utilitarioService.toastrInfo("Ingrese descripción del producto.");
      return false;
    }

    if(this.txtMaterial=="" && Boolean(this.productoControles.showMaterial)==true){
      this.utilitarioService.toastrInfo("Ingrese descripción del material del producto.");
      return false;
    }

    if(this.txtPages=="" && Boolean(this.productoControles.showLibretaNroPaginas)==true){
      this.utilitarioService.toastrInfo("Ingrese número de páginas.");
      return false;
    }

    if(this.txtGrammage=="" && Boolean(this.productoControles.showLibretaGrosorPapel)==true){
      this.utilitarioService.toastrInfo("Ingrese el gramaje del papel.");
      return false;
    }

    if(this.txtMeasure=="" && Boolean(this.productoControles.showProductUnidadesMeasure)==true){
      this.utilitarioService.toastrInfo("Ingrese el monto en (Unidad,Paquete etc..)");
      return false;
    }

    if(this.txtLife=="" && Boolean(this.productoControles.showProductTiempoVidaParaEdades)==true){
      this.utilitarioService.toastrInfo("Ingrese edad o edades.");
      return false;
    }

    if(this.txtSize=="" && Boolean(this.productoControles.showProductTamanioSize)==true){
      this.utilitarioService.toastrInfo("Ingrese el tamaño del producto.");
      return false;
    }

    if(this.txtWeight=="" && Boolean(this.productoControles.showProductPeso)==true){
      this.utilitarioService.toastrInfo("Ingrese el peso del producto.");
      return false;
    }
    
    if(this.cboGroupId==""){
      this.utilitarioService.toastrInfo("Seleccione el grupo del producto.");
      return false;
    }

    if(this.cboTopicId==""){
      this.utilitarioService.toastrInfo("Seleccione el topico del producto.");
      return false;
    }

    if(this.cboEnabled=="" && Boolean(this.productoControles.showEnabled)==true){
      this.utilitarioService.toastrInfo("Seleccione estado.");
      return false;
    }

    if(this.txtOrder=="" && this.x_proceso == 'U'){
      this.utilitarioService.toastrInfo("Ingre orden de año.");
      return false;
    }

    if(this.x_foto=="" && this.x_proceso == 'I'){
      this.utilitarioService.toastrInfo("Seleccione la foto de portada del prodcuto.");
      return false;
    }

    return true;

  }

  fc_ValNumber(event: any) {          
        const pattern = /[0-9\+\-\ ]/;
        let inputChar = String.fromCharCode(event.charCode);
    
        if (!pattern.test(inputChar)) {
          // invalid character, prevent input
          return false;
          event.preventDefault();
        }    
        return true;
  }

  onSelect($event: any) {
    
    this.imgSrc = [];
    
    switch (typeof($event)) {
      case 'string':

        let indice:number = 0;

        this.imgSrc = [$event];
        this.x_foto= [$event].toString();
        indice = this.x_foto.indexOf(',');
        this.x_foto = this.x_foto.substr(indice+1);

        console.log(this.x_foto);
        console.log(this.x_foto.substr(indice+1));
        break;
      case 'object':
        console.log('prueba de registro');
        this.imgSrc = $event;
        break;
      default:
    }
  }

  onReset() {
    this.imgSrc = [];
  }

  options = {
    imgSrc: '', // base64 encoded image for default preview
    fileSize: 1024, // in Bytes (by default 2048 Bytes = 2 MB)
    minWidth: 0, // minimum width of image that can be uploaded (by default 0, signifies any width)
    maxWidth: 0,  // maximum width of image that can be uploaded (by default 0, signifies any width)
    minHeight: 0,  // minimum height of image that can be uploaded (by default 0, signifies any height)
    maxHeight: 0,  // maximum height of image that can be uploaded (by default 0, signifies any height)
    fileType: ['image/gif', 'image/jpeg', 'image/png'], // mime type of files accepted
    height: 400, // height of cropper
    quality: 0.8, // quality of image after compression
    crop: [  // array of objects for mulitple image crop instances (by default null, signifies no cropping)
      {
        autoCropArea: 0.8, // A number between 0 and 1. Define the automatic cropping area size (percentage).
        ratio: 1, // ratio in which image needed to be cropped (by default null, signifies ratio to be free of any restrictions)
        minWidth: 0, // minimum width of image to be exported (by default 0, signifies any width)
        maxWidth: 0,  // maximum width of image to be exported (by default 0, signifies any width)
        minHeight: 0,  // minimum height of image to be exported (by default 0, signifies any height)
        maxHeight: 0,  // maximum height of image to be exported (by default 0, signifies any height)
        width: 0,  // width of image to be exported (by default 0, signifies any width)
        height: 0,  // height of image to be exported (by default 0, signifies any height)
        output: 'base64',  // Output format. Can be 'base64' or 'blob'. (by default 'base64')
      }
    ]
  };

  async getBase64ImageFromUrl(imageUrl) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();
  
    return new Promise((resolve, reject) => {
      var reader  = new FileReader();
      reader.addEventListener("load", function () {
          resolve(reader.result.toString());
      }, false);
  
      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
  }

  onchangeControl(){

    this.utilitarioService.loading = true;

      this.productoService.obtenerListControles(this.cboGroupId.toString()).subscribe
        (result => {
        console.log(result)

        this.productoControles = result.response.list[0];          
        this.utilitarioService.loading = false;          

      });

  }

}
