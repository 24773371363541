import { Component, OnInit} from '@angular/core';
import { UtilitarioService } from 'src/app/service/utilitarios/utilitario.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Libreria Virtual';
  constructor(public utilitarioService:UtilitarioService){
    
  }

  ngOnInit(): void {    
    
  }

  ngAfterViewInit() {
    this.utilitarioService.loading = false;
  }

}
