import { Injectable } from '@angular/core';
import { ProductoListBean } from '../../modal/producto/productoBean';
import { InventarioProListBean } from '../../modal/inventario-producto/inventarioProBean';
import { LibroAdminListBean } from '../../modal/libro/libroAdminBean';
import { InventarioLibListBean } from '../../modal/inventario-libro/inventarioLibBean';
import { OrderAdminBean } from '../../modal/order-pago/orderAdminBean';

import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor() { }  

  exportExcelProductos(productoListBean:ProductoListBean[]) {
 
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Productos');
    let fila:number = 0;

    worksheet.columns = [
      { header: 'NRO', key: 'nro', width: 6 },
      { header: 'PRODUCTO', key: 'titulo', width: 60 },
      { header: 'COD.PRODUCTO', key: 'id', width: 10 },
      { header: 'TIPO DE REGALO', key: 'tipo', width: 50 },
      { header: 'TEMATICA', key: 'tematica', width: 70 },            
      { header: 'MATERIAL', key: 'material', width: 20},
      { header: 'PESO (g)', key: 'weight', width: 20},
      { header: 'ESTADO', key: 'estado', width: 40 },
    ];
   
    productoListBean.forEach(data => {
      fila = fila + 1;
      worksheet.addRow({nro:fila,titulo: data.title, id:data.id ,tipo: data.groupName, tematica: data.submenuName, material:data.material,estado:data.status,weight:data.weight },"n");
    });
   
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'ProductoData.xlsx');
    })
   
  }

  exportExcelProductosInve(inventarioProListBean:InventarioProListBean[]) {
 
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('ProductosInvent');
    let fila:number = 0;

    worksheet.columns = [
      { header: 'NRO', key: 'nro', width: 6 },
      { header: 'PRODUCTO', key: 'titulo', width: 60 },
      { header: 'COD.PRODUCTO', key: 'productid', width: 10},
      { header: 'TIPO', key: 'tipo', width: 50 },
      { header: 'TEMATICA', key: 'tematica', width: 50 },      
      { header: 'STOCK', key: 'inventoryStock', width: 10},
      { header: 'NOMBRE DE CONCEPTO', key: 'inventoryDescription', width: 40 },
      { header: 'MONEDA', key: 'inventoryCurrency', width: 10 },
      { header: 'PRECIO', key: 'inventoryPrice', width: 20 },
      { header: 'COD.SIGA', key: 'sigaId', width: 10 },
      { header: 'SEC.SIGA', key: 'sigaSecuencia', width: 10 },
      { header: 'ESTADO', key: 'inventoryState', width: 10 },      
    ];
   
    inventarioProListBean.forEach(data => {
      fila = fila + 1;
      worksheet.addRow({nro:fila,titulo: data.productTitle, productid: data.productId, tipo: data.groupName, tematica: data.topicName,inventoryStock: data.inventoryStock, inventoryDescription:data.inventoryDescription,inventoryCurrency:data.inventoryCurrency,inventoryPrice:data.inventoryPrice,sigaId:data.sigaId,sigaSecuencia:data.sigaSecuencia, inventoryState: data.inventoryState == "1" ? "Asignado":"No asignado" },"n");
    });
   
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'ProductoInventario.xlsx');
    })
   
  }

  exportExcelLibros(libroAdminListBean:LibroAdminListBean[]) {
    
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Libros');
    let fila:number = 0;
    
    worksheet.columns = [
      { header: 'NRO', key: 'nro', width: 6 },
      { header: 'LIBRO', key: 'title', width: 60 },
      { header: 'COD.LIBRO', key: 'id', width: 10 },
      { header: 'DESCRIPCION', key: 'description', width: 50 },
      { header: 'CATEGORIA', key: 'categoryDescription', width: 70 },      
      { header: 'PAGINA', key: 'pages', width: 10},
      { header: 'PESO (g)', key: 'weight', width: 10 },
      { header: 'ESTADO', key: 'status', width: 10 },
    ];
   
    libroAdminListBean.forEach(data => {
      fila = fila + 1;
      worksheet.addRow({nro:fila,title: data.title, id:data.id ,description: data.description,categoryDescription: data.categoryDescription,pages: data.pages,weight: data.weight, status: data.status},"n");
    });
   
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'LibrosData.xlsx');
    })
   
  }

  exportExcelLibroInve(inventarioLibListBean:InventarioLibListBean[]) {
 
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('LibrosInvent');
    let fila:number = 0;
    console.log(inventarioLibListBean);
    worksheet.columns = [
      { header: 'NRO', key: 'nro', width: 6 },
      { header: 'LIBRO', key: 'bookTitle', width: 60 },
      { header: 'COD.LIBRO', key: 'bookId', width: 10},
      { header: 'CATEGORIA', key: 'categoryName', width: 50 },
      { header: 'MATERIAL', key: 'materialName', width: 50 },      
      { header: 'STOCK', key: 'inventoryStock', width: 10},
      { header: 'NOMBRE DE CONCEPTO', key: 'inventoryDescription', width: 40 },
      { header: 'MONEDA', key: 'inventoryCurrency', width: 10 },
      { header: 'PRECIO', key: 'inventoryPrice', width: 20 },
      { header: 'COD.SIGA', key: 'sigaId', width: 10 },
      { header: 'SEC.SIGA', key: 'sigaSecuencia', width: 10 },
      { header: 'ESTADO', key: 'inventoryState', width: 10 },      
    ];
   
    inventarioLibListBean.forEach(data => {
      fila = fila + 1;
      worksheet.addRow({nro:fila,bookTitle: data.bookTitle, bookId: data.bookId, categoryName: data.categoryName, materialName: data.materialName,inventoryStock: data.inventoryStock, inventoryDescription:data.inventoryDescription,inventoryCurrency:data.inventoryCurrency,inventoryPrice:data.inventoryPrice,sigaId:data.sigaId,sigaSecuencia:data.sigaSecuencia, inventoryState: data.inventoryState == "1" ? "Asignado":"No asignado" },"n");
    });
   
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'ProductoInventario.xlsx');
    })
   
  }

  exportExcelBandejaPagados(orderAdminBean:OrderAdminBean[]) {
 
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Bandeja');
    let fila:number = 0;

    worksheet.columns = [
      { header: 'NRO', key: 'nro', width: 6 },
      { header: 'NRO.ORDEN', key: 'number', width: 15 },            
      { header: 'NOMBRES Y APELLIDOS', key: 'clientName', width: 40 },
      { header: 'NRO.DOCUMENTO', key: 'clientNro', width: 15 },
      { header: 'TOTAL S/', key: 'total', width: 13 },
      { header: 'FECHA', key: 'date', width: 15 },
      { header: 'ESTADO', key: 'stateName', width: 15},
    ];
   
    orderAdminBean.forEach(data => {
      fila = fila + 1;
      worksheet.addRow({nro:fila,number: data.number, clientName:data.clientName ,clientNro: data.clientNro, total: data.total, date:data.date, stateName:data.stateName },"n");
    });
   
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'ProductoData.xlsx');
    })
   
  }

}
