<!--Header-->
<div class="modal-header text-center" style="color: white;background-color: #544d84">    
    <h4 class="modal-title w-100">Cambio de estado</h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onClosemodal()">
      <span aria-hidden="true" class="white-text">&times;</span>
    </button>
</div>
  
<!--Body-->
<div class="modal-body">
  <div class="form-row">
    <div class="col-3">
        Nro Orden:
    </div>
    <div class="col-9">                
        <span>{{nroOrden}}</span>
    </div>
  </div>
  <br>
  <div class="form-row">
    <div class="col-3">
        Estado:
    </div>
    <div class="col-9">                
        <select class="browser-default custom-select" [(ngModel)]="cboEstado">            
          <option *ngFor="let estado of listEstado.response.list" [value]="estado.stateId">{{estado.stateDescription}}</option>
        </select>
    </div>    
  </div>
  <br>
  <div class="form-row">
    <div class="col-3">
        Observacion:
    </div>
    <div class="col-9">                
        <input mdbInput type="text" class="form-control" id="txtObservacion" [(ngModel)]="txtObservacion" placeholder="Observacion">
    </div>    
  </div>
</div>
  <!--Footer-->
<div class="modal-footer justify-content-center">
    <button mdbBtn type="button" (click)="onGrabar()" size="sm" color="primary" mdbWavesEffect>
    <mdb-icon fas icon="magic" class="mr-1"></mdb-icon>Grabar</button>
</div>
