import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { map } from "rxjs/operators";
import { environment } from 'src/environments/environment';
import { UsuarioRequest } from 'src/app/modal/usuario/usuario-request';
import { UsuarioResponse } from 'src/app/modal/usuario/usuario-response';
import { DireccionUserResponse } from 'src/app/modal/usuario/direccion-user-response';

import { DireccionRequest } from 'src/app/modal/usuario/direccion-request';
import { DireccionResponse } from 'src/app/modal/usuario/direccion-response';
import { UsuarioBeanResponse } from 'src/app/modal/usuario/usuario-bean-response';
import { UsuarioAdminRequest } from 'src/app/modal/usuario/usuarioAdminRequest';
import { UsuarioAdminResponse } from 'src/app/modal/usuario/usuarioAdminResponse';
import { UsuarioRegistroRequest } from 'src/app/modal/usuario/usuario-registro-request';
import { ResponseComun } from 'src/app/modal/catalogo/response-comun';
import {PedidosUsuarioResponse} from 'src/app/modal/usuario/pedidos-usuario-response';
import { ActualizarDatosRequest } from 'src/app/modal/usuario/actualizar-datos-request';
import {ContrasenaRequest  } from 'src/app/modal/usuario/contrasena-request';
import {PasswordToken  } from 'src/app/modal/usuario/password-token';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  constructor(private httpClient: HttpClient) {
  }

  loginUser(usuarioRequest:UsuarioRequest){
    
    return this.httpClient.post<UsuarioResponse>(environment.urlBase+environment.login,usuarioRequest).pipe(
      map(data => {        
        let response: UsuarioResponse;
        response = data;
        if(data.response.codeResponse==="0000"){
          this.createUserSession(response);
        }
        return response;  
      },
      error =>{      
        return new Response("9999", error);
      }) 
    )
  }

  loginUserAdmin(usuarioAdminRequest:UsuarioAdminRequest){
    
    return this.httpClient.post<UsuarioAdminResponse>(environment.urlBase+environment.loginAdmin,usuarioAdminRequest).pipe(
      map(data => {        
        let response: UsuarioAdminResponse;
        response = data;
        if(data.response.codeResponse==="0000"){
          this.createUserAdminSession(response);
        }
        return response;  
      },
      error =>{      
        return new Response("9999", error);
      }) 
    )
  }
  

  obtenerUser(codusuario:String){
    let url = `${environment.urlBase}${environment.userid}/${codusuario}`;
    return this.httpClient.get<UsuarioBeanResponse>(url).pipe(
      map(data => {   
        let response: UsuarioBeanResponse;
        response = data;
        
        return response;
        
      },
      error =>{      
        return new Response("9999", error);
      }))
  }

  createUserSession(usuarioResponse:UsuarioResponse){
    localStorage.setItem("usuario-sesion",JSON.stringify(usuarioResponse));
  }

  createUserAdminSession(usuarioAdminResponse:UsuarioAdminResponse){
    localStorage.setItem("usuarioadmin-sesion",JSON.stringify(usuarioAdminResponse));
  }
  obtenerSesionUsuarioAdmin() {
    if (!this.existeSesionUsuario()) return null;
    let usuarioAdminResponse: UsuarioAdminResponse;
    usuarioAdminResponse =  JSON.parse(localStorage.getItem('usuarioadmin-sesion'));
   // //console.log(usuarioSesion)
    return  usuarioAdminResponse;
  }  
  obtenerIDSessionUsuarioAdmin(){
    if (!this.existeSesionUsuarioAdmin()) return null;
    let usuarioAdminResponse: UsuarioAdminResponse;
    usuarioAdminResponse =  JSON.parse(localStorage.getItem('usuarioadmin-sesion'));
    let idUsuario = usuarioAdminResponse.response.list[0].id;    
    return  idUsuario;
  }

  obtenerIDPerfilUsuarioAdmin():String{
    if (!this.existeSesionUsuarioAdmin()) return null;
    let usuarioAdminResponse: UsuarioAdminResponse;
    usuarioAdminResponse =  JSON.parse(localStorage.getItem('usuarioadmin-sesion'));
    let idPerfil = usuarioAdminResponse.response.list[0].isAdministrator;    
    return  idPerfil;
  }

  existeSesionUsuario() {
    return !(localStorage.getItem('usuario-sesion') === null)
  }

  existeSesionUsuarioAdmin() {
    return !(localStorage.getItem('usuarioadmin-sesion') === null)
  }

  obtenerSesionUsuario() {
    if (!this.existeSesionUsuario()) return null;
    let usuarioSesion: UsuarioResponse;
    usuarioSesion =  JSON.parse(localStorage.getItem('usuario-sesion'));
   // //console.log(usuarioSesion)
    return  usuarioSesion;
  }  



  obtenerNombreCompletoSesionUsuario() {
    if (!this.existeSesionUsuario()) return null;
    let usuarioSesion: UsuarioResponse;
    usuarioSesion =  JSON.parse(localStorage.getItem('usuario-sesion'));

    let nombreCompleto = ""
    if (usuarioSesion.response.list[0].name===""){
      nombreCompleto = usuarioSesion.response.list[0].name +" "+ usuarioSesion.response.list[0].surname
    }else{
      nombreCompleto = usuarioSesion.response.list[0].name +" "+ usuarioSesion.response.list[0].surname
    }
 //   //console.log(usuarioSesion);
    return  nombreCompleto;
  }    
  
  obtenerIDSessionUsuario(){
    if (!this.existeSesionUsuario()) return null;
    let usuarioSesion: UsuarioResponse;
    usuarioSesion =  JSON.parse(localStorage.getItem('usuario-sesion'));
    let idUsuario = usuarioSesion.response.list[0].id;    
    return  idUsuario;
  }  

  logOut() {
    localStorage.removeItem('usuario-sesion')
  }
  
  regDireccion(direccionRequest:DireccionRequest){
    return this.httpClient.post<DireccionResponse>(environment.urlBase+environment.direccion,direccionRequest).pipe(
      map(data => {
        let response: DireccionResponse;
        response = data;
        if(data.response.codeResponse==="0000"){
          return response;
        }        
      },
      error =>{      
        return new Response("9999", error);
      }) 
    )
  }

  delDireccionUser(direccionRequest:DireccionRequest){
    let url = `${environment.urlBase}${environment.direccion}/${direccionRequest.id}`;
    return this.httpClient.put<DireccionResponse>(url,direccionRequest).pipe(
      map(data => {
        let response: DireccionResponse;
        response = data;
        if(data.response.codeResponse==="0000"){
          return response;
        }
      },
      error =>{      
        return new Response("9999", error);
      }) 
    )
  }

  obtenerDireccionUser(codusuario:String){
    let url = `${environment.urlBase}${environment.direccionList}/${codusuario}`;
    //console.log("url",url);
    return this.httpClient.get<DireccionUserResponse>(url).pipe(
      map(data => {   
        let response: DireccionUserResponse;
        response = data;
        
        return response;
        
      },
      error =>{      
        return new Response("9999", error);
      }))
  }
  
  registrarUsuario(registroRequest:UsuarioRegistroRequest){
    return this.httpClient.post<ResponseComun>(environment.urlBase+environment.registrarUsuario,registroRequest).pipe(
      map(data => {
        let response: ResponseComun;
        response = data;
       
          return response;
               
      },
      error =>{      
        return new Response("9999", error);
      }) 
    )
  }
  actualizarUsuario(codigoUsuario:string,registroRequest:ActualizarDatosRequest){
    let url = `${environment.urlBase}${environment.actualizarUsuario}/${codigoUsuario}`;
    //console.log("url",url);
    return this.httpClient.put<ResponseComun>(url,registroRequest).pipe(
      map(data => {
        let response: ResponseComun;
        response = data;
      
          return response;
              
      },
      error =>{      
        return new Response("9999", error);
      }) 
    )
  }

  obtenerListaPedidos(codigoUsuario:string){
    let url = `${environment.urlBase}${environment.listaPedidos}/${codigoUsuario}`; 
    //console.log("url",url);
    return this.httpClient.get<PedidosUsuarioResponse>(url).pipe(
      map(data => {   
        let response: PedidosUsuarioResponse;
        response = data;
        
        return response;
        
      },
      error =>{      
        return new Response("9999", error);
      }))
  }
  recuperarContrasena(contrasenaRequest:ContrasenaRequest){
    return this.httpClient.post<ResponseComun>(environment.urlBase+environment.recuperarContrasea,contrasenaRequest).pipe(
      map(data => {
        let response: ResponseComun;
        response = data;
        if(data.response.codeResponse==="0000"){
          return response;
        }        
      },
      error =>{      
        return new Response("9999", error);
      }) 
    )
  }
  actualizarPassword(token:string,passwordToken:PasswordToken){
    let url = `${environment.urlBase}${environment.recuperarPassToken}?token=${token}`;
    //console.log("url",url);
    return this.httpClient.put<ResponseComun>(url,passwordToken).pipe(
      map(data => {
        let response: ResponseComun;
        response = data;
      
          return response;
              
      },
      error =>{      
        return new Response("9999", error);
      }) 
    )
  }

}
