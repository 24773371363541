
    <br>
    <div class="row">
        <div class="col-sm-12" style="font-weight: 600;text-align: left;">
            <span>Tipo de entrega</span>
        </div>
    </div>
    <br>
    
    <div class="row">
        <div class="col-sm-2">
        Destino:
        </div>
        <div class="col-sm-3">
            <select class="browser-default custom-select" (change)="onchangeDestino();" [(ngModel)]="cboRegionModel">                
                <option *ngFor="let region of listDestino.response.list" [value]="region.masterId">{{region.masterDescription}}</option>
            </select>
        </div>
        <div class="col-sm-7" [style.display]="displayTipoEntregaRadios">
            <div class="custom-control custom-radio custom-control-inline" *ngFor="let tipoEntrega of tipoEntregaBean.response.list">
                <input type="radio" (change)="onchangeDireccion(tipoEntrega.masterId);" class="custom-control-input" [id]="'tipoentrega'+ tipoEntrega.masterId" name="tipoentrega" 
                [(ngModel)]="rdbTipoEntregaVal" [value]="tipoEntrega.masterId" mdbInput>
                <label class="custom-control-label" style="font-size: 15px;" [for]="'tipoentrega'+ tipoEntrega.masterId">{{tipoEntrega.masterDescription}}</label>
            </div>            
        </div>
    </div>    
    
    <div [style.display]="displayTipoEntrega">

        <hr class="w-100">

        <div class="row">
            <div class="col-sm-12" style="font-weight: 600;text-align: left;">
                <span>Dirección de envío</span>
            </div>
        </div>    
        <br>

        <div class="row">
            <div class="col-sm-12">
                <div class="bordertablediv table-responsive">                    
                <table mdbTable hover="true" id="tbdireccion">
                    <thead class="white-text" style="background: linear-gradient(40deg, #303f9f,#42b7e2) !important;">
                      <tr>                    
                        <th style="padding: 3px 20px 3px 20px;" *ngFor="let head of headElements" scope="col">{{head}} </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr mdbTableCol *ngFor="let direccionuserBean of direccionUserResponse.response.list">
                        <td style="text-align: center;">
                            <div class="custom-control custom-radio">
                                <input type="radio" class="custom-control-input" (change)="onEnviarEventoPadre();" [id]="'txtdireccion'+direccionuserBean.id.toString()" name="direccionenvio" 
                                [(ngModel)]="rdbDireccionEnvio" [value]="direccionuserBean.id" mdbInput>
                                <label class="custom-control-label" style="font-size: 15px;" [for]="'txtdireccion'+direccionuserBean.id.toString()"></label>
                            </div>
                        </td>
                        <th>{{direccionuserBean.identifier}}</th>
                        <td>{{direccionuserBean.description}}, {{direccionuserBean.department}}, {{direccionuserBean.province}}, {{direccionuserBean.district}}</td>
                       
                        <td style="text-align: center;">
                            <button class="btn btn-rounded btniconborder btn-flat btn-sm" (click)="feliminarDireccion(direccionuserBean);"><i class="fas fa-trash fa-lg"></i></button>
                        </td>
                     
                      </tr>
                    </tbody>
                </table>  
            </div>      
            </div>        
        </div>  
        <br>
        <div class="row">
            <div class="col-sm-12" style="font-weight: 600;text-align: right;">
                <button class="btn btn-rounded btn-outline-yellow btn-sm" id="btnagregardireccion" type="button" (click)="direccion.toggle()" mdbWavesEffect>
                    <b>Agregar dirección de envío </b>
                </button>
            </div>        
        </div>    
        <div mdbCollapse [isCollapsed]="divAgregarDireccion" #direccion="bs-collapse">
            <hr>
            <br>        
            <div class="form-row">
                <div class="form-group col-md-6">           
                    <select class="browser-default custom-select" [formControl]="cboDepartamentoValidar" (change)="obtenerProvincia($event.target.value)" [(ngModel)]="cboDepartamentoVal">
                        <option value="" selected>Departamento</option>
                        <option *ngFor="let departamentoBean of departamento.response.list" [value]="departamentoBean.codeDepartment">{{departamentoBean.name}}</option>
                    </select>
                    <mdb-error *ngIf="cboDepartamentoValidar.hasError('required') && cboDepartamentoValidar.touched">Seleccione Departamento</mdb-error>
                </div>
                <div class="form-group col-md-6">
                    <div class="md-form md-outline">
                    <input type="text" placeholder="Mi casa, Trabajo ..." id="txtIdentificador" [(ngModel)]="txtIdentificador" maxlength="100" [formControl]="txtIdentificadorVal" class="form-control" mdbInput>
                    <label for="txtIdentificador">Identificador</label>
                    </div>
                    <div style="flex-direction:row-reverse;display: flex;">
                        <mdb-error style="position: inherit;" *ngIf="txtIdentificadorVal.invalid && (txtIdentificadorVal.dirty || txtIdentificadorVal.touched)">Ingrese el identificador</mdb-error>
                    </div>                    
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-6">   
                    <div class="md-form md-outline">                 
                    <select class="browser-default custom-select" [(ngModel)]="cboProvincia" [formControl]="cboProvinciaValidar" (change)="obtenerDistritos(cboDepartamentoVal,$event.target.value)">
                        <option value="" selected>Provincia</option>
                        <option *ngFor="let provinciaBean of provincia.response.list" [value]="provinciaBean.codeProvince">{{provinciaBean.name}}</option>
                    </select>
                    </div>                    
                        <mdb-error style="position: inherit;" *ngIf="cboProvinciaValidar.hasError('required') && cboProvinciaValidar.touched">Seleccione provincia</mdb-error>                    
                </div>                
                <div class="form-group col-md-6">
                    <div class="md-form md-outline">
                        <input type="text" id="txtReferencia" [(ngModel)]="txtReferencia" maxlength="250" [formControl]="txtReferenciaValidar" class="form-control" mdbInput>
                        <label for="txtReferencia">Referencia</label>
                    </div>
                    <div style="flex-direction:row-reverse;display: flex;">
                        <mdb-error style="position: inherit;" *ngIf="txtReferenciaValidar.invalid && (txtReferenciaValidar.dirty || txtReferenciaValidar.touched)">Ingrese referencia</mdb-error>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-6">
                    <select class="browser-default custom-select" [(ngModel)]="cboDistrito" [formControl]="cboDistritoValidar">
                        <option value="" selected>Distrito</option>
                        <option *ngFor="let distrito of distritoResponse.response.list" [value]="distrito.codeDistrict">{{distrito.name}}</option>
                    </select>
                    <mdb-error *ngIf="cboDistritoValidar.hasError('required') && cboDistritoValidar.touched">Seleccione distrito</mdb-error>
                </div>
                <div class="form-group col-md-6">
                    <div class="md-form md-outline">
                        <input type="text" id="txtPerContact" [(ngModel)]="txtPerContact" maxlength="100" [formControl]="txtPerContactValidar" class="form-control" mdbInput>
                        <label for="txtPerContact">Persona de contacto</label>
                    </div>
                    <div style="flex-direction:row-reverse;display: flex;">
                        <mdb-error style="position: inherit;" *ngIf="txtPerContactValidar.invalid && (txtPerContactValidar.dirty || txtPerContactValidar.touched)">Ingrese persona de contacto</mdb-error>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-6">
                    <div class="md-form md-outline">
                        <input type="text" id="txtDireccion" [(ngModel)]="txtDireccion" maxlength="150" [formControl]="txtDireccionValidar" class="form-control" mdbInput>
                        <label for="txtDireccion">Dirección</label>
                    </div>
                    <div style="flex-direction:row;display: flex;">
                        <mdb-error style="position: inherit;" *ngIf="txtDireccionValidar.invalid && (txtDireccionValidar.dirty || txtDireccionValidar.touched)">Ingrese su dirección</mdb-error>
                    </div>
                </div>
                <div class="form-group col-md-6">
                    <div class="md-form md-outline">
                        <input type="text" id="txtCelular" [(ngModel)]="txtCelular" maxlength="15" [formControl]="txtCelularValidar" class="form-control" mdbInput>
                        <label for="txtCelular">Celular de contacto</label>
                    </div>
                    <div style="flex-direction:row-reverse;display: flex;">
                        <mdb-error style="position: inherit;" *ngIf="txtCelularValidar.invalid && (txtCelularValidar.dirty || txtCelularValidar.touched)">Ingrese numero de celular</mdb-error>
                    </div>
                </div>
            </div>
            <div class="row form-group justify-content-center">
                <div class="col-3">
                    <button class="btn btn-rounded btn-yellow btn-sm" type="button" (click)="flimpiarDireccion()" mdbWavesEffect>
                        <b>Cancelar</b>
                    </button>
                </div>
                <div class="col-3">
                    <button class="btn btn-rounded btn-yellow btn-sm" type="button" (click)="onGrabarDireccion()" mdbWavesEffect>
                        <b>Grabar</b>
                    </button>
                </div>        
            </div> 
        </div>  

    </div>
    
    <div [style.display]="displayEnvioInternacional">
        <hr class="w-100">
        
        <div class="row">
            <div class="col-sm-12" style="font-weight: 600;text-align: left;">
                <span>Datos de envío internacional</span>
            </div>
        </div>
        <br>
        <div class="row form-group">
            <div class="col-sm-6">
                <select class="browser-default custom-select" [(ngModel)]="cboPaisModel">
                    <option value="" selected>País</option>
                    <option *ngFor="let pais of listPais.response.list" [value]="pais.masterId">{{pais.masterDescription}}</option>
                </select>
                <!-- <mdb-error *ngIf="cboDepartamentoValidar.hasError('required') && cboDepartamentoValidar.touched">Seleccione Departamento</mdb-error> -->
            </div>
            <div class="col-sm-6">
                <div class="md-form md-outline">
                <input type="text" id="txtDireccionInternacional" [(ngModel)]="txtDireccionInternacional" maxlength="200" class="form-control" mdbInput>
                <label for="txtDireccionInternacional">Dirección</label>
                </div>
                <!-- <mdb-error *ngIf="txtIdentificadorVal.invalid && (txtIdentificadorVal.dirty || txtIdentificadorVal.touched)">Ingrese el nro de documento</mdb-error> -->
            </div>
        </div>
        <div class="row form-group">
            <div class="col-sm-12">
                <span style="font-size: 12px;"><b>Nota al cliente:</b>. Al momento de realizar su solicitud de compra desde el exterior, se le notificará el costo de envío y la forma de pago a través de un correo electrónico.</span>
            </div>
        </div>
    </div>

    <hr class="w-100">

    <div class="row" [style.display]="displayTipoComprobante">
        <div class="col-sm-12" style="font-weight: 600;text-align: left;">
            <span>Tipo de comprobante</span>
        </div>
    </div>
    <br>
    <div class="row" [style.display]="displayTipoComprobante">
        <div class="col-sm-6">

            <div class="row">

                <div class="col-sm-4">
                    <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" class="custom-control-input" id="idboleta" name="groupcomprabante" 
                        [(ngModel)]="rdbComprobante" (change)="onchangeComprobante()" value="22" mdbInput>
                        <label class="custom-control-label" style="font-size: 15px;" for="idboleta">Boleta</label>
                    </div>
                </div>
                <div class="col-sm-8">
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" style="font-size: 15px;" id="txtDNI" [(ngModel)] = "txtDNIModel" maxlength="8" placeholder="DNI" 
                          aria-describedby="button-addon2">
                        <div class="input-group-append">
                          <button mdbBtn color="primary" (click)="onBuscarDni();" size="sm" class="m-0 px-3 py-2" type="button" id="button-addon2"
                            mdbWavesEffect><mdb-icon fas icon="search"></mdb-icon></button>
                        </div>
                    </div>
                </div>

            </div>
            
            <div class="row justify-content-end" [style.display]="displayTipoComprobante">        
                <div class="col-sm-8">
                    <div class="md-form md-outline">
                        <input type="text" id="txtFullName" style="background-color: #e9ecef;font-size:14px" disabled maxlength="100" [(ngModel)] = "txtFullNameModel" class="form-control" mdbInput>
                        <label for="txtFullName" style="background: #e4e4e400;">Nombres y Apellidos</label>
                    </div>
                </div>
            </div>

        </div>
        <div class="col-sm-6">
            <div class="row">
                <div class="col-sm-4">
                    <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" (change)="onchangeComprobante()" class="custom-control-input" id="idfactura" name="groupcomprabante" 
                        [(ngModel)]="rdbComprobante" value="23" mdbInput>
                        <label class="custom-control-label" style="font-size: 15px;" for="idfactura">Factura</label>
                    </div>
                </div>
                <div class="col-sm-8">
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" style="font-size: 15px;" id="txtRuc" [(ngModel)] = "txtRucModel" maxlength="11" placeholder="RUC" 
                          aria-describedby="button-addon2">
                        <div class="input-group-append">
                          <button mdbBtn color="primary" size="sm" class="m-0 px-3 py-2" (click)="onBuscarRUC();" type="button" id="button-addon2"
                            mdbWavesEffect><mdb-icon fas icon="search"></mdb-icon></button>
                        </div>
                    </div>            
                </div>
            </div>
            <div class="row justify-content-end" [style.display]="displayTipoComprobante">        
                <div class="col-sm-8">
                    <div class="md-form md-outline">
                        <input type="text" id="txtRazonSocial" style="background-color: #e9ecef;font-size:14px" disabled maxlength="100" [(ngModel)] = "txtRazonSocialModel" class="form-control" mdbInput>
                        <label for="txtRazonSocial" style="background: #e4e4e400;">Razón Social</label>
                    </div>
                </div>
            </div>
        </div>
                
    </div>    
    <br>
    <div class="row" >
        <div class="col-sm-12" style="text-align: center;font-size:17px">
            <div [style.display]="displayBtnNacional">
            <button class="btn btn-rounded btn-yellow btn-sm" (click)="onSiguiente();">Siguiente  <i class="fas fa-angle-double-right"></i></button>
            </div>
            <div [style.display]="displayEnvioInternacional">
            <button class="btn btn-rounded btn-yellow btn-sm" (click)="onSolicitarCotizacion();">Solicitar Contización</button>
            </div>
        </div>
    </div>