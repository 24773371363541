export class DireccionResponse {

    httpStatus: String;
    response: {
        id: String;
        codeResponse: String;
        response: String;
        list: []
    }
      
}