
<br>
<div class="container">
    <div class="row">
        <div class="col-sm-8">
            <mdb-card style="background-color: #fff0;">            
                <mdb-card-body>
                        <ng-stepper #cdkStepper [linear]="true">
                            <cdk-step #cdkStepOne>
                              <ng-template cdkStepLabel>
                                <div class="step-bullet">1</div>
                                <div class="step-title">Información de envío<span class="required"></span></div>
                              </ng-template>
                              <app-informacion-envio [cdkStepOne] = "cdkStepOne"  [cdkStepper] = "cdkStepper" (enviarOrderRequest)="recibirOrder($event);" (enviarPadreEvent)="recibirMensajeHijoInf($event);" #stepOne></app-informacion-envio>
                            </cdk-step>
                            
                            <cdk-step completed="false">
                              <ng-template cdkStepLabel style="pointer-events: none !important;">
                                <div class="step-bullet">2</div>
                                <div class="step-title">Medio de Pago</div>
                              </ng-template>
                              <app-medio-pago [cdkStepOne] = "cdkStepOne" [cdkStepper] = "cdkStepper" [orderRequest]="orderRequestInfoEnvio" #stepTwo></app-medio-pago>
                            </cdk-step>    
                         </ng-stepper>
                        </mdb-card-body>                 
            </mdb-card>
        </div>
        <div class="col-sm-4">
            <mdb-card>
                <div class="card-header"><h5>Resumen de compra</h5></div>
                 <mdb-card-body>                    
                   <mdb-card-text class="mb-0"> 
                     <div class="row">
                       <div class="col-6">
                       Cantidad : 
                       </div>              
                       <div class="col">
                         {{this.cantidadTotal}}
                       </div>
                     </div>
                     <div class="row">
                       <div class="col-6">
                          Sub Total: 
                       </div>              
                       <div class="col">
                         <!-- S/ {{orderCalculateResponse.response.list[0].subtotalCost | number:'1.2-2'}} -->
                         S/ {{SubTotalTemp | number:'1.2-2'}}

                         
                       </div>
                     </div>
                     <div class="row">
                       <div class="col-6">
                        Delivery:
                       </div>              
                       <div class="col-6">
                         S/ {{orderCalculateResponse.response.list[0].shippingCost | number:'1.2-2'}}
                       </div>
                     </div>
                     <div class="row">
                      <div class="col-6">
                        IGV *: 
                      </div>              
                      <div class="col">
                        <!-- S/ {{orderCalculateResponse.response.list[0].igvCost | number:'1.2-2'}} -->
                        S/ {{IGVTotal | number:'1.2-2'}}
                        
                      </div>
                    </div>
                     <hr>
                     <div class="row">
                       <div class="col-6">
                         Total:
                       </div>              
                       <div class="col">
                         <span style="font-size: 20px;color: coral;">S/ {{orderCalculateResponse.response.list[0].totalCost | number:'1.2-2'}}</span>                                                  
                       </div>
                       <div class="col-sm-12">
                         <p class="textAviso">
                          <br>
                          <br>
                          <br>
                          <br>
                          Base legal:
                         
                         </p>
                         <ul class="textAviso">
                          <li>
                           Art. 29 de la Ley N° 31053, Ley que reconoce y fomenta el derecho a la lectura y promueve el libro. *(Libros exonerados de IGV)
                          </li>
                          <li>
                           Art. 1 del Decreto Legislativo N° 821, Ley del Impuesto General a las Ventas e Impuesto Selectivo al Consumo. *(Producto y Delivery)
                          </li>
                        </ul>
                       </div>
                     </div>                      
                   </mdb-card-text>          
                 </mdb-card-body>
            </mdb-card>            
        </div>
    </div>
</div>  

<div style="display: none;">
  <input type="text" id="txtaddressId" />
  <input type="text" id="txttypeDeliveryId" />  
</div>

<br>
<br>