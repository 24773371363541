export class ActualizarDatosRequest {
    birthDate :  string  ;
    document :  string  ;
    email :  string  ;
    id :  string  ;
    name :  string  ;
    passwordNew :  string  ;
    passwordOld :  string  ;
    photo :  string  ;
    provider :  string  ;
    surname :  string  ;
    telephone :  string  ;
    typeDocument :  string 
    constructor(){
       this.birthDate = ""  ;
       this.document =  ""  ;
       this.email =  ""  ;
       this.id =  ""  ;
       this.name =  ""  ;
       this.passwordNew =  ""  ;
       this.passwordOld =  ""  ;
       this.photo =  ""  ;
       this.provider = ""  ;
       this.surname =  ""  ;
       this.telephone = "" ;
       this.typeDocument =  "" 
    }

}
