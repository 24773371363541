import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders} from '@angular/common/http';

import { LibroAdminListResponse } from '../../modal/libro/libroAdminListResponse';
import { LibroAdminRegIdResponse } from '../../modal/libro/libroAdminRegIdResponse';
import { LibroAdminDmlResponse } from '../../modal/libro/libroAdminDmlResponse';
import { LibroAdminBeanInsRequest } from '../../modal/libro/libroAdminBeanInsRequest';
import { LibroBeanUpdRequest } from '../../modal/libro/LibroAdminBeanUpdRequest';

@Injectable({
  providedIn: 'root'
})
export class BookService {

  constructor(private httpClient:HttpClient) { }

  obtenerAdminList(nombre:string,estado:string,idcategoria:string,enNovedades01:string){
    let url = `${environment.urlBase}${environment.listBookAdmin}?nombre=${nombre}&estado=${estado}&categoriaID=${idcategoria}&enNovedades01=${enNovedades01}`;
    console.log("url",url);
    return this.httpClient.get<LibroAdminListResponse>(url).pipe(
      map(data => {   
        let response: LibroAdminListResponse;
        response = data;
        return response;
      },
      error =>{      
        return new Response("9999", error);
      })) 
  }

  obtenerRegistro(id:String){
    let url = `${environment.urlBase}${environment.getBookAdminId}/${id}`;
    //console.log("url",url);
    return this.httpClient.get<LibroAdminRegIdResponse>(url).pipe(
      map(data => {   
        let response: LibroAdminRegIdResponse;
        response = data;
        return response;
      },
      error =>{      
        return new Response("9999", error);
      })) 
  }

  grabar(libroAdminBeanInsRequest:LibroAdminBeanInsRequest){
    let url = `${environment.urlBase}${environment.insertBookAdmin}`;
    return this.httpClient.post<LibroAdminDmlResponse>(url,libroAdminBeanInsRequest).pipe(
      map(data => {
        let response: LibroAdminDmlResponse;
        response = data;        

        return response;        
      },
      error =>{      
        return new Response("9999", error);
      }) 
    )
  }

  update(libroBeanUpdRequest:LibroBeanUpdRequest,id:String){
    let url = `${environment.urlBase}${environment.updateBookAdmin}/${id}`;
    return this.httpClient.put<LibroAdminDmlResponse>(url,libroBeanUpdRequest).pipe(
      map(data => {
        let response: LibroAdminDmlResponse;
        response = data;        
        return response;        
      },
      error =>{      
        return new Response("9999", error);
      }) 
    )
  }
  
}
