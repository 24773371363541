export class CatalogoSourvenirRequest {
     groupId :  String;
     page :  String;
     size :  String;
     submenuId :  String;
     topicId :  String;
     
     constructor(){
        this.groupId =  "";
        this.page =  "";
        this.size =  "";
        this.submenuId =  "";
        this.topicId =  "";
     }
}
