<!--Header-->
<div class="modal-header text-center" style="color: white;background-color: #544d84">    
    <h4 class="modal-title w-100">{{x_titulo}}</h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onClosemodal()">
      <span aria-hidden="true" class="white-text">&times;</span>
    </button>
</div>

<!--Body-->
<div class="modal-body">
  <div class="form-row">
        <div class="col-12">
          <mdb-card>
            <mdb-card-body>              
                  <div class="form-row">
                    <div class="col-3">Código:</div>
                    <div class="col-9">
                        {{id}}
                    </div>
                  </div>
                  <br>
                  <div class="form-row">
                    <div class="col-3">Libro:</div>
                    <div class="col-9">
                        {{x_titulo_libro}}
                    </div>
                  </div>
                  <br>
                  <div class="form-row">
                    <div class="col-3">Material:</div>
                    <div class="col-9">                
                        <select class="browser-default custom-select" [(ngModel)]="cboMaterial">
                            <option value="" selected>Seleccione</option>
                            <option *ngFor="let listMaterial of listMaterial.response.list" [value]="listMaterial.masterId">{{listMaterial.masterDescription}}</option>
                        </select>
                    </div>
                  </div>
                  <br>              
                  <div class="form-row">
                    <div class="col-3">Stock:</div>
                    <div class="col-9">                
                      <input mdbInput type="number" pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==4) return false;" min="1" max="9999" class="form-control" [(ngModel)]="txtStock" id="txtStock">
                    </div>
                  </div>
                  <br>
                  <div class="form-row">
                    <div class="col-3">Estado:</div>
                    <div class="col-9">                
                        <select class="browser-default custom-select" [(ngModel)]="cboEstado">
                            <option value="" selected>Seleccione</option>
                            <option *ngFor="let estado of listEstado.response.list" [value]="estado.masterName">{{estado.masterDescription}}</option>
                        </select>
                    </div>
                  </div>
            </mdb-card-body>
          </mdb-card>
        </div>
  </div>
  <br>
  <div class="form-row">
    <div class="col-12">
      <mdb-card>
        <mdb-card-body>
          <div class="form-row">
            <div class="col-12">
                <button mdbBtn type="button" (click)="onAgregarConcepto()" size="sm" class="btn btn-deep-purple btn-rounded" mdbWavesEffect>Enlazar Concepto de Pago</button>
            </div>                
          </div>          
          <br>
          <div class="form-row">
            <div class="col-3">Código:</div>
            <div class="col-9">                
              <input mdbInput type="text" class="form-control" readonly [(ngModel)]="txtCodigoSigaSec" placeholder="0000-0">
            </div>
          </div>
          <br>
          <div class="form-row">
            <div class="col-3">Descripción:</div>
            <div class="col-9">                
              <input mdbInput type="text" class="form-control" readonly [(ngModel)]="txtDescription" placeholder="Concepto de pago">
            </div>
          </div>
          <br>
          <div class="form-row">
            <div class="col-3">Moneda:</div>
            <div class="col-9">                
              <input mdbInput type="text" class="form-control" readonly [(ngModel)]="txtCurrency" placeholder="">
            </div>
          </div>
          <br>
          <div class="form-row">
            <div class="col-3">Precio:</div>
            <div class="col-9">
              <input mdbInput type="text" class="form-control" readonly [(ngModel)]="txtPrice" placeholder="">
            </div>
          </div>
          <br>
        </mdb-card-body>
      </mdb-card>
    </div>
  </div>
  
  <br>
  <!--Footer-->
<div class="modal-footer justify-content-center">
    <button mdbBtn type="button" (click)="onGrabar()" size="sm" class="btn btn-deep-purple btn-rounded" mdbWavesEffect>
        <mdb-icon fas icon="save" class="mr-1"></mdb-icon>Grabar</button>
</div>
