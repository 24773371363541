export class DireccionRequest {
    cellphoneContact: String;
    codeDepartment: String;
    codeDistrict: String;
    codeProvince: String;
    countryId: String;
    description: String;
    enabled: String;
    id: String;
    identifier: String;
    personContact: String;
    reference: String;
    userId: String;    
}