<!-- Footer -->
<footer class="page-footer font-small fondoFooter pt-4">

  <!-- Footer Links -->
  <div class="container text-center text-md-left">

    <!-- Grid row -->
    <div class="row">

      <!-- Grid column -->
      
      <div class="col-md-2 mt-md-0 mt-3 mb-3">
        <p class=" mt-2">
          <img src="/assets/image/portafolio/Libreria-virtual-Logo.png" width="80%">
          <!-- <img src="/assets/image/inicio/LOGO-TIENDA-BNP.png" width="80%"> -->

        </p>
        <p>
          <br>
          <!-- Contacto:<br><a style="color:#000" href="mailto:libreriavirtual@bnp.gob.pe">libreriavirtual@bnp.gob.pe</a> -->
        </p>
      </div>
      <div class="col-md-1 mt-md-0 mt-3 mb-3"></div>

      <div class="col-md-4 mt-md-0 mt-3 mb-3">

        <!-- Content -->
        <p><b>Biblioteca Nacional del Perú - RUC 20131379863</b></p>
        <p>Sistema de información administrado por la<br> Oficina de Tecnologías de la Información y Estadística</p>
        <p>
          <img src="/assets/image/portafolio/u1.png" width="3%">&nbsp; Av. La Poesía 160, San Borja, Lima, Perú
        </p>
        <p>
          <img src="/assets/image/portafolio/u2.png" width="3%">&nbsp; (511) 513-6900
        </p>
        <p>
          <img src="/assets/image/portafolio/u3.png" width="3%">&nbsp; libreriavirtual@bnp.gob.pe
        </p>
      </div>
      <!-- Grid column -->

      <hr class="clearfix w-100 d-md-none pb-3">
      <!-- Grid column -->
      <div class="col-md-1 mb-md-0 mb-3"></div>
      <div class="col-md-3 mb-md-0 mb-3">

        <p class="mt-2">
          <!-- Enlaces de interés:--><br><br> 
          <!-- <img src="/assets/image/portafolio/I_peru_primero.png" width="70%"> -->
        </p>
        <p class="">
          <img src="/assets/image/portafolio/i_cultura.png" width="100%">
        </p>
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-md-3 mb-md-0 mb-3">

        </div>
        
        
        <!-- Grid column -->

    </div>
    <!-- Grid row -->

  </div>
  <!-- Footer Links -->

  <!-- Copyright -->
  
  <!-- Copyright -->

</footer>
<div class="container white">
  <div class="row">
   
        <div class="col-md-12 mb-md-0 mb-3 mt-3 white">
          <p class="text-left fontCopy">© 2020 Copyright; Biblioteca Nacional del Perú</p>
        </div>
  </div>
</div>
<!-- Footer -->