import { DetailBook } from './detailBook'

export class OrderInternacionalRequest {
    clientId: string;
    countryAddress: string;
    countryId: string;
    detailBookAndProduct: Array<DetailBook>;
    igvCost: string;
    shippingCost: string;
    subtotalCost: string;
    totalCost: string;
}