<app-banner></app-banner>
<app-novedades></app-novedades>
<app-catalogo></app-catalogo>
<div class="container">
    <div class="row mb-5">
        <div class="col-sm-3"></div>

        <div class="col-sm-5">
            <p class="font-weight-bold">Pagos 100% seguros<br>   <img src="/assets/image/portafolio/line_pagos.png" width="35%"></p>
            <p >
                <img src="/assets/image/portafolio/p1.png" width="10%">&nbsp;
                <img src="/assets/image/portafolio/p2.png" width="10%">&nbsp;
                <img src="/assets/image/portafolio/p3.png" width="15%">&nbsp;
                <img src="/assets/image/portafolio/p4.png" width="20%">&nbsp;

            </p>
        </div>
        <div class="col-sm-4">
            <p class="font-weight-bold">Siguenos en: <br>   <img src="/assets/image/portafolio/line_siguenos.png" width="28%"></p>
            <p >
                <a href="https://www.facebook.com/BibliotecaNacionalPeru/" target="_blank"> <img src="/assets/image/portafolio/r1.png" width="8%"></a>&nbsp;
                <a href="https://twitter.com/BibliotecaPeru" target="_blank"> <img src="/assets/image/portafolio/r2.png" width="8%"></a>&nbsp;
                <a href="https://www.instagram.com/bibliotecaperu/" target="_blank"> <img src="/assets/image/portafolio/r3.png" width="8%"></a>
 
            </p>
        </div>
       

    </div>
</div>