import { Component, ContentChildren, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UbigeoService } from 'src/app/service/ubigeo/ubigeo.service';
import { UsuarioService } from 'src/app/service/usuario/usuario.service';
import { DireccionRequest } from 'src/app/modal/usuario/direccion-request';
import { DireccionResponse } from 'src/app/modal/usuario/direccion-response';
import { DireccionUserResponse } from 'src/app/modal/usuario/direccion-user-response';
import { UbigeoResponse } from 'src/app/modal/ubigeo/ubbigeoResponse';
import { UbigeoBean } from 'src/app/modal/ubigeo/ubigeoBean';
import { UsuarioBeanResponse } from 'src/app/modal/usuario/usuario-bean-response';
import { RespuestaComun } from 'src/app/modal/maestras/respuesta-comun';
import { OrderRequest } from 'src/app/modal/procesapago/orderRequest';
import { OrderInternacionalRequest } from 'src/app/modal/procesapago/OrderInternacionalRequest';
import { MaestrasService } from 'src/app/service/maestras/maestras.service';
import { DetailBook } from '../../../modal/procesapago/detailBook';
import { CarritoService } from 'src/app/service/carrito/carrito.service';
import { CarritoHeader } from 'src/app/modal/carrito/carrito-header';
import { CdkStep, CdkStepper } from '@angular/cdk/stepper';
import { UtilitarioService } from 'src/app/service/utilitarios/utilitario.service';
import { ProcesarPagoService } from 'src/app/service/procesarpago/procesar-pago.service';
import { ExternosService } from 'src/app/service/externos/externos.service';
import { PersonaBeanM,RequestCredenciales,RucEntidadBeanMResponse } from 'src/app/modal/externo/externo';
import { LibroDetalle } from 'src/app/modal/catalogo/libro-detalle';
import { NovedadesService } from 'src/app/service/catalogo/novedades.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SourvenirDetalleResponse } from 'src/app/modal/sourvenir/sourvenir-detalle-response';

@Component({
  selector: 'app-informacion-envio',
  templateUrl: './informacion-envio.component.html',
  styleUrls: ['./informacion-envio.component.scss']
})

export class InformacionEnvioComponent implements OnInit {

  constructor(private ubigeoService:UbigeoService,
              private usuarioService:UsuarioService,
              private maestrasService:MaestrasService,
              public router : Router,
              private carritoService:CarritoService,
              private procesarPagoService:ProcesarPagoService,  
              private novedadesService:NovedadesService,
              private utilitarioService:UtilitarioService,
              private externosService:ExternosService,) { }
  
  public stepOneForm: FormGroup;  
  public divAgregarDireccion:Boolean;

  public displayTipoEntrega:String;
  public displayEnvioInternacional:String;
  public displayTipoEntregaRadios:String;
  public displayBtnNacional:String;  
  public displayTipoComprobante:String;
  
  rdbTipoEntregaVal:String;
  rdbComprobante:String;
  orderRequest:OrderRequest = new OrderRequest();
  usuarioBeanResponse:UsuarioBeanResponse = new UsuarioBeanResponse();
  ubigeoBean:UbigeoBean[] = new Array<UbigeoBean>();
  carritoDetalle:CarritoHeader;

  public departamento: UbigeoResponse;
  public provincia: UbigeoResponse;
  public distritoResponse: UbigeoResponse;
  public listDestino: RespuestaComun;
  public listPais: RespuestaComun;

  direccionUserResponse: DireccionUserResponse;
  tipoEntregaBean:RespuestaComun;

  txtIdentificador:String;
  cboDepartamentoVal:String;
  cboRegionModel:String;
  cboPaisModel:String;
  cboDistrito:String;
  cboProvincia:String;
  txtReferencia:String;  
  txtPerContact:String;
  txtDireccion:String;
  txtCelular:String;
  txtPersonaApellidoMaterno: String;
  txtPersonaApellidoPaterno: String;
  txtPersonaNombres: String;
  txtDireccionInternacional:String;
  rdbDireccionEnvio:String = "0";

  txtRucModel:String = "";
  txtRazonSocialModel:String = "";
  txtDNIModel:String = "";
  txtFullNameModel:String = "";
  
  flagBuscarDniVerificado:String = "0";
  flagBuscarRucVerificado:String = "0";

  precioTotal:number=0;
  cantidadTotal:number=0;

  SubTotalTemp = 0;
  IGVProducto = 0;
  IGVDelivery = 0;
  IGVTotal = 0;   



  
  headElements = ['Dirección de entrega','Nombre', 'Dirección',  'Eliminar'];

  @Input() cdkStepper : CdkStepper;
  @Input() cdkStepOne : CdkStep;
  
  @Output()
  enviarPadreEvent:EventEmitter<string> = new EventEmitter<string>();

  @Output()
  enviarOrderRequest:EventEmitter<OrderRequest> = new EventEmitter<OrderRequest>();

  get txtIdentificadorVal() {return this.stepOneForm.get('txtIdentificadorVal');}
  get cboDepartamentoValidar() {return this.stepOneForm.get('cboDepartamentoValidar');}
  get cboProvinciaValidar() {return this.stepOneForm.get('cboProvinciaValidar');}
  get txtReferenciaValidar() {return this.stepOneForm.get('txtReferenciaValidar');}
  get cboDistritoValidar() {return this.stepOneForm.get('cboDistritoValidar');}
  get txtPerContactValidar() {return this.stepOneForm.get('txtPerContactValidar');}
  get txtDireccionValidar() {return this.stepOneForm.get('txtDireccionValidar');}
  get txtCelularValidar() {return this.stepOneForm.get('txtCelularValidar');}

  ngOnInit(): void {    
      
    this.cboRegionModel = "13"; //por defecto Nacional
    this.rdbTipoEntregaVal = "11"; //por defecto entrega a docmicilio
    this.displayBtnNacional = "block";
    this.displayTipoEntrega = "block";
    this.displayTipoEntregaRadios = "block";
    this.displayEnvioInternacional = "none";  
    this.rdbComprobante = "22"; //por defecto boleto
    this.cboDepartamentoVal = "";    
    this.cboProvincia = "";
    this.cboDistrito = "";
    this.cboPaisModel = "";
    this.txtDireccionInternacional= "";
    this.divAgregarDireccion = true;
    this.cdkStepOne.completed = false; //no puede pasar al siguiente step

    this.provincia = new UbigeoResponse();
    this.distritoResponse = new UbigeoResponse();

    this.listarDireccion();
    this.obtenerDepartamento();
    this.listarTipoEntrega();
    this.obtenerDestino();
    this.obtenerPais();

    this.stepOneForm = new FormGroup({
      txtIdentificadorVal: new FormControl('', Validators.required),
      cboDepartamentoValidar: new FormControl('', Validators.required),
      cboProvinciaValidar: new FormControl('', Validators.required),
      txtReferenciaValidar: new FormControl('', Validators.required),
      cboDistritoValidar: new FormControl('', Validators.required),
      txtPerContactValidar: new FormControl('', Validators.required),
      txtDireccionValidar: new FormControl('', Validators.required),
      txtCelularValidar: new FormControl('', Validators.required)
    });    

  }

  onEnviarEventoPadre(){
    this.enviarPadreEvent.emit("1");
  }

  ngAfterViewInit(){
    this.stepOneForm.clearValidators();
    this.onchangeComprobante();
  }  

  onEnviarOrder(){
    
    let detailBookList = new Array<DetailBook>();    

    this.carritoDetalle = new CarritoHeader();
    this.carritoDetalle=this.carritoService.obtenerCarritoCompras()

    for (let i = 0; i < this.carritoDetalle.lista.length; i++) {
      let detailBook = new DetailBook();
      if(this.carritoDetalle.lista[i].tipoProducto=="1"){
        detailBook.bookId = this.carritoDetalle.lista[i].id;
        detailBook.productId = "0";
        detailBook.sizeId = this.carritoDetalle.lista[i].sizeID;

      }
      else if(this.carritoDetalle.lista[i].tipoProducto=="2"){
        detailBook.productId = this.carritoDetalle.lista[i].id;
        detailBook.bookId ="0";
        detailBook.sizeId = this.carritoDetalle.lista[i].sizeID;
      }
      //detailBook.bookId = this.carritoDetalle.lista[i].id;
      detailBook.quantity = this.carritoDetalle.lista[i].cantidad;      
     
      //detailBook.productId = this.carritoDetalle.lista[i].tipoProducto;
      detailBookList.push(detailBook);
    }

    this.orderRequest.detailBookAndProduct = detailBookList;

    this.orderRequest.addressId= this.rdbDireccionEnvio;
    this.orderRequest.clientId= this.usuarioService.obtenerIDSessionUsuario();    

    this.usuarioService.obtenerUser(this.orderRequest.clientId).subscribe(data=>{

      this.usuarioBeanResponse = data;
      console.log(this.usuarioBeanResponse);
      this.orderRequest.fiscalAddress= "";
      

      if(this.flagBuscarDniVerificado="1"){ //obtuene datos del compobante boleta

        this.orderRequest.fullName= this.txtPersonaNombres + ' ' + this.txtPersonaApellidoPaterno + ' ' + this.txtPersonaApellidoMaterno;
        this.orderRequest.nroDocumento= this.txtDNIModel;
        this.orderRequest.personaNombres = this.txtPersonaNombres;
        this.orderRequest.personaApellidoPaterno = this.txtPersonaApellidoPaterno;
        this.orderRequest.personaApellidoMaterno = this.txtPersonaApellidoMaterno;

      }
      else{//obtiene datos del usuario para factura

        this.orderRequest.fullName= this.usuarioBeanResponse.response.list[0].name + ' ' + this.usuarioBeanResponse.response.list[0].surname;
        this.orderRequest.nroDocumento= this.usuarioBeanResponse.response.list[0].document;
        this.orderRequest.personaNombres = this.usuarioBeanResponse.response.list[0].name;
        this.orderRequest.personaApellidoPaterno = "";
        this.orderRequest.personaApellidoMaterno = "";

      }

      this.orderRequest.razonSocial= this.txtRazonSocialModel;
      this.orderRequest.ruc= this.txtRucModel;
      this.orderRequest.igvCost= "";
      this.orderRequest.shippingCost= "";
      this.orderRequest.subtotalCost= "";
      this.orderRequest.totalCost= "";
      this.orderRequest.typeDeliveryId= this.rdbTipoEntregaVal; //domicilio
      this.orderRequest.typeRegionId= "13"; //envio nacional
      this.orderRequest.typeShippingId= "12"; // peru
      this.orderRequest.typeVoucherId= this.rdbComprobante;
      
      console.log(this.orderRequest);
      this.enviarOrderRequest.emit(this.orderRequest);

    })

  }

  onchangeDireccion(idDireccion:String){

    if (idDireccion=="11"){ //si no es envio a direccion se oculta seccion registrar direccion
       this.displayTipoEntrega = "block";
    }else{this.displayTipoEntrega = "none";}
    
    //refresca datos totales del padre component
    this.onEnviarEventoPadre();

  }

  onchangeDestino(){

    if (this.cboRegionModel=="13"){ //envio nacional
       this.displayTipoEntrega = "block";
       this.displayEnvioInternacional = "none";
       this.displayTipoEntregaRadios = "block";
       this.displayBtnNacional = "block";
       this.displayTipoComprobante = "block";
    }else{
      this.displayTipoEntrega = "none";
      this.displayTipoEntregaRadios = "none";
      this.displayBtnNacional = "none";
      this.displayEnvioInternacional = "block";    
      this.displayTipoComprobante = "none";
 
    }
    
    //refresca datos totales del padre component
    //this.onEnviarEventoPadre();

  }

  onchangeComprobante(){

    if (this.rdbComprobante=="22"){ //boleta
      //console.log(this.rdbComprobante);
      (<HTMLInputElement>document.getElementById("txtRuc")).disabled = true;      
      (<HTMLInputElement>document.getElementById("txtDNI")).disabled = false;

      this.txtRucModel = "";
      this.txtRazonSocialModel = "";
      this.flagBuscarRucVerificado = "0";

    }else{
      //console.log(this.rdbComprobante);
      (<HTMLInputElement>document.getElementById("txtRuc")).disabled = false;      
      (<HTMLInputElement>document.getElementById("txtDNI")).disabled = true;

      this.txtDNIModel = "";
      this.txtFullNameModel = "";
      this.flagBuscarDniVerificado = "0";

    }
    
  }

  obtenerDepartamento(){
    this.utilitarioService.loading = true;
    this.departamento = new UbigeoResponse();    
    this.ubigeoService.obtenerDepartamento().subscribe(data=>{
      this.departamento = data;
      this.utilitarioService.loading = false;
    })
  }

  obtenerDestino(){

    this.utilitarioService.loading = true;
    this.listDestino = new RespuestaComun();
    this.maestrasService.obtenerMaestra("TIPO_REGION").subscribe(data=>{
      this.listDestino = data;
      //console.log(data);
      this.utilitarioService.loading = false;
    });
    
  }

  obtenerPais(){

    this.utilitarioService.loading = true;
    this.listPais = new RespuestaComun();
    this.maestrasService.obtenerMaestra("TIPO_PAIS").subscribe(data=>{
      this.listPais = data;      
      //console.log(data);
      this.utilitarioService.loading = false;
    });
    
  }

  obtenerProvincia(codDepartamento:String){    
    this.distritoResponse.response.list = [];
    this.provincia = new UbigeoResponse();
    this.utilitarioService.loading = true;
    this.ubigeoService.obtenerProvincia(codDepartamento).subscribe(data=>{
      this.provincia = data;
      this.cboProvincia = "";
      this.cboDistrito = "";
      this.utilitarioService.loading = false;
    })
  }

  obtenerDistritos(codDepartamento:String,codProvincia:String){
    this.distritoResponse = new UbigeoResponse();
    this.utilitarioService.loading = true;
    this.ubigeoService.obtenerDistrito(codDepartamento,codProvincia).subscribe(data=>{
      this.distritoResponse = data;
      this.cboDistrito = "";
      this.utilitarioService.loading = false;
    })
  }

  onGrabarDireccion(){    
    
    if (!this.stepOneForm.valid){
      
      this.stepOneForm.markAllAsTouched();      
      return;

    }
    
    if(this.usuarioService.obtenerIDSessionUsuario()=="" || this.usuarioService.obtenerIDSessionUsuario() ==null ){ //factura
      this.utilitarioService.toastrInfo("Debe ingresar con su usuario para continuar con su compra.");
      return;
    }
    
    this.utilitarioService.loading = true;

    let direccionRequest:DireccionRequest = new DireccionRequest();
    let direccionResponse:DireccionResponse = new DireccionResponse();
    let userid = this.usuarioService.obtenerIDSessionUsuario();

    direccionRequest.cellphoneContact= this.txtCelular;
    direccionRequest.codeDepartment= this.cboDepartamentoVal;
    direccionRequest.codeDistrict= this.cboDistrito;
    direccionRequest.codeProvince= this.cboProvincia;
    direccionRequest.countryId= "12"; //codigo de pais
    direccionRequest.description= this.txtDireccion;
    direccionRequest.enabled= "";
    direccionRequest.id= "";
    direccionRequest.identifier= this.txtIdentificador;
    direccionRequest.personContact= this.txtPerContact;
    direccionRequest.reference= this.txtReferencia;
    direccionRequest.userId= userid;

    this.usuarioService.regDireccion(direccionRequest).subscribe(data=>{
      direccionResponse = data;
      if(direccionResponse.response.codeResponse="0000"){
        this.listarDireccion();
        this.flimpiarDireccion();
      }
      this.utilitarioService.loading = false;           
    });        

  }

  listarDireccion(){
    
    this.utilitarioService.loading = true;

    this.direccionUserResponse = new DireccionUserResponse();
    let userid = this.usuarioService.obtenerIDSessionUsuario();    
    
    this.usuarioService.obtenerDireccionUser(userid).subscribe(data=>{
      this.direccionUserResponse = data;
      //console.log(this.direccionUserResponse);
      //console.log("ingreso");
      this.utilitarioService.loading = false;
    })

  }

  listarTipoEntrega(){

    this.tipoEntregaBean = new RespuestaComun();    
    this.maestrasService.obtenerMaestra("TIPO_ENTREGA").subscribe(data=>{
      this.tipoEntregaBean = data;            
    })    

  }

  feliminarDireccion(direccionRequest:DireccionRequest){

    this.utilitarioService.loading = true;

    if(direccionRequest.id==this.rdbDireccionEnvio){
        this.rdbDireccionEnvio="0";
    }
    
    let direccionResponse:DireccionResponse = new DireccionResponse();
    
    direccionRequest.enabled="0";
    this.usuarioService.delDireccionUser(direccionRequest).subscribe(data=>{
      direccionResponse = data;      
      if(direccionResponse.response.codeResponse="0000"){
        this.listarDireccion();
      }
      this.utilitarioService.loading = false;
    })

  }

  flimpiarDireccion(){

    //this.divAgregarDireccion = false;
    (<HTMLButtonElement>document.getElementById("btnagregardireccion")).click();
    this.txtCelular = "";
    this.cboDepartamentoVal = "";
    this.cboDistrito= "";
    this.cboProvincia= "";  
    this.txtDireccion= ""; 
    this.txtIdentificador= "";
    this.txtPerContact= "";
    this.txtReferencia= "";

  }

  onSiguiente(){               
    
    if (this.validarGeneral()==false){ return;}

    //valida nacional
    if (this.cboRegionModel=="13"){

      if((this.rdbDireccionEnvio==null || this.rdbDireccionEnvio == "0") && this.rdbTipoEntregaVal=="11"){ //domicilio
        this.utilitarioService.toastrInfo("Seleccione o registre una dirección como mínimo.")
        return;
      }

      console.log("this.rdbComprobante",this.rdbComprobante);      

      if (this.rdbComprobante=="22" && this.flagBuscarDniVerificado=="0"){        
          this.utilitarioService.toastrInfo("Debe ingresar y buscar el número de DNI, hacer click en el boton lupa");
          return;        
      }

      if (this.rdbComprobante=="23" && this.flagBuscarRucVerificado=="0"){        
        this.utilitarioService.toastrInfo("Debe ingresar y buscar el número de RUC, hacer click en el boton lupa");
        return;        
      }


      this.onEnviarOrder();
      this.cdkStepOne.completed = true; //colca a completado para porder avanzar al sgt step
      this.cdkStepper.next();
      this.cdkStepOne.completed = false; //coloca el step uno a incompleta para desabilidatr tecla left rigth cuando retorne
      //console.log(this.orderRequest);
      
    }                
    
  }

  validarGeneral() : Boolean{
    //valida que exista uno libro seleccionado
    this.carritoService.verificarCarritoSelecionados();
    //valida formulario
    if(this.usuarioService.obtenerIDSessionUsuario()=="" || this.usuarioService.obtenerIDSessionUsuario() ==null ){ //factura
      this.utilitarioService.toastrInfo("Debe ingresar con su usuario para continuar con su compra.");
      return false;
    }

    return true;

  }

  onSolicitarCotizacion(){

    if (this.validarGeneral()==false){ return;}
      
      //valida internacional
    if (this.cboRegionModel=="14"){
        
      if(this.cboPaisModel==""){
          this.utilitarioService.toastrInfo("Seleccione el país a enviar.");
          return;
      }
    
      if(this.txtDireccionInternacional==""){
          this.utilitarioService.toastrInfo("Ingrese la dirección del país.");
          return;
      }

      //envia correo electronico      
      this.enviarOrdenInternacional();      
    }
  }

  async enviarOrdenInternacional(){        

    this.carritoDetalle = new CarritoHeader();
    this.carritoDetalle=this.carritoService.obtenerCarritoCompras();
    let orderInternacionalRequest = new OrderInternacionalRequest();    
    let listDetailBook = new Array<DetailBook>();

    this.SubTotalTemp = 0;
    this.IGVDelivery = 0;
    this.IGVProducto = 0;
    this.IGVTotal = 0;



    this.utilitarioService.loading=true;
    //obtiene nuevamente costos con BD por seguridad
    for (let i = 0; i < this.carritoDetalle.lista.length; i++) {
        
        let libroDetalleTemp=new LibroDetalle();
        let libroSourvenirTemp=new SourvenirDetalleResponse();

        //recorre carrito en temporal con bd para obtener costos y detalle del producto uno por uno
        // var libroDetalle:LibroDetalle = await this.novedadesService.obtenerDetalleLibroAsync(this.carritoDetalle.lista[i].id).toPromise();                
        // libroDetalleTemp = libroDetalle;      
        // this.precioTotal = Number((this.precioTotal + ( Number(this.carritoDetalle.lista[i].cantidad) * Number(libroDetalleTemp.response.list[0].price))).toFixed(2));
        // this.cantidadTotal = this.cantidadTotal + Number(this.carritoDetalle.lista[i].cantidad);        
        
        if(this.carritoDetalle.lista[i].tipoProducto=="1"){
          var libroDetalle:LibroDetalle = await this.novedadesService.obtenerDetalleLibroAsync(this.carritoDetalle.lista[i].id).toPromise();                
          libroDetalleTemp = libroDetalle;      
          this.precioTotal = Number((this.precioTotal + ( Number(this.carritoDetalle.lista[i].cantidad) * Number(libroDetalleTemp.response.list[0].price))).toFixed(2));
          this.cantidadTotal = this.cantidadTotal + Number(this.carritoDetalle.lista[i].cantidad);  

          this.SubTotalTemp = Number((this.SubTotalTemp + ( Number(this.carritoDetalle.lista[i].cantidad) * Number(libroDetalleTemp.response.list[0].price))).toFixed(2));

        }
        else if(this.carritoDetalle.lista[i].tipoProducto=="2"){
          var sourvenirDetalle:SourvenirDetalleResponse = await this.novedadesService.obtenerDetalleSourvenirAsync(this.carritoDetalle.lista[i].id).toPromise();                
          libroSourvenirTemp = sourvenirDetalle;      
          this.precioTotal = Number((this.precioTotal + ( Number(this.carritoDetalle.lista[i].cantidad) * Number(libroSourvenirTemp.response.list[0].price))).toFixed(2));
          this.cantidadTotal = this.cantidadTotal + Number(this.carritoDetalle.lista[i].cantidad);  

          let tempPrecioSinIGV = 0;
            //tempPrecioSinIGV = Number(libroSourvenirTemp.response.list[0].price) - ( Number(libroSourvenirTemp.response.list[0].price) * 0.18);
            tempPrecioSinIGV =  Number((Number(libroSourvenirTemp.response.list[0].price) / 1.18).toFixed(2));

            
            //this.IGVProducto = this.IGVProducto + (Number(libroSourvenirTemp.response.list[0].price) * 0.18);
            //this.IGVProducto = this.IGVProducto + ((Number(libroSourvenirTemp.response.list[0].price) * 0.18)*Number(this.carritoDetalle.lista[i].cantidad));


           // this.IGVProducto = this.IGVProducto + (Number((Number((Number(libroSourvenirTemp.response.list[0].price) / 1.18).toFixed(2)) * 0.18).toFixed(2))*Number(this.carritoDetalle.lista[i].cantidad));
           this.IGVProducto = this.IGVProducto +  (Number(libroSourvenirTemp.response.list[0].price) - tempPrecioSinIGV ) * Number(this.carritoDetalle.lista[i].cantidad);


            this.SubTotalTemp = Number((this.SubTotalTemp + ( Number(this.carritoDetalle.lista[i].cantidad) * tempPrecioSinIGV)).toFixed(2));
  
        }

    }

    for (let i = 0; i < this.carritoDetalle.lista.length; i++) {
      let detailBook = new DetailBook();
      //detailBook.bookId = this.carritoDetalle.lista[i].id;
      detailBook.quantity = this.carritoDetalle.lista[i].cantidad;

      if(this.carritoDetalle.lista[i].tipoProducto=="1"){
        detailBook.bookId = this.carritoDetalle.lista[i].id;
        detailBook.productId = "0";
        detailBook.sizeId = this.carritoDetalle.lista[i].sizeID;

      }
      else if(this.carritoDetalle.lista[i].tipoProducto=="2"){
        detailBook.productId = this.carritoDetalle.lista[i].id;
        detailBook.bookId ="0";
        detailBook.sizeId = this.carritoDetalle.lista[i].sizeID;
      }




      listDetailBook.push(detailBook);
    }
    this.IGVTotal = this.IGVProducto + this.IGVDelivery;


    orderInternacionalRequest.clientId = this.usuarioService.obtenerIDSessionUsuario();
    orderInternacionalRequest.countryAddress = this.txtDireccionInternacional.toString();
    orderInternacionalRequest.countryId = this.cboPaisModel.toString();
    orderInternacionalRequest.detailBookAndProduct = listDetailBook;
    //orderInternacionalRequest.igvCost = "0";
    orderInternacionalRequest.igvCost = this.IGVTotal.toFixed(2).toString();
    orderInternacionalRequest.subtotalCost = this.SubTotalTemp.toString();


    orderInternacionalRequest.shippingCost = "0";
    //orderInternacionalRequest.subtotalCost = this.precioTotal.toString();
    orderInternacionalRequest.totalCost = this.precioTotal.toString();
    console.log("orderInternacionalRequest",orderInternacionalRequest);
    this.procesarPagoService.grabarOrdenInternacional(orderInternacionalRequest).subscribe(data=>{
      //console.log(data);
      if (data.response.codeResponse=="0000"){        
        this.router.navigate(['/respuestainternacional', data.response.list[0].orderId]);
        this.utilitarioService.toastrSuccess("Se ha enviado su orden via correo electronico");
        this.carritoService.limpiarCarrito();
      }else{
        this.utilitarioService.toastrSuccess(data.response.response);
      }
      this.utilitarioService.loading=false;
    });

  }

  onBuscarDni(){
    
    if(this.txtDNIModel.length==0){
        this.utilitarioService.toastrInfo("Ingrese número de DNI");
        return;
    }    

    this.utilitarioService.loading=true;

    let formDataPersonaBus = new FormData();
    formDataPersonaBus.append("P_NRO_DOCUMENTO",this.txtDNIModel.toString());
    formDataPersonaBus.append("P_DOCUMENTO_IDENTIDAD","1");
    formDataPersonaBus.append("P_CREDENCIALES", JSON.stringify({credencialesbean:this.externosService.getCredencialesGestionUsuario()}));
    
    this.externosService.busDocumentoIdentidad(formDataPersonaBus).subscribe((personaBeanM : PersonaBeanM) =>{
      console.log(personaBeanM);

      switch(personaBeanM.mensajeResponseBeanM.codigoservicerespuesta) { 
        case "0001": {
          console.log(personaBeanM);
          this.flagBuscarDniVerificado = "1";
          this.txtFullNameModel = personaBeanM.nombres + " " + personaBeanM.apepaterno + " " + personaBeanM.apematerno;
          this.txtPersonaNombres = personaBeanM.nombres;
          this.txtPersonaApellidoPaterno= personaBeanM.apepaterno;
          this.txtPersonaApellidoMaterno = personaBeanM.apematerno;
          break;

        } 
        case "0000": { 
          this.utilitarioService.toastrError("Ocurrio un error en el servicio.");
          console.log(personaBeanM);
          this.flagBuscarDniVerificado = "0";
          this.txtFullNameModel = "";
          this.txtPersonaNombres = "";
          this.txtPersonaApellidoPaterno= "";
          this.txtPersonaApellidoMaterno = "";
          break; 
        }
        default: { 
          this.utilitarioService.toastrInfo(personaBeanM.mensajeResponseBeanM.descripcion);
          this.flagBuscarDniVerificado = "0";
          this.txtFullNameModel = "";
          this.txtPersonaNombres = "";
          this.txtPersonaApellidoPaterno= "";
          this.txtPersonaApellidoMaterno = "";
          break; 
        }
      }
      
      this.txtRazonSocialModel = "";
      this.utilitarioService.loading=false;

    });

  }
  
  onBuscarRUC(){

    if(this.txtRucModel.length==0){
      this.utilitarioService.toastrInfo("Ingrese número de RUC");
      return;
    } 

    this.utilitarioService.loading=true;

    let formDataRucBus = new FormData();
    formDataRucBus.append("P_RUC",this.txtRucModel.toString());
    formDataRucBus.append("P_CREDENCIALES", JSON.stringify({credencialesbean:this.externosService.getCredencialesGestionUsuario()}));

    this.externosService.busDocumentoRuc(formDataRucBus).subscribe((rucEntidadBeanMResponse : RucEntidadBeanMResponse) => {
      
      console.log(rucEntidadBeanMResponse);
          switch(rucEntidadBeanMResponse.mensajeResponseBeanM.codigoservicerespuesta) { 
            case "0001": {              
              this.flagBuscarRucVerificado = "1";
              this.txtRazonSocialModel = rucEntidadBeanMResponse.x_razonsocial;
              break;

            }
            case "0000": { 
              this.utilitarioService.toastrError("Ocurrio un error en el servicio.");
              this.flagBuscarRucVerificado = "0";
              this.txtRazonSocialModel = "";
              break;

            }
            default: { 
              this.utilitarioService.toastrInfo(rucEntidadBeanMResponse.mensajeResponseBeanM.descripcion);
              this.flagBuscarRucVerificado = "0";
              this.txtRazonSocialModel = "";
              break;  

            }
          }

          this.txtPersonaNombres = "";
          this.txtPersonaApellidoPaterno= "";
          this.txtPersonaApellidoMaterno = "";

          this.utilitarioService.loading=false;

    });

  }

}
