import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";
import { environment } from '../../../environments/environment';
import { AnioAdminListResponse } from '../../modal/anio/anioAdminListResponse';
import { AnioAdminRegIdResponse } from '../../modal/anio/anioAdminRegIdResponse';
import { AnioAdminDmlResponse } from '../../modal/anio/anioAdminDmlResponse';
import { AnioAdminBeanInsRequest,AnioAdminBeanUpdRequest } from '../../modal/anio/anioAdminBean';
import { HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AnioService {

  constructor(private httpClient:HttpClient) { }

  obtenerAdminList(nombre:string,estado:string){
    let url = `${environment.urlBase}${environment.listAnioAdmin}?nombre=${nombre}&estado=${estado}`;
    //console.log("url",url);
    return this.httpClient.get<AnioAdminListResponse>(url).pipe(
      map(data => {   
        let response: AnioAdminListResponse;
        response = data;
        return response;
      },
      error =>{      
        return new Response("9999", error);
      })) 
  }

  obtenerRegistro(id:String){
    let url = `${environment.urlBase}${environment.getAnioAdminId}/${id}`;
    //console.log("url",url);
    return this.httpClient.get<AnioAdminRegIdResponse>(url).pipe(
      map(data => {   
        let response: AnioAdminRegIdResponse;
        response = data;
        return response;
      },
      error =>{      
        return new Response("9999", error);
      })) 
  }

  grabar(anioAdminBeanInsRequest:AnioAdminBeanInsRequest){
    let url = `${environment.urlBase}${environment.insertAnioAdmin}`;
    return this.httpClient.post<AnioAdminDmlResponse>(url,anioAdminBeanInsRequest).pipe(
      map(data => {
        let response: AnioAdminDmlResponse;
        response = data;        

        return response;        
      },
      error =>{      
        return new Response("9999", error);
      }) 
    )
  }

  update(anioAdminBeanUpdRequest:AnioAdminBeanUpdRequest,id:String){
    let url = `${environment.urlBase}${environment.updateAnioAdmin}/${id}`;
    return this.httpClient.put<AnioAdminDmlResponse>(url,anioAdminBeanUpdRequest).pipe(
      map(data => {
        let response: AnioAdminDmlResponse;
        response = data;        
        return response;        
      },
      error =>{      
        return new Response("9999", error);
      }) 
    )
  }

}
