import { Component, OnInit } from '@angular/core';
import { UsuarioService } from 'src/app/service/usuario/usuario.service';
import { UtilitarioService } from 'src/app/service/utilitarios/utilitario.service';
import { UsuarioAdminRequest } from 'src/app/modal/usuario/usuarioAdminRequest';
import { UsuarioAdminResponse } from 'src/app/modal/usuario/usuarioAdminResponse';
import { ActivatedRoute,Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-login-admin',
  templateUrl: './login-admin.component.html',
  styleUrls: ['./login-admin.component.scss']
})
export class LoginAdminComponent implements OnInit {

  constructor(private usuarioService:UsuarioService,
    private utilitarioService:UtilitarioService,
    private router : Router,) { }
  
    txtLoginAdmin:String = "";
    txtPasswordAdmin:String = "";

    valFormLogin: FormGroup;
    
  get txtUsuario() {return this.valFormLogin.get('txtUsuarioVal');}
  get txtclave() {return this.valFormLogin.get('txtClaveVal');}

  ngOnInit(): void {
    this.valFormLogin = new FormGroup({
      txtUsuarioVal: new FormControl('', Validators.required),
      txtClaveVal: new FormControl('', Validators.required)      
    });
  }

  onIngresar(){

    if (this.valFormLogin.valid){

      let usuarioAdminRequest = new UsuarioAdminRequest();
      this.utilitarioService.loading=true;
      usuarioAdminRequest.domain = this.txtLoginAdmin;
      usuarioAdminRequest.password = this.txtPasswordAdmin;

      //console.log(this.txtLoginAdmin);
      //console.log(usuarioAdminRequest);

      this.usuarioService.loginUserAdmin(usuarioAdminRequest).subscribe(data=>{      
        
        if (data.response.codeResponse=="0000"){
          this.router.navigate(['/dasboard/bandejapedidos']);
        }else{
          this.utilitarioService.toastrError(data.response.response);
        }
        this.utilitarioService.loading=false;
      });

    }else{    
      this.valFormLogin.markAllAsTouched();
    } 

  }

}
