import { LibroDetalleBean } from 'src/app/modal/catalogo/libro-detalle-bean';

export class LibroDetalle {
   httpStatus : number;
   response : {
     id : string  ,
     codeResponse :  string ,
     response :  string ,
     list : LibroDetalleBean[]
  }
  constructor(){
    this.httpStatus = 0;
    this.response = {
      id : ""  ,
      codeResponse :  "" ,
      response :  "" ,
      list : []
  }
}
}
