export class OrderDetalleResponse{
    httpStatus: String;
    response: {
        id: String,
        codeResponse: String,
        response: String,
        list: [{
            orderDetailId: String,
            bookId: String,
            bookImage: String,
            bookTitle: String,
            bookSubtitle: String,
            bookAuthorId: String,
            bookAuthorName: String,
            bookMaterialId: String,
            bookMaterialName: String,
            bookYearId: String,
            bookYearName: String,
            bookPrice: String,
            orderDetailQuantity: String,
            orderDetailSubtotal: String,
            bookSizeId:String,
            bookSizeName:String,
        }]
    }

    constructor(){
        this.httpStatus="0";
        this.response={
            id:"",
            codeResponse:"",
            response:"",
            list:[{
                orderDetailId: "",
                bookId: "",
                bookImage: "",
                bookTitle: "",
                bookSubtitle: "",
                bookAuthorId: "",
                bookAuthorName: "",
                bookMaterialId: "",
                bookMaterialName: "",
                bookYearId: "",
                bookYearName: "",
                bookPrice: "",
                orderDetailQuantity: "",
                orderDetailSubtotal: "",
                bookSizeId:"",
                bookSizeName:"",
            }]
        }
      }
      
}