export class OrderCabeceraResponse {

    httpStatus:number;
    response: {
      id:string,
      codeResponse:string,
      response:string,
      list: [{
        id: String,
        date: String,
        books: String,
        number: String,
        quantity: String,
        subtotal: String,
        shipping: String,
        igv: String,
        total: String,
        stateId: String,
        stateName: String,
        voucherType: String,
        voucherNro: String,
        deliveryType: String,
        regionType: String,
        countryName: String,
        countryAddress: String
      }]
    }    

    constructor(){
      this.httpStatus=0;
      this.response={
          id:"",
          codeResponse:"",
          response:"",
          list:[{
            id: "",
            date: "",
            books: "",
            number: "",
            quantity: "",
            subtotal: "",
            shipping: "",
            igv: "",
            total: "",
            stateId: "",
            stateName: "",
            voucherType: "",
            voucherNro: "",
            deliveryType: "",
            regionType: "",
            countryName: "",
            countryAddress: ""
          }]
      }
    }
    
}