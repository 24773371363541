<div class="container">
    <div class="row">
        <div class="col-sm-12 mt-5"></div>   
            
        <div class="col-sm-8">

                <div *ngFor="let libro of carritoCompras[0];let i = index;"> 
                    <div class="row">
                       
                        <div class="col-sm-4 pb-5" >                       
                            <img class="z-depth-1" width="80%" src="{{libro.lista[0].image}}">
                        </div>
                        <div class="col-sm-8 pb-5">
                            <span class="font-weight-bold">{{libro.lista[0].title}}</span><br>
                            <span>{{libro.lista[0].subtitle}}</span><br>
                            <span *ngIf="libro.lista[0].size.length>0">Talla: {{libro.lista[0].sizeName}}<br></span>
                            <span>S/ {{libro.lista[0].price}}</span><br><br>
                            <div class="row" *ngIf="carritoDetalle.lista[i].cantidad>0">
                                <div class="col-sm-2">
                                    <span>Cantidad:</span>
                                </div>
                                <div class="col-sm-10">
                                    <input mdbInput type="number" style="width: 60px;" class="form-control" id="txtCantidad" onkeypress="return event.charCode >= 48" (change) = "frecalculartotal(i,$event.target.value,libro.lista[0].quantity);" min="1" max="{{libro.lista[0].quantity}}" [(ngModel)]="carritoDetalle.lista[i].cantidad">
                                </div>
                            </div>
                            <div class="alert alert-danger" *ngIf="carritoDetalle.lista[i].cantidad==0" role="alert">
                                Producto sin Stock
                             </div>
                            <br>
                            <div class="row">
                                <div class="col-sm-12" style="padding-right: 20px;text-align: right;">                                    
                                    <button class="btn btn-rounded btn-flat btn-sm" (click)="feliminarCarrito(i);"><i class="fas fa-trash fa-lg"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
        </div>
        <div class="col-sm-4">
            <div class="card">
                <h5 class="card-header h5">Total a Pagar</h5>
                <div class="card-body">                  
                    S/<span class="precio">{{precioTotal | number:'1.2-2'}}</span>
                  <br>
                  <a *ngIf="carritoCompras[0]" class="btn btn-comprar w-100" (click)="procesarPago()"><mdb-icon fas icon="shopping-bag"></mdb-icon> Pagar</a>                  
                  <a *ngIf="!carritoCompras[0]" class="btn btn-comprar w-100 disabled" ><mdb-icon fas icon="shopping-bag"></mdb-icon> Pagar</a>                  

                </div>                
            </div>
        </div>
    </div>
</div>