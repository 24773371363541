<!--Header-->
<div class="modal-header text-center" style="color: white;background-color: #544d84">    
    <h4 class="modal-title w-100">{{x_titulo}}</h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onClosemodal()">
      <span aria-hidden="true" class="white-text">&times;</span>
    </button>
</div>
  
<!--Body-->
<div class="modal-body">

  <mdb-card>
    <mdb-card-body>

    <div class="form-row">
      <div class="col-3">Código:</div>
      <div class="col-9">
          {{id}}
      </div>
    </div>
    <br>
    <div class="form-row">
      <div class="col-3">Descripción:</div>
      <div class="col-9">                
        <input mdbInput type="text" maxlength="200" class="form-control" [(ngModel)]="txtDescription" placeholder="Ingrese descripción">
      </div>
    </div>
    <br>
    <div class="form-row">
      <div class="col-3">Grupo de Regalo:</div>
      <div class="col-9">                
          <select class="browser-default custom-select" [(ngModel)]="cboSubmenu">
              <option value="" selected>Seleccione</option>
              <option *ngFor="let submenu of listSubmenu.response.list" [value]="submenu.id">{{submenu.name}}</option>
          </select>
      </div>
    </div>
    <br>
    <div class="form-row">
      <div class="col-3">Orden:</div>
      <div class="col-9">                
        <input mdbInput type="number" pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==3) return false;" min="1" max="999" class="form-control" [(ngModel)]="txtOrder" id="txtOrder">
      </div>
    </div>
    <br>
    <div class="form-row">
      <div class="col-3">Estado:</div>
      <div class="col-9">                
          <select class="browser-default custom-select" [(ngModel)]="cboEstado">
              <option value="" selected>Seleccione</option>
              <option *ngFor="let estado of listEstado.response.list" [value]="estado.masterName">{{estado.masterDescription}}</option>
          </select>
      </div>
    </div>
    <br>
    </mdb-card-body>
  </mdb-card>
  <br>  
  <mdb-card>
    <mdb-card-body>
      <span style="font-weight: 500;font-size: 16px;">Mostrar Controles</span>
      <hr>      
      <div class="form-row form-group">
        <div class="col-3">Gramaje de Papel:</div>
        <div class="col-2">
            <div class="px-4">
                <mdb-checkbox [(ngModel)]="productoControles.showLibretaGrosorPapel" class="check-input"></mdb-checkbox>
            </div>
        </div>
        <div class="col-2"></div>
        <div class="col-3">Nro. de Paginas:</div>
        <div class="col-2">
            <div class="px-4">
                <mdb-checkbox [(ngModel)]="productoControles.showLibretaNroPaginas" class="check-input"></mdb-checkbox>
            </div>        
        </div>
      </div>
      <div class="form-row form-group">
        <div class="col-3">Tipo de Papel:</div>
        <div class="col-2">
            <div class="px-4">
                <mdb-checkbox [(ngModel)]="productoControles.showLibretaPapel" class="check-input"></mdb-checkbox>
            </div>
        </div>
        <div class="col-2"></div>
        <div class="col-3">Material:</div>
        <div class="col-2">
            <div class="px-4">
                <mdb-checkbox [(ngModel)]="productoControles.showMaterial" class="check-input"></mdb-checkbox>
            </div>
        </div>
      </div>      
      <div class="form-row form-group">
        <div class="col-3">Comentario:</div>
        <div class="col-2">
            <div class="px-4">
                <mdb-checkbox [(ngModel)]="productoControles.showProductComentario" class="check-input"></mdb-checkbox>
            </div>
        </div>
        <div class="col-2"></div>
        <div class="col-3">Descripcion:</div>
        <div class="col-2">
            <div class="px-4">
                <mdb-checkbox [(ngModel)]="productoControles.showProductDescripcion" class="check-input"></mdb-checkbox>
            </div>
        </div>
      </div>      
      <div class="form-row form-group">
        <div class="col-3">Peso:</div>
        <div class="col-2">
            <div class="px-4">
                <mdb-checkbox disabled [(ngModel)]="productoControles.showProductPeso" class="check-input"></mdb-checkbox>
            </div>
        </div>
        <div class="col-2"></div>
        <div class="col-3">Subtitulo:</div>
        <div class="col-2">
            <div class="px-4">
                <mdb-checkbox [(ngModel)]="productoControles.showProductSubtitulo" class="check-input"></mdb-checkbox>
            </div>
        </div>
      </div>      
      <div class="form-row form-group">
        <div class="col-3">Tamaño :</div>
        <div class="col-2">
            <div class="px-4">
                <mdb-checkbox [(ngModel)]="productoControles.showProductTamanioSize" class="check-input"></mdb-checkbox>
            </div>
        </div>
        <div class="col-2"></div>
        <div class="col-3">Edad :</div>
        <div class="col-2">
            <div class="px-4">
                <mdb-checkbox [(ngModel)]="productoControles.showProductTiempoVidaParaEdades" class="check-input"></mdb-checkbox>
            </div>
        </div>
      </div>
      <div class="form-row form-group">
        <div class="col-3">Nombre / Titulo :</div>
        <div class="col-2">
            <div class="px-4">
                <mdb-checkbox disabled [(ngModel)]="productoControles.showProductTitulo" class="check-input"></mdb-checkbox>
            </div>
        </div>
        <div class="col-2"></div>
        <div class="col-3">Unidad de Medida :</div>
        <div class="col-2">
            <div class="px-4">
                <mdb-checkbox [(ngModel)]="productoControles.showProductUnidadesMeasure" class="check-input"></mdb-checkbox>
            </div>
        </div>
      </div>
      <div class="form-row form-group">
        <div class="col-3">Talla :</div>
        <div class="col-2">
            <div class="px-4">
                <mdb-checkbox [(ngModel)]="productoControles.showTalla" class="check-input"></mdb-checkbox>
            </div>
        </div>        
      </div>
    </mdb-card-body>
  </mdb-card>

</div>
  <!--Footer-->
<div class="modal-footer justify-content-center">
    <button mdbBtn type="button" (click)="onGrabar()" size="sm" class="btn btn-deep-purple btn-rounded" mdbWavesEffect>
        <mdb-icon fas icon="save" class="mr-1"></mdb-icon>Grabar</button>
</div>


