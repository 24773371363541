import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CatalogoResponse } from 'src/app/modal/catalogo/catalogo-response';

import { ResponseComun } from 'src/app/modal/catalogo/response-comun';
import {RespuestaComun} from 'src/app/modal/maestras/respuesta-comun';
import { NovedadesService } from 'src/app/service/catalogo/novedades.service';
import {MaestrasService} from 'src/app/service/maestras/maestras.service';
import {CatalogoRequest} from 'src/app/modal/catalogo/catalogo-request';
import { UtilitarioService } from 'src/app/service/utilitarios/utilitario.service';
import {ResponseSourvenirComun} from 'src/app/modal/maestras/response-sourvenir-comun';
import { ActivatedRoute, ParamMap } from '@angular/router';
import {CatalogoSourvenirRequest} from 'src/app/modal/catalogo/catalogo-sourvenir-request';
import {CatalogoSourvenirResponse} from 'src/app/modal/catalogo/catalogo-sourvenir-response';


@Component({
  selector: 'app-catalogo-sourvenir',
  templateUrl: './catalogo-sourvenir.component.html',
  styleUrls: ['./catalogo-sourvenir.component.scss']
})
export class CatalogoSourvenirComponent implements OnInit {
  listAutor:ResponseComun;
  listCategoria:ResponseComun;
  listAno:ResponseComun;
  listTipoPubli:RespuestaComun;

  catalogoResquest:CatalogoSourvenirRequest;
  listProductos:CatalogoSourvenirResponse;

  listGroupXIDSourvenir:ResponseSourvenirComun;
  tipoSourvenir:String;
  idTema:string;
  filtrosForm: FormGroup;
  page = 1;
  pageSize = 9;
  cargaPagina = 1;
  constructor(private fb: FormBuilder,private novedadesService:NovedadesService,private maestraService:MaestrasService,private utilitarioService:UtilitarioService,private route: ActivatedRoute) { 
    this.createForm();
  }

  createForm(){
    this.filtrosForm = this.fb.group({      
      txtCategoria:[''],  
    
    });
  }    
  buscarLibros(){
    this.utilitarioService.loading = true;
    this.catalogoResquest = new CatalogoSourvenirRequest();

    
    this.listProductos = new CatalogoSourvenirResponse();
    this.catalogoResquest.groupId = "0";
    this.catalogoResquest.page = "0";
    this.catalogoResquest.size = "0";
    this.catalogoResquest.submenuId = this.tipoSourvenir; 
    this.catalogoResquest.topicId = "0";



    if(this.filtrosForm.get("txtCategoria").value.id){
      this.catalogoResquest.groupId = this.filtrosForm.get("txtCategoria").value.id;
    }
    console.log("catalogoResquest2",this.catalogoResquest);
    this.novedadesService.buscarCatalogoSourvenir (this.catalogoResquest).subscribe(data=>{
      this.listProductos=data;        
      this.utilitarioService.loading = false;
      this.page = this.maestraService.obtenerPaginaSourvenir(this.idTema,this.tipoSourvenir);
      console.log("NrmPagina",this.page);
      this.cargaPagina = this.cargaPagina + 1;
    })


  
  }

  buscarLibrosTema(){
    this.utilitarioService.loading = true;
    this.catalogoResquest = new CatalogoSourvenirRequest();

    
    this.listProductos = new CatalogoSourvenirResponse();
    this.catalogoResquest.groupId = "0";
    this.catalogoResquest.page = "0";
    this.catalogoResquest.size = "0";
    this.catalogoResquest.submenuId = this.tipoSourvenir; 
    this.catalogoResquest.topicId = "0";
    console.log("thiID",this.idTema);
    if(this.idTema!=null){
      if(this.idTema=="1"){
        this.catalogoResquest.topicId = "1";
        this.filtrosForm.get("txtCategoria").setValue("Somos libres, leamos siempre");
      }
      else if(this.idTema=="2"){
        this.catalogoResquest.topicId = "2";
        this.filtrosForm.get("txtCategoria").setValue("Justo Apu Sahuaraura Inca");

      }
      else if(this.idTema=="3"){
        this.catalogoResquest.topicId = "3";
        this.filtrosForm.get("txtCategoria").setValue("Acuarelas de José María Eguren");

      }
      else if(this.idTema=="5"){
        this.catalogoResquest.topicId = "5";
        this.filtrosForm.get("txtCategoria").setValue("Ilustraciones de Fondo Antiguo");

      }
    }

    if(this.filtrosForm.get("txtCategoria").value.id){
      this.catalogoResquest.groupId = this.filtrosForm.get("txtCategoria").value.id;      
    }


    console.log("catalogoResquest",this.catalogoResquest);
    this.novedadesService.buscarCatalogoSourvenir (this.catalogoResquest).subscribe(data=>{
      this.listProductos=data;        
      this.utilitarioService.loading = false;
      this.page = this.maestraService.obtenerPaginaSourvenir(this.idTema,this.tipoSourvenir);
      console.log("NrmPagina",this.page);

      this.cargaPagina = this.cargaPagina + 1;

    })


  
  }



  buscarLibrosTemas(){
    this.utilitarioService.loading = true;
    this.catalogoResquest = new CatalogoSourvenirRequest();

    
    this.listProductos = new CatalogoSourvenirResponse();


    this.catalogoResquest.groupId = "0";
    this.catalogoResquest.page = "0";
    this.catalogoResquest.size = "0";
    this.catalogoResquest.submenuId = this.tipoSourvenir; 
    this.catalogoResquest.topicId = "0";


    if(this.tipoSourvenir!='6'){
      if(this.filtrosForm.get("txtCategoria").value.id){
        this.catalogoResquest.groupId = this.filtrosForm.get("txtCategoria").value.id;
      }
    }
    else{
      if(this.filtrosForm.get("txtCategoria").value.id){        
        this.catalogoResquest.topicId = this.filtrosForm.get("txtCategoria").value.id;

      }
    }




    console.log("catalogoResquest",this.catalogoResquest);
    this.novedadesService.buscarCatalogoSourvenir (this.catalogoResquest).subscribe(data=>{
      this.listProductos=data;        
      this.utilitarioService.loading = false;
      this.page = 1;
      console.log("NrmPagina",this.page);

      this.cargaPagina = this.cargaPagina + 1;

    })


  
  }

  obtenerGrupoXIDSourvenir(idSourvenir){
    this.listGroupXIDSourvenir= new ResponseSourvenirComun();
    this.novedadesService.obtenerGrupoProductoSourvenir(idSourvenir).subscribe(data=>{
      this.listGroupXIDSourvenir = data;
      console.log(data);
    })
  }

  obtenerListaTemasSourvenir(){
    this.listGroupXIDSourvenir= new ResponseSourvenirComun();
    this.novedadesService.obtenerListaTemasSourvenir().subscribe(data=>{
      this.listGroupXIDSourvenir = data;
      console.log(data);
    })
  }


  keyword = 'name'; 
  selectEvent(item) {
    // do something with selected item
    ////console.log("item",item.name);
  }
 
  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }
  
  onFocused(e){
    // do something when input is focused
  }

  cambiarPagina(pageNumber){
    console.log("cargaPagina",this.cargaPagina);

    console.log("pageNumber",pageNumber);
    console.log("tipoSourvenir",this.tipoSourvenir);
    console.log("idTema",this.idTema);
 
    if(this.cargaPagina>1){
        if(pageNumber){
          this.maestraService.guardarPaginacionSourvenir(pageNumber,this.tipoSourvenir,this.idTema);

        }
      
    }
  }


  ngOnInit(): void {


    this.route.paramMap.subscribe((params: ParamMap) => {
      this.filtrosForm.get("txtCategoria").setValue('');

      this.tipoSourvenir = params.get('idSourvenir');   
      this.idTema = params.get('idTema');   
      console.log("thiID",this.idTema);


      if(this.tipoSourvenir!='6'){
        this.obtenerGrupoXIDSourvenir(this.tipoSourvenir);
        this.buscarLibros();

      }
     
      else{
        if(this.idTema!=null){
          this.buscarLibrosTema();
        }
        else{
          this.buscarLibros();
        }
        this.obtenerListaTemasSourvenir();
       // this.buscarLibros();

      }
     
     });



  }

}
