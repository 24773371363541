export class NovedadesResponse {
     httpStatus : number;
     response : {
       id : string,
       codeResponse : string ,
       response : string,
       list : [
        {
           id :  string ,
           title :  string,
           subtitle :  string,
           author :  string,
           material :  string ,
           price :  string ,
           quantity :  string ,
           stock :  string ,
           image :  string
        }]
    }
    constructor(){
    this.httpStatus = 0;
    this.response = {
       id : "",
       codeResponse : "" ,
       response : "",
       list : [
        {
           id :  "" ,
           title :  "",
           subtitle :  "",
           author :  "",
           material :  "" ,
           price :  "" ,
           quantity :  "" ,
           stock :  "" ,
           image :  ""
        }]
    }
    } 
}
