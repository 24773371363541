import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import {LibroDetalle} from 'src/app/modal/catalogo/libro-detalle';
import { NovedadesResponse } from 'src/app/modal/catalogo/novedades-response';
import { NovedadesService } from 'src/app/service/catalogo/novedades.service';
import { CarritoService } from 'src/app/service/carrito/carrito.service';
import { UtilitarioService } from 'src/app/service/utilitarios/utilitario.service';
import { UsuarioService } from 'src/app/service/usuario/usuario.service';
import { CarritoHeader } from 'src/app/modal/carrito/carrito-header';

@Component({
  selector: 'app-detalle',
  templateUrl: './detalle.component.html',
  styleUrls: ['./detalle.component.scss']
})
export class DetalleComponent implements OnInit {
  idLibro:string;
  flgAgregar:string = "0";
  detalleLibro:LibroDetalle = new LibroDetalle();
  stockLibro=0;
  librosRecomendados:NovedadesResponse;
  cantidadLibro:number=1;
  precioTotal:number=1;
  Arr = Array;
  constructor(  private route: ActivatedRoute,
    private novedadesService:NovedadesService ,
    private router : Router,
    private carritoService:CarritoService ,
    private usuarioService:UsuarioService,
    private utilitarioService: UtilitarioService) {
    this.idLibro=this.route.snapshot.params.idLibro
  }

  obtenerDetalleLibro(id){
    
    if (this.cantidadLibro<1){
      this.cantidadLibro = 0;
    }

    if(this.cantidadLibro>10){
      this.cantidadLibro = 10;
      this.utilitarioService.toastrInfo("cantidad maxíma : " + "10");
    }

    this.detalleLibro = new LibroDetalle();
    this.utilitarioService.loading=true;
    if(this.cantidadLibro>0){
      
     

      this.novedadesService.obtenerDetalleLibro(id).subscribe(data=>{

        // if(this.cantidadLibro>Number(this.detalleLibro.response.list[0].stock)){
        //   this.utilitarioService.toastrWarning("Cantidad Mayor a Stock");
        //   return false;
        // }

        this.detalleLibro = data;
        console.log(data);

        
        this.precioTotal = Number((this.cantidadLibro * Number(this.detalleLibro.response.list[0].price)).toFixed(2));
  
        if(Number(this.detalleLibro.response.list[0].quantity)<10){
          this.stockLibro = Number(this.detalleLibro.response.list[0].quantity);
        }

      
        else{
          this.stockLibro = 10;          
        }

        
        if(this.detalleLibro.response.list[0].stock=="0"){
          this.cantidadLibro = Number(this.detalleLibro.response.list[0].stock);
        }


        this.utilitarioService.loading=false;

      })
    }

    else{
      this.cantidadLibro=0;
    }
    
  }

  obtenerDetalleLibroAgregarLibros(id){
    
    console.log("cantidadLibro",this.cantidadLibro,Number(this.detalleLibro.response.list[0].quantity));
    if(this.cantidadLibro>Number(this.detalleLibro.response.list[0].quantity) || this.cantidadLibro==0){
      this.utilitarioService.toastrWarning("Cantidad debe ser estar entre 1 y "+this.stockLibro);
      this.cantidadLibro = this.stockLibro;
      return false;
    }
   
    if (this.cantidadLibro<1){
      this.cantidadLibro = 0;
    }

    if(this.cantidadLibro>10){
      this.cantidadLibro = 10;
      this.utilitarioService.toastrInfo("cantidad maxíma : " + "10");
    }
  


    this.detalleLibro = new LibroDetalle();
    this.utilitarioService.loading=true;
    if(this.cantidadLibro>0){
      
  


      this.novedadesService.obtenerDetalleLibro(id).subscribe(data=>{

     
        this.detalleLibro = data;
        console.log(data);

        
        this.precioTotal = Number((this.cantidadLibro * Number(this.detalleLibro.response.list[0].price)).toFixed(2));
  
        if(Number(this.detalleLibro.response.list[0].quantity)<10){
          this.stockLibro = Number(this.detalleLibro.response.list[0].quantity);
        }

      
        else{
          this.stockLibro = 10;          
        }

        
        if(this.detalleLibro.response.list[0].stock=="0"){
          this.cantidadLibro = Number(this.detalleLibro.response.list[0].stock);
        }


        this.utilitarioService.loading=false;

      })
    }

    else{
      this.cantidadLibro=0;
    }
    
  }





  obtenerLibrosRecomendados(id){
    this.librosRecomendados = new NovedadesResponse();
    this.novedadesService.obtenerLibrosRecomendados(id).subscribe(data=>{
      this.librosRecomendados = data;
    })
  }
  slideConfig = {"slidesToShow": 4, "slidesToScroll": 1,"responsive": [
    {
      'breakpoint': 600,
      'settings': {
        'slidesToShow': 1
              }
    }
      
    
  ]};
  cargarDetalleLibro(id){    
    this.obtenerDetalleLibro(id);
    this.obtenerLibrosRecomendados(id);
  }
  
  agregarCarrito(){
    this.flgAgregar = "1";
    let idLibro = this.detalleLibro.response.list[0].id;
    let cantidad = this.cantidadLibro;
    let tipoProducto = "1";
    let sizeID ="0";

    let libroArray = {};
    libroArray ={id:idLibro,cantidad:cantidad,tipoProducto:tipoProducto,sizeID:sizeID};

    let carritoSesionTemp = new CarritoHeader();
    carritoSesionTemp = this.carritoService.obtenerCarritoCompras();
    for(let i=0;i<carritoSesionTemp.lista.length;i++){
      if(carritoSesionTemp.lista[i].id==idLibro){
        let cantidadTemp = carritoSesionTemp.lista[i].cantidad;
        let cantidadSumaTemp = Number(cantidadTemp) + Number(cantidad);
        if(Number(cantidadSumaTemp)>this.stockLibro){
          this.utilitarioService.toastrWarning("El limite de stock es "+this.stockLibro);
          return false;
        }
      }
    }
    if(carritoSesionTemp.lista)


    this.carritoService.agregarCarrito(libroArray);
    this.utilitarioService.toastrSuccess('Agregado al carrito');
  }

  onComprar(){
    if(this.usuarioService.obtenerSesionUsuario()){
    let idLibro = this.detalleLibro.response.list[0].id;
    let cantidad = this.cantidadLibro;
    let tipoProducto = "1";
    let sizeID ="0";
    let libroArray = {};
    
    
    cantidad = this.cantidadLibro;

    this.carritoService.obtenerCantidadCarrito();
    
    libroArray ={id:idLibro,cantidad:cantidad,tipoProducto:tipoProducto,sizeID:sizeID};
      //si agrega y limpio el carrito
      if((this.flgAgregar == "1") && ( this.carritoService.cantidadCarrito == 0)){
        this.carritoService.obtenerCantidadCarrito();
        this.carritoService.agregarCarrito(libroArray);
        this.router.navigate(['/procesarpago']);
        return;
      }
      //si no agrega, adiciona automaticamente al carrito
      if(this.flgAgregar == "0"){
      this.carritoService.obtenerCantidadCarrito();
      this.carritoService.agregarCarrito(libroArray);
      this.router.navigate(['/procesarpago']);
      return;
      }
      //si flag es 1 y no entra en las 2 condiciiones anteriores
      this.router.navigate(['/procesarpago']);
    }
    else{
      this.utilitarioService.toastrWarning("Necesita iniciar sesión")
    }
  }  

  slickInit(e) {
    //console.log('slick initialized');
  }
  
  breakpoint(e) {
    //console.log('breakpoint');
  }
  
  afterChange(e) {
    //console.log('afterChange');
  }
  
  beforeChange(e) {
    //console.log('beforeChange');
  }

  ngOnInit(): void {
    this.obtenerDetalleLibro(this.idLibro);
    this.obtenerLibrosRecomendados(this.idLibro);
   // //console.log("this",this.idLibro)
  }

}
