export class RequestPayme{

    acquirerId:String;
    idCommerce:String;
    purchaseOperationNumber:String;
    purchaseAmount:String;
    purchaseCurrencyCode:String;
    language:String;
    shippingFirstName:String;
    shippingLastName:String;
    shippingEmail:String;
    shippingAddress:String;
    shippingZIP:String;
    shippingCity:String;
    shippingState:String;
    shippingCountry:String;
    shippingPhone:String;
    userCommerce:String;
    userCodePayme:String;
    descriptionProducts:String;
    programmingLanguage:String;
    purchaseVerification:String;
    
    }