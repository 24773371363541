<ng-sidebar-container> 
    <!-- left sidebar -->
    <ng-sidebar [(opened)]="opened" [mode]="'push'">
        <!-- Logo -->
            <br>
            <div style="text-align: center;">
                <img src="assets/image/admin/logo.png">
            </div>        
        <!--/. Logo -->
            <hr>
            <br>                        
            <ng-material-multilevel-menu [configuration]='config' [items]='appitems' (selectedItem)="selectedItem($event)">
            </ng-material-multilevel-menu>
                       
    </ng-sidebar>
    
    <div ng-sidebar-content>
        <mdb-navbar SideClass="navbar navbar-expand-lg navbar-light navradios" style="border-radius: 10px !important;" [containerInside]="false">
            <mdb-navbar-brand><a (click)="toggleSidebar()"><i class="fas fa-bars"></i></a></mdb-navbar-brand>
            <mdb-navbar-brand><img style="padding-left: 10px; width: 100px;" src="/assets/image/portafolio/Libreria-virtual-Logo.png" class="rounded float-left"></mdb-navbar-brand>
            <links>                        
              <ul class="navbar-nav ml-auto">
                <!-- Dropdown -->
                <li class="nav-item dropdown" dropdown>
                  <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
                    <mdb-icon fas icon="user"></mdb-icon> Cuenta<span class="caret"></span></a>
                  <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown dropdown-primary" role="menu">                    
                    <a class="dropdown-item waves-light" mdbWavesEffect (click)="fcSalir()">Salir</a>
                  </div>
                </li>
              </ul>              
            </links>          
        </mdb-navbar>
        <div class="container" style="padding-right: 25px;padding-left: 25px;">            
            <br>                        
            <router-outlet></router-outlet>
        </div>    
    </div>    
</ng-sidebar-container>
