<br>
<br>
<div class="container">
    <mdb-card [borderColor]="borderCard">
        <mdb-card-header>Mensaje de Operación</mdb-card-header>
        <mdb-card-body>
          <mdb-card-title>
            <h5 style="font-size: 16px;font-weight: 600;"><span [style.color]="colorMensaje">{{tituloRespuesta}}</span></h5>
          </mdb-card-title>
          <br>
          <div>            
            <mdb-card-text>
                <div class="row">
                    <div class="col-3">Número de orden :</div>
                    <div class="col">{{orderCabeceraResponse.response.list[0].number}}</div>
                </div>
                <div class="row">
                  <div class="col-3">Estado:</div>
                  <div class="col">{{orderCabeceraResponse.response.list[0].stateName}}</div>
                </div>
                <div class="row">
                  <div class="col-3">Fecha:</div>
                  <div class="col">{{orderCabeceraResponse.response.list[0].date}}</div>
                </div>
                <div class="row">
                  <div class="col-3">Región:</div>
                  <div class="col">{{orderCabeceraResponse.response.list[0].regionType}}</div>
                </div>
                <div class="row">
                  <div class="col-3">País:</div>
                  <div class="col">{{orderCabeceraResponse.response.list[0].countryName}}</div>
                </div>
                <div class="row">
                  <div class="col-3">Dirección:</div>
                  <div class="col">{{orderCabeceraResponse.response.list[0].countryAddress}}</div>
                </div>
                <br>
                <span style="font-size: 14px;">* Su orden se ha enviado vía correo electrónico, nos comunicaremos a la brevedad posible, a efectos de coordinar el medio y costo de envío al extranjero. Gracias por su pedido.</span>
            </mdb-card-text>            
           </div>
        </mdb-card-body>
    </mdb-card>
</div>
<br>
<br>
<br>

