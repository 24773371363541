import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InventLibAsignadosBeanRequest } from 'src/app/modal/inventario-libro/inventarioLibBean';

@Component({
  selector: 'app-confirmar',
  templateUrl: './confirmar.component.html',
  styleUrls: ['./confirmar.component.scss']
})

export class ConfirmarComponent implements OnInit {

  constructor(    
      public dialogo: MatDialogRef<ConfirmarComponent>,
      @Inject(MAT_DIALOG_DATA) public data: {mensaje: string, libros:InventLibAsignadosBeanRequest[]}) { }
  
      headElements = ['Libro/Producto','Concepto'];
      page = 1;
      pageSize = 3;

      cerrarDialogo(): void {
        this.dialogo.close(false);
      }
      confirmado(): void {
        this.dialogo.close(true);
      }
  
      ngOnInit() {
        console.log(this.data.libros);
      }
  
  }