import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsuarioService } from 'src/app/service/usuario/usuario.service';
import { Menu } from 'src/app/modal/menu/menu';
import { MultilevelMenuService, ExpandedRTL, ExpandedLTR } from 'ng-material-multilevel-menu';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(private router:Router,
    private usuarioService:UsuarioService) { }

  opened: boolean = true;

  appitems = [];
  menuOperador = [];
  menuAdmin = [];  

  config = {
    paddingAtStart: true,
    classname: 'navbarMenu',
    listBackgroundColor: 'rgb(65 82 179 / 0%)',
    fontColor: 'white',
    backgroundColor: 'rgb(65 82 179 / 0%)',
    selectedListFontColor: 'yellow',
  };

  ngOnInit(): void {

    let menu:Menu = new Menu();    
    
    if (this.usuarioService.existeSesionUsuarioAdmin()==false) return this.fcSalir();

    if (this.usuarioService.obtenerIDPerfilUsuarioAdmin() == "1" ) {

      this.menuAdmin = menu.menuAdmin;
      this.appitems = this.menuAdmin;

    }else{

      this.menuOperador = menu.menuOperador;
      this.appitems = this.menuOperador;

    }

  }
 
  toggleSidebar() {
    this.opened = !this.opened;
  }

  fcSalir(){    
    localStorage.clear(); 
    this.router.navigate(['/loginadmin']);    
  }

  selectedItem(selectedData) {
    console.log(selectedData);    
  }

}
