import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { UsuarioService } from 'src/app/service/usuario/usuario.service';
import { UtilitarioService } from 'src/app/service/utilitarios/utilitario.service';
import { MaestrasService } from 'src/app/service/maestras/maestras.service';
import { RespuestaComun } from 'src/app/modal/maestras/respuesta-comun';
import { ResponseComun } from 'src/app/modal/catalogo/response-comun';

import { SubmenuService } from 'src/app/service/submenu/submenu.service';
import { GrupoService } from 'src/app/service/grupo/grupo.service';
import { GrupoAdmBeanInsRequest,GrupoAdmBeanUpdRequest } from 'src/app/modal/grupo/grupoAdmBean';
import { ProductoControles } from 'src/app/modal/producto/productoBean';

@Component({
  selector: 'app-dml-grupos',
  templateUrl: './dml-grupos.component.html',
  styleUrls: ['./dml-grupos.component.scss']
})
export class DmlGruposComponent implements OnInit {

  constructor(public  modalRef: MDBModalRef,
    private maestrasService: MaestrasService,    
    private utilitarioService: UtilitarioService,
    private usuarioService: UsuarioService,
    private submenuService: SubmenuService,    
    private grupoService: GrupoService,    
    ) { }

  listEstado:RespuestaComun = new RespuestaComun();
  listSubmenu:ResponseComun = new ResponseComun();
  productoControles:ProductoControles = new ProductoControles();

  cboEstado:String = "1";
  cboSubmenu:String = "";
  
  //variables del formulario reactivo
  txtDescription:String= "";
  txtOrder:String = "";
  //variables enviado desde el padre
  id:String;  
  x_titulo:String;
  x_proceso:String;

  ngOnInit(): void {

      this.cargarDatoInicial(this.x_proceso);

  }

  onClosemodal(){
    this.modalRef.hide();
  }

  onGrabar(){

    if (this.validarFormulario()==false){      
      return false;
    }
    
    let grupoAdmBeanInsRequest = new GrupoAdmBeanInsRequest();
    let grupoAdmBeanUpdRequest = new GrupoAdmBeanUpdRequest();    

    if (this.x_proceso=='I'){

      this.utilitarioService.loading = true;
      this.rellenarBean(grupoAdmBeanInsRequest,grupoAdmBeanUpdRequest,this.x_proceso);
      console.log(JSON.stringify(grupoAdmBeanInsRequest));
      this.grupoService.grabar(grupoAdmBeanInsRequest).subscribe(data=>{        

        if (data.response.codeResponse=="0000"){
          this.utilitarioService.toastrSuccess("Se ha registrado satisfactoriamente.");
          (<HTMLButtonElement>document.getElementById("btnBuscar")).click();
        }else{
          this.utilitarioService.toastrError(data.response.response);
        }

        this.utilitarioService.loading = false;
        this.onClosemodal();
        
      });

    }else{

      this.utilitarioService.loading = true;
      
      this.rellenarBean(grupoAdmBeanInsRequest,grupoAdmBeanUpdRequest,this.x_proceso);

      this.grupoService.update(grupoAdmBeanUpdRequest,this.id).subscribe(data=>{        
        console.log(data);
        if (data.response.codeResponse=="0000"){
          this.utilitarioService.toastrSuccess("Se ha actualizado satisfactoriamente.");
          (<HTMLButtonElement>document.getElementById("btnBuscar")).click();
        }else{
          this.utilitarioService.toastrError(data.response.response);
        }        

        this.utilitarioService.loading = false;
        this.onClosemodal();
        
      });

    }    

  }

  cargarDatoInicial(dml:String){

    this.utilitarioService.loading = true;
    this.listEstado = new RespuestaComun();

    const cargarEstado = this.maestrasService.obtenerMaestra("TIPO_STATUS_TABLA").toPromise()
    .then(result => {
      this.listEstado = result;
    });

    this.listSubmenu = new ResponseComun();

    const cargarSubmenu = this.submenuService.obtenerListCombo().toPromise()
    .then(result => {
      this.listSubmenu = result;
    });

    if (dml=='I'){
      //carga los promesas culminadas para asignar los datos a las variables
      (<HTMLInputElement>document.getElementById("txtOrder")).disabled=true;
      Promise.all([cargarEstado,cargarSubmenu])
      .then(response => {
        this.utilitarioService.loading = false;
      })
      .catch(error => console.log(`Error in executing ${error}`));
    }

    if (dml=='U'){
      //carga los promesas culminadas para asignar los datos a las variables
      Promise.all([cargarEstado,cargarSubmenu])    
      .then(response => {      
        //obtener por id objeto
        this.grupoService.obtenerRegistro(this.id).subscribe(data=>{
          console.log(data);
          this.id = data.response.list[0].groupId;
          this.txtDescription = data.response.list[0].name;
          this.txtOrder = data.response.list[0].order;
          this.cboEstado = data.response.list[0].enabled;
          this.cboSubmenu = data.response.list[0].submenuId;
          this.productoControles = data.response.list[0].menuItems;
          this.utilitarioService.loading = false;          
        });
      })
      .catch(error => console.log(`Error in executing ${error}`));
    }
  }

  rellenarBean(grupoAdmBeanInsRequest:GrupoAdmBeanInsRequest,grupoAdmBeanUpdRequest:GrupoAdmBeanUpdRequest,dml:String){    
    

    if (dml=='I'){
      grupoAdmBeanInsRequest.order = '';
      grupoAdmBeanInsRequest.name =this.txtDescription;
      grupoAdmBeanInsRequest.description = this.txtDescription;
      grupoAdmBeanInsRequest.createdUserId = this.usuarioService.obtenerIDSessionUsuarioAdmin().toString();
      grupoAdmBeanInsRequest.submenuId = this.cboSubmenu;
      grupoAdmBeanInsRequest.menuItems = this.productoControles;
      grupoAdmBeanInsRequest.enabled = this.cboEstado;
    }else{
      grupoAdmBeanUpdRequest.order = this.txtOrder;
      grupoAdmBeanUpdRequest.submenuId = this.cboSubmenu;
      grupoAdmBeanUpdRequest.id = this.id;
      grupoAdmBeanUpdRequest.description =this.txtDescription;
      grupoAdmBeanUpdRequest.name = this.txtDescription;      
      grupoAdmBeanUpdRequest.updatedUserid = this.usuarioService.obtenerIDSessionUsuarioAdmin().toString();
      grupoAdmBeanUpdRequest.menuItems = this.productoControles;
      grupoAdmBeanUpdRequest.enabled = this.cboEstado;
    }        

  }

  validarFormulario() : Boolean{    
    //valida formulario
    if (this.usuarioService.obtenerIDSessionUsuarioAdmin().toString()==null){
      this.utilitarioService.toastrError("Su session ha culminado, ingrese nuevamente al sistema.");      
      return false;
    }

    //valida tipo de comprobante
    if(this.txtDescription==""){
      this.utilitarioService.toastrInfo("Ingrese descripción del grupo.");
      return false;
    }

    if(this.cboSubmenu==""){
      this.utilitarioService.toastrInfo("Seleccione el submenu del grupo.");
      return false;
    }

    if(this.cboEstado==""){
      this.utilitarioService.toastrInfo("Seleccione estado.");
      return false;
    }

    if(this.txtOrder=="" && this.x_proceso == 'U'){
      this.utilitarioService.toastrInfo("Ingre orden de año.");
      return false;
    }

    return true;

  }

  fc_ValNumber(event: any) {          
        const pattern = /[0-9\+\-\ ]/;
        let inputChar = String.fromCharCode(event.charCode);
    
        if (!pattern.test(inputChar)) {
          // invalid character, prevent input
          return false;
          event.preventDefault();
        }    
        return true;
  }

}
