import { Component, OnInit } from '@angular/core';
import { OrderPagoService } from 'src/app/service/orderpago/order-pago.service';
import { OrderCabeceraResponse } from 'src/app/modal/order-pago/orderCabeceraResponse';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilitarioService } from 'src/app/service/utilitarios/utilitario.service';

@Component({
  selector: 'app-resul-internacional',
  templateUrl: './resul-internacional.component.html',
  styleUrls: ['./resul-internacional.component.scss']
})
export class ResulInternacionalComponent implements OnInit {

  constructor(private orderPagoService:OrderPagoService,
    private utilitarioService:UtilitarioService,
    private route : ActivatedRoute,) { }

    idorden:String;
    idaplicativo:String;
    autorizathionResult:String;
    tituloRespuesta:String;
    orderCabeceraResponse:OrderCabeceraResponse = new OrderCabeceraResponse();
    colorMensaje:String = "";
    borderCard:String = "";

  ngOnInit(): void {

    this.tituloRespuesta = "Su número de orden se ha registrado satisfactoriamente.";
    this.borderCard = "border-success";
    this.colorMensaje = "#1cb71c";

    this.route.paramMap.subscribe(params => {
      this.idorden = params.get('nrorden');
      
      this.utilitarioService.loading=true;                  

      this.orderPagoService.obtenerOrderCabecera(this.idorden).subscribe(data=>{
        this.orderCabeceraResponse = data;
        //console.log(data);
        if(data.response.codeResponse=="0000"){
          //console.log(data);
        }else{          
        }

        this.utilitarioService.loading=false;

      });

    });

  }

}
