import { ConceptoPagoBean } from './concepto-pago-bean';

export class conceptoPagoListResponse{
    httpStatus: String;
    response: {
        id: String,
        codeResponse: String,
        response: String,
        list: Array<ConceptoPagoBean>
    }

    constructor(){
        this.httpStatus="0";
        this.response={
            id:"",
            codeResponse:"",
            response:"",
            list:[]
        }
      }
      
}