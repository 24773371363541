import { ProductoControles } from '../producto/productoBean';

export class GrupoAdmListBean{
    groupId: String;
    submenuId: String;
    name: String;
    description: String;
    order: String;
    enabled: String;
    status: String;
    createdUserId: String;
    updatedUserid: String;
}

export class GrupoAdmIdBean{
    groupId: String;
    submenuId: String;
    name: String;
    description: String;
    order: String;
    enabled: String;
    status: String;
    menuItems: ProductoControles;
    createdUserId: String;
    updatedUserid: String;    
}

export class GrupoAdmBeanInsRequest{
    createdUserId: String;
    description: String;
    enabled: String;
    id: String;
    name: String;
    order: String;
    submenuId: String;
    menuItems: ProductoControles;
}

export class GrupoAdmBeanUpdRequest{    
    createdUserId: String;
    description: String;
    enabled: String;
    id: String;
    name: String;
    order: String;
    status: String;
    submenuId: String;
    menuItems: ProductoControles;
    updatedUserid: String;
}