<div *ngIf="!flagLogin">
<div class="container groupImages" >
    <div class="row">
        <div class="col-sm-12">           
            <form [formGroup]="loginForm" class="text-center p-3 m-2" >
                <p class="text-center font-weight-bold">
                    -Iniciar Sesión-
                </p>              
                <!-- Contraseña -->
                <div class="md-form">
                    <input mdbInput type="text" id="txtUsuario" class="form-control"                        
                    [ngClass]="displayFieldCss('txtUsuario')" 
                    formControlName="txtUsuario">

                    <label for="txtUsuario" class="">Ingrese Correo</label>
                </div>                
                <!-- Password -->
                <div class="md-form">
                    <input mdbInput type="password" id="txtPass" 
                    [ngClass]="displayFieldCss('txtPass')" 
                    formControlName="txtPass"
                    class="form-control">

                    <label for="txtPass" class="">Ingrese contraseña</label>
                </div> 
                
                <div class="d-flex justify-content-around">                  
                    <div>
                      <!-- Forgot password -->
                      <a [routerLink]="['/recuperar-contrasena']">¿Olvido su contraseña?</a>
                    </div>
                  </div>            
                <!-- Sign in button -->
                <p></p>
                <a  class="btn btn-sm btnIngresar" (click)="login()" >Ingresar</a>
                <p></p>
                <div class="justify-content-around">                  
                    <div>
                      <!-- Forgot password -->
                      <span class="font-12">¿Eres Nuevo?</span><br> 
                      <a class="btn btn-sm btnPass font-weight-bold" [routerLink]="['/registrar']" >Crear una cuenta</a><br>
                      <!-- <span class="font-12">O ingresa con:</span><br>  -->
                    </div>
                  </div> 
                  <p></p>
                  
                    <!-- <a (click)="loginFacebook()"><img class="imgRedes1" src="/assets/image/portafolio/ic_face.png"> </a> -->
                    <a  (click)="loginGoogle()"><img class="imgRedes2" src="/assets/image/portafolio/ic_goog.png"> </a>
                  
            </form>
        </div>
    </div>
</div>

</div>
<div *ngIf="flagLogin">
    <div class="container" >
        <div class="row">
            <div class="col-sm-12 text-center p-3">
               Hola,<b>{{obtenerNombreUsuario()}}</b>
            </div> 
            <div class="col-sm-12 bqkRed p-3">
                <div class="w-50 float-left text-center">
                   <a [routerLink]="['/mi-cuenta']"><img width="40%" src="assets/image/portafolio/m1_1.png"> </a>
                </div>
                <div class="w-50 float-left text-center">
                  <a [routerLink]="['/mi-cuenta/mis-pedidos']">  <img width="50%" src="assets/image/portafolio/m2_2.png"> </a>

                </div><br>
                <div class="w-100 float-left text-center">
                    <br>
                    <a class="btn butonSalir" (click)="logout()">Cerrar Sesión</a>
                </div>
            </div>
        </div>
    </div>
</div>