import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { NovedadesResponse } from 'src/app/modal/catalogo/novedades-response';
import { NovedadesService } from 'src/app/service/catalogo/novedades.service';

@Component({
  selector: 'app-novedades-sourvenir',
  templateUrl: './novedades-sourvenir.component.html',
  styleUrls: ['./novedades-sourvenir.component.scss']
})
export class NovedadesSourvenirComponent implements OnInit {

  novedades:NovedadesResponse;
  tipoSourvenir="";

  constructor(private novedadesService:NovedadesService,private route: ActivatedRoute) { }

  obtenerNovedades(idSourvenir){
    this.novedades = new NovedadesResponse();
    this.novedadesService.obtenerNovedadesSourvenir(idSourvenir).subscribe(data=>{
      this.novedades = data;
    })
  }



  slides = [
    {img: "http://placehold.it/350x150/000000"},
    {img: "http://placehold.it/350x150/111111"},
    {img: "http://placehold.it/350x150/333333"},
    {img: "http://placehold.it/350x150/666666"},
    {img: "http://placehold.it/350x150/333333"},
    {img: "http://placehold.it/350x150/666666"}
  ];
  slideConfig = {"slidesToShow": 4, "slidesToScroll": 1,"responsive": [
    {
      'breakpoint': 600,
      'settings': {
        'slidesToShow': 1
              }
    }
      
    
  ]};
  
  addSlide() {
    this.slides.push({img: "http://placehold.it/350x150/777777"})
  }
  
  removeSlide() {
    this.slides.length = this.slides.length - 1;
  }
  
  slickInit(e) {
    //console.log('slick initialized');
  }
  
  breakpoint(e) {
    //console.log('breakpoint');
  }
  
  afterChange(e) {
    //console.log('afterChange');
  }
  
  beforeChange(e) {
    //console.log('beforeChange');
  }
  ngOnInit(): void {
    //this.obtenerNovedades();

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.tipoSourvenir = params.get('idSourvenir');
      console.log(this.tipoSourvenir);
      this.obtenerNovedades(this.tipoSourvenir);
  });
  }


}
