<div class="container">
    <div class="row" *ngIf="detalleLibro.response.list.length != 0">
        <div class="col-sm-12 mt-5">
            <a [routerLink]="['/']"><< Regresar</a>
        </div>
        <div class="col-sm-4">
            <div class="divImagenPortada">
                <!-- <img width="100%" src="{{detalleLibro.response.list[0].image}}"> -->

                <lib-ngx-image-zoom
                [thumbImage]=detalleLibro.response.list[0].image
                width="100%"
                [zoomMode]="hover"
                [magnification]="0.3"
                [scrollStepSize]="0.1"
                [enableScrollZoom]="true"
                [enableLens]="false"
                [lensWidth]="100"
            ></lib-ngx-image-zoom>


            </div>
        </div>
        <div class="col-sm-5">
            <div class="datosLibro">
                <p>
                    <span class="font-weight-bold h3-responsive">{{detalleLibro.response.list[0].title}}</span><br>
                    <span class="font-weight-bold">{{detalleLibro.response.list[0].subtitle}}</span>
                </p>
                <p>
                    <ngx-text-expander
                            text="{{detalleLibro.response.list[0].description}}"
                        [max-char-count]="150"
                        less-text="Ver menos"
                        more-text="Ver más"
                        [show-ellipse]="true"> 
                    </ngx-text-expander>
                    <!-- <span class="detalle"><span class="font-weight-bold">{{detalleLibro.response.list[0].description}}</span></span> -->
                </p>
                    <ul>

                        <li><span class="font-weight-bold">Categoría: </span>{{detalleLibro.response.list[0].categoryName}}</li>
                        <li><span class="font-weight-bold">Tipo publicación: </span>{{detalleLibro.response.list[0].publicationName}}</li>
                        <li><span class="font-weight-bold">Tipo de material: </span>{{detalleLibro.response.list[0].materialName}}</li>
                        <li><span class="font-weight-bold">Autor: </span>{{detalleLibro.response.list[0].authorName}}</li>
                        <li><span class="font-weight-bold">Año: </span>{{detalleLibro.response.list[0].yearName}}</li>
                        <li><span class="font-weight-bold">Páginas: </span>{{detalleLibro.response.list[0].pages}}</li>
                        <li><span class="font-weight-bold">Dimensiones: </span>{{detalleLibro.response.list[0].measure}}</li>
                        <li><span class="font-weight-bold">Peso: </span>{{detalleLibro.response.list[0].weightView}} g</li>
                    </ul>
                    <p *ngIf="detalleLibro.response.list[0].stock>0">
                        <span>Cantidad</span>
                    </p>        
                                
                    <input *ngIf="detalleLibro.response.list[0].stock>0" class="form-control w-auto" type="number" onkeypress="return event.charCode >= 48" (change)="obtenerDetalleLibroAgregarLibros(detalleLibro.response.list[0].id);" [(ngModel)]="cantidadLibro" min="0" max="{{stockLibro}}">
            </div>
        </div>
        <div class="col-sm-3">
            <div class="divPrecio">
                <div class="alert alert-danger" *ngIf="detalleLibro.response.list[0].stock==0" role="alert">
                   Producto sin Stock
                </div>

                <p class="text-right" *ngIf="detalleLibro.response.list[0].stock>0">
                    <span class="precio">S/ {{precioTotal | number:'1.2-2'}}</span>
                </p>
                 <a  *ngIf="detalleLibro.response.list[0].stock>0" class="btn btn-carrito w-100" (click)="agregarCarrito()"><mdb-icon fas icon="shopping-cart"></mdb-icon> Agregar al Carrito</a> <br><br>
                <a  *ngIf="detalleLibro.response.list[0].stock>0" class="btn btn-comprar w-100" (click)="onComprar()"><mdb-icon fas icon="shopping-bag"></mdb-icon> Comprar</a>
                
                <br><br><br><br>  
                <p>
                    <span>Comparte en:</span><br><br>
                        <a class="btnRedSocial" target="_blank" href="http://www.facebook.com/sharer.php?u=https://libreriavirtual.bnp.gob.pe%2F%23%2Fdetalle/{{detalleLibro.response.list[0].id}}"><mdb-icon fab icon="facebook-f"></mdb-icon></a> &nbsp;
                        <a class="btnRedSocial" target="_blank" href="https://twitter.com/intent/tweet?text=Libro&url=https://libreriavirtual.bnp.gob.pe%2F%23%2Fdetalle/{{detalleLibro.response.list[0].id}}"><mdb-icon fab icon="twitter"></mdb-icon></a> &nbsp;
                        <a class="btnRedSocial" target="_blank" href="https://api.whatsapp.com/send?text=https://libreriavirtual.bnp.gob.pe%2F%23%2Fdetalle/{{detalleLibro.response.list[0].id}}"><mdb-icon fab icon="whatsapp"></mdb-icon></a>
                </p>

            </div>
        </div>
        <div class="col-sm-12 p-5 mt-5 mb-5">
            <p>
                <span class="h4-responsive font-weight-bold">Recomendados</span><br>
                <img src="/assets/image/portafolio/line_novedades.png" width="10%">
            </p>
            <ngx-slick-carousel class="carousel" 
            #slickModal="slick-carousel" 
            [config]="slideConfig" 
            (init)="slickInit($event)"
            (breakpoint)="breakpoint($event)"
            (afterChange)="afterChange($event)"
            (beforeChange)="beforeChange($event)">
            <div ngxSlickItem *ngFor="let novedad of librosRecomendados.response.list" class="slide m-3">
              <div class="divLibroNovedades w-100">
                <p class="text-center">
                  <a [routerLink]="['/detalle',novedad.id]">
                    <img src="{{novedad.image}}" (click)="cargarDetalleLibro(novedad.id)" class="z-depth-5" style="display: initial;" width="150px"/>
                  </a>
                </p> 
                <br>     
                <p class="text-left">
                  <span class="title">{{novedad.title}}</span><br>
                  <span class="subtitle">{{novedad.subtitle}}</span>

                </p>
                <p  class="text-center">
                  <span class="price">S/{{novedad.price}}</span>
                </p>
              </div>        
              
           
              
            </div>
            </ngx-slick-carousel>
        </div>
        <hr>
        <div class="col-sm-6 mb-3">
            <p class="font-weight-bold">Pagos 100% seguros<br>   <img src="/assets/image/portafolio/line_pagos.png" width="35%"></p>
            <p >
                <img src="/assets/image/portafolio/p1.png" width="10%">&nbsp;
                <img src="/assets/image/portafolio/p2.png" width="10%">&nbsp;
                <img src="/assets/image/portafolio/p3.png" width="15%">&nbsp;
                <img src="/assets/image/portafolio/p4.png" width="20%">&nbsp;

            </p>
        </div>
        <div class="col-sm-6 mb-3">
            <p class="font-weight-bold">Siguenos en: <br>   <img src="/assets/image/portafolio/line_siguenos.png" width="28%"></p>
            <p >
               <a href="https://www.facebook.com/BibliotecaNacionalPeru/" target="_blank"> <img src="/assets/image/portafolio/r1.png" width="8%"></a>&nbsp;
               <a href="https://twitter.com/BibliotecaPeru" target="_blank"> <img src="/assets/image/portafolio/r2.png" width="8%"></a>&nbsp;
               <a href="https://www.instagram.com/bibliotecaperu/" target="_blank"> <img src="/assets/image/portafolio/r3.png" width="8%"></a>

            </p>
        </div>
    </div>
</div>