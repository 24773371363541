import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { map } from "rxjs/operators";
import { environment } from 'src/environments/environment';
import {NovedadesResponse} from 'src/app/modal/catalogo/novedades-response';
import {CatalogoResponse} from 'src/app/modal/catalogo/catalogo-response';
import {ResponseComun} from 'src/app/modal/catalogo/response-comun';
import {LibroDetalle} from 'src/app/modal/catalogo/libro-detalle';
import { CatalogoRequest } from 'src/app/modal/catalogo/catalogo-request';
import { Observable } from 'rxjs';
import { SourvenirDetalleResponse } from 'src/app/modal/sourvenir/sourvenir-detalle-response';
import { RespuestaComun } from 'src/app/modal/maestras/respuesta-comun';
import {ResponseSourvenirComun} from 'src/app/modal/maestras/response-sourvenir-comun';
import {CatalogoSourvenirRequest} from 'src/app/modal/catalogo/catalogo-sourvenir-request';
import {CatalogoSourvenirResponse} from 'src/app/modal/catalogo/catalogo-sourvenir-response';

@Injectable({
  providedIn: 'root'
})
export class NovedadesService {

  constructor(private httpClient: HttpClient) { }
  obtenerNovedades(){
    let url = `${environment.urlBase}${environment.inicioNovedades}`;
    
    return this.httpClient.get<NovedadesResponse>(url).pipe(
      map(data => {   
        let response: NovedadesResponse;
        response = data;
        if(data.response.codeResponse==="0000"){
          return response;  
        }
      },
      error =>{      
        return new Response("9999", error);
      })) 
  }


  obtenerNovedadesSourvenir(idSourvenir){
    let url = `${environment.urlBase}${environment.sourvenirNovedades}/${idSourvenir}`;
    
    return this.httpClient.get<NovedadesResponse>(url).pipe(
      map(data => {   
        let response: NovedadesResponse;
        response = data;
        if(data.response.codeResponse==="0000"){
          return response;  
        }
      },
      error =>{      
        return new Response("9999", error);
      })) 
  }


  obtenerAutor(){
    let url = `${environment.urlBase}${environment.cata_autor}`;

    return this.httpClient.get<ResponseComun>(url).pipe(
      map(data => {   
        let response: ResponseComun;
        response = data;
        if(data.response.codeResponse==="0000"){
          return response;  
        }
      },
      error =>{      
        return new Response("9999", error);
      })) 
  }


  obtenerCategoria(){
    let url = `${environment.urlBase}${environment.cata_categoria}`;
    
    return this.httpClient.get<ResponseComun>(url).pipe(
      map(data => {   
        let response: ResponseComun;
        response = data;
        if(data.response.codeResponse==="0000"){
          return response;  
        }
      },
      error =>{      
        return new Response("9999", error);
      })) 
  }

  obtenerGrupoProductoSourvenir(idGroupSourvenir:String){
    let url = `${environment.urlBase}${environment.grupoXSubMenu}/${idGroupSourvenir}`;
    
    return this.httpClient.get<ResponseSourvenirComun>(url).pipe(
      map(data => {   
        let response: ResponseSourvenirComun;
        response = data;
        if(data.response.codeResponse==="0000"){
          return response;  
        }
      },
      error =>{      
        return new Response("9999", error);
      })) 
  }
  obtenerListaTemasSourvenir(){
    let url = `${environment.urlBase}${environment.listarTemasSourvenir}`;
    
    return this.httpClient.get<ResponseSourvenirComun>(url).pipe(
      map(data => {   
        let response: ResponseSourvenirComun;
        response = data;
        if(data.response.codeResponse==="0000"){
          return response;  
        }
      },
      error =>{      
        return new Response("9999", error);
      })) 
  }





  obtenerAno(){
    let url = `${environment.urlBase}${environment.cata_anoPublicacion}`;
    
    return this.httpClient.get<ResponseComun>(url).pipe(
      map(data => {   
        let response: ResponseComun;
        response = data;
        if(data.response.codeResponse==="0000"){
          return response;  
        }
      },
      error =>{      
        return new Response("9999", error);
      })) 
  }

  buscarCatalogo(catalogoResquest:CatalogoRequest){
    let url = `${environment.urlBase}${environment.catalogo}`;
    //console.log("Buscar",catalogoResquest);
    return this.httpClient.post<CatalogoResponse>(url,catalogoResquest).pipe(
      map(data => {   
        let response: CatalogoResponse;
        response = data;
        if(data.httpStatus===200){
          //console.log("asdf",response);
          return response;  
        }
      },
      error =>{      
        return new Response("9999", error);
      })) 
  }

  buscarCatalogoSourvenir(CatalogoSourvenirRequest:CatalogoSourvenirRequest){
    let url = `${environment.urlBase}${environment.catalogoSourvenir}`;
    //console.log("Buscar",catalogoResquest);
    return this.httpClient.post<CatalogoSourvenirResponse>(url,CatalogoSourvenirRequest).pipe(
      map(data => {   
        let response: CatalogoSourvenirResponse;
        response = data;
        if(data.httpStatus===200){
          //console.log("asdf",response);
          return response;  
        }
      },
      error =>{      
        return new Response("9999", error);
      })) 
  }


  
  obtenerDetalleLibro(idLibro:string){
    let url = `${environment.urlBase}${environment.detalleLibro}/${idLibro}`;
    
    return this.httpClient.get<LibroDetalle>(url).pipe(
      map(data => {   
        let response: LibroDetalle;
        response = data;
        if(data.httpStatus===200){
          return response;  
        }
      },
      error =>{      
        return new Response("9999", error);
      })) 
  }

  obtenerDetalleSourvenir(idSourvenirProd:string){
    let url = `${environment.urlBase}${environment.sourvenirDetalle}/${idSourvenirProd}`;
    
    return this.httpClient.get<SourvenirDetalleResponse>(url).pipe(
      map(data => {   
        let response: SourvenirDetalleResponse;
        response = data;
        if(data.httpStatus===200){
          return response;  
        }
      },
      error =>{      
        return new Response("9999", error);
      })) 
  }







  obtenerDetalleLibroAsync(idLibro:string):Observable<LibroDetalle>{
    let url = `${environment.urlBase}${environment.detalleLibro}/${idLibro}`;    
    return this.httpClient.get<LibroDetalle>(url);
  }
  obtenerDetalleSourvenirAsync(idLibro:string):Observable<SourvenirDetalleResponse>{
    let url = `${environment.urlBase}${environment.sourvenirDetalle}/${idLibro}`;    
    return this.httpClient.get<SourvenirDetalleResponse>(url);
  }
  obtenerLibrosRecomendados(idLibro:string){
    let url = `${environment.urlBase}${environment.recomendados}/${idLibro}`;
    
    return this.httpClient.get<NovedadesResponse>(url).pipe(
      map(data => {   
        let response: NovedadesResponse;
        response = data;
        if(data.httpStatus===200){
          return response;  
        }
      },
      error =>{      
        return new Response("9999", error);
      })) 
  }
  obtenerSourvenirRecomendados(idSourvenirProd:string){
    let url = `${environment.urlBase}${environment.sourvenirRecomendados}/${idSourvenirProd}`;
    
    return this.httpClient.get<NovedadesResponse>(url).pipe(
      map(data => {   
        let response: NovedadesResponse;
        response = data;
        if(data.httpStatus===200){
          return response;  
        }
      },
      error =>{      
        return new Response("9999", error);
      })) 
  }
}
