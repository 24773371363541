export class PedidosUsuarioResponse {
     httpStatus : number;
     response : {
        id :  string  ,
        codeResponse :   string ,
        response :  string,
        list : [
        {
            id :   string ,
            date :   string ,
            books :   string ,
            number :   string ,
            quantity :   string ,
            subtotal :   string ,
            shipping :   string ,
            igv :   string ,
            total :   string ,
            stateId :   string ,
            stateName :   string ,
            voucherType :  string  ,
            voucherNro :  string  ,
            deliveryType :  string  ,
            regionType :   string ,
            countryName :   string ,
            countryAddress :   string 
        },
        ]
    }
    constructor(){
        this.httpStatus = 0;
        this.response = {
           id :  ""   ,
           codeResponse :  ""   ,
           response :  "" ,
           list : [
           {
            id :  "" ,
            date :  "" ,
            books :  "" ,
            number :  "" ,
            quantity :  "" ,
            subtotal :  "" ,
            shipping :  "" ,
            igv :  "" ,
            total :  "" ,
            stateId :  "" ,
            stateName :  "" ,
            voucherType : ""  ,
            voucherNro : ""  ,
            deliveryType : ""  ,
            regionType :  "" ,
            countryName :  "" ,
            countryAddress :  "" 
           },
           ]
       }
    }
}
