import { Component, Input, OnInit, SimpleChanges, ɵConsole } from '@angular/core';
import { CarritoService } from 'src/app/service/carrito/carrito.service';
import { NovedadesService } from 'src/app/service/catalogo/novedades.service';
import {CarritoHeader} from 'src/app/modal/carrito/carrito-header';
import { LibroDetalle } from 'src/app/modal/catalogo/libro-detalle';
import { retry } from 'rxjs/operators';
import { SourvenirDetalleResponse } from 'src/app/modal/sourvenir/sourvenir-detalle-response';
import { UtilitarioService } from 'src/app/service/utilitarios/utilitario.service';

@Component({
  selector: 'app-carrito-compras-header',
  templateUrl: './carrito-compras-header.component.html',
  styleUrls: ['./carrito-compras-header.component.scss']
})
export class CarritoComprasHeaderComponent implements OnInit {
  carritoDetalle:CarritoHeader;
  carritoCompras=[];
  libroDetalle:LibroDetalle;
  precioTotal:number;
  precio:number;
  carritoComprasTemp=[];

  @Input() flagActualizar: number;
  constructor(private carritoService:CarritoService,private novedadesService:NovedadesService,private utilitarioService:UtilitarioService) {

  }

  // rellenarCarrito(){
  //  this.precioTotal=0;
  //   this.carritoCompras=[];
    
  //   if(this.carritoDetalle){
  //     for (let i = 0; i < this.carritoDetalle.lista.length; i++) {
  //       if(this.carritoDetalle.lista[i].tipoProducto=="1"){
  //         this.novedadesService.obtenerDetalleLibro(this.carritoDetalle.lista[i].id).subscribe(data=>{
  //           this.carritoCompras.push(data.response.list);
  //           this.precioTotal = this.precioTotal + ( Number(this.carritoDetalle.lista[i].cantidad) * Number(data.response.list[0].price) ); 
            
  //         })
  //       }
  //       else if(this.carritoDetalle.lista[i].tipoProducto=="2"){
  //         this.novedadesService.obtenerDetalleSourvenir(this.carritoDetalle.lista[i].id).subscribe(data=>{
  //           this.carritoCompras.push(data.response.list);
  //           this.precioTotal = this.precioTotal + ( Number(this.carritoDetalle.lista[i].cantidad) * Number(data.response.list[0].price) ); 
            
  //         })
  //       }
       
  //     }
  //   }
  // }
  async rellenarCarrito(){

    //this.utilitarioService.loading=true;
    this.precioTotal=0;
    this.carritoCompras=[];
    this.carritoComprasTemp=[];

    for (let i = 0; i < this.carritoDetalle.lista.length; i++) {
      
      let libroDetalleTemp = new LibroDetalle();
      let sourvenirDetalleTemp = new SourvenirDetalleResponse();

      if(this.carritoDetalle.lista[i].tipoProducto=="1"){

        libroDetalleTemp = await this.novedadesService.obtenerDetalleLibroAsync(this.carritoDetalle.lista[i].id).toPromise();
        libroDetalleTemp.response.list[0].size = [];        

      //console.log(libroDetalleTemp);
      if(Number(libroDetalleTemp.response.list[0].quantity)>10){
        libroDetalleTemp.response.list[0].quantity = "10";
      } 
            
      this.carritoComprasTemp.push({lista:libroDetalleTemp.response.list,cantidad:this.carritoDetalle.lista[i].cantidad})
      this.carritoCompras.push(this.carritoComprasTemp);
      console.log(this.carritoCompras);
      this.precioTotal = Number((this.precioTotal + ( Number(this.carritoDetalle.lista[i].cantidad) * Number(libroDetalleTemp.response.list[0].price) )).toFixed(2));

      }
      else if(this.carritoDetalle.lista[i].tipoProducto=="2"){
        sourvenirDetalleTemp = await this.novedadesService.obtenerDetalleSourvenirAsync(this.carritoDetalle.lista[i].id).toPromise();
      //console.log(libroDetalleTemp);

      if(sourvenirDetalleTemp.response.list[0].size.length>0){              
        const result = sourvenirDetalleTemp.response.list[0].size.find( ({ id }) => id ==  this.carritoDetalle.lista[i].sizeID);
        sourvenirDetalleTemp.response.list[0].quantity = result.stock;
        sourvenirDetalleTemp.response.list[0].sizeName = result.name;            
      }

      if(Number(sourvenirDetalleTemp.response.list[0].quantity)>10){
        sourvenirDetalleTemp.response.list[0].quantity = "10";
      } 
            
      this.carritoComprasTemp.push({lista:sourvenirDetalleTemp.response.list,cantidad:this.carritoDetalle.lista[i].cantidad})
      this.carritoCompras.push(this.carritoComprasTemp);
      this.precioTotal = Number((this.precioTotal + ( Number(this.carritoDetalle.lista[i].cantidad) * Number(sourvenirDetalleTemp.response.list[0].price) )).toFixed(2));

      }
    }
  }

  obtenerCarrito(){
    this.carritoDetalle = new CarritoHeader();
    this.carritoDetalle=this.carritoService.obtenerCarritoCompras()
    this.rellenarCarrito();
  }
  limpiarCarrito(){
    // this.rellenarCarrito();
    this.carritoService.limpiarCarrito();
    this.carritoCompras = [];
    this.precioTotal = 0;
  }
  fprecioTotal(){
    this.precio = this.precioTotal
    return this.precio
  }

  feliminarCarrito(i: number){
    console.log("Index Eliminar",i);
    this.carritoDetalle.lista.splice(i, 1);
    console.log("carrito compras 1");
    console.log(this.carritoDetalle);
  
    this.carritoService.eliminarCarrito(this.carritoDetalle.lista);
    this.rellenarCarrito();

    console.log("carrito compras 2");
    console.log(this.carritoDetalle);

  }
  
  frecalculartotal(fila:number,cantidad:number,stock:string){
    //console.log(cantidad);
    //console.log(stock);
    
    if (cantidad>10){
      //console.log("por defecto");
      this.carritoDetalle.lista[fila].cantidad = stock;
      this.utilitarioService.toastrInfo("cantidad maxíma : " + "10");
    }else{
      //console.log("stock");
      if (cantidad > Number(stock)){
        this.utilitarioService.toastrInfo("queda en stock : " + stock + " cantidades");
        this.carritoDetalle.lista[fila].cantidad = stock;
      }      
    }

    if (cantidad<1){
      this.carritoDetalle.lista[fila].cantidad = "1";
    }
  
    //actualiza storage con nuevos cantidades    
    this.carritoService.actualizarCarrito(this.carritoDetalle.lista);
    this.rellenarCarrito();
    
  }

  ngOnInit(): void {
   
    
  }
  ngOnChanges(changes: SimpleChanges) {
   

    this.obtenerCarrito();
  
  }

}