import { Component, OnInit } from '@angular/core';
import { UsuarioService } from 'src/app/service/usuario/usuario.service';
import { PedidosUsuarioResponse} from 'src/app/modal/usuario/pedidos-usuario-response';
import {OrderDetalleService} from 'src/app/service/orderdetalle/order-detalle.service';
import { OrderDetalleResponse } from 'src/app/modal/order-detalle/orderDetalleResponse';
import { OrderPagoService } from 'src/app/service/orderpago/order-pago.service';
import { OrderCabeceraResponse } from 'src/app/modal/order-pago/orderCabeceraResponse';
import { UtilitarioService } from 'src/app/service/utilitarios/utilitario.service';
@Component({
  selector: 'app-mis-pedidos',
  templateUrl: './mis-pedidos.component.html',
  styleUrls: ['./mis-pedidos.component.scss']
})
export class MisPedidosComponent implements OnInit {
   listaPedidos:PedidosUsuarioResponse;
   orderCabeceraResponse:OrderCabeceraResponse =new OrderCabeceraResponse();
   pedidoDetalle:OrderDetalleResponse = new OrderDetalleResponse();
   page = 1;
   pageSize = 10;
  constructor(private usuarioService:UsuarioService,private utilService:UtilitarioService,
    private orderDetalleService:OrderDetalleService,private orderPagoService:OrderPagoService) { }

  obtenerListaPedidos(){
    this.listaPedidos = new PedidosUsuarioResponse();
    this.usuarioService.obtenerListaPedidos(this.usuarioService.obtenerIDSessionUsuario()).subscribe(data=>{
      this.listaPedidos = data;
      //console.log(this.listaPedidos);
    })
  }
  obtenerDatosPedido(id){
    this.utilService.loading = true;
    this.obtenerPedidioXID(id);
    this.pedidoDetalle = new OrderDetalleResponse();
    this.orderDetalleService.obtenerOrderDetallePedido(id).subscribe(data=>{
      this.pedidoDetalle = data;
      console.log(data);

      this.utilService.loading = false;
    })
  }
  obtenerPedidioXID(id){
    this.orderCabeceraResponse = new OrderCabeceraResponse();
    this.orderPagoService.obtenerOrderCabecera(id).subscribe(data=>{
      this.orderCabeceraResponse=data;
      console.log(data);
    })
  }
  ngOnInit(): void {
    this.obtenerListaPedidos();
  }

}
