import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { UsuarioService } from 'src/app/service/usuario/usuario.service';
import { UtilitarioService } from 'src/app/service/utilitarios/utilitario.service';
import { MaestrasService } from 'src/app/service/maestras/maestras.service';
import { RespuestaComun } from 'src/app/modal/maestras/respuesta-comun';

import { AutorService } from 'src/app/service/autor/autor.service';
import { AutorAdminBeanInsRequest,AutorAdminBeanUpdRequest } from 'src/app/modal/autor/autorAdminBean';

@Component({
  selector: 'app-dml-autor',
  templateUrl: './dml-autor.component.html',
  styleUrls: ['./dml-autor.component.scss']
})
export class DmlAutorComponent implements OnInit {

  constructor(public  modalRef: MDBModalRef,
    private maestrasService: MaestrasService,    
    private utilitarioService: UtilitarioService,
    private usuarioService: UsuarioService,    
    private autorService: AutorService,    
    ) { }

  listEstado:RespuestaComun = new RespuestaComun();
  cboEstado:String = "1";

  //variables del formulario reactivo
  txtDescription:String= "";
  txtOrder:String = "";

  createdDate:String = "";
  //variables enviado desde el padre
  id:String;  
  x_titulo:String;
  x_proceso:String;

  ngOnInit(): void {

      this.cargarDatoInicial(this.x_proceso);

  }

  onClosemodal(){
    this.modalRef.hide();
  }

  onGrabar(){

    if (this.validarFormulario()==false){      
      return false;
    }
    
    let autorAdminBeanInsRequest = new AutorAdminBeanInsRequest();
    let autorAdminBeanUpdRequest = new AutorAdminBeanUpdRequest();    

    if (this.x_proceso=='I'){

      this.utilitarioService.loading = true;
      this.rellenarBean(autorAdminBeanInsRequest,autorAdminBeanUpdRequest,this.x_proceso);

      this.autorService.grabar(autorAdminBeanInsRequest).subscribe(data=>{        

        if (data.response.codeResponse=="0000"){
          this.utilitarioService.toastrSuccess("Se ha registrado satisfactoriamente.");
          (<HTMLButtonElement>document.getElementById("btnBuscar")).click();
        }else{
          this.utilitarioService.toastrError(data.response.response);
        }        

        this.utilitarioService.loading = false;
        this.onClosemodal();
        
      });

    }else{

      this.utilitarioService.loading = true;
      this.rellenarBean(autorAdminBeanInsRequest,autorAdminBeanUpdRequest,this.x_proceso);

      this.autorService.update(autorAdminBeanUpdRequest,this.id).subscribe(data=>{        

        if (data.response.codeResponse=="0000"){
          this.utilitarioService.toastrSuccess("Se ha actualizado satisfactoriamente.");
          (<HTMLButtonElement>document.getElementById("btnBuscar")).click();
        }else{
          this.utilitarioService.toastrError(data.response.response);
        }        

        this.utilitarioService.loading = false;
        this.onClosemodal();
        
      });

    }    

  }

  cargarDatoInicial(dml:String){

    this.utilitarioService.loading = true;
    this.listEstado = new RespuestaComun();    

    const cargarEstado = this.maestrasService.obtenerMaestra("TIPO_STATUS_TABLA").toPromise()
    .then(result => {
      this.listEstado = result;
    });

    if (dml=='I'){
      //carga los promesas culminadas para asignar los datos a las variables      
      Promise.all([cargarEstado])    
      .then(response => {      
        this.utilitarioService.loading = false;
      })
      .catch(error => console.log(`Error in executing ${error}`));
    }

    if (dml=='U'){
      //carga los promesas culminadas para asignar los datos a las variables
      Promise.all([cargarEstado])    
      .then(response => {      
        //obtener por id objeto
        this.autorService.obtenerRegistro(this.id).subscribe(data=>{
          this.id = data.response.list[0].id;
          this.txtDescription = data.response.list[0].name;
          this.txtOrder = data.response.list[0].order;
          this.cboEstado = data.response.list[0].enabled;
          this.createdDate = data.response.list[0].createdDate;
          this.utilitarioService.loading = false;
        });
      })
      .catch(error => console.log(`Error in executing ${error}`));
    }    

  }

  rellenarBean(autorAdminBeanInsRequest:AutorAdminBeanInsRequest,autorAdminBeanUpdRequest:AutorAdminBeanUpdRequest,dml:String){    
        
    if (dml=='I'){
      autorAdminBeanInsRequest.order = '';
      autorAdminBeanInsRequest.name =this.txtDescription;
      autorAdminBeanInsRequest.about = this.txtDescription;
      autorAdminBeanInsRequest.usuarioRegistroId = this.usuarioService.obtenerIDSessionUsuarioAdmin().toString();
      autorAdminBeanInsRequest.enabled = this.cboEstado;
    }else{
      autorAdminBeanUpdRequest.order = this.txtOrder;
      autorAdminBeanUpdRequest.id = this.id;
      autorAdminBeanUpdRequest.about =this.txtDescription;
      autorAdminBeanUpdRequest.name = this.txtDescription;
      autorAdminBeanUpdRequest.usuarioModificaId = this.usuarioService.obtenerIDSessionUsuarioAdmin().toString();      
      autorAdminBeanUpdRequest.enabled = this.cboEstado;
    }

  }

  validarFormulario() : Boolean{    
    //valida formulario
    if (this.usuarioService.obtenerIDSessionUsuarioAdmin().toString()==null){
      this.utilitarioService.toastrError("Su session ha culminado, ingrese nuevamente al sistema.");      
      return false;
    }

    //valida tipo de comprobante
    if(this.txtDescription.trim()==""){
      this.utilitarioService.toastrInfo("Ingrese autor.");
      return false;
    }

    if(this.cboEstado==""){
      this.utilitarioService.toastrInfo("Seleccione estado.");
      return false;
    }

    return true;

  }

}
