export class OrderCabeceraAdminResponse{
    httpStatus: String;
    response: {
        id: String,
        codeResponse: String,
        response: String,
        list: [{
            books: string;
            clientEmail: string;
            clientName: string;
            clientNro: string;
            contactCellphone: string;
            contactName: string;
            countryAddress: string;
            countryName: string;
            date: string;
            deliveryType: string;
            id: string;
            igv: string;
            number: string;
            quantity: string;
            regionType: string;
            shipping: string;
            stateId: string;
            stateName: string;
            subtotal: string;
            total: string;
            voucherDni: string;
            voucherName: string;
            voucherNro: string;
            voucherRazonSocial: string;
            voucherRuc: string;
            voucherType: String;
        }]
    }

    constructor(){
        this.httpStatus="0";
        this.response={
            id:"",
            codeResponse:"",
            response:"",
            list: [{
                books: "",
                clientEmail: "",
                clientName: "",
                clientNro: "",
                contactCellphone: "",
                contactName: "",
                countryAddress: "",
                countryName: "",
                date: "",
                deliveryType: "",
                id: "",
                igv: "",
                number: "",
                quantity: "",
                regionType: "",
                shipping: "",
                stateId: "",
                stateName: "",
                subtotal: "",
                total: "",
                voucherDni: "",
                voucherName: "",
                voucherNro: "",
                voucherRazonSocial: "",
                voucherRuc: "",
                voucherType: "",
            }]
        }
      }
      
}