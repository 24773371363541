import { Injectable } from '@angular/core';
import {CarritoHeader} from 'src/app/modal/carrito/carrito-header';
import {UtilitarioService} from 'src/app/service/utilitarios/utilitario.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CarritoService {
  carrito=[];
  cantidadCarrito:number;
  constructor(private utilitarioService:UtilitarioService,
    private router:Router) {
        if(!this.existeCarrito()){
          localStorage.setItem("carrito-sesion",JSON.stringify([]));
        }      
  }

  agregarCarrito(id){

    console.log(id);
    console.log(id.id);
    let idLibro = id.id;
    let idSize = id.sizeID;

    let cantidadLibro = id.cantidad;
    let carritoSesionTemp = new CarritoHeader();

    if(this.carrito.length==0 || this.existeCarrito()){
      this.carrito = JSON.parse(localStorage.getItem('carrito-sesion'));
      //console.log("data", this.carrito);
      let flagExisteProducto=0;
      console.log("paso 1");
      carritoSesionTemp.lista =  JSON.parse(localStorage.getItem('carrito-sesion'));

      if(carritoSesionTemp.lista.length>0){
        console.log("paso 2");
        console.log(carritoSesionTemp);
        for(let i = 0;i<carritoSesionTemp.lista.length;i++){
          
          if(idLibro==carritoSesionTemp.lista[i].id && idSize==carritoSesionTemp.lista[i].sizeID){
            console.log("paso 3");
            flagExisteProducto=1;
            carritoSesionTemp.lista[i].cantidad=(Number(carritoSesionTemp.lista[i].cantidad)+Number(cantidadLibro)).toString();
          }         
        
        }
        if(flagExisteProducto==0){
          carritoSesionTemp.lista.push(id);
        }
        // console.log("a buscar",id.id)
        // console.log("carritoSesionTemp",carritoSesionTemp);
        // console.log("Si existe",carritoSesionTemp.lista.filter(data=>{ data.id == id.id}).length)
        // let cantProdExi = carritoSesionTemp.lista.filter(data=>{ data.id == id.id}).length;
        // console.log("Si existe cantProdExi",cantProdExi)
        // if(carritoSesionTemp.lista.filter(data=>{ data.id == id.id}).length>0){
        //    carritoSesionTemp.lista.push(id);
        // }
    //    console.log("prueba",        carritoSesionTemp.lista.filter(data=>data.id == id.id))


        localStorage.setItem("carrito-sesion",JSON.stringify(carritoSesionTemp.lista));
      }else{
        console.log("paso 4");

        this.carrito.push(id);
        localStorage.setItem("carrito-sesion",JSON.stringify(this.carrito));
      }
      //this.carrito = this.carritoSesionTemp;      

    }
    else{
      console.log("paso 5");
      this.carrito.push(id);
      localStorage.setItem("carrito-sesion",JSON.stringify(this.carrito));
    }
    //this.carrito.push(id);
    //localStorage.setItem("carrito-sesion",JSON.stringify(this.carrito));
    //console.log(JSON.stringify(this.carrito));
    this.obtenerCantidadCarrito();
  }

  actualizarCarrito(carrito){
    //localStorage.setItem("carrito-sesion",JSON.stringify(carrito));    
    localStorage.setItem("carrito-sesion",JSON.stringify(carrito));
    this.obtenerCantidadCarrito();
  }

  existeCarrito() {
    return !(localStorage.getItem('carrito-sesion') === null)
  }

  obtenerCarritoCompras() {
    if (!this.existeCarrito()) return null;
    let carritoSesion: CarritoHeader;
    carritoSesion = new CarritoHeader();
    carritoSesion.lista =  JSON.parse(localStorage.getItem('carrito-sesion'));
   
    return  carritoSesion;
  }

  obtenerCantidadCarrito(){
    if(this.existeCarrito()){
    this.cantidadCarrito =  JSON.parse(localStorage.getItem('carrito-sesion')).length;
    }
    else{
      this.cantidadCarrito =0;
    }
  }

  verificarCarritoSelecionados():boolean{
    this.obtenerCantidadCarrito();
    if (this.cantidadCarrito<1){
        this.utilitarioService.toastrWarning('No existe productos seleccionados en el carrito de compras.');
        this.router.navigate(['/']);
        return false;
    }
    return true;
  }
  eliminarCarrito(carrito){
    //localStorage.setItem("carrito-sesion",JSON.stringify(carrito));    
    this.carrito=[];
    localStorage.setItem("carrito-sesion",JSON.stringify(carrito));
    //console.log("carrito en carrito");
    //console.log(this.carrito);
    let i = this.carrito.indexOf( carrito );
    this.carrito.splice( i, 1 );
   // this.carrito.splice(this.carrito,1);
    this.obtenerCantidadCarrito();
  }

  limpiarCarrito(){
    localStorage.setItem("carrito-sesion",JSON.stringify([]));
    this.carrito = [];
    this.obtenerCantidadCarrito();
  }
}
