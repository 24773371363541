export class OrderEstadoContResponse{

    httpStatus: String;
    response: {
        id: String,
        codeResponse: String,
        response: String,
        list: [
            {
              stateId:string,
              stateDescriptio:string
            }
        ]
    }

    constructor(){
        this.httpStatus="0";
        this.response={
            id:"",
            codeResponse:"",
            response:"",
            list:
            [{
              stateId:"",
              stateDescriptio:""
            }]
        }
    }
      
}