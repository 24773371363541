import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { UsuarioService } from 'src/app/service/usuario/usuario.service';
import { UtilitarioService } from 'src/app/service/utilitarios/utilitario.service';
import { MaestrasService } from 'src/app/service/maestras/maestras.service';
import { RespuestaComun } from 'src/app/modal/maestras/respuesta-comun';

import { CategoriaService } from 'src/app/service/categoria/categoria.service';
import { CategoriaAdminBeanInsRequest,CategoriaAdminBeanUpdRequest } from 'src/app/modal/categoria/categoriaAdminBean';

@Component({
  selector: 'app-dml-categoria',
  templateUrl: './dml-categoria.component.html',
  styleUrls: ['./dml-categoria.component.scss']
})
export class DmlCategoriaComponent implements OnInit {

  constructor(public  modalRef: MDBModalRef,
    private maestrasService: MaestrasService,    
    private utilitarioService: UtilitarioService,
    private usuarioService: UsuarioService,    
    private categoriaService: CategoriaService,    
    ) { }

  listEstado:RespuestaComun = new RespuestaComun();
  cboEstado:String = "1";
  createdDate:String = "";

  //variables del formulario reactivo
  txtDescription:String= "";
  txtOrder:String = "";
  //variables enviado desde el padre
  id:String;
  x_titulo:String;
  x_proceso:String;

  ngOnInit(): void {

      this.cargarDatoInicial(this.x_proceso);

  }

  onClosemodal(){
    this.modalRef.hide();
  }

  onGrabar(){

    if (this.validarFormulario()==false){      
      return false;
    }

    let categoriaAdminBeanInsRequest = new CategoriaAdminBeanInsRequest();
    let categoriaAdminBeanUpdRequest = new CategoriaAdminBeanUpdRequest();    

    if (this.x_proceso=='I'){

      this.utilitarioService.loading = true;
      this.rellenarBean(categoriaAdminBeanInsRequest,categoriaAdminBeanUpdRequest,this.x_proceso);

      this.categoriaService.grabar(categoriaAdminBeanInsRequest).subscribe(data=>{        

        if (data.response.codeResponse=="0000"){
          this.utilitarioService.toastrSuccess("Se ha registrado satisfactoriamente.");
          (<HTMLButtonElement>document.getElementById("btnBuscar")).click();
        }else{
          this.utilitarioService.toastrError(data.response.response);
        }        

        this.utilitarioService.loading = false;
        this.onClosemodal();
        
      });

    }else{

      this.utilitarioService.loading = true;
      this.rellenarBean(categoriaAdminBeanInsRequest,categoriaAdminBeanUpdRequest,this.x_proceso);
      console.log(JSON.stringify(categoriaAdminBeanUpdRequest));
      this.categoriaService.update(categoriaAdminBeanUpdRequest,this.id).subscribe(data=>{        

        if (data.response.codeResponse=="0000"){
          this.utilitarioService.toastrSuccess("Se ha actualizado satisfactoriamente.");
          (<HTMLButtonElement>document.getElementById("btnBuscar")).click();
        }else{
          this.utilitarioService.toastrError(data.response.response);
        }        

        this.utilitarioService.loading = false;
        this.onClosemodal();
        
      });

    }    

  }

  cargarDatoInicial(dml:String){

    this.utilitarioService.loading = true;
    this.listEstado = new RespuestaComun();    

    const cargarEstado = this.maestrasService.obtenerMaestra("TIPO_STATUS_TABLA").toPromise()
    .then(result => {
      this.listEstado = result;
    });

    if (dml=='I'){
      //carga los promesas culminadas para asignar los datos a las variables      
      Promise.all([cargarEstado])    
      .then(response => {      
        this.utilitarioService.loading = false;
      })
      .catch(error => console.log(`Error in executing ${error}`));
    }

    if (dml=='U'){
      //carga los promesas culminadas para asignar los datos a las variables
      Promise.all([cargarEstado])    
      .then(response => {      
        //obtener por id objeto
        this.categoriaService.obtenerRegistro(this.id).subscribe(data=>{
          this.id = data.response.list[0].id;
          this.txtDescription = data.response.list[0].description;
          this.txtOrder = data.response.list[0].order;
          this.cboEstado = data.response.list[0].enabled;
          this.createdDate = data.response.list[0].createdDate
          this.utilitarioService.loading = false;
        });
      })
      .catch(error => console.log(`Error in executing ${error}`));
    }    

  }

  rellenarBean(categoriaAdminBeanInsRequest:CategoriaAdminBeanInsRequest,categoriaAdminBeanUpdRequest:CategoriaAdminBeanUpdRequest,dml:String){    

    if (dml=='I'){
      categoriaAdminBeanInsRequest.order = '';
      categoriaAdminBeanInsRequest.description =this.txtDescription;
      categoriaAdminBeanInsRequest.name = this.txtDescription;
      categoriaAdminBeanInsRequest.createdUser = this.usuarioService.obtenerIDSessionUsuarioAdmin().toString();
      categoriaAdminBeanInsRequest.enabled = this.cboEstado;
    }else{
      categoriaAdminBeanUpdRequest.order = this.txtOrder;
      categoriaAdminBeanUpdRequest.description =this.txtDescription;
      categoriaAdminBeanUpdRequest.name = this.txtDescription;
      categoriaAdminBeanUpdRequest.updatedUser = this.usuarioService.obtenerIDSessionUsuarioAdmin().toString();      
      categoriaAdminBeanUpdRequest.enabled = this.cboEstado;
    }        

  }

  validarFormulario() : Boolean{    
    //valida formulario
    if (this.usuarioService.obtenerIDSessionUsuarioAdmin().toString()==null){
      this.utilitarioService.toastrError("Su session ha culminado, ingrese nuevamente al sistema.");      
      return false;
    }

    //valida campos
    if(this.txtDescription.trim()==""){
      this.utilitarioService.toastrInfo("Ingrese descripción.");
      return false;
    }

    if(this.cboEstado==""){
      this.utilitarioService.toastrInfo("Seleccione estado.");
      return false;
    }

    if(this.txtOrder=="" && this.x_proceso == 'U'){
      this.utilitarioService.toastrInfo("Ingre orden de categoria.");
      return false;
    }

    return true;

  }

}
