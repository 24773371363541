import { DireccionUserBean } from 'src/app/modal/usuario/direccion-user-bean';

export class DireccionUserResponse {

    httpStatus: String;
    response: {
        id: String;
        codeResponse: String;
        response: String;
        list: Array<DireccionUserBean>;
    }

    constructor(){
        this.httpStatus="0";
        this.response={
            id:"",
            codeResponse:"",
            response:"",
            list:[]
        }
      }
      
}