export class TopicListBean{
    id: String;
    name: String;
    description: String;
    order: String;
    enabled: String;
    status: String;
    createdUserId: String;
    updatedUserid: String;
}

export class TopicIdBean{
    id: String;    
    name: String;
    description: String;
    order: String;
    enabled: String;
    status: String;
    createdUserId: String;
    updatedUserid: String;    
}

export class TopicBeanInsRequest{
    createdUserId: String;
    description: String;    
    id: String;
    name: String;
    order: String;    
}

export class TopicBeanUpdRequest{    
    createdUserId: String;
    description: String;
    enabled: String;
    id: String;
    name: String;
    order: String;
    status: String;    
    updatedUserid: String;
}