import { Component, OnInit,ViewChild, AfterViewInit } from '@angular/core';
import { ProcesarPagoService } from 'src/app/service/procesarpago/procesar-pago.service';
import { OrderCalculateResponse } from 'src/app/modal/procesapago/orderCalculateResponse';
import { OrderCalculateRequest } from 'src/app/modal/procesapago/orderCalculateRequest';
import { OrderRequest } from 'src/app/modal/procesapago/orderRequest';
import { CarritoHeader } from 'src/app/modal/carrito/carrito-header';
import { LibroDetalle } from 'src/app/modal/catalogo/libro-detalle';
import { NovedadesService } from 'src/app/service/catalogo/novedades.service';
import { CarritoService } from 'src/app/service/carrito/carrito.service';
import { InformacionEnvioComponent } from "src/app/pagina-externa/procesar-pago/informacion-envio/informacion-envio.component";
import { UtilitarioService } from 'src/app/service/utilitarios/utilitario.service';
import { SourvenirDetalleResponse } from 'src/app/modal/sourvenir/sourvenir-detalle-response';

@Component({
  selector: 'app-procesar-pago',
  templateUrl: './procesar-pago.component.html',
  styleUrls: ['./procesar-pago.component.scss']
})
export class ProcesarPagoComponent implements AfterViewInit {
  
  constructor(private procesarPagoService:ProcesarPagoService,
              private novedadesService:NovedadesService,
              private carritoService:CarritoService,                         
              private utilitarioService:UtilitarioService) { }

  @ViewChild(InformacionEnvioComponent) informacionEnvioComponent: InformacionEnvioComponent;
  carritoDetalle:CarritoHeader;  
  libroDetalle:LibroDetalle;
  precioTotal:number = 0;
  cantidadTotal:number = 0;
  weightTotal:number = 0;
  addressId:String;
  typeDeliveryId:String;
  orderCalculateResponse : OrderCalculateResponse = new OrderCalculateResponse();
  orderRequestInfoEnvio:OrderRequest = new OrderRequest();

  SubTotalTemp = 0;
  IGVProducto = 0;
  IGVDelivery = 0;
  IGVTotal = 0;    

  ngOnInit(): void {
       
    this.carritoService.verificarCarritoSelecionados();
    
  }
  

  obtenerCarrito(){
    this.carritoDetalle = new CarritoHeader();
    this.carritoDetalle=this.carritoService.obtenerCarritoCompras()
    this.rellenarTotales();
  };

  async rellenarTotales(){        
    this.SubTotalTemp = 0;
    this.precioTotal = 0;
    this.IGVDelivery = 0;
    this.IGVProducto = 0;
    this.IGVTotal = 0;
    this.cantidadTotal = 0;
    this.weightTotal = 0;
    this.utilitarioService.loading=true;

    for (let i = 0; i < this.carritoDetalle.lista.length; i++) {
        
        let libroDetalleTemp=new LibroDetalle();
        let libroSourvenirTemp=new SourvenirDetalleResponse();

        if(this.carritoDetalle.lista[i].tipoProducto=="1"){
          //recorre carrito en temporal con bd para obtener costos y detalle del producto uno por uno
          var libroDetalle:LibroDetalle = await this.novedadesService.obtenerDetalleLibroAsync(this.carritoDetalle.lista[i].id).toPromise();                
          libroDetalleTemp = libroDetalle;
          //console.log(libroDetalle);

          this.IGVProducto = this.IGVProducto + (Number(libroDetalle.response.list[0].igv_base) * Number(this.carritoDetalle.lista[i].cantidad));
          this.precioTotal = Number((this.precioTotal + ( Number(this.carritoDetalle.lista[i].cantidad) * Number(libroDetalleTemp.response.list[0].price))).toFixed(2));
          this.SubTotalTemp = Number((this.SubTotalTemp + ( Number(this.carritoDetalle.lista[i].cantidad) * Number(libroDetalleTemp.response.list[0].price_base) )).toFixed(2));
          
          this.cantidadTotal = this.cantidadTotal + Number(this.carritoDetalle.lista[i].cantidad);
          //this.weightTotal = this.weightTotal + Number(libroDetalleTemp.response.list[0].weight); 
          this.weightTotal = this.weightTotal + ( Number(this.carritoDetalle.lista[i].cantidad) *  Number(libroDetalleTemp.response.list[0].weight) );

        }
        else if(this.carritoDetalle.lista[i].tipoProducto=="2"){
          //recorre carrito en temporal con bd para obtener costos y detalle del producto uno por uno
          var libroSourvenir:SourvenirDetalleResponse = await this.novedadesService.obtenerDetalleSourvenir(this.carritoDetalle.lista[i].id).toPromise();                
          libroSourvenirTemp = libroSourvenir;
         //console.log(libroSourvenir);
         //let tempPrecioSinIGV = 0;
         // tempPrecioSinIGV = Number(libroSourvenirTemp.response.list[0].price) - ( Number(libroSourvenirTemp.response.list[0].price) * 0.18);
         // this.IGVProducto = this.IGVProducto + (Number(libroSourvenirTemp.response.list[0].price) - tempPrecioSinIGV ) * Number(this.carritoDetalle.lista[i].cantidad);
         //tempPrecioSinIGV =  Number((Number(libroSourvenirTemp.response.list[0].price) / 1.18).toFixed(2));
         
         console.log("Precio x Libro",libroSourvenirTemp.response.list[0].price);         

          this.IGVProducto = this.IGVProducto + (Number(libroSourvenirTemp.response.list[0].igv_base) * Number(this.carritoDetalle.lista[i].cantidad));
          this.precioTotal = Number((this.precioTotal + ( Number(this.carritoDetalle.lista[i].cantidad) * Number(libroSourvenirTemp.response.list[0].price))).toFixed(2));
          this.SubTotalTemp = Number((this.SubTotalTemp + ( Number(this.carritoDetalle.lista[i].cantidad) * Number(libroSourvenirTemp.response.list[0].price_base) )).toFixed(2));
          
          this.cantidadTotal = this.cantidadTotal + Number(this.carritoDetalle.lista[i].cantidad);
          //this.weightTotal = this.weightTotal + Number(libroSourvenirTemp.response.list[0].weight); 
          this.weightTotal = this.weightTotal + ( Number(this.carritoDetalle.lista[i].cantidad) *  Number(libroSourvenirTemp.response.list[0].weight) );

        }

      
        
    }




    //console.log("ingreso totales");
    let orderCalculateRequest = new OrderCalculateRequest();
    orderCalculateRequest.addressId = this.informacionEnvioComponent.rdbDireccionEnvio;
    orderCalculateRequest.amount = this.precioTotal.toString();
    orderCalculateRequest.quantity= this.cantidadTotal.toString();
    orderCalculateRequest.typeDeliveryId = this.informacionEnvioComponent.rdbTipoEntregaVal; //tipo entrega
    orderCalculateRequest.typeShippingId = "12"; //codigo pais peru
    orderCalculateRequest.weight = this.weightTotal.toString();
    
    if(this.informacionEnvioComponent.rdbDireccionEnvio==null || this.informacionEnvioComponent.rdbDireccionEnvio == undefined || this.informacionEnvioComponent.rdbDireccionEnvio == "0"){
      this.orderCalculateResponse.response.list = [{subtotalCost:orderCalculateRequest.amount,shippingCost:"0",igvCost:"0",totalCost:orderCalculateRequest.amount}];
      this.utilitarioService.loading=false;
      this.IGVTotal = this.IGVProducto + this.IGVDelivery;
      return;
    }
    //console.log(orderCalculateRequest);
    this.procesarPagoService.obtenerCalculoTotales(orderCalculateRequest).subscribe(data => {
      
      console.log(data);
      this.utilitarioService.loading=false;    
      if (data.response.codeResponse!="0000"){this.utilitarioService.toastrError(data.response.response); return;}      
      
      this.orderCalculateResponse = data;            
      this.IGVDelivery = Number(this.orderCalculateResponse.response.list[0].igvCost);

      this.IGVTotal = this.IGVProducto + this.IGVDelivery;
    },
    (err:any) => {
      this.utilitarioService.toastrError("Ocurrio un error en el Servicio");
      //console.log(err);
    });

  }

  onCargarDatosPersonales() {          

    //this.personaBeanM.direccion = (<HTMLInputElement>document.getElementById("txt_buscar_flag")).value;
    this.addressId = (<HTMLInputElement>document.getElementById("txtaddressId")).value;
    this.typeDeliveryId = (<HTMLInputElement>document.getElementById("txttypeDeliveryId")).value;       
    
 
   }

  ngAfterViewInit() {
    //this.addressId = this.informacionEnvioComponent.rdbDireccionEnvio;
    this.obtenerCarrito();
  }

  recibirMensajeHijoInf(mensaje:String){    
    this.obtenerCarrito();
  }

  recibirOrder(orderRequestInformacion:OrderRequest){
    //console.log(orderRequestInformacion);    
    this.orderRequestInfoEnvio = orderRequestInformacion;

  }


}
