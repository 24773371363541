import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CatalogoResponse } from 'src/app/modal/catalogo/catalogo-response';

import { ResponseComun } from 'src/app/modal/catalogo/response-comun';
import {RespuestaComun} from 'src/app/modal/maestras/respuesta-comun';
import { NovedadesService } from 'src/app/service/catalogo/novedades.service';
import {MaestrasService} from 'src/app/service/maestras/maestras.service';
import {CatalogoRequest} from 'src/app/modal/catalogo/catalogo-request';
import { UtilitarioService } from 'src/app/service/utilitarios/utilitario.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
@Component({
  selector: 'app-catalogo',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss']
})
export class CatalogoComponent implements OnInit {
  listAutor:ResponseComun;
  listCategoria:ResponseComun;
  listAno:ResponseComun;
  listTipoPubli:RespuestaComun;
  listLibros:CatalogoResponse;
  filtrosForm: FormGroup;
  catalogoResquest:CatalogoRequest;
  tipoPublicacion:string;
  cargaPagina = 1;
  page = 1;
  pageSize = 9;
  initialValue="prueba";
  constructor(private fb: FormBuilder,private route: ActivatedRoute,private novedadesService:NovedadesService,private maestraService:MaestrasService,private utilitarioService:UtilitarioService) { 
    this.createForm();
  }

  createForm(){
    this.filtrosForm = this.fb.group({      
      txtTitulo:[''],   
      txtAutor:[''], 
      txtCategoria:[''], 
      txtTipoPublicacion:[''], 
      txtAnoPublicacion:[''], 
    });
  }    
  buscarLibros(){
    this.utilitarioService.loading = true;
    this.catalogoResquest = new CatalogoRequest();

    
    this.listLibros = new CatalogoResponse();
    this.catalogoResquest.title = this.filtrosForm.get("txtTitulo").value;
    if(this.filtrosForm.get("txtAutor").value.id){
      this.catalogoResquest.authorId = this.filtrosForm.get("txtAutor").value.id;
    }
    if(this.filtrosForm.get("txtCategoria").value.id){
      this.catalogoResquest.categoryId = this.filtrosForm.get("txtCategoria").value.id;
    }
    if(this.filtrosForm.get("txtTipoPublicacion").value.masterId){
      this.catalogoResquest.publicationId = this.filtrosForm.get("txtTipoPublicacion").value.masterId;
    }
    if(this.filtrosForm.get("txtAnoPublicacion").value.id){
      this.catalogoResquest.yearId = this.filtrosForm.get("txtAnoPublicacion").value.id;
    }


    this.novedadesService.buscarCatalogo(this.catalogoResquest).subscribe(data=>{
      this.listLibros=data;      
      //console.log(data);
      //console.log(this.listLibros.response.list.length);
      //console.log(this.listLibros.response.list);
      this.page = Number(this.maestraService.obtenerPaginacionLibros());
      this.cargaPagina = this.cargaPagina + 1;

      this.utilitarioService.loading = false;

    })


  
  }



  obtenerAutor(){
    this.listAutor = new ResponseComun();
    this.novedadesService.obtenerAutor().subscribe(data=>{
      this.listAutor = data;
    })
  }
  obtenerCategoria(){
    this.listCategoria = new ResponseComun();
    this.novedadesService.obtenerCategoria().subscribe(data=>{
      this.listCategoria = data;
    })
  }
  obtenerAno(){
    this.listAno = new ResponseComun();
    this.novedadesService.obtenerAno().subscribe(data=>{
      this.listAno = data;
      //console.log(this.listAno)
    })
  }
  obtenerTipoPublicacion(){
    console.log("TIPO P",this.tipoPublicacion);
    this.listTipoPubli = new RespuestaComun();
    this.maestraService.obtenerMaestra("TIPO_PUBLICACION").subscribe(data=>{
      this.listTipoPubli = data;
      //console.log(this.listTipoPubli)
      if(this.tipoPublicacion=="LIBROS"){
        this.filtrosForm.get("txtTipoPublicacion").setValue("Libros");
      }

      if(this.tipoPublicacion=="REVISTAS"){
        this.filtrosForm.get("txtTipoPublicacion").setValue("Revistas");
      }
    });
    //this.buscarLibros();
  }



  keyword = 'name';
  keyword2 = 'masterName';
  data = [
     {
       id: 1,
       name: 'Usa'
     },
     {
       id: 2,
       name: 'England'
     }
  ];
 
 
  selectEvent(item) {
    // do something with selected item
    ////console.log("item",item.name);
  }
 
  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }
  ejecutarBusquedaTipoDoc(){
    console.log("ejecutando busqueda");
    this.utilitarioService.loading = true;
    this.catalogoResquest = new CatalogoRequest();

    
    this.listLibros = new CatalogoResponse();
    this.catalogoResquest.title = this.filtrosForm.get("txtTitulo").value;
    if(this.filtrosForm.get("txtAutor").value.id){
      this.catalogoResquest.authorId = this.filtrosForm.get("txtAutor").value.id;
    }
    if(this.filtrosForm.get("txtCategoria").value.id){
      this.catalogoResquest.categoryId = this.filtrosForm.get("txtCategoria").value.id;
    }
    if(this.filtrosForm.get("txtTipoPublicacion").value.masterId){
      this.catalogoResquest.publicationId = this.filtrosForm.get("txtTipoPublicacion").value.masterId;
    }
    if(this.tipoPublicacion=="LIBROS"){
      this.catalogoResquest.publicationId = "15";
    }

    if(this.tipoPublicacion=="REVISTAS"){
      this.catalogoResquest.publicationId = "16";
    }

    if(this.filtrosForm.get("txtAnoPublicacion").value.id){
      this.catalogoResquest.yearId = this.filtrosForm.get("txtAnoPublicacion").value.id;
    }


    this.novedadesService.buscarCatalogo(this.catalogoResquest).subscribe(data=>{
      this.listLibros=data;      
      //console.log(data);
      //console.log(this.listLibros.response.list.length);
      //console.log(this.listLibros.response.list);
      this.utilitarioService.loading = false;

    })

  }
  onFocused(e){
    // do something when input is focused
  }

   cambiarPagina(pageNumber){
    console.log(this.page);
    if(this.cargaPagina>1){
      console.log("aca",pageNumber);
      this.maestraService.guardarPaginacionLibros(pageNumber);
    }
          
   
  }
  // private cambiarPagina = (pageNumber) => {
    
  //   if(pageNumber){
  //     console.log("aca",pageNumber);
  //     this.maestraService.guardarPaginacionLibros(pageNumber);
  //   } 

  
  // }
  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {

      this.tipoPublicacion = params.get('idTipoPublicacion');   
      this.obtenerAutor();
      this.obtenerCategoria();
      this.obtenerAno();
      this.obtenerTipoPublicacion();
      
      if(this.tipoPublicacion=="LIBROS"){
        this.ejecutarBusquedaTipoDoc();
      }
      else if(this.tipoPublicacion=="REVISTAS"){
        this.ejecutarBusquedaTipoDoc();

      }
      else {
        this.buscarLibros();
      }
    })
    
   
  }


}
