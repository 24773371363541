import { AfterViewInit, Component, OnInit, Renderer2 } from '@angular/core';
import { ProcesarPagoService } from 'src/app/service/procesarpago/procesar-pago.service';
import { RespuestaPayme } from 'src/app/modal/procesapago/respuestaPayme';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilitarioService } from 'src/app/service/utilitarios/utilitario.service';
import { CarritoService } from 'src/app/service/carrito/carrito.service';

@Component({
  selector: 'app-resul-pago-payme',
  templateUrl: './resul-pago-payme.component.html',
  styleUrls: ['./resul-pago-payme.component.scss']
})
export class ResulPagoPaymeComponent implements AfterViewInit  {

  constructor(private procesarPagoService:ProcesarPagoService,
    private utilitarioService:UtilitarioService,
    private carritoService:CarritoService,
    private route : ActivatedRoute,
    private router : Router,
    private renderer: Renderer2) { }
    
  operationNumber:String;
  idaplicativo:String;
  autorizathionResult:String;
  tituloRespuesta:String;
  titulocuerpoRespuesta:String;
  show: boolean = true;
  respuestaPaymeResponse:RespuestaPayme = new RespuestaPayme();
  colorMensaje:String = "";
  borderCard:String = "";
  displayRespuesta:String = "none";

  ngAfterViewInit(): void {                

  }

  ngOnInit(): void {    
    
    this.route.paramMap.subscribe(params => {
      
      this.operationNumber = params.get('nrorden');
      this.idaplicativo = "2"; //codigo de aplicativo libreria virtual
      //console.log(this.operationNumber);

      this.procesarPagoService.obtenerRespuestaPayme(this.operationNumber.toString(),this.idaplicativo).subscribe(data=>{
        this.hideSpinner();
        //console.log(data);
        this.respuestaPaymeResponse = data;

        if(this.respuestaPaymeResponse.response.codeResponse=="0000"){

          this.autorizathionResult = this.respuestaPaymeResponse.response.list[0].authorizationResult;
          switch (this.autorizathionResult) {
            case "00":
              this.tituloRespuesta = "Su pago se ha realizado exitosamente.";              
              this.carritoService.limpiarCarrito();
              break;
            case "01":
              this.tituloRespuesta = "Su transacción ha sido denegada.";
              this.titulocuerpoRespuesta = "Si presenta alguna dificultad en su pago, puede comunicarse con nosotros al siguiente correo electrónico libreriavirtual@bnp.gob.pe";
              break;
            case "05":
              //this.utilitarioService.toastrInfo("Su transacción ha sido cancelado.");
              this.tituloRespuesta = "Su transacción ha sido cancelada.";
              this.titulocuerpoRespuesta = "Si presenta alguna dificultad en su pago, puede comunicarse con nosotros al siguiente correo electrónico libreriavirtual@bnp.gob.pe";
              //this.router.navigate(['/']);
              break;
            default:
              this.tituloRespuesta = "Ocurrio un inconveniente con el servicio de pago, puede comunicarse con nosotros al siguiente correo electrónico libreriavirtual@bnp.gob.pe"
              //console.log(this.operationNumber);
              //console.log(this.autorizathionResult);
              //this.router.navigate(['/']);
              break;
          }
    
          if(this.autorizathionResult=="00"){
            this.show = true;
            this.borderCard = "border-success";
            this.colorMensaje = "#1cb71c";
          }else{
            this.show = false;
            this.borderCard = "border-danger";
            this.colorMensaje = "red";
          }
                    
          //console.log(data);
        }else{
          this.tituloRespuesta = "No se ha efectuado la transación de su compra, puede comunicarse con nosotros al siguiente correo electrónico libreriavirtual@bnp.gob.pe"
          //console.log(data);          
          //this.utilitarioService.toastrInfo("No se ha efectuado la transación de su compra.");
          //this.router.navigate(['/']);
        }        
      });

    },
    (err:any) => {
      this.utilitarioService.toastrError("Ocurrio un inconveniente con el Servicio, vuelva a intentar el pago de su compra.");
      this.router.navigate(['/']);
      //console.log(err);
    });  

  }

  hideSpinner(){
    let loader = this.renderer.selectRootElement('#loader');
    this.renderer.setStyle(loader, 'display', 'none');
    this.displayRespuesta = "block";
  }

}

