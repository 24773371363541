export class UsuarioRegistroRequest {
     birthDate :  string  ;
     document :  string  ;
     email :  string  ;
     id :  string  ;
     name :  string  ;
     password :  string  ;
     photo :  string  ;
     provider :  string  ;
     surname :  string  ;
     telephone: string;
     typeDocument :  string 
     constructor(){
        this.birthDate = ""  ;
        this.document =  ""  ;
        this.email =  ""  ;
        this.id =  ""  ;
        this.name =  ""  ;
        this.password =  ""  ;
        this.photo =  ""  ;
        this.provider = ""  ;
        this.surname =  ""  ;
        this.telephone = "" ;
        this.typeDocument =  "" 
     }
}
