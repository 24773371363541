<mdb-card>
    <mdb-card-body>
        <div class="form-row">
            <div class="col">
                <!-- First name -->
                <div class="md-form mt-3">
                  <input type="text" id="txtorden" [(ngModel)]="txtOrden" maxlength="15" class="form-control" mdbInput>
                  <label for="txtorden">Nro.Orden</label>
                </div>
            </div>
            <div class="col">
                <div class="md-form mt-3">
                    <input type="date" id="txtFechaIni" [value]="fechaIniLoad" maxlength="10" [(ngModel)]="txtFechaIni" class="form-control" mdbInput>
                    <label for="txtFechaIni">Fecha Inicio</label>
                </div>
            </div>
            <div class="col">
              <!-- Last name -->
              <div class="md-form mt-3">
                <input type="date" id="txtFechaFin" [(ngModel)]="txtFechaFin" maxlength="10" class="form-control" mdbInput>
                <label for="txtFechaFin">Fecha Fin</label>
              </div>
            </div>            
        </div>
        <div class="form-row d-flex justify-content-center">
            <div class="form-group col-md-4">
            <div class="md-form mt-0 mb-0">
                <input type="text" id="txtnrodocumento" maxlength="15" [(ngModel)]="txtnrodocumento" class="form-control" mdbInput>
                <label for="txtnrodocumento">Nro Documento</label>
            </div>
            </div>
            <div class="form-group col-md-1">
                Estado:
            </div>
            <div class="form-group col-md-3">
                    <select class="browser-default custom-select" [(ngModel)]="cboEstado">
                    <option value="0" selected>Todos</option>
                    <option *ngFor="let estado of listEstado.response.list" [value]="estado.masterId">{{estado.masterDescription}}</option>
                    </select>
            </div>  
            <div class="form-group col-md-1">
              Sede:
          </div>
          <div class="form-group col-md-3">
                  <select class="browser-default custom-select" [(ngModel)]="cboSede">
                  <option value="0" selected>Todos</option>
                  <option *ngFor="let sede of listSede.response.list" [value]="sede.masterId">{{sede.masterName}}</option>
                  </select>
          </div>            
            <div class="form-group col-md-3">                
                    <button mdbBtn type="button" id="btnBuscar" (click)="onBuscar('buscar')" size="sm" class="btn btn-deep-purple btn-rounded" mdbWavesEffect>
                    <mdb-icon fas icon="search" class="mr-1"></mdb-icon>Buscar</button>                    
            </div>
        </div>
    </mdb-card-body>
  </mdb-card>
  <br>
  <div class="row">
    <div class="col-md-10" style="text-align: left;padding-bottom: 5px;font-weight: 500;font-size: 13px;">Total : {{listOrder.response.list.length}}</div>
    <div class="col-md-2" style="text-align: end;"> <a (click)="onExportarExcel()" style="text-align: right;font-weight: 500;font-size: 13px;">Exportar <mdb-icon far icon="file-excel" class="indigo-text" size="lg" aria-hidden="true"></mdb-icon></a></div>
  </div>
  <mdb-card>
    <div class="table-responsive text-nowrap">
    <table mdbTable hover="true">
        <thead>
          <tr>
            <th *ngFor="let head of headElements" scope="col">{{head}} </th>
          </tr>
        </thead>
        <tbody>
          <tr mdbTableCol *ngFor="let order of listOrder.response.list | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
            <th>{{order.number}}</th>
            <td>{{order.clientName}}</td>
            <td>{{order.clientNro}}</td>            
            <td>{{order.date}}</td>
            <td>{{order.total}}</td>
            <td>{{order.stateName}}</td>            
            <td><button type="button" (click)="onOpenOrdenDetalle(order)" color="deep-purple" outline="true" class="btn-sm" mdbWavesEffect mdbBtn><mdb-icon fas icon="book" size="lg"></mdb-icon></button></td>
            <td><div *ngIf="ocultarbuttonEstado(order.stateId)"><button type="button" (click)="onOpenCambioEstado(order)" color="deep-purple" outline="true" class="btn-sm" mdbWavesEffect mdbBtn><mdb-icon fas icon="sync" size="lg"></mdb-icon></button></div></td>
            <td>{{order.sedeName}}</td>
          </tr>
        </tbody>
      </table>
      </div>
        <div class="text-center">
          
          <ngb-pagination [maxSize]=3 [(page)]="page" class="d-flex justify-content-center"
            [pageSize]="pageSize" [boundaryLinks]="true" [collectionSize]="listOrder.response.list.length">
          </ngb-pagination>
          
        </div>
            
  </mdb-card>
  <br>