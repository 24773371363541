<div class="fondo align-items-center" style="display: flex;height: 100vh;">

    <div class="container">
    <mdb-card>
        <div class="row">
            <div class="col-6 loginleft" style="flex-direction: column;justify-content: center;">
                <!-- <div class="bzg-welcome-landing__left__square"></div>
                        <p style="text-align: center;" class="bzg-welcome-landing__top-title">Bienvenido</p>
                        <p class="bzg-welcome-landing__middle-title"></p>
                        <p style="text-align: center;" class="bzg-welcome-landing__bottom-title">Sistema <br> Libreria Virtual</p> -->
            </div>
            <div class="loginrigth col-6">
                <form >                         
                        <br>
                        <div style="width: 100%;text-align: center;" >
                            <div style="height: 55px;">                            
                            <a href="https://www.bnp.gob.pe/">
                                <img _ngcontent-kmt-c2="" src="assets/image/admin/logo.png" width="60px">            
                            </a>
                            </div>                                
                        </div>
                        <div>
                        <p style="font-size: 12px;line-height: 20px;text-align: center;padding-top: 5px;">BIBLIOTECA NACIONAL DEL PERÚ</p>    
                        </div>
                        <br>
                        <hr style="border-bottom: 1px solid #e0e0e0;margin-bottom: 15px;">
                        <br>
                        <h5>Iniciar Sesión</h5>
                        
                        <div class="md-form">
                            <mdb-icon fas icon="user" class="prefix"></mdb-icon>
                            <input mdbInput type="text" required id="txtLoginAdmin" [formControl]="txtUsuario" [(ngModel)]="txtLoginAdmin" class="form-control">
                            <label for="txtLoginAdmin">Usuario</label>
                            <mdb-error *ngIf="txtUsuario.invalid && (txtUsuario.dirty || txtUsuario.touched)">Ingresar usuario</mdb-error>
                        </div>

                        <div class="md-form">
                            <mdb-icon fas icon="key" class="prefix"></mdb-icon>
                            <input mdbInput type="password" required id="txtPasswordAdmin" [formControl]="txtclave" [(ngModel)]="txtPasswordAdmin" class="form-control">
                            <label for="txtPasswordAdmin">Clave</label>
                            <mdb-error *ngIf="txtclave.invalid && (txtclave.dirty || txtclave.touched)">Ingresar clave</mdb-error>
                        </div>
                        <br>
                        <button mdbBtn type="submit" (click)="onIngresar()" class="btn btn-rounded btn-yellow" block="true" mdbWavesEffect>Ingresar</button>
                        
                        <br>
                                                                                        
                </form>                      
            </div>
        </div>
    </mdb-card>
    </div>
</div>
