import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmarsiono',
  templateUrl: './confirmarsiono.component.html',
  styleUrls: ['./confirmarsiono.component.scss']
})
export class ConfirmarsionoComponent implements OnInit {

  constructor(    
    public dialogo: MatDialogRef<ConfirmarsionoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {mensaje: string}) { }
    
    cerrarDialogo(): void {
      this.dialogo.close(false);
    }
    confirmado(): void {
      this.dialogo.close(true);
    }

    ngOnInit() {
      
    }

}
