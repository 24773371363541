<div id="loader">
    <style>
      .application-loading-container {
        display: flex;
        position: absolute;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100vw;
        background-color: #000000e3;
        z-index: 1500;
        top:0px;
      }

      .application-loading-box {        
        text-align: center;
      }
    </style>
    <div class="application-loading-container">
      <div class="application-loading-box">
        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        <br>
        <span style="color: white;">Cargando...</span>
      </div>
    </div>
  </div>
<br>
<br>
<div class="container" [style.display]="displayRespuesta">
    <mdb-card [borderColor]="borderCard">
        <mdb-card-header>Mensaje de Operación</mdb-card-header>
        <mdb-card-body>
          <mdb-card-title>
            <h5 style="font-size: 16px;font-weight: 600;"><div [style.color]="colorMensaje" [innerHTML]="tituloRespuesta"></div></h5>            
            <div [style.color]="colorMensaje" style="font-size: 14px;font-weight: 700;" [innerHTML]="titulocuerpoRespuesta"></div>
          </mdb-card-title>
          <br>
          <div *ngIf="show">            
            <mdb-card-text>
                <div class="row">
                    <div class="col-3">Número de Operación :</div>
                    <div class="col">{{respuestaPaymeResponse.response.list[0].purchaseOperationNumber}}</div>
                </div>
                <div class="row">
                    <div class="col-3">Nombre de tarjeta Habiente :</div>
                    <div class="col">{{respuestaPaymeResponse.response.list[0].shippingFirstName}} {{respuestaPaymeResponse.response.list[0].shippingLastName}}</div>
                </div>
                <div class="row">
                    <div class="col-3">Número de tarjeta :</div>
                    <div class="col">{{respuestaPaymeResponse.response.list[0].paymentReferenceCode}}</div>
                </div>
                <div class="row">
                    <div class="col-3">Fecha y hora :</div>
                    <div class="col">{{respuestaPaymeResponse.response.list[0].datetime}}</div>
                </div>
                <div class="row">
                    <div class="col-3">Importe de transacción :</div>
                    <div class="col">{{respuestaPaymeResponse.response.list[0].purchaseAmount}}</div>
                </div>
                <div class="row">
                    <div class="col-3">Moneda :</div>
                    <div class="col">Soles</div>
                </div>
                <div class="row">
                    <div class="col-3">Producto :</div>
                    <div class="col">{{respuestaPaymeResponse.response.list[0].descriptionProducts}}</div>
                </div>
                <br>
                <div class="row">                                
                    <div class="col-sm-12">* Recuerde que también puede ver sus órdenes y el estado de su compra en Mi cuenta, opción Mis pedidos.</div>                
                </div>
            </mdb-card-text>
            <div class="row">
                <div class="col-sm-12" style="text-align: center;">                    
                </div>
            </div>
        </div>
        </mdb-card-body>
    </mdb-card>
</div>
<br>
<br>

