import { Component, OnInit } from '@angular/core';
import { MDBModalRef, MDBModalService} from 'angular-bootstrap-md';
import { UsuarioService } from 'src/app/service/usuario/usuario.service';
import { UtilitarioService } from 'src/app/service/utilitarios/utilitario.service';
import { MaestrasService } from 'src/app/service/maestras/maestras.service';
import { RespuestaComun } from 'src/app/modal/maestras/respuesta-comun';
import { MatDialog } from "@angular/material/dialog";

import { ConceptoPagoBean } from 'src/app/modal/conceptoPago/concepto-pago-bean';
import { InventProListAddBean } from 'src/app/modal/inventario-producto/inventarioProBean';

import { BusConceptosComponent } from 'src/app/pagina-admin/dashboard/bus-comunes/bus-conceptos/bus-conceptos.component';
import { AddInventarioComponent } from 'src/app/pagina-admin/dashboard/gestionProductos/mant-inventario-prod/dml-inventario-prod/add-inventario/add-inventario.component';
import { ConfirmarsionoComponent } from 'src/app/comunes/confirmarsiono/confirmarsiono.component';

import { ProductoService } from 'src/app/service/producto/producto.service';
import { ProductoControles } from 'src/app/modal/producto/productoBean';
import { InventarioProdService } from 'src/app/service/inventario-prod/inventario-prod.service';
import { InventarioProBeanInsRequest,InventarioProBeanUpdRequest,InventProSigaBeanUpdRequest } from 'src/app/modal/inventario-producto/inventarioProBean';

@Component({
  selector: 'app-dml-inventario-prod',
  templateUrl: './dml-inventario-prod.component.html',
  styleUrls: ['./dml-inventario-prod.component.scss']
})

export class DmlInventarioProdComponent implements OnInit {

  constructor(public  modalRef: MDBModalRef,
    private maestrasService: MaestrasService,
    private utilitarioService: UtilitarioService,
    private usuarioService: UsuarioService,    
    private inventarioProdService: InventarioProdService,
    private modalService: MDBModalService,
    private productoService:ProductoService,
    private dialogo: MatDialog,
    ) { }

  modalRefConceptoBus: MDBModalRef;
  modalRefAddInventory: MDBModalRef;

  listInventProAddBean:Array<InventProListAddBean> = [];

  listEstado:RespuestaComun = new RespuestaComun();
  productoControles:ProductoControles = new ProductoControles();
  cboEstado:String = "1";  
  
  txtinventoryCurrency: String = "";
  txtinventoryDescription: String = "";
  txtinventoryId: String = "";
  txtinventoryPrice: String = "";
  txtinventorySizeId: String = "";
  txtinventoryStock: String = "";
  txtinventoryStockOrigin: String = "";
  txtproductId: String = "";
  txtsigaId: String = "";
  txtsigaSecuencia: String = "";
  txtusuarioCrearId:String = "";

  old_txtinventoryCurrency: String = "";
  old_txtinventoryDescription: String = "";
  old_txtinventoryPrice: String = "";
  old_txtsigaId: String = "";
  old_txtsigaSecuencia: String = "";

  txtCodigoSigaSec: String = "";

  //variables enviado desde el padre
  id:String;
  x_titulo:String;
  x_proceso:String;
  x_titulo_libro:String;
  x_groupid:String;

  showTallaTable:boolean = false;
  estadoConceptoModificar:boolean = false;

  ngOnInit(): void {

    console.log(this.id);

    (<HTMLButtonElement>document.getElementById("btn_agregar_concepto")).style.display = "none";
    (<HTMLButtonElement>document.getElementById("btn_modificar_concepto")).style.display = "none";
    (<HTMLButtonElement>document.getElementById("btn_actualizar_concepto")).style.display = "none";
    (<HTMLButtonElement>document.getElementById("btn_cancelar_concepto")).style.display = "none";
    (<HTMLButtonElement>document.getElementById("btn_agregar_inventario")).style.display = "none";

    this.cargarDatoInicial(this.x_proceso);

  }

  onClosemodal(){
    this.modalRef.hide();
  }
  
  cargarDatoInicial(dml:String){

    this.utilitarioService.loading = true;    

    const cargarControles = this.productoService.obtenerListControles(this.x_groupid.toString()).toPromise()
    .then(result => {          
          this.productoControles = result.response.list[0];
    });

    if (dml=='I'){
      //carga los promesas culminadas para asignar los datos a las variables

      this.txtproductId = this.id;
      (<HTMLButtonElement>document.getElementById("btn_agregar_concepto")).style.display = "block";
      (<HTMLButtonElement>document.getElementById("btn_agregar_inventario")).style.display = "block";

      Promise.all([cargarControles])
      .then(response => {
        
        if (this.productoControles.showTalla==true){
          this.showTallaTable = true;
        }

        this.utilitarioService.loading = false;

      })
      .catch(error => console.log(`Error in executing ${error}`));
    }

    if (dml=='U'){

      (<HTMLButtonElement>document.getElementById("btn_modificar_concepto")).style.display = "block";
      
      Promise.all([cargarControles])    
      .then(response => {                      

        //obtiene datos del ultimo invetario para sacar los conpcetos de pago
        this.inventarioProdService.obtenerRegistro(this.id).subscribe(data=>{
          
          this.txtinventoryCurrency= data.response.list[0].inventoryCurrency;
          this.txtinventoryDescription= data.response.list[0].inventoryDescription;
          this.txtinventoryId= data.response.list[0].inventoryId;          
          this.txtinventoryPrice= data.response.list[0].inventoryPrice;
          this.txtsigaId= data.response.list[0].sigaId;
          this.txtsigaSecuencia = data.response.list[0].sigaSecuencia;
          this.txtproductId = data.response.list[0].productId;

          this.old_txtinventoryCurrency= data.response.list[0].inventoryCurrency;
          this.old_txtinventoryDescription= data.response.list[0].inventoryDescription;          
          this.old_txtinventoryPrice= data.response.list[0].inventoryPrice;
          this.old_txtsigaId= data.response.list[0].sigaId;
          this.old_txtsigaSecuencia = data.response.list[0].sigaSecuencia;          
          
          this.id = this.txtproductId; //reasigna a codigo de producto el codigo de inventario
          
          this.txtCodigoSigaSec = this.txtsigaId + '-' + this.old_txtsigaSecuencia;
          
          this.inventarioProdService.obtenerAdminAddList(this.txtproductId).subscribe(data=>{

            this.listInventProAddBean = data.response.list;            

            this.hideAgregarButton();
            this.utilitarioService.loading = false;

          });

        });        

      })
      .catch(error => console.log(`Error in executing ${error}`));
    }    

  }

  rellenarBean(inventarioProBeanInsRequest:InventarioProBeanInsRequest,inventarioProBeanUpdRequest:InventarioProBeanUpdRequest,dml:String){

    if (dml=='I'){

      inventarioProBeanInsRequest.enabled =this.cboEstado;
      inventarioProBeanInsRequest.inventoryCurrency=this.txtinventoryCurrency;
      inventarioProBeanInsRequest.inventoryDescription=this.txtinventoryDescription;
      inventarioProBeanInsRequest.inventoryId=this.txtinventoryId;
      inventarioProBeanInsRequest.inventoryPrice=this.txtinventoryPrice;
      inventarioProBeanInsRequest.inventorySizeId=this.txtinventorySizeId;
      inventarioProBeanInsRequest.inventoryStock=this.txtinventoryStock;
      inventarioProBeanInsRequest.inventoryStockOrigin=this.txtinventoryStockOrigin;
      inventarioProBeanInsRequest.productId=this.txtproductId;
      inventarioProBeanInsRequest.sigaId=this.txtsigaId;
      inventarioProBeanInsRequest.sigaSecuencia=this.txtsigaSecuencia;
      inventarioProBeanInsRequest.usuarioCrearId=this.usuarioService.obtenerIDSessionUsuarioAdmin().toString();

    }else{

      inventarioProBeanUpdRequest.enabled =this.cboEstado;
      inventarioProBeanUpdRequest.inventoryCurrency=this.txtinventoryCurrency;
      inventarioProBeanUpdRequest.inventoryDescription=this.txtinventoryDescription;
      inventarioProBeanUpdRequest.inventoryId=this.txtinventoryId;
      inventarioProBeanUpdRequest.inventoryPrice=this.txtinventoryPrice;
      inventarioProBeanUpdRequest.inventorySizeId=this.txtinventorySizeId;
      inventarioProBeanUpdRequest.inventoryStock=this.txtinventoryStock;
      inventarioProBeanUpdRequest.inventoryStockOrigin=this.txtinventoryStockOrigin;
      inventarioProBeanUpdRequest.productId=this.txtproductId;
      inventarioProBeanUpdRequest.sigaId=this.txtsigaId;
      inventarioProBeanUpdRequest.sigaConcepto=this.txtsigaSecuencia;
      inventarioProBeanUpdRequest.usuarioModificaId=this.usuarioService.obtenerIDSessionUsuarioAdmin().toString();
      
    }  

  }

  validarFormulario() : Boolean{

    //valida formulario
    if (this.usuarioService.obtenerIDSessionUsuarioAdmin().toString()==null){
      this.utilitarioService.toastrError("Su session ha culminado, ingrese nuevamente al sistema.");      
      return false;
    }        
  
    if (this.listInventProAddBean.length<1){
      this.utilitarioService.toastrInfo("Debe agregar un inventario como mínimo.");
      return false;
    }
    
    if(this.txtsigaId==""){
      this.utilitarioService.toastrInfo("Debe asociar el concepto de pago.");
      return false;
    }  
  
    /* if(this.txtOrder=="" && this.x_proceso == 'U'){
      this.utilitarioService.toastrInfo("Ingre orden de categoria.");
      return false;
    } */
  
    return true;
  
  }

  onAgregarConcepto(){
    this.modalRefConceptoBus = this.modalService.show(BusConceptosComponent,
      {
        backdrop: true,
        keyboard: true,
        focus: true,
        show: false,
        ignoreBackdropClick: false,
        class: 'modal-dialog modal-dialog-centered modal-dialog-scrollable modal-notify modal-warning modal-lg',
        containerClass: 'top',
        animated: true,
        data: {x_titulo:'Buscar Concepto de Pago',
               x_tipo_concepto:'PRODUCTO',}
      }
    );

    this.modalRefConceptoBus.content.action.subscribe( (result: ConceptoPagoBean) => 
    {

      this.old_txtinventoryCurrency= this.txtinventoryCurrency;
      this.old_txtinventoryDescription= this.txtinventoryDescription;
      this.old_txtinventoryPrice= this.txtinventoryPrice;
      this.old_txtsigaId = this.txtsigaId;
      this.old_txtsigaSecuencia= this.txtsigaSecuencia;

      this.txtsigaId = result.cincodigo;
      this.txtsigaSecuencia = result.idstpcodigo;
      this.txtinventoryPrice = result.cinimporte;
      this.txtinventoryDescription = result.sindescrip;
      this.txtinventoryCurrency = result.idmoncodigo;

      this.txtCodigoSigaSec = this.txtsigaId + '-' + this.txtsigaSecuencia;

    });

  }

  onAgregarInventario(){

    if(this.txtsigaId==""){
      this.utilitarioService.toastrInfo("Debe enlazar el concepto de pago.");
      return false;
    }

    let inventarioProBeanInsRequest = new InventarioProBeanInsRequest();
    let inventarioProBeanUpdRequest = new InventarioProBeanUpdRequest();    
    
    this.rellenarBean(inventarioProBeanInsRequest,inventarioProBeanUpdRequest,"I");    

    this.modalRefAddInventory = this.modalService.show(AddInventarioComponent,
      {
        backdrop: true,
        keyboard: true,
        focus: true,
        show: false,
        ignoreBackdropClick: false,
        class: 'modal-dialog modal-dialog-centered modal-dialog-scrollable modal-notify modal-warning',
        containerClass: 'top',
        animated: true,
        data: {
          id: this.id, //id de producto
          x_titulo:'Agregar Inventario',
          x_proceso:'I',
          x_titulo_libro:this.x_titulo_libro,
          x_groupid:this.x_groupid,
          inventarioProBeanUpdRequest: inventarioProBeanUpdRequest,
          inventarioProBeanInsRequest:inventarioProBeanInsRequest,
          listInventProAddBean : this.listInventProAddBean,
          }
      }
    );

    this.modalRefAddInventory.content.action.subscribe( (result: String) => 
    {

      if (result=="1") {

        (<HTMLButtonElement>document.getElementById("btn_agregar_concepto")).style.display = "none";
        (<HTMLButtonElement>document.getElementById("btn_cancelar_concepto")).style.display = "none";
        (<HTMLButtonElement>document.getElementById("btn_actualizar_concepto")).style.display = "none";
        (<HTMLButtonElement>document.getElementById("btn_modificar_concepto")).style.display = "block";                

        this.old_txtinventoryCurrency= this.txtinventoryCurrency;
        this.old_txtinventoryDescription= this.txtinventoryDescription;
        this.old_txtinventoryPrice= this.txtinventoryPrice;
        this.old_txtsigaId= this.txtsigaId;
        this.old_txtsigaSecuencia = this.txtsigaSecuencia;

        this.utilitarioService.loading = true;
        this.inventarioProdService.obtenerAdminAddList(this.id).subscribe(data=>{
          this.listInventProAddBean = data.response.list;
          this.hideAgregarButton();
          this.utilitarioService.loading = false;
        });

      }

    });

  }

  onEditarInventario(inventory_id:String){

    if (this.estadoConceptoModificar==true){
      this.utilitarioService.toastrWarning("Debe cancelar el cambio de concepto para realizar este proceso.");
      return false;
    }

    let inventarioProBeanInsRequest = new InventarioProBeanInsRequest();
    let inventarioProBeanUpdRequest = new InventarioProBeanUpdRequest();    
    
    this.rellenarBean(inventarioProBeanInsRequest,inventarioProBeanUpdRequest,"U");

    this.modalRefAddInventory = this.modalService.show(AddInventarioComponent,
      {
        backdrop: true,
        keyboard: true,
        focus: true,
        show: false,
        ignoreBackdropClick: false,
        class: 'modal-dialog modal-dialog-centered modal-dialog-scrollable modal-notify modal-warning',
        containerClass: 'top',
        animated: true,
        data: {
          id: inventory_id,
          x_titulo:'Modificar Inventario',
          x_proceso:'U',
          x_titulo_libro:this.x_titulo_libro,
          x_groupid:this.x_groupid,
          inventarioProBeanUpdRequest: inventarioProBeanUpdRequest,
          inventarioProBeanInsRequest:inventarioProBeanInsRequest,
          listInventProAddBean : this.listInventProAddBean,
          }
      }
    );

    this.modalRefAddInventory.content.action.subscribe( (result: String) => 
    {

      if (result=="1") {

        (<HTMLButtonElement>document.getElementById("btn_agregar_concepto")).style.display = "none";
        (<HTMLButtonElement>document.getElementById("btn_cancelar_concepto")).style.display = "none";
        (<HTMLButtonElement>document.getElementById("btn_actualizar_concepto")).style.display = "none";
        (<HTMLButtonElement>document.getElementById("btn_modificar_concepto")).style.display = "block";                

        this.utilitarioService.loading = true;
        this.inventarioProdService.obtenerAdminAddList(this.id).subscribe(data=>{          
          this.listInventProAddBean = data.response.list;          
          this.utilitarioService.loading = false;
        });

      }

    });

  }

  onEliminarInventario(inventory_id:String){

    if (this.estadoConceptoModificar==true){
      this.utilitarioService.toastrWarning("Debe cancelar el cambio de concepto para realizar este proceso.");
      return false;
    }

    this.dialogo.open(ConfirmarsionoComponent, {
      data: { mensaje: 'Desea eliminar el registro seleccionado?'},
      width: '550px'
    })
    .afterClosed()
    .subscribe((confirmado: Boolean) => {

      if (confirmado) {
        // validar con el servicio segun concepto
        this.inventarioProdService.delete(inventory_id).subscribe(data=>{
          console.log(data);
          if (data.response.codeResponse=="0000"){

            this.utilitarioService.toastrSuccess("Se ha eliminado el registro satisfactoriamente.");

            this.utilitarioService.loading = true;
            this.inventarioProdService.obtenerAdminAddList(this.id).subscribe(data=>{          
              this.listInventProAddBean = data.response.list;

              if (this.listInventProAddBean.length<1){ // if no hay inventarios activa enlace de conceptos
                
                (<HTMLButtonElement>document.getElementById("btn_agregar_concepto")).style.display = "block";        
                (<HTMLButtonElement>document.getElementById("btn_modificar_concepto")).style.display = "none";

              }

              this.hideAgregarButton();
              this.utilitarioService.loading = false;

            });

            (<HTMLButtonElement>document.getElementById("btnBuscar")).click();

          }else{
            this.utilitarioService.toastrError(data.response.response);
          }
    
          this.utilitarioService.loading = false;
    
        }); 
                       
      } else {
        return;
      }
    });        

  }

  onActualizarConceptos(){

    if (this.validarFormulario()==false){
        return;
    }

    let inventProSigaBeanUpdRequest = new InventProSigaBeanUpdRequest();
    inventProSigaBeanUpdRequest.product_id = this.id;
    inventProSigaBeanUpdRequest.siga_id= this.txtsigaId;
    inventProSigaBeanUpdRequest.siga_secuencia= this.txtsigaSecuencia;
    inventProSigaBeanUpdRequest.usuarioCrearId= this.usuarioService.obtenerIDSessionUsuarioAdmin().toString();

    this.dialogo.open(ConfirmarsionoComponent, {
      data: { mensaje: 'Desea actualizar el concepto de pago?'},
      width: '550px'
    })
    .afterClosed()
    .subscribe((confirmado: Boolean) => {
      if (confirmado) {
        // validar con el servicio segun concepto
        
        this.inventarioProdService.updateSigaConceptos(inventProSigaBeanUpdRequest,this.id).subscribe(data=>{
          console.log(data);
          this.estadoConceptoModificar=false;          
          
          if (data.response.codeResponse=="0000"){

            this.old_txtinventoryCurrency= this.txtinventoryCurrency;
            this.old_txtinventoryDescription= this.txtinventoryDescription;
            this.old_txtinventoryPrice= this.txtinventoryPrice;
            this.old_txtsigaId= this.txtsigaId;
            this.old_txtsigaSecuencia = this.txtsigaSecuencia;

            this.utilitarioService.toastrSuccess("Se ha actualizado satisfactoriamente.");
            
            (<HTMLButtonElement>document.getElementById("btn_agregar_concepto")).style.display = "none";
            (<HTMLButtonElement>document.getElementById("btn_cancelar_concepto")).style.display = "none";
            (<HTMLButtonElement>document.getElementById("btn_actualizar_concepto")).style.display = "none";
            (<HTMLButtonElement>document.getElementById("btn_modificar_concepto")).style.display = "block";

            if (this.showTallaTable==false && this.listInventProAddBean.length > 0){
              (<HTMLButtonElement>document.getElementById("btn_agregar_inventario")).style.display = "none";
            }else if(this.showTallaTable==false && this.listInventProAddBean.length < 1){
              (<HTMLButtonElement>document.getElementById("btn_agregar_inventario")).style.display = "block";
            }else if(this.showTallaTable==true){
              (<HTMLButtonElement>document.getElementById("btn_agregar_inventario")).style.display = "block";
            }

            (<HTMLButtonElement>document.getElementById("btnBuscar")).click();

          }else{
            this.utilitarioService.toastrError(data.response.response);
          }
          
          this.utilitarioService.loading = false;

        });
                
      } else {
        this.estadoConceptoModificar=false;
        return;
      }
    });
    
  }

  onModificarConcepto(){
    (<HTMLButtonElement>document.getElementById("btn_agregar_inventario")).style.display = "none";
    (<HTMLButtonElement>document.getElementById("btn_modificar_concepto")).style.display = "none";
    (<HTMLButtonElement>document.getElementById("btn_agregar_concepto")).style.display = "block";
    (<HTMLButtonElement>document.getElementById("btn_actualizar_concepto")).style.display = "block";
    (<HTMLButtonElement>document.getElementById("btn_cancelar_concepto")).style.display = "block";
    this.estadoConceptoModificar = true;
  }

  onCancelarConcepto(){
    
    (<HTMLButtonElement>document.getElementById("btn_modificar_concepto")).style.display = "block";
    (<HTMLButtonElement>document.getElementById("btn_agregar_concepto")).style.display = "none";
    (<HTMLButtonElement>document.getElementById("btn_actualizar_concepto")).style.display = "none";
    (<HTMLButtonElement>document.getElementById("btn_cancelar_concepto")).style.display = "none";

    this.estadoConceptoModificar = false;

    this.txtsigaId = this.old_txtsigaId;
    this.txtsigaSecuencia = this.old_txtsigaSecuencia;
    this.txtinventoryPrice = this.old_txtinventoryPrice;
    this.txtinventoryDescription = this.old_txtinventoryDescription;
    this.txtinventoryCurrency = this.old_txtinventoryCurrency;

    if (this.showTallaTable==false && this.listInventProAddBean.length > 0){
      (<HTMLButtonElement>document.getElementById("btn_agregar_inventario")).style.display = "none";
    }else if(this.showTallaTable==false && this.listInventProAddBean.length < 1){
      (<HTMLButtonElement>document.getElementById("btn_agregar_inventario")).style.display = "block";
    }else if(this.showTallaTable==true){
      (<HTMLButtonElement>document.getElementById("btn_agregar_inventario")).style.display = "block";
    }

  }

  hideAgregarButton(){
    
    if (this.estadoConceptoModificar==true){
      this.utilitarioService.toastrWarning("Debe cancelar el cambio de concepto para realizar este proceso.");
      return false;
    }

    if (this.productoControles.showTalla==true){
      this.showTallaTable = true;
      (<HTMLButtonElement>document.getElementById("btn_agregar_inventario")).style.display = "block";
    }else{
      //if not have talla and is > 0 then se hide agregar
      if (this.listInventProAddBean.length > 0){
        (<HTMLButtonElement>document.getElementById("btn_agregar_inventario")).style.display = "none";             
      }else{
        (<HTMLButtonElement>document.getElementById("btn_agregar_inventario")).style.display = "block";
      }
      
    }

  }

}
