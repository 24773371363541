export class SubmenuListBean{
    id: String;
    name: String;
    description: String;
    order: String;
    enabled: String;
    status: String;
    createdUserId: String;
    updatedUserid: String;
}

export class SubmenuIdBean{
    id: String;    
    name: String;
    description: String;
    order: String;
    enabled: String;
    status: String;
    createdUserId: String;
    updatedUserid: String;    
}

export class SubmenuBeanInsRequest{
    createdUserId: String;
    description: String;    
    id: String;
    name: String;
    order: String;    
}

export class SubmenuBeanUpdRequest{    
    createdUserId: String;
    description: String;
    enabled: String;
    id: String;
    name: String;
    order: String;
    status: String;    
    updatedUserid: String;
}