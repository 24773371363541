export class RespuestaPayme{

    
  httpStatus:number;
  response: {
    id:string,
    codeResponse:string,
    response:string,
    list: [{
      authorizationResult: string,
      authorizationCode: string,
      errorCode: string,
      errorMessage: string,
      bin: string,
      brand: string,
      paymentReferenceCode: string,
      acquirerId: string,
      idCommerce: string,
      purchaseOperationNumber: string,
      purchaseAmount: string,
      purchaseCurrencyCode: string,
      shippingFirstName: string,
      shippingLastName: string,
      shippingEmail: string,
      shippingAddress: string,
      shippingZIP: string,
      shippingCity: string,
      shippingState: string,
      shippingCountry: string,
      descriptionProducts: string,
      datetime:string
    }]
  }   
  
  constructor(){
    this.httpStatus=0;
    this.response={
        id:"",
        codeResponse:"",
        response:"",
        list:[{
          authorizationResult: "",
          authorizationCode: "",
          errorCode: "",
          errorMessage: "",
          bin: "",
          brand: "",
          paymentReferenceCode: "",
          acquirerId: "",
          idCommerce: "",
          purchaseOperationNumber: "",
          purchaseAmount: "",
          purchaseCurrencyCode: "",
          shippingFirstName: "",
          shippingLastName: "",
          shippingEmail: "",
          shippingAddress: "",
          shippingZIP: "",
          shippingCity: "",
          shippingState: "",
          shippingCountry: "",
          descriptionProducts: "",
          datetime:""
        }]
    }
  }
    
}