import { Component, OnInit } from '@angular/core';
import { UtilitarioService } from 'src/app/service/utilitarios/utilitario.service';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { MaestrasService } from 'src/app/service/maestras/maestras.service';
import { RespuestaComun } from 'src/app/modal/maestras/respuesta-comun';

import { SubmenuListResponse } from 'src/app/modal/submenu/submenuListResponse';
import { SubmenuService } from 'src/app/service/submenu/submenu.service';
import { DmlSubmenuComponent } from 'src/app/pagina-admin/dashboard/gestionProductos/mant-submenu/dml-submenu/dml-submenu.component';


@Component({
  selector: 'app-mant-submenu',
  templateUrl: './mant-submenu.component.html',
  styleUrls: ['./mant-submenu.component.scss']
})
export class MantSubmenuComponent implements OnInit {

  constructor(private submenuService:SubmenuService,
    private utilitarioService: UtilitarioService,
    private maestrasService: MaestrasService,
    private modalService: MDBModalService,
    ) { }

  txtdescripcion:String = "";
  cboEstado:String = "";
  modalRefAnioDml: MDBModalRef;
  listEstado:RespuestaComun = new RespuestaComun();
  headElements = ['Grupo de Regalo','Orden', 'Estado']; //quitar editado
  listSubmenu:SubmenuListResponse = new SubmenuListResponse();
  
  page = 1;
  pageSize = 9;

  ngOnInit(): void {
    this.cargarDatoInicial();
    this.onBuscar();
  }

  onBuscar(){
    this.utilitarioService.loading = true;
    this.submenuService.obtenerAdminList(this.txtdescripcion.toString(), this.cboEstado.toString()).subscribe(data=>{
      this.listSubmenu = data;
      console.log(data);
      this.utilitarioService.loading = false;
    })
  }

  onNuevo(id:String){
    this.modalRefAnioDml = this.modalService.show(DmlSubmenuComponent,
      {
        backdrop: true,
        keyboard: true,
        focus: true,
        show: false,
        ignoreBackdropClick: false,
        class: 'modal-dialog modal-dialog-centered modal-dialog-scrollable modal-notify modal-warning modal-lg',
        containerClass: 'top',
        animated: true,
        data: {
          id: id,
          x_titulo:'Nuevo Grupo de Regalo',
          x_proceso:'I',
        }
      }
    );
  }

  onEditar(id:String){
    this.modalRefAnioDml = this.modalService.show(DmlSubmenuComponent,
      {
        backdrop: true,
        keyboard: true,
        focus: true,
        show: false,
        ignoreBackdropClick: false,
        class: 'modal-dialog modal-dialog-centered modal-dialog-scrollable modal-notify modal-warning modal-lg',
        containerClass: 'top',
        animated: true,
        data: {
          id: id,
          x_titulo:'Editar Grupo de Regalo',
          x_proceso:'U',
        }
      }
    );
  }

  cargarDatoInicial(){

    this.utilitarioService.loading = true;
    this.listEstado = new RespuestaComun();
    this.maestrasService.obtenerMaestra("TIPO_STATUS_TABLA").subscribe(data=>{
      this.listEstado = data;      
      this.utilitarioService.loading = false;
    });

  }

}
