import { AutorAdminBeanUpdRequest } from 'src/app/modal/autor/autorAdminBean';
import { AnioAdminBeanUpdRequest } from 'src/app/modal/anio/anioAdminBean';

export class LibroBeanUpdRequest{
    authorsIds: Array<AutorAdminBeanUpdRequest>;
    editionsIds: Array<AnioAdminBeanUpdRequest>;
    categoryId: String;
    description: String;
    enabled: String;
    id: String;
    isbn: String;
    isnn: String;
    measure: String;
    novelty: String;
    order: String;
    pages: String;
    photo64: String;
    publicationId: String;
    publishedDate: String;
    state: String;
    subTitle: String;
    subject: String;
    title: String;
    usuarioModificaId: String;
    weight: String;    
}