import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { OrderPagoService } from "src/app/service/orderpago/order-pago.service";
import { OrderEstadoContResponse } from "src/app/modal/order-pago/orderEstadoContResponse";
import { OrderCambioEstadoRequest } from "src/app/modal/order-pago/orderCambioEstadoRequest";
import { UtilitarioService } from "src/app/service/utilitarios/utilitario.service";
import { UsuarioService } from 'src/app/service/usuario/usuario.service';

@Component({
  selector: 'app-cambio-estado',
  templateUrl: './cambio-estado.component.html',
  styleUrls: ['./cambio-estado.component.scss']
})

export class CambioEstadoComponent implements OnInit {

  constructor(public  modalRef: MDBModalRef,
    private orderPagoService:OrderPagoService,
    private utilitarioService:UtilitarioService,
    private usuarioService:UsuarioService) { }

  cboEstado:String;
  listEstado:OrderEstadoContResponse;
  txtObservacion:String;
  //variables enviado desde el padre
  idEstado:String;
  idOrden:String;
  nroOrden:String;  

  ngOnInit(): void {
    this.utilitarioService.loading=true;
    this.listEstado = new OrderEstadoContResponse();
    this.txtObservacion = " ";
    this.orderPagoService.obtenerEstadosContinuos(this.idEstado).subscribe(data=>{
      this.listEstado = data;
      this.cboEstado = this.listEstado.response.list[0].stateId;
      this.utilitarioService.loading=false;
    });
    
  }

  onClosemodal(){
    this.modalRef.hide();
  }

  onGrabar(){

    if (this.usuarioService.obtenerIDSessionUsuarioAdmin().toString()==null){
      this.utilitarioService.toastrError("Su session ha culminado, ingrese nuevamente al sistema.");      
      return;
    }

    this.utilitarioService.loading=true;

    let orderCambioEstadoRequest = new OrderCambioEstadoRequest();
    orderCambioEstadoRequest.stateActualId = this.idEstado.toString();
    orderCambioEstadoRequest.orderId = this.idOrden.toString();
    orderCambioEstadoRequest.observation = this.isnull(this.txtObservacion).toString();    
    orderCambioEstadoRequest.adminId = this.usuarioService.obtenerIDSessionUsuarioAdmin().toString();
    orderCambioEstadoRequest.stateNewId= this.cboEstado.toString();

    //console.log(orderCambioEstadoRequest);
    this.orderPagoService.grabarCambioEstados(orderCambioEstadoRequest).subscribe(data=>{
      //console.log(data);
      if (data.response.codeResponse=="0000"){
        this.utilitarioService.toastrSuccess("Se ha cambiado de estado satisfactoarimente.");        
        //actiualiza bandeja padre
        (<HTMLButtonElement>document.getElementById("btnBuscar")).click();
      }else{
        this.utilitarioService.toastrError(data.response.response);
      }
      this.utilitarioService.loading=false;
      this.onClosemodal();
    });

  }
  isnull(valor:String):String {
    if (valor==null){
      return "";
    }else{
      return valor.toString();
    }    
  }
}