import { Component, OnInit } from '@angular/core';
import {NovedadesResponse} from 'src/app/modal/catalogo/novedades-response';
import {NovedadesService} from 'src/app/service/catalogo/novedades.service';

@Component({
  selector: 'app-novedades',
  templateUrl: './novedades.component.html',
  styleUrls: ['./novedades.component.scss']
})
export class NovedadesComponent implements OnInit {
  novedades:NovedadesResponse;
  constructor(private novedadesService:NovedadesService) { }

  obtenerNovedades(){
    this.novedades = new NovedadesResponse();
    this.novedadesService.obtenerNovedades().subscribe(data=>{
      this.novedades = data;
    })
  }



  slides = [
    {img: "http://placehold.it/350x150/000000"},
    {img: "http://placehold.it/350x150/111111"},
    {img: "http://placehold.it/350x150/333333"},
    {img: "http://placehold.it/350x150/666666"},
    {img: "http://placehold.it/350x150/333333"},
    {img: "http://placehold.it/350x150/666666"}
  ];
  slideConfig = {"slidesToShow": 4, "slidesToScroll": 1,"responsive": [
    {
      'breakpoint': 600,
      'settings': {
        'slidesToShow': 1
              }
    }
      
    
  ]};
  
  addSlide() {
    this.slides.push({img: "http://placehold.it/350x150/777777"})
  }
  
  removeSlide() {
    this.slides.length = this.slides.length - 1;
  }
  
  slickInit(e) {
    //console.log('slick initialized');
  }
  
  breakpoint(e) {
    //console.log('breakpoint');
  }
  
  afterChange(e) {
    //console.log('afterChange');
  }
  
  beforeChange(e) {
    //console.log('beforeChange');
  }
  ngOnInit(): void {
    this.obtenerNovedades();
  }

}
