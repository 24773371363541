export class ProductoListBean{
    id: String;
    groupId: String;
    topicId: String;
    title: String;
    subtitle: String;
    description: String;
    paper: String;
    pages: String;
    grammage: String;
    measure: String;
    material: String;
    life: String;
    size: String;
    comments: String;
    quotation: String;
    weight: String;
    order: String;
    state: String;
    novelty: String;
    topicNovelty: String;
    routeImage: String;
    nameImage: String;
    extensionImage: String;
    enabled: String;
    status: String;
    groupName: String;
    submenuId: String;
    submenuName: String;
    createdUserId: String;    
    updatedUserid: String;    
}

export class ProductoIdBean{
    id: String;
    groupId: String;
    topicId: String;
    title: String;
    subtitle: String;
    description: String;
    paper: String;
    pages: String;
    grammage: String;
    measure: String;
    material: String;
    life: String;
    size: String;
    comments: String;
    quotation: String;
    weight: String;
    order: String;
    state: String;
    novelty: String;
    topicNovelty: String;
    routeImage: String;
    nameImage: String;
    extensionImage: String;
    enabled: String;
    status: String;
    createdUserId: String;
    updatedUserid: String;
    updatedDate: String;
    createdDate: String;
}

export class ProductoBeanInsRequest{
    comments: String;
    createdUserId: String;
    description: String;
    grammage: String;
    groupId: String;
    id: String;
    life: String;
    material: String;
    measure: String;
    novelty: String;
    order: String;
    pages: String;
    paper: String;
    photo64: String;
    quotation: String;
    size: String;
    state: String;
    subtitle: String;
    title: String;
    topicId: String;
    topicNovelty: String;
    weight: String;
}

export class ProductoBeanUpdRequest{    
    comments: String;
    description: String;
    enabled: String;
    grammage: String;
    groupId: String;
    id: String;
    life: String;
    material: String;
    measure: String;
    novelty: String;
    order: String;
    pages: String;
    paper: String;
    photo64: String;
    quotation: String;
    size: String;
    state: String;
    subtitle: String;
    title: String;
    topicId: String;
    topicNovelty: String;
    updatedUserId: String;
    weight: String;
}

export class ProductoControles{

    showProductId:Boolean;
    showProductTitulo:Boolean;
    showProductSubtitulo:Boolean;
    showProductDescripcion:Boolean;
    showMaterial:Boolean;
    showLibretaNroPaginas:Boolean;
    showLibretaPapel:Boolean;
    showLibretaGrosorPapel:Boolean;
    showProductUnidadesMeasure:Boolean;
    showProductTiempoVidaParaEdades:Boolean;
    showProductTamanioSize:Boolean;
    showProductPeso:Boolean;
    showProductComentario:Boolean;
    showEnabled: Boolean;
    showTalla: Boolean;

    constructor(){
        
    this.showProductId = false;
    this.showProductTitulo= true;
    this.showProductSubtitulo= false;
    this.showProductDescripcion= false;
    this.showMaterial= false;
    this.showLibretaNroPaginas= false;
    this.showLibretaPapel= false;
    this.showLibretaGrosorPapel= false;
    this.showProductUnidadesMeasure= false;
    this.showProductTiempoVidaParaEdades= false;
    this.showProductTamanioSize= false;
    this.showProductPeso= true;
    this.showProductComentario= false;
    this.showEnabled = true;
    this.showTalla = false;
    
    }

}