import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RespuestaComun } from 'src/app/modal/maestras/respuesta-comun';
import { MaestrasService } from 'src/app/service/maestras/maestras.service';
import { UtilitarioService } from 'src/app/service/utilitarios/utilitario.service';
import {UsuarioRegistroRequest} from 'src/app/modal/usuario/usuario-registro-request';
import { UsuarioService } from 'src/app/service/usuario/usuario.service';
import { UsuarioRequest } from 'src/app/modal/usuario/usuario-request';
import { Router } from '@angular/router';
import { UsuarioBeanResponse } from 'src/app/modal/usuario/usuario-bean-response';
import { DatePipe } from '@angular/common';
import {ActualizarDatosRequest} from 'src/app/modal/usuario/actualizar-datos-request';
@Component({
  selector: 'app-datos-personales',
  templateUrl: './datos-personales.component.html',
  styleUrls: ['./datos-personales.component.scss']
})
export class DatosPersonalesComponent implements OnInit {

  registrarForm: FormGroup;
  listTipoDocumento:RespuestaComun;
  registrarRequest:ActualizarDatosRequest;
  datosUsuario:UsuarioBeanResponse;
  maxLengthTxtNroDocumento:number=0;

  //@ViewChild(LoginComponent) loginComponent:LoginComponent;

  constructor(private routerLink:Router,private fb: FormBuilder,private maestraService:MaestrasService,private utilService:UtilitarioService,private usuarioService:UsuarioService) { 
  this.createForm();
  }


  createForm(){
    this.registrarForm = this.fb.group({
     
      txtCorreo:['',Validators.required],
      txtNombre:['',Validators.required],
      txtApellidos:['',Validators.required],
      txtTipoDocumento:['',Validators.required],
      txtNroDocumento:['',Validators.required],
      txtFechaNacimiento:['',Validators.required],
      txtTelefono:['',Validators.required],
      txtPasswordActual:['',],
      txtPassword:['',],
      txtPassword2:['',],

    });
  }
  displayFieldCss(field: string) {
    if (this.registrarForm.get(field).touched){
      return {
        'is-invalid': this.isFieldValid(field),
        'is-valid': !this.isFieldValid(field)
      };      
    }
  }    
  tipoDeDocumento(){
    this.listTipoDocumento = new RespuestaComun();
    this.maestraService.obtenerMaestra("TIPO_DOCUMENTO").subscribe(data=>{
      this.listTipoDocumento = data;
      //console.log(data);
    })
  }
  valirdarPass(){
    let retorno = true;
    let pass1 = this.registrarForm.get("txtPassword").value;
    let pass2 = this.registrarForm.get("txtPassword2").value;
    if(pass1===pass2){
      retorno = false;
    }
    return retorno;

  }
  formatearFecha(fecha){
    let fechaNac:string="";
    if(fecha!=null){
      var date = new Date(fecha+"T05:00:00Z");
      ////console.log(date);
      var day = date.getDate();
      var month = date.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
      var year = date.getFullYear();
      fechaNac = day+"/"+month+"/"+year;
    //console.log(fechaNac);
    }
    return fechaNac
  }

  actualizarUsuario(){  
    if (this.registrarForm.valid) {
      if(this.valirdarPass()){
        
        this.utilService.toastrError("Contraseñas Incorrectas");
       
        return false;
      }
      
      this.registrarRequest = new ActualizarDatosRequest();
      this.registrarRequest.birthDate = this.formatearFecha(this.registrarForm.get("txtFechaNacimiento").value);
      //this.registrarRequest.email = this.registrarForm.get("txtCorreo").value;
      this.registrarRequest.email = this.datosUsuario.response.list[0].email;
      this.registrarRequest.name = this.registrarForm.get("txtNombre").value;
      this.registrarRequest.surname = this.registrarForm.get("txtApellidos").value;
      this.registrarRequest.typeDocument = this.registrarForm.get("txtTipoDocumento").value;
      this.registrarRequest.passwordOld = this.registrarForm.get("txtPasswordActual").value;
      this.registrarRequest.passwordNew = this.registrarForm.get("txtPassword").value;
      this.registrarRequest.document = this.registrarForm.get("txtNroDocumento").value;
      this.registrarRequest.telephone = this.registrarForm.get("txtTelefono").value;
      
      if((this.registrarRequest.document.length > 8 ||  this.registrarRequest.document.length < 8) && this.registrarRequest.typeDocument=='1'){
        this.utilService.toastrError("El número de DNI debe ser de 8 dígitos");
        this.utilService.loading=false;
        return false;
      }

      if((this.registrarRequest.document.length > 15) && this.registrarRequest.typeDocument=='2'){
        this.utilService.toastrError("El número de Carnet de Extranjeria no puede ser mayor a 15 dígitos");
        this.utilService.loading=false;
        return false;
      }

      if((this.registrarRequest.document.length > 15) && this.registrarRequest.typeDocument=='3'){
        this.utilService.toastrError("El número de Pasaporte no puede ser mayor a 15 dígitos");
        this.utilService.loading=false;
        return false;
      }
      
      //console.log(this.registrarRequest);
      this.usuarioService.actualizarUsuario(this.usuarioService.obtenerIDSessionUsuario(),this.registrarRequest).subscribe(data=>{
        //console.log(data);
        if(data.response.codeResponse==="0000"){
          this.utilService.toastrSuccess(data.response.response);
          this.createForm();
          this.obtenerInformacion();
        }
        else{
          this.utilService.toastrError(data.response.response);
        }
      })
    }
    else{
      this.validateAllFormFields(this.registrarForm);
      this.utilService.toastrError("Rellene los campos obligatorios");
    }

  }

  fc_ValTextDocumento(event: any) { 
    
    switch (this.registrarForm.get("txtTipoDocumento").value) {
      case "1":case "2":      
        const pattern = /[0-9\+\-\ ]/;
        let inputChar = String.fromCharCode(event.charCode);
    
        if (!pattern.test(inputChar)) {
          // invalid character, prevent input
          return false;
          event.preventDefault();
        }
        break;
      case "3":case "4":
        return true;
        break;
      default:
        break;
    }
    
  }

  onChangeTipoDoc(codigo:String){
    switch (codigo) {
      case "1":
        this.maxLengthTxtNroDocumento = 8;        
        break;
      case "2":
        this.maxLengthTxtNroDocumento = 15;        
        break;
      case "3":
        this.maxLengthTxtNroDocumento = 15;                
        break;
      case "4":
        this.maxLengthTxtNroDocumento = 15;
        break;
      default:
        break;
    }
  } 

  isFieldValid(field: string) {
    return !this.registrarForm.get(field).valid && this.registrarForm.get(field).touched;
  }   

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  obtenerInformacion(){
    this.datosUsuario = new UsuarioBeanResponse();
    this.usuarioService.obtenerUser(this.usuarioService.obtenerIDSessionUsuario()).subscribe(data=>{
      this.datosUsuario = data;
      this.rellenarDatos();
    })
  }
  formateFechaSet(fecha){
    if(fecha){
    let dateA = fecha;
    var dateParts = dateA.split("/");
  
  // month is 0-based, that's why we need dataParts[1] - 1
    var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]); 
   
  
    return new DatePipe('en-US').transform(dateObject , 'yyyy-MM-dd')
    }
    else{
      return ""
    }
  }
  rellenarDatos(){

    this.registrarForm.get("txtCorreo").setValue(this.datosUsuario.response.list[0].email);
    this.registrarForm.get("txtNombre").setValue(this.datosUsuario.response.list[0].name);
    this.registrarForm.get("txtApellidos").setValue(this.datosUsuario.response.list[0].surname);    
    this.registrarForm.get("txtTipoDocumento").setValue(this.datosUsuario.response.list[0].typeDocumentId);
    this.registrarForm.get("txtNroDocumento").setValue(this.datosUsuario.response.list[0].document);
    this.registrarForm.get("txtTelefono").setValue(this.datosUsuario.response.list[0].telephone);
    this.registrarForm.get("txtFechaNacimiento").setValue(this.formateFechaSet(this.datosUsuario.response.list[0].birthDate));



  }
  ngOnInit(): void {
    this.tipoDeDocumento();
    this.obtenerInformacion();
  }

}
