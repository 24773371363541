import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders} from '@angular/common/http';

import { ProductoBeanInsRequest,ProductoBeanUpdRequest } from '../../modal/producto/productoBean';
import { ProductoListResponse } from '../../modal/producto/productoListResponse';
import { ProductoRegIdResponse } from '../../modal/producto/productoRegIdResponse';
import { ProductoDmlResponse } from '../../modal/producto/productoDmlResponse';

import { ProductoControlResponse } from '../../modal/producto/productoControlResponse';

@Injectable({
  providedIn: 'root'
})

export class ProductoService {

  constructor(private httpClient:HttpClient) { }

  obtenerAdminList(titulo:string,descripcion:string,estado:string,idTipoRegalo:string,idTemaProducto:string,isNovelty01:string,isTopicNovelty01:string){
    let url = `${environment.urlBase}${environment.listProductoAdmin}?titulo=${titulo}&descripcion=${descripcion}&estado=${estado}&idTipoRegalo=${idTipoRegalo}&idTemaProducto=${idTemaProducto}&isNovelty01=${isNovelty01}&isTopicNovelty01=${isTopicNovelty01}`;
    
    //console.log("url",url);
    return this.httpClient.get<ProductoListResponse>(url).pipe(
      map(data => {
        let response: ProductoListResponse;
        response = data;
        return response;
      },
      error =>{
        return new Response("9999", error);
      })) 
  }

  obtenerRegistro(id:String){
    let url = `${environment.urlBase}${environment.getProductoAdminId}/${id}`;
    //console.log("url",url);
    return this.httpClient.get<ProductoRegIdResponse>(url).pipe(
      map(data => {   
        let response: ProductoRegIdResponse;
        response = data;
        return response;
      },
      error =>{      
        return new Response("9999", error);
      })) 
  }

  grabar(productoBeanInsRequest:ProductoBeanInsRequest){
    let url = `${environment.urlBase}${environment.insertProductoAdmin}`;
    return this.httpClient.post<ProductoDmlResponse>(url,productoBeanInsRequest).pipe(
      map(data => {
        let response: ProductoDmlResponse;
        response = data;        

        return response;        
      },
      error =>{      
        return new Response("9999", error);
      }) 
    )
  }

  update(productoBeanUpdRequest:ProductoBeanUpdRequest,id:String){
    let url = `${environment.urlBase}${environment.updateProductoAdmin}/${id}`;
    return this.httpClient.put<ProductoDmlResponse>(url,productoBeanUpdRequest).pipe(
      map(data => {
        let response: ProductoDmlResponse;
        response = data;        
        return response;        
      },
      error =>{      
        return new Response("9999", error);
      }) 
    )
  }

  obtenerListControles(idGroup:string){
    let url = `${environment.urlBase}${environment.listProductoControles}?idGroup=${idGroup}`;
    //console.log("url",url);
    return this.httpClient.get<ProductoControlResponse>(url).pipe(
      map(data => {
        let response: ProductoControlResponse;
        response = data;
        return response;
      },
      error =>{
        return new Response("9999", error);
      })) 
  }
  
}
